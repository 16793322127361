/* istanbul ignore file */
import React from 'react';
import ShowDate from 'shared/uibuilder/ShowDate';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import TimelineRecordAuthor from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordAuthor';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import styles from '../CandidateTimeline.module.scss';

interface CandidateTimeLineHeaderProps {
  createdAtSource?: string;
  actionName?: string | ((entity: any) => string);
  timelineId?: string;
  entity?: any;
}

const CandidateTimelineRecordHeader: React.FC<CandidateTimeLineHeaderProps> = ({
  actionName,
  createdAtSource = null,
}) => {
  const { data: entity } = useShowContext();
  const createdAt = entity.getValueBySource(createdAtSource ?? 'createdAt');
  const createdById = entity.getValueBySource('createdById');
  const createdByName = entity.getValueBySource('createdByName');

  const resultActionName = typeof actionName === 'function' ? actionName(entity.getData()) : actionName;

  return (
    <>
      <div className={styles.candidate_timeline_header}>
        <div className={styles.employee_action}>
          <TimelineRecordAuthor id={createdById} name={createdByName} />
          {resultActionName}
        </div>
        <div className={styles.candidate_timeline_datetime}>
          <ShowDate dateUTC={createdAt} format={DATE_FORMAT.FULL} />
        </div>
      </div>
    </>
  );
};

export default CandidateTimelineRecordHeader;
