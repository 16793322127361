import React, { useEffect, useState } from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import style from './CandidateStepsStatuses.module.scss';
import { stepConfigurations } from 'erp/recruitment/recruitingBoard/constants';
import Stack from '@mui/material/Stack';
import IconMenu from 'erp/recruitment/recruitingBoard/shared/IconMenu';
import useRecruitingBoardService from 'erp/recruitment/recruitingBoard/useRecruitingBoardService';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { DEFAULT_ERROR_MESSAGE } from 'shared/uibuilder/form/formHelper';

const CandidateStepsStatuses = () => {
  const { data } = useShowContext();
  const { addMessage } = useMessageService();
  const candidateId = data.id;
  const vacancyAlias = data.lastAppliedVacancy;
  type AppInfo = {
    stage: string;
    activeStates: string[];
    tooltipData: any;
  };

  const [appInfo, setAppInfo] = useState<AppInfo | null>(null);

  const { searchCandidateApplicationsById } = useRecruitingBoardService();

  useEffect(() => {
    (async () => {
      if (data.lastAppliedVacancy) {
        try {
          // This getting data need to revamp on backend.
          // Create endpoint to get statuses of application of candidate.
          const boadData = await searchCandidateApplicationsById(data.id);
          const boardOfCandidateApplication = boadData.result.filter(
            (applicationItem: any) => applicationItem.alias === vacancyAlias,
          );
          const appStatusesOfCandidate = boardOfCandidateApplication[0].items.filter(
            (applicationItem: any) => applicationItem.candidateId === candidateId,
          );

          setAppInfo(() => {
            if (appStatusesOfCandidate.length === 0) {
              return [];
            } else {
              return appStatusesOfCandidate[0];
            }
          });
        } catch (e) {
          const result = await (e as any).json();
          addMessage(new ErrorMessage(result?.errors?.stateStep || DEFAULT_ERROR_MESSAGE));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (appInfo === null || !Object.prototype.hasOwnProperty.call(appInfo, 'stage')) {
    return '';
  }

  return (
    <Stack direction="column" gap="2px">
      {stepConfigurations[appInfo.stage]?.map(config => (
        <Stack direction="row" gap="2px" key={config.job}>
          <Stack direction="row" gap="5px" className={style.candidateIconParent}>
            <IconMenu
              key={config.job}
              icon={config?.icon}
              items={config?.states}
              tooltipData={appInfo.tooltipData}
              defaultKey={
                config?.states?.find(state => appInfo.activeStates.includes(state.key))?.key || config.states[0].key
              }
            />
            {config?.states?.find(state => appInfo.activeStates.includes(state.key))?.text || config.states[0].text}
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};

export default CandidateStepsStatuses;
