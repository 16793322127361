import React, { useEffect, useState } from 'react';
import IconButton from 'uibuilder/button/IconButton';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import AddLinkRoundedIcon from '@mui/icons-material/AddLinkRounded';
import Typography from 'uibuilder/Typography';
import CopyToClipboardButton from 'shared/uibuilder/CopyToClipboardButton';
import Box from 'uibuilder/Box';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import {
  SOURCE_NAME,
  SOURCE_NAME_OPTIONS,
  SYCOM_UI_URL,
} from 'erp/recruitment/extension/candidateFromExtensionService';
import { FormFieldsData } from '../../../../../shared/uibuilder/form/FormContext';
import EnumDropdown from '../../../../../shared/uibuilder/form/input/EnumDropdown';
import { TextInput } from 'shared/uibuilder/form/input';
import VacancyDropdown from '../../../../candidate/shared/input/VacancyDropdown';

const GenerateLinkForSycomForm = ({ isUsingFromVacancyPage }: { isUsingFromVacancyPage: boolean }) => {
  const { data: showData } = useShowContext();
  const { alias } = showData.getData();

  const [vacancyAlias, setVacancyAlias] = useState<string>(alias);
  const [sourceName, setSourceName] = useState<string>();
  const [customSourceName, setCustomSourceName] = useState<string>();

  const [applyVacancyLink, setApplyVacancyLink] = useState<string>();

  const rabotaByGeneratedLink = isUsingFromVacancyPage
    ? `${SYCOM_UI_URL}/careers/vacancy/apply/?id=${vacancyAlias}&sourceName=${SOURCE_NAME_OPTIONS[SOURCE_NAME.RABOTA_BY]}`
    : '';
  const jjitGeneratedLink = isUsingFromVacancyPage
    ? `${SYCOM_UI_URL}/careers/vacancy/apply/?id=${vacancyAlias}&sourceName=${SOURCE_NAME_OPTIONS[SOURCE_NAME.JJIT]}`
    : '';
  const pracaPlGeneratedLink = isUsingFromVacancyPage
    ? `${SYCOM_UI_URL}/careers/vacancy/apply/?id=${vacancyAlias}&sourceName=${SOURCE_NAME_OPTIONS[SOURCE_NAME.PRACA_PL]}`
    : '';

  useEffect(() => {
    if (sourceName && sourceName !== SOURCE_NAME.OTHER && vacancyAlias) {
      setApplyVacancyLink(
        `${SYCOM_UI_URL}/careers/vacancy/apply/?id=${vacancyAlias}&sourceName=${SOURCE_NAME_OPTIONS[sourceName]}`,
      );
    } else if (customSourceName && vacancyAlias) {
      setApplyVacancyLink(`${SYCOM_UI_URL}/careers/vacancy/apply/?id=${vacancyAlias}&sourceName=${customSourceName}`);
    } else {
      setApplyVacancyLink('');
    }
  }, [sourceName, customSourceName, vacancyAlias]);

  const onVacancyAliasChangeCallback = (values: FormFieldsData) => {
    setVacancyAlias(values.vacancyAlias);
  };

  const onSourceNameChangeCallback = (values: FormFieldsData) => {
    setSourceName(values.sourceName);
  };

  const onCustomSourceNameChangeCallback = (values: FormFieldsData) => {
    setCustomSourceName(values.customSourceName);
  };

  return (
    <>
      {isUsingFromVacancyPage ? <Typography variant="h4">Generated links (passive sourcing)</Typography> : <></>}
      {isUsingFromVacancyPage ? (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: '10px',
            marginBottom: '20px',
          }}
        >
          <Box
            sx={{
              width: '33%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <CopyToClipboardButton
              className="ml-1"
              id="copyRabotaByVacancyLink"
              value={rabotaByGeneratedLink}
              isIcon={false}
              sx={{ border: '0px', color: '#5954A8', fontSize: '15px', textDecoration: 'underline', padding: '0px' }}
              isCopyingFromModal
            >
              Rabota.by
            </CopyToClipboardButton>
            <CopyToClipboardButton
              className="ml-1"
              id="copyRabotaByVacancyLink"
              value={rabotaByGeneratedLink}
              isContentCopyIcon
              sx={{ color: '#5954A8', marginLeft: '5px' }}
              isCopyingFromModal
            >
              Copy Rabota.by link
            </CopyToClipboardButton>
          </Box>
          <Box
            sx={{
              width: '33%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <CopyToClipboardButton
              className="ml-1"
              id="copyJJiTVacancyLink"
              value={jjitGeneratedLink}
              isIcon={false}
              sx={{ border: '0px', color: '#5954A8', fontSize: '15px', textDecoration: 'underline', padding: '0px' }}
              isCopyingFromModal
            >
              JJIT
            </CopyToClipboardButton>
            <CopyToClipboardButton
              className="ml-1"
              id="copyJJiTVacancyLink"
              value={jjitGeneratedLink}
              isContentCopyIcon
              sx={{ color: '#5954A8', marginLeft: '5px' }}
              isCopyingFromModal
            >
              Copy JJiT link
            </CopyToClipboardButton>
          </Box>
          <Box
            sx={{
              width: '33%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              paddingRight: '2%',
            }}
          >
            <CopyToClipboardButton
              className="ml-1"
              id="copyPracaPlVacancyLink"
              value={pracaPlGeneratedLink}
              isIcon={false}
              sx={{ border: '0px', color: '#5954A8', fontSize: '15px', textDecoration: 'underline', padding: '0px' }}
              isCopyingFromModal
            >
              Praca.pl
            </CopyToClipboardButton>
            <CopyToClipboardButton
              className="ml-1"
              id="copyPracaPlVacancyLink"
              value={pracaPlGeneratedLink}
              isContentCopyIcon
              sx={{ color: '#5954A8', marginLeft: '5px' }}
              isCopyingFromModal
            >
              Copy Praca.pl link
            </CopyToClipboardButton>
          </Box>
        </Box>
      ) : (
        <></>
      )}
      <Box sx={{ marginTop: '10px' }}>
        <Typography variant="h4">Generate link</Typography>
      </Box>
      <Box sx={{ marginTop: '10px' }}>
        {!isUsingFromVacancyPage ? (
          <VacancyDropdown
            source="vacancyAlias"
            placeholder="Applied to vacancy"
            label=""
            onChangeCallback={onVacancyAliasChangeCallback}
            value={vacancyAlias}
          />
        ) : (
          <></>
        )}
        <EnumDropdown
          source="sourceName"
          placeholder="Source"
          options={SOURCE_NAME_OPTIONS}
          label=""
          onChangeCallback={onSourceNameChangeCallback}
          value={sourceName}
        />
        {sourceName && sourceName === SOURCE_NAME.OTHER ? (
          <TextInput
            source="customSourceName"
            label="Other source"
            value={customSourceName}
            onChangeCallback={onCustomSourceNameChangeCallback}
          />
        ) : (
          <></>
        )}
        <Box
          sx={{
            minHeight: '80px',
            width: '100%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
            border: '1px solid #828282',
            borderRadius: '4px',
          }}
        >
          {applyVacancyLink ? (
            [
              <Box key="copyButton" sx={{ position: 'absolute', right: '5px', top: '5px' }}>
                <CopyToClipboardButton
                  className="ml-1"
                  id="copyApplyVacancyLink"
                  value={applyVacancyLink}
                  isContentCopyIcon
                  sx={{ color: '#5954A8' }}
                  isCopyingFromModal
                >
                  Copy the link
                </CopyToClipboardButton>
              </Box>,
              <Box key="copyText" sx={{ display: 'flex', alignItems: 'center', padding: '20px' }}>
                <CopyToClipboardButton
                  className="ml-1"
                  id="copyApplyVacancyLink"
                  value={applyVacancyLink}
                  isIcon={false}
                  sx={{ border: '0px', color: '#5954A8' }}
                  isCopyingFromModal
                >
                  {applyVacancyLink}
                </CopyToClipboardButton>
              </Box>,
            ]
          ) : (
            <Typography sx={{ color: '#8E8E93' }} variant="body2">
              The link will be generated after selecting the vacancy and sourcing type
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

const GenerateLinkForSycomButton = ({ isUsingFromVacancyPage }: { isUsingFromVacancyPage: boolean }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <IconButton
        onClick={(e: React.MouseEvent<HTMLElement>) => {
          setModalOpen(true);
          (e.target as HTMLElement).blur();
        }}
      >
        <AddLinkRoundedIcon color="primary" fontSize="medium" />
      </IconButton>
      <ModalWindow backdrop="static" isOpen={modalOpen} title="Link generation" onToggle={closeModal}>
        <GenerateLinkForSycomForm isUsingFromVacancyPage={isUsingFromVacancyPage} />
      </ModalWindow>
    </>
  );
};

export default GenerateLinkForSycomButton;
