/* istanbul ignore file */
import React from 'react';
import Dropdown from 'shared/uibuilder/form/input/Dropdown';
import { DefaultDropdownProps } from 'shared/uibuilder/form/input/dropdownHelper';

export const LANGUAGES = [
  { name: 'RUSSIAN', short: 'RU' },
  { name: 'ENGLISH', short: 'EN' },
  { name: 'POLISH', short: 'PL' },
  { name: 'OTHER', short: 'UN' },
];

/**
 * Component is used to display dropdown with languages.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const NativeLanguageDropdown = (props: DefaultDropdownProps) => {
  return (
    <Dropdown
      {...props}
      placeholder="Select language"
      options={LANGUAGES.map(state => ({
        value: state.name,
        label: state.name,
      }))}
    />
  );
};

export default NativeLanguageDropdown;
