import React, { useEffect, useState } from 'react';
import { MAP_ENGLISH_LEVEL_TO_SHORT } from 'erp/candidate/candidateService';
import useSkillsService from 'erp/candidate/skills/skillsService';
import { INIT_COMPETENCY_LEVEL, CANDIDATE_TAGS, COMPETENCY_LEVEL } from 'erp/recruitment/newVacancy/constants';
import { CountryDropdown } from 'shared/uibuilder/form/input';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import { Filters } from 'shared/uibuilder/list/filter';
import ChipFilterLayout from 'shared/uibuilder/list/filter/layout/ChipFilterLayout/ChipFilterLayout';
import FilterAccordion from 'shared/uibuilder/list/filter/layout/ChipFilterLayout/FilterAccordion';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import SkillsInput from 'shared/uibuilder/skillsInput/SkillsInput';
import { SOURCING_FILTERS } from '../../useVacancySourcingService';
import Box from 'uibuilder/Box';
import Typography from 'uibuilder/Typography';
import AccordionCheckboxesFilter from 'shared/uibuilder/list/filter/layout/ChipFilterLayout/shared/AccordionCheckboxesFilter';
import RadioButtonsSourcingFilter from './RadioButtonsSourcingFilter';
import useChipFilterLayout, {
  ChipFilter,
} from 'shared/uibuilder/list/filter/layout/ChipFilterLayout/useChipFilterLayout';
import VerticalBarsIcon from 'uibuilder/AppIcon/Icons/VerticalBarsIcon';
import LocationIcon from 'uibuilder/AppIcon/Icons/LocationIcon';
import InlineEnglishIcon from 'uibuilder/AppIcon/Icons/InlineEnglishIcon';
import SkillsIcon from 'uibuilder/AppIcon/Icons/SkillsIcon';
import TagIcon from 'uibuilder/AppIcon/Icons/TagIcon';
import { BookmarkAddOutlined, Clear } from '@mui/icons-material';
import Button from 'uibuilder/button/Button';
import IconButton from 'uibuilder/button/IconButton';
import { useVacancyGroupId } from 'erp/recruitment/newVacancy/routing';
import useSavedCandidateFilterService from 'erp/recruitment/newVacancy/show/sourcing/useSavedCandidateFilterService';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';

type CompetencyTypes = keyof typeof COMPETENCY_LEVEL;

const levels = Object.values(INIT_COMPETENCY_LEVEL);
const tags = Object.values(CANDIDATE_TAGS);

const DEFAULT_FILTER = {
  [SOURCING_FILTERS.stricts]: [],
  [SOURCING_FILTERS.level]: [],
  [SOURCING_FILTERS.location]: [],
  [SOURCING_FILTERS.englishLevel]: [],
  [SOURCING_FILTERS.skills]: [],
  [SOURCING_FILTERS.tags]: [],
};

const SavedFilters = ({ savedFilters, fetchFilters }: { savedFilters: any; fetchFilters: () => void }) => {
  const { forceFilter } = useChipFilterLayout({ defaultFilter: {} });
  const { deleteById } = useSavedCandidateFilterService();

  return (
    <FilterAccordion Icon={<BookmarkAddOutlined />} title="Saved filters" source="">
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
        {savedFilters.map((filter: any) => (
          <Box key={filter.id}>
            <Button link style={{ minWidth: 0 }} onClick={() => forceFilter(filter.searchFields)}>
              {filter.name}
            </Button>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                deleteById(filter.id).then(fetchFilters);
              }}
            >
              <Clear sx={{ fontSize: 12 }} />
            </IconButton>
          </Box>
        ))}
      </Box>
    </FilterAccordion>
  );
};

const SaveFilterButton = ({ fetchFilters }: { fetchFilters: () => void }) => {
  const groupId = useVacancyGroupId();
  const { filters } = useFilterContext();
  const { create } = useSavedCandidateFilterService();
  const [isSaveMenuOpen, setIsSaveMenuOpen] = useState(false);

  return (
    <>
      <IconButton sx={{ p: 0 }} onClick={() => setIsSaveMenuOpen(true)}>
        <BookmarkAddOutlined />
      </IconButton>
      <ModalWindow isOpen={isSaveMenuOpen} onToggle={() => setIsSaveMenuOpen(false)}>
        <ModalForm
          closeModal={() => setIsSaveMenuOpen(false)}
          modalOpen={isSaveMenuOpen}
          submitFormFunc={async values =>
            create({
              ...values,
              groupId,
              searchFields: filters,
            })
          }
          afterSubmit={{ execute: fetchFilters }}
        >
          <TextInput label="You can save this filter for general use. Enter the name of the filter: " source="name" />
        </ModalForm>
      </ModalWindow>
    </>
  );
};

const SourcingCandidatesFilters = () => {
  const groupId = useVacancyGroupId();
  const { searchByName } = useSkillsService();
  const { loading, data = {} } = useListContext();
  const { getFiltersForGroup } = useSavedCandidateFilterService();

  const [savedFilters, setSavedFilters] = useState<any[]>([]);

  const fetchFilters = async () => {
    const fetchedFilters = await getFiltersForGroup(groupId);
    setSavedFilters(fetchedFilters);
  };

  useEffect(() => {
    fetchFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const displayedTotalElements = !loading && data.totalElements;

  const updateLevelRange = (value: string, filterState: ChipFilter) => {
    const currentLevels = filterState[SOURCING_FILTERS.level] || [];
    const isNA = value === INIT_COMPETENCY_LEVEL.N_A;

    if (isNA) return currentLevels.includes(value) ? [] : [value];
    if (currentLevels.includes(INIT_COMPETENCY_LEVEL.N_A)) return [value];

    if (currentLevels.includes(value)) {
      const filteredLevels = currentLevels.filter(level => level !== value);

      if (filteredLevels.length > 0) {
        return levels.slice(levels.indexOf(filteredLevels[0]), levels.indexOf(value) + 1);
      }

      return filteredLevels;
    }

    const newLevels = [...currentLevels, value].sort((a, b) => levels.indexOf(a) - levels.indexOf(b));
    return levels.slice(levels.indexOf(newLevels[0]), levels.indexOf(newLevels[newLevels.length - 1]) + 1);
  };

  return (
    <ShowSection>
      <Box>
        <Filters>
          <ChipFilterLayout
            defaultFilter={DEFAULT_FILTER}
            additionalButtons={<SaveFilterButton fetchFilters={fetchFilters} />}
          >
            <SavedFilters savedFilters={savedFilters} fetchFilters={fetchFilters} />
            <FilterAccordion
              Icon={<VerticalBarsIcon />}
              isSingleChip
              mapViewChipText={(values: CompetencyTypes[]) => {
                const firstLevel = COMPETENCY_LEVEL[values[0]];
                const lastLevel = values.length > 1 ? ` - ${COMPETENCY_LEVEL[values[values.length - 1]]}` : '';
                return `Level: ${firstLevel}${lastLevel}`;
              }}
              title="Level"
              mapValueForChange={updateLevelRange}
              source={SOURCING_FILTERS.level}
            >
              <AccordionCheckboxesFilter
                mapOption={(value: string) => (value === INIT_COMPETENCY_LEVEL.N_A ? COMPETENCY_LEVEL.N_A : value)}
                isCustomChange
                options={levels}
                source={SOURCING_FILTERS.level}
              />
            </FilterAccordion>

            <FilterAccordion
              Icon={<LocationIcon />}
              title="Location"
              source={SOURCING_FILTERS.location}
              mapValueForChange={value => value.location}
            >
              <CountryDropdown multiple source={SOURCING_FILTERS.location} label="" />
            </FilterAccordion>

            <FilterAccordion
              Icon={<InlineEnglishIcon />}
              isSingleChip
              title="Min English level"
              source={SOURCING_FILTERS.englishLevel}
              mapViewChipText={(values: string[]) => `Min english: ${MAP_ENGLISH_LEVEL_TO_SHORT[values[0]]}`}
              mapValueForChange={value => {
                return [value[SOURCING_FILTERS.englishLevel]];
              }}
            >
              <RadioButtonsSourcingFilter />
            </FilterAccordion>

            <FilterAccordion
              Icon={<SkillsIcon />}
              title="Skills"
              source={SOURCING_FILTERS.skills}
              mapValueForChange={value => value.skills}
            >
              <SkillsInput source={SOURCING_FILTERS.skills} label="" loadSuggestionsMethod={searchByName} />
            </FilterAccordion>

            <FilterAccordion Icon={<TagIcon />} title="Tags" source={SOURCING_FILTERS.tags}>
              <AccordionCheckboxesFilter options={tags} source={SOURCING_FILTERS.tags} />
            </FilterAccordion>
          </ChipFilterLayout>
        </Filters>
      </Box>

      <Typography sx={{ color: (theme: any) => theme.vars.palette.neutral.main }} variant="caption">
        {displayedTotalElements ? `Found candidates: ${displayedTotalElements}` : <>&nbsp;</>}
      </Typography>
    </ShowSection>
  );
};

export default SourcingCandidatesFilters;
