/* istanbul ignore file */
import EmailTimelineRecordLayout from 'erp/candidate/communication/email/EmailTimelineRecordLayout';
import { TimelineItem } from 'shared/uibuilder/timeline/timelineHelper';
import { SEND_EMAIL } from 'erp/candidate/candidateService';
import CreateCandidateEmailForm from 'erp/candidate/Timeline/communication/CreateCandidateEmailForm';

const useEmailCommunicationItem = () => ({
  AddForm: CreateCandidateEmailForm,
  isApplicable: (item: TimelineItem) => item,
  source: '',
  urlPostfix: 'email',
  RecordLayout: EmailTimelineRecordLayout,
  name: 'Communication',
  hasFullData: true,
  permissionToCheck: SEND_EMAIL,
});

export default useEmailCommunicationItem;
