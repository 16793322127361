import React from 'react';
import ListPageLayout from 'shared/uibuilder/list/layout/ListPageLayout';
import Board from '../shared/board/Board';
import { Filters, FiltersLayout } from 'shared/uibuilder/list/filter';
import EmployeeMultiSelectFilter from './filter/EmployeeMultiSelectFilter';
import CandidateMultiSelectFilter from './filter/CandidateMultiSelectFilter';
import VacancyGroupStatusFilter from './filter/VacancyGroupStatusFilter';
import VacancyGroupFilter from './filter/VacancyGroupFilter';
import Box from 'uibuilder/Box';
import GenerateLinkForSycomButton from 'erp/recruitment/newVacancy/shared/button/GenerateLinkForSycomButton';

const filters = (
  <Filters>
    <FiltersLayout>
      <VacancyGroupFilter source="specification:vacancyGroupByAlias" label="Vacancy Group" />
      <VacancyGroupStatusFilter source="specification:vacancyGroupByStatus" label="Vacancy Group Status" />
      <EmployeeMultiSelectFilter source="specification:vacancyGroupByDRI" label="DRI" />
      <EmployeeMultiSelectFilter source="specification:vacancyGroupBySourcer" label="Sourcer" />
      <EmployeeMultiSelectFilter source="specification:vacancyGroupByRecruiter" label="Recruiter" />
      <CandidateMultiSelectFilter source="specification:applicationByCandidateId" label="Candidate" />
    </FiltersLayout>
  </Filters>
);

const RecruitingBoardContent = () => {
  return (
    <Box sx={{ position: 'fixed', width: '-webkit-fill-available', maxWidth: '1654px' }}>
      <ListPageLayout
        sxPaper={{
          backgroundColor: 'transparent',
          padding: '0 !important',
        }}
        filter={filters}
        actions={[<GenerateLinkForSycomButton isUsingFromVacancyPage={false} />]}
      >
        <Board
          sx={{
            height: '-webkit-fill-available',
            position: 'fixed',
            width: '-webkit-fill-available',
            marginRight: '10px',
            marginBottom: '55px',
          }}
        />
      </ListPageLayout>
    </Box>
  );
};

export default RecruitingBoardContent;
