import React, { useCallback } from 'react';
import TextField from 'shared/uibuilder/field/TextField';
import AppIconLabel from 'uibuilder/layout/AppIconLabel';
import VacancyGroupSectionTitle from '../shared/VacancyGroupSectionTitle';
import { CommaListField } from 'shared/uibuilder/field';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import DateField from 'shared/uibuilder/field/DateField';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import EmployeesInput from 'financialAnalytic/corporateEvents/shared/input/EmployeesInput';
import useAuthorization from 'shared/authorization/authorizationService';
import { RECRUITING_VACANCY_GROUP_UPDATE } from 'erp/recruitment/newVacancy/constants';
import { RECRUITING_CONTEXT } from 'erp/recruitment/RecruitingContext';
import './VacancyGroupInfo.scss';
import IconSectionRow from 'uibuilder/layout/IconSectionRow';
import Box from 'uibuilder/Box';
import CountryBadge from 'shared/uibuilder/form/input/CountryBadge';
import { Checkbox } from 'shared/uibuilder/form/input';
import { useFormShowContext } from 'shared/uibuilder/FormShowContexts/FormShowContext';
import {
  SaveGroupButton,
  CloseEditGroupButton,
  EditGroupButton,
} from 'shared/uibuilder/FormShowContexts/buttons/EditSaveModeButtons';

const VacancyGroupInfo = () => {
  const { isGranted } = useAuthorization();
  const { isEditMode } = useFormShowContext();
  const { data: show } = useShowContext();
  const showData = show.getData();

  const renderActions = useCallback(() => {
    if (!isGranted(RECRUITING_VACANCY_GROUP_UPDATE, RECRUITING_CONTEXT)) {
      return null;
    }

    return isEditMode ? (
      <>
        <SaveGroupButton />
        <CloseEditGroupButton />
      </>
    ) : (
      <EditGroupButton />
    );
  }, [isGranted, isEditMode]);

  return (
    <ShowSection
      justifyContent="space-between"
      titleId="vacancy-group-title"
      title={data => <VacancyGroupSectionTitle data={data?.getData()} actions={renderActions()} isEdit={isEditMode} />}
    >
      <IconSectionRow>
        <TextField source="details.englishLevel" label={<AppIconLabel icon="english" tooltipTitle="English Level" />} />
        <Box display="flex" alignItems="center" sx={{ width: '100%' }}>
          <AppIconLabel icon="salary" tooltipTitle="Compensations" />
          {showData?.details?.compensations.length ? (
            showData.details.compensations.map((item: any) => (
              <Box display="flex" alignItems="center" gap={1} sx={{ width: '100%' }}>
                <CountryBadge
                  key={`registration-${item.registration}`}
                  country={item.registration}
                  sx={{ width: '20px', height: '20px' }}
                />
                <Box>
                  {item.compensationCurrencies.map((salary: any) => (
                    <TextField value={salary.value} width="25%" />
                  ))}
                </Box>
              </Box>
            ))
          ) : (
            <TextField />
          )}
        </Box>
        <DateField
          source="details.nearestTargetDate"
          label={<AppIconLabel icon="calendar" tooltipTitle="Nearest Target Date" />}
          dateFormat={DATE_FORMAT.FULL_DATE}
        />
        <TextField
          source="details.openPositionsCount"
          label={<AppIconLabel icon="baseline-people" tooltipTitle="Open Positions" />}
        />
        <CommaListField label={<AppIconLabel icon="work" tooltipTitle="Projects" />} source="details.projectNames" />
        {isEditMode
          ? [
              <EmployeesInput
                className="inputs-in-view"
                source="sourcers"
                label={<AppIconLabel icon="s-user" tooltipTitle="Sourcers" />}
                customMapper={(item: any) => ({
                  value: item.alias,
                  label: `${item.nameEn?.firstName ?? ''} ${item.nameEn?.lastName ?? ''}`,
                })}
              />,

              <EmployeesInput
                className="inputs-in-view"
                source="details.coordinators"
                label={<AppIconLabel icon="cs-user" tooltipTitle="Coordinators" />}
                customMapper={(item: any) => ({
                  value: item.alias,
                  label: `${item.nameEn?.firstName ?? ''} ${item.nameEn?.lastName ?? ''}`,
                })}
              />,

              <EmployeesInput
                className="inputs-in-view"
                source="recruiters"
                label={<AppIconLabel icon="r-user" tooltipTitle="Recruiters" />}
                customMapper={(item: any) => ({
                  value: item.alias,
                  label: `${item.nameEn?.firstName ?? ''} ${item.nameEn?.lastName ?? ''}`,
                })}
                defaultFilter={{ 'specification:isRecruiter': { eq: true } }}
              />,

              <Checkbox label="Hide candidates" source="isCandidatesHidden" />,
            ]
          : [
              <CommaListField
                label={<AppIconLabel icon="s-user" tooltipTitle="Sourcers" />}
                source="sourcers"
                isNeedJoin={false}
                mapValue={value => <EmployeeLinkFieldWithNameLoading label="" externalId={value} />}
              />,

              <CommaListField
                label={<AppIconLabel icon="cs-user" tooltipTitle="Coordinators" />}
                source="details.coordinators"
                isNeedJoin={false}
                mapValue={value => <EmployeeLinkFieldWithNameLoading label="" externalId={value} />}
              />,

              <CommaListField
                label={<AppIconLabel icon="r-user" tooltipTitle="Recruiters" />}
                source="recruiters"
                isNeedJoin={false}
                mapValue={value => <EmployeeLinkFieldWithNameLoading label="" externalId={value} />}
              />,

              <Checkbox label="Hide candidates" source="isCandidatesHidden" disabled />,
            ]}
      </IconSectionRow>
    </ShowSection>
  );
};

export default VacancyGroupInfo;
