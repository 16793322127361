/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import { ProtectedRouter } from 'routing';
import { Router, Route } from 'shared/routing';
import ExtensionPageLayout from 'erp/candidate/applications/createupdate/Create/layout/ExtensionPageLayout';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { CANDIDATE_APPLICATION_CREATE } from 'erp/candidate/applications/shared/candidateApplicationService';
import CandidateContextPage from 'erp/recruitment/extension/Show/CandidateContextPage';
import CreateForm from 'shared/uibuilder/form/CreateForm';

export const EXTENSION_PATH = '/extension';

const CandidateContextExtensionRouter: Router = () => {
  return (
    <ProtectedRouter basePath={EXTENSION_PATH}>
      <Routes>
        <Route
          path="/candidate/:linkedInId"
          element={
            <GlobalProtectedRoute globalPermissionToCheck={CANDIDATE_APPLICATION_CREATE}>
              <ExtensionPageLayout isWhiteLogo>
                <CreateForm submitFormFunc={(data: any) => data}>
                  <CandidateContextPage />
                </CreateForm>
              </ExtensionPageLayout>
            </GlobalProtectedRoute>
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

CandidateContextExtensionRouter.getRouterPath = () => `${EXTENSION_PATH}/*`;

export default CandidateContextExtensionRouter;
