import React, { useState } from 'react';
import NotificationAddOutlinedIcon from '@mui/icons-material/NotificationAddOutlined';
import Stack from '@mui/material/Stack';
import Tooltip from 'uibuilder/Tooltip';
import Typography from 'uibuilder/Typography';
import { useDateService, DATE_FORMAT } from 'shared/uibuilder/dateService';
import Box from 'uibuilder/Box';
import { IconButton } from '@mui/material';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import DateInput from 'shared/uibuilder/form/input/DateInput';
import TextArea from 'shared/uibuilder/form/input/TextArea';
import useEmployeesDashboardService from 'erp/employee/dashboard/useEmployeesDashboardService';
import { DashboardEmployee } from 'erp/employee/dashboard/types';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const FollowUpSummaryButton = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const { createFollowUp, getFollowUpValidation } = useEmployeesDashboardService();
  const { data, setData } = useShowContext();

  const { formatDate } = useDateService();

  const employeeDashboardData = (data?.getData() || {}) as DashboardEmployee;
  const { retentionRiskId: riskId = '', retentionRiskFollowUps: followUps = [] } = employeeDashboardData;

  const toggleModal = () => {
    setModalOpen(prev => !prev);
  };

  const tooltipContent = followUps.length ? (
    <Box>
      {followUps.map(followUp => (
        <Box mb={2} key={followUp.id}>
          <b>{formatDate(followUp.followUpDate, DATE_FORMAT.NATURAL)} - </b>
          {followUp.description}
        </Box>
      ))}
    </Box>
  ) : null;

  return (
    <>
      <Tooltip title={tooltipContent} placement="top">
        <IconButton>
          <Stack
            onClick={toggleModal}
            direction="row"
            alignItems="center"
            sx={{ color: 'var(--mui-palette-primary-main)' }}
          >
            <NotificationAddOutlinedIcon sx={{ fontSize: 14, mr: '5px' }} />
            {followUps?.length ? (
              <Typography variant="caption" color="var(--mui-palette-primary-main)">
                {formatDate(followUps[0]?.followUpDate, DATE_FORMAT.NATURAL)}
              </Typography>
            ) : null}
          </Stack>
        </IconButton>
      </Tooltip>

      <ModalWindow isOpen={modalOpen} onToggle={() => toggleModal()}>
        <ModalForm
          getValidationSchemaFunc={getFollowUpValidation}
          closeModal={() => toggleModal()}
          modalOpen={modalOpen}
          submitFormFunc={requestData => createFollowUp(riskId, requestData)}
          afterSubmit={{
            execute: response => {
              setData!({
                ...employeeDashboardData,
                retentionRiskFollowUps: [response, ...followUps].sort(
                  (a, b) => new Date(a.followUpDate).getTime() - new Date(b.followUpDate).getTime(),
                ),
              });
            },
          }}
        >
          <DateInput label="" placeholder="Reminder date" source="followUpDate" />
          <TextArea label="" placeholder="Text" source="description" />
        </ModalForm>
      </ModalWindow>
    </>
  );
};

export default FollowUpSummaryButton;
