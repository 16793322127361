import { useRecruitmentApi } from 'api';
import { ENGLISH_LEVEL_OPTIONS } from 'erp/candidate/candidateService';
import { cloneDeep } from 'lodash';
import useCrudService from 'shared/crud';
import { LoadingParams } from 'shared/uibuilder/list/ListContext';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { COMPETENCY_LEVEL } from '../../constants';

const SOURCING_API_PATH = '/candidates/specialization';

export const SOURCING_FILTERS = {
  stricts: 'stricts',
  level: 'qualificationLevel',
  location: 'location',
  englishLevel: 'englishLevel',
  skills: 'skills',
  tags: 'tags',
} as const;

export const STRICTS = {
  STRICT: 'STRICT',
  NON_STRICT: 'NON_STRICT',
} as const;

const useVacancySourcingService = () => {
  const { data: show } = useShowContext();
  const vacancyGroupData = show.getData();

  const { search: baseSearch, ...baseCrudRequests } = useCrudService({
    listResourceUrl: SOURCING_API_PATH,
    singleResourceUrl: `${SOURCING_API_PATH}/id`,
    apiService: useRecruitmentApi,
  });

  const search = async (request: LoadingParams) => {
    const requestData = cloneDeep(request);
    const allEnglishLevels = Object.values(ENGLISH_LEVEL_OPTIONS);
    const filtres = requestData?.filter;

    const response = await baseSearch({
      pageNumber: requestData.pageNumber || 0,
      pageSize: requestData.pageSize || 30,
      fields: {
        specialization: vacancyGroupData.specialization,
        ...Object.entries(filtres || {})
          .filter(([key]) => key !== SOURCING_FILTERS.stricts)
          .reduce((acc: { [key: string]: any }, [key, value]) => {
            acc[key] = {
              value:
                key === SOURCING_FILTERS.englishLevel
                  ? allEnglishLevels.slice(allEnglishLevels.indexOf(value[0]))
                  : value,
              mode: requestData?.filter?.stricts?.includes(key) ? STRICTS.STRICT : STRICTS.NON_STRICT,
            };
            return acc;
          }, {}),
      },
    });

    return {
      ...response,
      result: response.result.map((item: any) => {
        const currentSpecialization = item.specializations.find(
          (specialization: any) => specialization.specialization === vacancyGroupData.specialization,
        );

        return {
          ...item,
          specialization: currentSpecialization.specialization,
          level: COMPETENCY_LEVEL[currentSpecialization.qualificationLevel as keyof typeof COMPETENCY_LEVEL],
        };
      }),
    };
  };

  return {
    search,
    ...baseCrudRequests,
  };
};

export default useVacancySourcingService;
