/* istanbul ignore file */

import CreatePresaleOutputForm from 'crm/opportunity/presale/output/form/CreatePresaleOutputForm';
import UpdatePresaleOutputForm from 'crm/opportunity/presale/output/form/UpdatePresaleOutputForm';
import PresaleOutputTimelineRecordLayout from 'crm/opportunity/presale/output/PresaleOutputTimelineRecordLayout';
import { CREATE_PRESALE_OUTPUT, PRESALE_ITEMS_TYPE } from 'crm/opportunity/shared/opportunityService';
import Data from 'shared/uibuilder/Data';
import { TimelineItem } from 'shared/uibuilder/timeline/timelineHelper';

const usePresaleOutputItem = () => ({
  AddForm: CreatePresaleOutputForm,
  UpdateForm: UpdatePresaleOutputForm,
  isApplicable: (item: any) => item?.presaleOutput,
  source: 'presaleOutput',
  urlPostfix: PRESALE_ITEMS_TYPE.PRESALE_OUTPUT,
  RecordLayout: PresaleOutputTimelineRecordLayout,
  name: 'Presale Output',
  permissionToCheck: CREATE_PRESALE_OUTPUT,
  additionalAfterAddSubmit: (item: TimelineItem, parentContext?: Data) => {
    const oppState = parentContext?.getValueBySource('stateStep');
    if (oppState === 'PRESALE') {
      window.location.reload(); // reload page for fetch actual state
    }
  },
});

export default usePresaleOutputItem;
