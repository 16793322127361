import DOMPurify from 'dompurify';
import { marked } from 'marked';
import Mustache from 'mustache';
import { PlaceholderValues } from 'shared/markdownEditor/MarkdownTypes';

const CONDITIONAL_BLOCK_REGEX = /\{\{\s*#([a-zA-Z0-9_]+)\s*\}\}([\s\S]*?)\{\{\s*\/\1\s*\}\}/g;
const SIMPLE_PLACEHOLDER_REGEX = /\{\{\s*([a-zA-Z0-9_]+)\s*\}\}/g;

Mustache.templateCache = undefined;

export const replacePlaceholders = (text: string, values: PlaceholderValues): string => {
  try {
    return Mustache.render(text, values);
  } catch (e) {
    let processedText = text.replace(CONDITIONAL_BLOCK_REGEX, (match, key, content) => {
      return key === 'compensationDetails' ? '' : match;
    });

    processedText = processedText.replace(SIMPLE_PLACEHOLDER_REGEX, (match, key) => {
      return values[key] !== undefined ? String(values[key]) : '';
    });

    return processedText;
  }
};

export const markdownToHtml = (markdown: string): string => {
  marked.setOptions({
    breaks: true,
    gfm: true,
    headerIds: true,
    mangle: false,
    sanitize: false,
  });

  const html = marked(markdown);

  return DOMPurify.sanitize(html, {
    ADD_ATTR: ['target'],
    ALLOW_UNKNOWN_PROTOCOLS: true,
  });
};

export const calculateAcceptanceDate = (today: any) => {
  let date = today;
  let businessDaysToAdd = 2;

  while (businessDaysToAdd > 0) {
    date = date.add(1, 'days');
    if (date.day() !== 0 && date.day() !== 6) {
      businessDaysToAdd -= 1;
    }
  }
  return date.format('MMMM D, YYYY');
};
