import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/AddOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Menu } from '@mui/material';
import React, { ReactElement } from 'react';

type AddNewActivityButtonMenuProps = {
  children: ReactElement | ReactElement[];
};

const AddNewActivityButtonMenu = ({ children }: AddNewActivityButtonMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        variant="contained"
        onClick={handleClick}
        startIcon={<AddIcon />}
        endIcon={<KeyboardArrowDownIcon />}
        aria-expanded={open ? 'true' : undefined}
      >
        New activity
      </Button>
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ '& .MuiPaper-root': { minWidth: 160 } }}
      >
        {children}
      </Menu>
    </Box>
  );
};
export default AddNewActivityButtonMenu;
