import { useEffect, useState } from 'react';
import useEmployeeService from 'erp/employee/employeeService';

interface EmployeeSummary {
  fullName: string;
  employeeAvatar: string;
  specialization: string;
  birthday: string;
  status: string;
  loading: boolean;
  error: string | null;
}

// eslint-disable-next-line import/prefer-default-export
export const useEmployeeSummary = (employee: any): EmployeeSummary => {
  const { getFullName } = useEmployeeService();
  const [summary, setSummary] = useState<EmployeeSummary>({
    fullName: '',
    employeeAvatar: '',
    specialization: '',
    birthday: '',
    status: '',
    loading: true,
    error: null,
  });

  useEffect(() => {
    const fetchEmployeeData = async () => {
      const fullName = getFullName(employee.nameEn);
      const employeeAvatar = employee.mainPhotoId;
      const specialization = `${employee.specializationInfo?.specialization} (${employee.specializationInfo?.competencyLevel})`;
      const birthday = employee.extendedInformation?.dateOfBirth;
      const status = employee.workingStatus;

      setSummary({
        fullName,
        employeeAvatar,
        specialization,
        birthday,
        status,
        loading: false,
        error: null,
      });
    };

    fetchEmployeeData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getFullName]);

  return summary;
};
