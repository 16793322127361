import React, { useEffect } from 'react';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import { Routes } from 'react-router-dom';
import { INTERVIEW_SCHEDULING_PATH, useInterviewSchedulingRoute, useInterviewSchedulingToken } from './routing';

const DAVINCI_CANDIDATE_PUBLIC = process.env.REACT_APP_DAVINCI_CANDIDATE_PUBLIC_FRONTEND_URL;

const RedirectPage = () => {
  const token = useInterviewSchedulingToken();
  useEffect(() => {
    window.location.replace(`${DAVINCI_CANDIDATE_PUBLIC}/interview-scheduling/${token}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

const PublicInterviewSchedulingRouter = () => {
  const { singleEntityRoute } = useInterviewSchedulingRoute();

  return (
    <Routes>
      <Route path={singleEntityRoute} element={<RedirectPage />} />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

PublicInterviewSchedulingRouter.getRouterPath = () => `${INTERVIEW_SCHEDULING_PATH}/*`;

export default PublicInterviewSchedulingRouter;
