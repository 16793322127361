import React, { useMemo } from 'react';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Box from 'uibuilder/Box';
import Typography from 'uibuilder/Typography';
import { useDateService } from 'shared/uibuilder/dateService';
import { getStatusByMonths, HEALTHCHECK_CONFIG } from './constants';
import { SxProps } from '@mui/system';
import { EMPLOYEE_ZONES_ALIASES } from '../../constants';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

interface HealthcheckStatusProps {
  iconSx?: SxProps;
  textSx?: SxProps;
  className?: string;
  style?: React.CSSProperties;
}

type HealthcheckStatusType = keyof typeof EMPLOYEE_ZONES_ALIASES;

const HealthcheckStatus: React.FC<HealthcheckStatusProps> = ({ iconSx, textSx, className, style }) => {
  const { getValue } = useFieldHelper({ source: 'retentionRiskLastHealthcheck' });
  const { getToday, getMomentDateObj, getMonthsBetween, getStringDurationBetweenTwoDates } = useDateService();

  const lastHealthcheckDate = getValue();

  const statusInfo = useMemo(() => {
    if (!lastHealthcheckDate) {
      return {
        text: '0d',
        statusType: EMPLOYEE_ZONES_ALIASES.SAFE as HealthcheckStatusType,
      };
    }

    const today = getToday();
    const lastCheckDate = getMomentDateObj(lastHealthcheckDate);

    if (lastCheckDate.isSame(today, 'day')) {
      return {
        text: '0d',
        statusType: EMPLOYEE_ZONES_ALIASES.SAFE as HealthcheckStatusType,
      };
    }

    const daysDiff = today.startOf('day').diff(lastCheckDate.startOf('day'), 'days');
    const monthsDiff = getMonthsBetween(today, lastCheckDate) || 0;

    const statusType = getStatusByMonths(monthsDiff);

    let text;
    if (daysDiff < 30) {
      text = `${daysDiff}d`;
    } else {
      const durationString = getStringDurationBetweenTwoDates(lastCheckDate, today);
      if (durationString) {
        text = durationString
          .replace(/ years?/g, 'y')
          .replace(/ months?/g, 'm')
          .replace(/ days?/g, 'd')
          .replace(/,\s/g, ' ')
          .trim();
      } else {
        text = '0d';
      }
    }

    return { text, statusType };
  }, [lastHealthcheckDate, getToday, getMomentDateObj, getMonthsBetween, getStringDurationBetweenTwoDates]);

  const { textColor } = HEALTHCHECK_CONFIG[statusInfo.statusType as keyof typeof EMPLOYEE_ZONES_ALIASES];

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      color={textColor}
      className={className}
      style={style}
      data-status={statusInfo.statusType}
    >
      <AccessTimeIcon sx={iconSx} />
      <Typography sx={textSx}>{statusInfo.text}</Typography>
    </Box>
  );
};

export default HealthcheckStatus;
