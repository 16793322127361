import useEntityUrl from 'shared/routing/useEntityUrl';
import useRoute from 'shared/routing/useRoute';

export const SALARY_FORKS_PATH = '/recruiting/salary-ranges';
export const SALARY_FORKS_HISTORY_PATH = '/recruiting/salary-ranges/history';
export const SHISTORY_PATH = '/history/:specializationId';

export const useSalaryForksPath = () => {
  const routers = useRoute({ listRoute: SALARY_FORKS_PATH });
  return {
    ...routers,
    historyRoute: SHISTORY_PATH,
  };
};

export const useSalaryForksUrl = () => {
  const { ...restUrls } = useEntityUrl({
    baseLink: SALARY_FORKS_PATH,
  });

  return {
    ...restUrls,
    historyRoute: SALARY_FORKS_HISTORY_PATH,
    getHistoryUrl: (specializationId: string) => `${SALARY_FORKS_HISTORY_PATH}/${specializationId}`,
  };
};
