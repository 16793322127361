import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Stack } from '@mui/material';
import AvatarSection from '../AvatarSection/AvatarSection';
import SkillsBadges from '../Badges/SkillsBadges/SkillsBadges';
import CandidateApplicationSectionWrapper from '../CandidateApplicationSectionWrapper/CandidateApplicationSectionWrapper';
import CandidateStepsStatuses from '../CandidateStepsStatuses/CandidateStepsStatuses';
import { CandidateEmailField, CandidatePhoneField, CandidateDuplicateOfField } from '../ContactField/ContactField';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const CandidateProfileShowFields = () => {
  const { data } = useShowContext();

  const sectionFields = [
    <AvatarSection />,
    <Stack direction="column" gap="0.5rem">
      <CandidateApplicationSectionWrapper />
      <CandidateStepsStatuses />
    </Stack>,
    <Stack direction="column" gap="0.5rem">
      <CandidateEmailField source="personalEmail" />
      <CandidateEmailField source="otherEmail" />
      <CandidatePhoneField source="personalPhoneNumber" />
      <CandidatePhoneField source="otherPhoneNumber" />
      {data.duplicateOf && data.duplicateOf.id ? (
        <CandidateDuplicateOfField
          duplicateOfId={data.duplicateOf.id}
          duplicateFullName={data.duplicateOf.name.fullName}
        />
      ) : null}
    </Stack>,
  ];

  return (
    <>
      {sectionFields.map((section, index) => {
        const sectionId = uuidv4();
        return (
          <Stack
            key={sectionId}
            sx={{
              borderBottom: '1px solid #D9D9D9',
              padding: `${index === 0 ? '0' : '0.5rem'} 0 0.5rem 0 !important`,
              margin: '0 !important',
            }}
          >
            {section}
          </Stack>
        );
      })}
      <SkillsBadges />
    </>
  );
};

export default CandidateProfileShowFields;
