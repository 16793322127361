import React from 'react';
import { SearchInput } from 'shared/uibuilder/form/input';
import useCandidateService from 'erp/candidate/candidateService';

export const buildCandidateAutoSuggestLabel = (candidate: any) =>
  !!candidate && `${candidate.name.fullName} (id: ${candidate.id})`;

const mapResponse = (candidate: any) => ({
  id: candidate.id,
  text: buildCandidateAutoSuggestLabel(candidate),
});

interface CandidateInputProps {
  mapResults?: (candidate: any) => void;
  source: string;
  label?: string;
  props?: any;
}

const CandidateInput = ({ mapResults = mapResponse, ...props }: CandidateInputProps) => {
  const { search } = useCandidateService();

  return (
    <SearchInput
      searchRequest={search}
      noResultMessage="No Candidates are found."
      mapResults={mapResults}
      // @ts-ignore
      buildAutoSuggestLabel={buildCandidateAutoSuggestLabel}
      {...props}
    />
  );
};

export default CandidateInput;
