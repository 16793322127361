import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';
import { Tooltip } from '@mui/material';
import CountryBadge from 'shared/uibuilder/form/input/CountryBadge';

interface CountryFlagFieldProps extends FieldProps {
  hasTooltip?: boolean;
}

const CountryFlagField = ({ source, hasTooltip = false, ...props }: CountryFlagFieldProps) => {
  const { getValue } = useFieldHelper({ source });
  const value = getValue();
  const flagComponent = <CountryBadge country={value} />;
  if (!flagComponent) {
    return null;
  }
  return hasTooltip ? (
    <Tooltip title={value}>
      <span>{flagComponent}</span>
    </Tooltip>
  ) : (
    flagComponent
  );
};

export default CountryFlagField;
