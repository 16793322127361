/* istanbul ignore file */
import React from 'react';
import BaseInputLayout, {
  COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  getBaseInputLayoutProps,
  getCommonInputProps,
} from './BaseInputLayout';
import AutosuggestComponent, { RenderSuggestionsContainerParams } from 'react-autosuggest';
import {
  AutosuggestionDropdownLayoutProps,
  AutosuggestionDropdownLayoutType,
  Suggestion,
} from 'shared/uibuilder/form/input/AutosuggestDropdown';
import Loader from 'shared/uibuilder/SmallLoader';
import Box from 'uibuilder/Box';

import './AutosuggestionDropdownLayout.scss';

export const renderSuggestion = (suggestion: Suggestion) => <div>{suggestion.text}</div>;

export const getSuggestionValue = (suggestion: Suggestion) => suggestion.text;

export const getSuggestionsWarning = (resultsPerPage: number) =>
  `Only ${resultsPerPage} results are shown. Please refine your search query`;

const AutosuggestionDropdownLayout: AutosuggestionDropdownLayoutType = ({
  suggestions,
  suggestionsLoaded,
  suggestionsTotalPages,
  onSuggestionsFetchRequested,
  onSuggestionsClearRequested,
  onSuggestionSelected,
  loading,
  noResultMessage,
  resultsPerPage,
  onChangeCallback,
  InputIcon,
  suggestionsPageRef,
  isInfinityLoading,
  ...otherProps
}: AutosuggestionDropdownLayoutProps) => {
  const props = {
    ...COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
    ...otherProps,
  };

  const renderSuggestionsContainer = ({ containerProps, children }: RenderSuggestionsContainerParams) => {
    const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
      const isBottom = scrollTop + clientHeight >= scrollHeight - 5;

      if (isBottom && suggestionsLoaded && suggestionsPageRef?.current < suggestionsTotalPages) {
        onSuggestionsFetchRequested({ value: inputProps.value || '', reason: 'input-changed', loadNextPage: true });
      }
    };

    return (
      <div {...containerProps} onScroll={isInfinityLoading ? handleScroll : () => {}}>
        {suggestions.length === 0 && suggestionsLoaded && (
          <div className="react-autosuggest__no-result">{noResultMessage}</div>
        )}
        {!isInfinityLoading && suggestionsTotalPages > 1 && (
          <div className="react-autosuggest__warning-message">{getSuggestionsWarning(resultsPerPage)}</div>
        )}
        {children}
      </div>
    );
  };

  const inputProps = getCommonInputProps(props);

  return (
    <BaseInputLayout {...getBaseInputLayoutProps(props)}>
      <Box className="position-relative">
        {InputIcon}
        {loading ? <Loader className="autosuggest__loader" /> : null}
        <AutosuggestComponent
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          renderSuggestionsContainer={renderSuggestionsContainer}
          onSuggestionSelected={onSuggestionSelected}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            ...inputProps,
            value: inputProps.value || '',
            onChange: onChangeCallback,
          }}
        />
      </Box>
    </BaseInputLayout>
  );
};

export default AutosuggestionDropdownLayout;
