import React, { useEffect, useState } from 'react';
import Box from 'uibuilder/Box';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import IssuesEmployeeModal from './IssuesEmployeeModal';
import Add from '@mui/icons-material/Add';
import { Button } from 'uibuilder/button';

const AddIssueButton = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [predefinedIssues, setPredefinedIssues] = useState<string[]>([]);
  const { data } = useShowContext();

  useEffect(() => {
    if (data?.issues) {
      setPredefinedIssues(data.issues);
    }
  }, [data]);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  // update with backend expected
  const handleSubmit = () => {
    toggleModal();
  };

  return (
    <Box>
      <Button sx={{ minWidth: 0, p: 0, fontSize: '9px' }} fullWidth={false} onClick={toggleModal}>
        <Add />
      </Button>

      <IssuesEmployeeModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        onSubmit={handleSubmit}
        predefinedIssues={predefinedIssues}
      />
    </Box>
  );
};

export default AddIssueButton;
