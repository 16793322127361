export default {
  specialization: {
    type: 'string',
    required: false,
    maxSize: 255,
  },
  id: {
    type: 'string',
    required: false,
    maxSize: 255,
  },
  l1: {
    type: 'object',
    required: true,
    properties: {
      bySalaryFromValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l1.bySalaryToValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        maxValue: {
          value: '$l1.bySalaryToValue',
          message: 'Min value cannot exceed max.',
        },
      },
      bySalaryToValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l1.bySalaryFromValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
          minValue: {
            value: '$l1.bySalaryFromValue',
            message: 'Max value cannot be below min.',
          },
        },
      },
      byCurrency: {
        type: 'string',
        required: false,
      },
      plSalaryFromValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l1.plSalaryFromValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        maxValue: {
          value: '$l1.plSalaryToValue',
          message: 'Min value cannot exceed max.',
        },
      },
      plSalaryToValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l1.plSalaryFromValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
          minValue: {
            value: '$l1.plSalaryFromValue',
            message: 'Max value cannot be below min.',
          },
        },
        plCurrency: {
          type: 'string',
          required: {
            value: true,
            when: {
              '$l1.plSalaryFromValue': {
                notEmpty: true,
              },
            },
          },
        },
      },
    },
  },
  l2: {
    type: 'object',
    required: true,
    properties: {
      bySalaryFromValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l2.bySalaryToValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        maxValue: {
          value: '$l2.bySalaryToValue',
          message: 'Min value cannot exceed max.',
        },
      },
      bySalaryToValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l2.bySalaryFromValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        minValue: {
          value: '$l2.bySalaryFromValue',
          message: 'Max value cannot be below min.',
        },
      },
      byCurrency: {
        type: 'string',
        required: {
          value: true,
          when: {
            '$l2.bySalaryFromValue': {
              notEmpty: true,
            },
          },
        },
      },
      plSalaryFromValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l2.plSalaryToValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        maxValue: {
          value: '$l2.plSalaryToValue',
          message: 'Min value cannot exceed max.',
        },
      },
      plSalaryToValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l2.plSalaryFromValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        minValue: {
          value: '$l2.plSalaryFromValue',
          message: 'Max value cannot be below min.',
        },
      },
      plCurrency: {
        type: 'string',
        required: {
          value: true,
          when: {
            '$l2.plSalaryFromValue': {
              notEmpty: true,
            },
          },
        },
      },
    },
  },
  l3: {
    type: 'object',
    required: true,
    properties: {
      bySalaryFromValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l3.bySalaryToValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        maxValue: {
          value: '$l3.bySalaryToValue',
          message: 'Min value cannot exceed max.',
        },
      },
      bySalaryToValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l3.bySalaryFromValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        minValue: {
          value: '$l3.bySalaryFromValue',
          message: 'Max value cannot be below min.',
        },
      },
      byCurrency: {
        type: 'string',
        required: {
          value: true,
          when: {
            '$l3.bySalaryFromValue': {
              notEmpty: true,
            },
          },
        },
      },
      plSalaryFromValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l3.plSalaryToValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        maxValue: {
          value: '$l3.plSalaryToValue',
          message: 'Min value cannot exceed max.',
        },
      },
      plSalaryToValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l3.plSalaryFromValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        minValue: {
          value: '$l3.plSalaryFromValue',
          message: 'Max value cannot be below min.',
        },
      },
      plCurrency: {
        type: 'string',
        required: {
          value: true,
          when: {
            '$l3.plSalaryFromValue': {
              notEmpty: true,
            },
          },
        },
      },
    },
  },
  l4: {
    type: 'object',
    required: true,
    properties: {
      bySalaryFromValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l4.bySalaryToValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        maxValue: {
          value: '$l4.bySalaryToValue',
          message: 'Min value cannot exceed max.',
        },
      },
      bySalaryToValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l4.bySalaryFromValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        minValue: {
          value: '$l4.bySalaryFromValue',
          message: 'Max value cannot be below min.',
        },
      },
      byCurrency: {
        type: 'string',
        required: {
          value: true,
          when: {
            '$l4.bySalaryFromValue': {
              notEmpty: true,
            },
          },
        },
      },
      plSalaryFromValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l4.plSalaryToValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        maxValue: {
          value: '$l4.plSalaryToValue',
          message: 'Min value cannot exceed max.',
        },
      },
      plSalaryToValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l4.plSalaryFromValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        minValue: {
          value: '$l4.plSalaryFromValue',
          message: 'Max value cannot be below min.',
        },
      },
      plCurrency: {
        type: 'string',
        required: {
          value: true,
          when: {
            '$l4.plSalaryFromValue': {
              notEmpty: true,
            },
          },
        },
      },
    },
  },
  l5: {
    type: 'object',
    required: true,
    properties: {
      bySalaryFromValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l5.bySalaryToValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        maxValue: {
          value: '$l5.bySalaryToValue',
          message: 'Min value cannot exceed max.',
        },
      },
      bySalaryToValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l5.bySalaryFromValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        minValue: {
          value: '$l5.bySalaryFromValue',
          message: 'Max value cannot be below min.',
        },
      },
      byCurrency: {
        type: 'string',
        required: {
          value: true,
          when: {
            '$l5.bySalaryFromValue': {
              notEmpty: true,
            },
          },
        },
      },
      plSalaryFromValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l5.plSalaryToValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        maxValue: {
          value: '$l5.plSalaryToValue',
          message: 'Min value cannot exceed max.',
        },
      },
      plSalaryToValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l5.plSalaryFromValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        minValue: {
          value: '$l5.plSalaryFromValue',
          message: 'Max value cannot be below min.',
        },
      },
      plCurrency: {
        type: 'string',
        required: {
          value: true,
          when: {
            '$l5.plSalaryFromValue': {
              notEmpty: true,
            },
          },
        },
      },
    },
  },
  l6: {
    type: 'object',
    required: true,
    properties: {
      bySalaryFromValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l6.bySalaryToValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        maxValue: {
          value: '$l6.bySalaryToValue',
          message: 'Min value cannot exceed max.',
        },
      },
      bySalaryToValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l6.bySalaryFromValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        minValue: {
          value: '$l6.bySalaryFromValue',
          message: 'Max value cannot be below min.',
        },
      },
      byCurrency: {
        type: 'string',
        required: {
          value: true,
          when: {
            '$l6.bySalaryFromValue': {
              notEmpty: true,
            },
          },
        },
      },
      plSalaryFromValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l6.plSalaryToValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        maxValue: {
          value: '$l6.plSalaryToValue',
          message: 'Min value cannot exceed max.',
        },
      },
      plSalaryToValue: {
        type: 'number',
        required: {
          value: true,
          when: {
            '$l6.plSalaryFromValue': {
              notEmpty: true,
            },
          },
        },
        regex: {
          value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
          message: 'Invalid number.',
        },
        minValue: {
          value: '$l6.plSalaryFromValue',
          message: 'Max value cannot be below min.',
        },
      },
      plCurrency: {
        type: 'string',
        required: {
          value: true,
          when: {
            '$l6.plSalaryFromValue': {
              notEmpty: true,
            },
          },
        },
      },
    },
  },
};
