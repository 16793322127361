import useStorageService from 'shared/uibuilder/storageService';
import useBaseCrudService from 'shared/crud/baseCrudService';
import { useKernelApi } from 'api';

export type PositionType = {
  alias: string;
  createdAt: string;
  id: number;
  isLegacy: boolean;
  name: string;
  updatedAt: string;
  vacancyAlias: string;
};

const usePositionService = () => {
  const { findAll: findAllFromApi } = useBaseCrudService({
    singleResourceUrl: '/positions/:id',
    listResourceUrl: '/positions',
    apiService: useKernelApi,
  });

  const { getDataFromStorage } = useStorageService('positions', findAllFromApi, '1.17');

  const findPositionById = async (id: any): Promise<PositionType> => {
    const positions = await getDataFromStorage();
    return positions.find((position: { id: any }) => position.id === id) || {};
  };

  const findPositionByAlias = async (alias: any): Promise<PositionType> => {
    const positions = await getDataFromStorage();
    return positions.find((position: { alias: any }) => position.alias === alias) || {};
  };

  return {
    findAll: getDataFromStorage,
    findPositionById,
    findPositionByAlias,
  };
};

export default usePositionService;
