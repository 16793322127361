import React from 'react';
import { Typography, Paper } from '@mui/material';
import { SelectInput, DateInput, TextArea, Checkbox } from 'shared/uibuilder/form/input';
import CurrencyInput from 'shared/uibuilder/form/input/CurrencyInput';
import { Option as OptionType } from 'shared/uibuilder/form/input/dropdownHelper';
import FormRow from 'shared/layout/form/FormRow';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import FormSection from 'shared/layout/form/FormSection';
import FormInputGroup from 'shared/layout/form/FormInputGroup';
import CrmAttachmentUploaderInput from 'artifact/shared/input/CrmAttachmentUploaderInput';
import { useFormContext } from 'shared/uibuilder/form/FormContext';

export interface OpportunityChecklistData {
  expectedBusinessOutcomes?: string;
  perceivedPriority?: string;
  rates?: string;
  upperLimit?: string;
  mostExpected?: string;
  lowerLimit?: string;
  expectedStartDate?: string;
  expectedDeliveryDate?: string;
  timelinesBasis?: string;
  hasWorkedWithTeamBefore?: string;
  whatDidTheyDo?: string;
  theirRoleInProcess?: string;
  hasOwnEngineering?: string;
  whatDoTheyDo?: string;
  howInvolved?: string;
  techStackConstraints?: string;
  inputMaterials?: string[];
  firstCallRecording?: string[];
  noCallRecordingPossible?: boolean;
}

const getFieldLabel = (field: string): string => {
  const labels: Record<string, string> = {
    expectedBusinessOutcomes: 'Expected Business Outcomes',
    perceivedPriority: 'Perceived Priority',
    rates: 'Rates',
    upperLimit: 'Upper Limit',
    mostExpected: 'Most Expected',
    lowerLimit: 'Lower Limit',
    expectedStartDate: 'Expected Start Date',
    expectedDeliveryDate: 'Expected Delivery Date',
    timelinesBasis: 'What is it based on',
    hasWorkedWithTeamBefore: 'Has worked with team before',
    whatDidTheyDo: 'What did they do',
    theirRoleInProcess: 'Their role in process',
    hasOwnEngineering: 'Has own engineering',
    whatDoTheyDo: 'What do they do',
    howInvolved: 'How they will be involved',
    techStackConstraints: 'Tech stack constraints',
    noCallRecordingPossible: "It wasn't possible to record the call",
  };

  return labels[field] || field;
};

export const transformChecklistData = (data: OpportunityChecklistData) => {
  if (!data) return { content: '', attachments: [] };

  const attachments = [...(data.inputMaterials || []), ...(data.firstCallRecording || [])];

  const contentParts: string[] = [];

  Object.entries(data).forEach(([key, value]) => {
    if (key === 'inputMaterials' || key === 'firstCallRecording') return;

    if (!value) return;

    const label = getFieldLabel(key);
    contentParts.push(`<p><strong>${label}</strong>: ${value}</p>`);
  });

  return {
    content: contentParts.join('\n'),
    attachments,
  };
};

const priorityOptions: OptionType[] = [
  { value: 'timeline', label: 'Timeline' },
  { value: 'scope', label: 'Scope' },
];

const ratesOptions: OptionType[] = [
  { value: 'by', label: 'BY' },
  { value: 'eu', label: 'EU' },
];

const yesNoOptions: OptionType[] = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

interface OpportunityChecklistProps {
  source: string;
}

const OpportunityChecklist: React.FC<OpportunityChecklistProps> = ({ source }) => {
  const { data } = useFormContext();
  const getFieldSource = (field: string) => `${source}.${field}`;
  const getFieldValue = (field: string) => data?.[source]?.[field];

  return (
    <Paper elevation={2} sx={{ padding: 2, marginBottom: 2 }}>
      <Typography variant="h6" color="secondary">
        Opportunity Checklist:
      </Typography>

      <FormSection title="Motivation">
        <MediumFormRow>
          <TextArea
            label="Expected business outcomes from the service"
            source={getFieldSource('expectedBusinessOutcomes')}
            isRequired
          />
        </MediumFormRow>
        <FormRow>
          <SelectInput
            label="Perceived priority"
            source={getFieldSource('perceivedPriority')}
            options={priorityOptions}
            isRequired
          />
        </FormRow>
      </FormSection>

      <FormSection title="Budgets">
        <FormRow>
          <SelectInput label="Rates" source={getFieldSource('rates')} options={ratesOptions} isRequired />
        </FormRow>
        <FormRow>
          <CurrencyInput label="Upper limit" source={getFieldSource('upperLimit')} isRequired suffix="$" />
          <CurrencyInput label="Most expected" source={getFieldSource('mostExpected')} isRequired suffix="$" />
          <CurrencyInput label="Lower limit" source={getFieldSource('lowerLimit')} isRequired suffix="$" />
        </FormRow>
      </FormSection>

      <FormSection title="Timelines">
        <FormRow>
          <DateInput label="Expected start date" source={getFieldSource('expectedStartDate')} isRequired />
          <DateInput label="Expected delivery date" source={getFieldSource('expectedDeliveryDate')} isRequired />
        </FormRow>
        <MediumFormRow>
          <TextArea label="What is it based on" source={getFieldSource('timelinesBasis')} isRequired />
        </MediumFormRow>
      </FormSection>

      <FormSection title="Customer competency">
        <MediumFormRow>
          <FormInputGroup>
            <SelectInput
              label="Has customer worked with the customer software engineering team before?"
              source={getFieldSource('hasWorkedWithTeamBefore')}
              options={yesNoOptions}
              isRequired
            />

            <FormInputGroup isVisible={formData => formData?.[source]?.hasWorkedWithTeamBefore === 'yes'}>
              <TextArea label="What did they do?" source={getFieldSource('whatDidTheyDo')} isRequired />
              <TextArea
                label="What was their role in the process?"
                source={getFieldSource('theirRoleInProcess')}
                isRequired
              />
            </FormInputGroup>
          </FormInputGroup>
          <FormInputGroup>
            <SelectInput
              label="Does the customer have their own engineering or IT?"
              source={getFieldSource('hasOwnEngineering')}
              options={yesNoOptions}
              isRequired
            />
            <FormInputGroup isVisible={formData => formData?.[source]?.hasOwnEngineering === 'yes'}>
              <TextArea label="What do they do?" source={getFieldSource('whatDoTheyDo')} isRequired />
              <TextArea
                label="How they will be involved into the new project"
                source={getFieldSource('howInvolved')}
                isRequired
              />
            </FormInputGroup>
          </FormInputGroup>
        </MediumFormRow>

        <MediumFormRow>
          <TextArea
            label="Tech stack or cloud hosting constraints"
            source={getFieldSource('techStackConstraints')}
            isRequired
          />
        </MediumFormRow>
      </FormSection>

      <MediumFormRow>
        <FormSection title="Recording of the 1st call">
          <CrmAttachmentUploaderInput
            label="Recording of the 1st call with the customer"
            source={getFieldSource('firstCallRecording')}
            isRequired={!getFieldValue('noCallRecordingPossible')}
          />
          <Checkbox label="It wasn't possible to record the call" source={getFieldSource('noCallRecordingPossible')} />
        </FormSection>
        <FormSection title="Input materials">
          <CrmAttachmentUploaderInput
            label="Input materials (requirements, UI mockups, source code, etc.)"
            source={getFieldSource('inputMaterials')}
          />
        </FormSection>
      </MediumFormRow>
    </Paper>
  );
};

export default OpportunityChecklist;
