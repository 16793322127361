import React from 'react';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import ControlledList from 'shared/uibuilder/list/ControlledList';
import { CommaListField, DateField, TextField } from 'shared/uibuilder/field';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import CandidateLinkFieldWithName from 'erp/recruitment/newVacancy/shared/fields/CandidateLinkFieldWithName';
import CandidateLinkFieldWithNameWithCheckbox from '../shared/fields/CandidateLinkFieldWithNameWithCheckbox';
import Typography from 'uibuilder/Typography';

const CandidateDuplicateScanForm = () => {
  const { data } = useFormContext();

  const listFields = [
    <CommaListField label="Specializations" source="specializations" width="13%" />,
    <TextField label="Phone" source="personalPhoneNumber" width="10%" />,
    <TextField label="Email" source="personalEmail" width="10%" />,
    <TextField label="Location" source="residenceCountry" width="10%" />,
    <DateField label="Date of Birth" source="dateOfBirth" width="8%" />,
    <DateField label="Creation Date" source="createdAt" width="8%" />,
  ];

  return (
    <>
      <Typography className="c-title" variant="h3">
        Main Candidate:
      </Typography>

      <ControlledList data={{ items: [data?.mainCandidate], totalPages: 1, totalElements: 1 }}>
        <DataGridLayout isSmallTable showPagination={false} showTotal={false}>
          <CandidateLinkFieldWithName label="Name" nameSource="name.fullName" source="id" width="14%" />
          {listFields}
        </DataGridLayout>
      </ControlledList>

      <Typography className="c-title" mt="20px" variant="h3">
        Possible Duplicates:
      </Typography>

      <ControlledList
        data={{ items: data?.possibleDuplicates, totalPages: 1, totalElements: data?.possibleDuplicate?.length }}
      >
        <DataGridLayout isSmallTable isShowHeader={false} showPagination={false} showTotal={false}>
          <CandidateLinkFieldWithNameWithCheckbox label="Name" nameSource="name.fullName" source="id" width="14%" />
          {listFields}
        </DataGridLayout>
      </ControlledList>
    </>
  );
};

export default CandidateDuplicateScanForm;
