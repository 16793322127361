import React, { useState } from 'react';
import { Box, Divider, Typography, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { SalaryForkHistoryItem as HistoryItemType, SalaryForkChangeItem } from '../useSalaryForkService';

interface SalaryForkHistoryItemProps {
  data: HistoryItemType;
  salaryValues?: Record<string, number>;
}

const propertyLabels: Record<string, string> = {
  id: 'ID',
  specialization: 'Specialization',
  createdBy: 'Created By',
  updatedBy: 'Updated By',
  bySalaryFromId: 'BY Salary From',
  bySalaryToId: 'BY Salary To',
  byCurrency: 'BY Currency',
  plSalaryFromId: 'PL Salary From',
  plSalaryToId: 'PL Salary To',
  plCurrency: 'PL Currency',
};

const categoryLabels: { [key: string]: string } = {
  l1: 'Level 1',
  l2: 'Level 2',
  l3: 'Level 3',
  l4: 'Level 4',
  l5: 'Level 5',
  l6: 'Level 6',
  root: 'Basic Information',
};

const getPropertyLabel = (property: string): string => {
  return propertyLabels[property] || property;
};

const getCategoryLabel = (categoryKey: string): string => {
  return categoryLabels[categoryKey] || categoryKey;
};

const formatValue = (
  value: string | null,
  property: string,
  salaryValues?: Record<string, number>,
): number | string => {
  if (value === null || value === undefined || value === '') {
    return 'None';
  }

  const isSalaryField = ['bySalaryFromId', 'bySalaryToId', 'plSalaryFromId', 'plSalaryToId'].includes(property);

  if (isSalaryField && salaryValues && salaryValues[value]) {
    const salary = salaryValues[value];

    return salary;
  }

  return value;
};

const SalaryForkHistoryItem: React.FC<SalaryForkHistoryItemProps> = ({ data, salaryValues }) => {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});

  const handleAccordionChange = (categoryKey: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(prev => ({ ...prev, [categoryKey]: isExpanded }));
  };

  const categoryKeys = Object.keys(data.changes || {}).filter(
    key => data.changes[key] && Array.isArray(data.changes[key]),
  );

  if (categoryKeys.length === 0) {
    return <Typography>No changes recorded</Typography>;
  }

  return (
    <Box>
      {categoryKeys.map(categoryKey => {
        const changes = (data.changes[categoryKey] || []).filter(
          (change: SalaryForkChangeItem) => change.property !== 'id',
        );

        if (changes.length === 0) {
          return null;
        }

        const categoryLabel = getCategoryLabel(categoryKey);

        return (
          <Accordion
            key={categoryKey}
            expanded={expanded[categoryKey] ?? false}
            onChange={handleAccordionChange(categoryKey)}
            sx={{ mb: 2, '&:before': { display: 'none' } }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                bgcolor: 'background.default',
                borderRadius: expanded[categoryKey] ? '4px 4px 0 0' : 4,
                border: '1px solid rgba(0, 0, 0, 0.12)',
              }}
            >
              <Typography variant="subtitle1" fontWeight="bold">
                {categoryLabel} Changes ({changes.length})
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                p: 2,
                bgcolor: 'background.default',
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderTop: 'none',
                borderRadius: '0 0 4px 4px',
              }}
            >
              {changes.map((change: SalaryForkChangeItem, index: number) => (
                <Box key={`${change.oldValue}-${change.newValue}`}>
                  <Box sx={{ display: 'flex', my: 1, alignItems: 'center' }}>
                    <Typography variant="body2" fontWeight="medium" sx={{ flex: 1 }}>
                      {getPropertyLabel(change.property)}
                    </Typography>

                    <Box sx={{ flex: 1 }}>
                      <Typography variant="caption" color="text.secondary">
                        Previous value:
                      </Typography>
                      <Typography variant="body2">
                        {formatValue(change.oldValue, change.property, salaryValues)}
                      </Typography>
                    </Box>

                    <Box sx={{ flex: 1 }}>
                      <Typography variant="caption" color="text.secondary">
                        New value:
                      </Typography>
                      <Typography variant="body2">
                        {formatValue(change.newValue, change.property, salaryValues)}
                      </Typography>
                    </Box>
                  </Box>

                  {index < changes.length - 1 && <Divider sx={{ my: 1 }} />}
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </Box>
  );
};

export default SalaryForkHistoryItem;
