import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const VerticalBarsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15 15.5H11V2.5H15V15.5ZM12 14.5H14V3.5H12V14.5ZM10 15.5H6V6.5H10V15.5ZM5 15.5H1V9.5H5V15.5Z"
          fill="#666666"
        />
      </svg>
    </SvgIcon>
  );
};

export default VerticalBarsIcon;
