import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const SmallCopyIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <rect width="25" height="25" fill="url(#pattern0_2741_2934)" fillOpacity="0.5" />
        <defs>
          <pattern id="pattern0_2741_2934" patternContentUnits="objectBoundingBox" width="1" height="1">
            <use xlinkHref="#image0_2741_2934" transform="scale(0.01)" />
          </pattern>
          <image
            id="image0_2741_2934"
            width="100"
            height="100"
            xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAABkCAYAAABw4pVUAAAAAXNSR0IArs4c6QAABTlJREFUeAHtnUnIHEUUx38al4gGRVCjgit6UTx4iGiIgnpxuSiKngURQfBiEBUXDG6HEPWmKIqiKOJ6CQiCRIMgERERXPAi7jsuuC/9h2kYPrp63qR63lT19x40M19/1V3v/X9V3T3dr6sgLBQIBUKBUCAUCAVCgYkCa4CzgLuBp4AdwK4Cl53As8A24ILG37VjI7g3cA3wBfBfhctPwO3AujGAOQF4r0IIXQ1HDWpjzVBOB74bCYwW0O/AZTVCOQb4amQwWih/AmfWBGUP4M2RwmihfAYcUAuUy2fA0EnyCeAW4PoCl5uAhww9/NZagLzdA+R54JBKAtkfuK8nlh+BfUuP5dieALYD+i1Sm93fE9N5pQdzZcL5vwHBqtHUU75OxLW19ID0A6o98U1/vlG64zP8eyQR1zMztlv6vx9MOK6TeM2mE/10A2u/v156UKmWpPU12+YEEF3eF20BpDA8ASSAuCgQhywXme2VBBC7Vi4lA4iLzPZKAohdK5eSAcRFZnslAcSulUvJFJC/gO8Ni+6FvQu80OQWXAUc7uI1sNp+h7S3UOb91FPHBzzABJDue14pYMo5OHuRvSWAzAdEoNRbLl0UlAAyPxBB+Q3YsAgoAWT3gAjKR4CSCge11QbkfeBcw3IxcDPwYeI2fnuOUYbnoLbagMz7PGSvSW5zC2Dlp3rJoBZAbHI+3NNTTrLtwlYqgNh0UirUHwkoV9t2YSsVQGw6qdSrCSB32Hcxu2QAma1RW+LxBBBlTQ5mAcQupYtWLpXYYx6sZOrm4rxXWdMOuWjlUsl0VE7fA4iT0NZqAohVKadyAcRJaGs1AcSqlFO5AOIktLWaAGJVyqlcAHES2lpNALEq5VQugDgJba0mgFiVcioXQJyEtlYTQKxKOZULIE5CW6sJIFalnMoFECehrdUEEKtSTuUCiJPQ1moCiFUpp3IBxEloazUBxKqUU7nRAXnUSbhFVVMtkHsTyV/PLUopp/0qm3BlYrT+fjmjfpcMHY2h2OX4p4AGx6zVXknEJVF311yAXJhwXJA0OGaNdmrzbsc/ibiuywjIBch+wC8J5/X68CkZASxj0yOADxLxqJGdmOGUCxD591hPAD83afg3AOszAvHY9CBA40f2jVefk0aqGNyAHNfz7sP0+eUb4OMCl8+bMYX/7WlUbQznZLYMNyDyU4MMt46P8fPJTBiuPUSV6YpKc4SMEYYGitbQsbnm2kPkrEZ9TlVaK6jXgENzSUy2T2mj9Qs1vTOn80WtEOT3r82o3FsGfo98aUBE+8BmCIkbgbcqA6P3yu8BjlxAk10qkOl4DmtuOZwBXDQZ40PjfKSWOxMANbFKapvc9ZcAm5rD7dHTTi/gezFA5ont/AQQHUJqtwBSGMEAEkDyFYhDVr6Gg+4hgAwqZ/7OAki+hoPuIYAMKmf+zgJIvoaD7iGADCpn/s40fF7XPTCN5FnzM3opo1v4XbFpPN9i7eSE0wrkqGK9tjmmJ45dQG6zbb6cUnqUmnp6p1ypWk2zaKcSJ64oPahUS1LSxPGlO9/hnybXVD5XV+9Q4yu+5yspost5rfuksjnOdaf7pZ54dnUALG7VwcAPPUGoVelJ3l0FTnTcTr6siZif7kmRahucbv1XYUpIa50e6+fOmq4c9wReHDGUL2s4d6zsuuuaqR12jBDKt8BpK4Ot5e99gNR8uzUeyt5pMj2VWFi96Zl8zb1FbwNo6iONBT8q0xzt107yv7YDumwscVHj0YzZes9kI6BzYlgoEAqEAqFAKBAKhALwPyuOvoxD5F74AAAAAElFTkSuQmCC"
          />
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default SmallCopyIcon;
