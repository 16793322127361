import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const TagIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.94646 15.8975L6.77979 12.5642H3.76562L3.97396 11.7309H6.98729L7.85229 8.26921H4.84062L5.04896 7.43587H8.06146L8.89479 4.10254H9.69646L8.86312 7.43587H12.4206L13.254 4.10254H14.0556L13.2223 7.43587H16.2348L16.0265 8.26921H13.0131L12.1481 11.7309H15.1606L14.9523 12.5642H11.9398L11.1065 15.8975H10.3056L11.139 12.5642H7.58062L6.74729 15.8975H5.94646ZM7.78896 11.7309H11.3473L12.2123 8.26921H8.65396L7.78896 11.7309Z"
          fill="#666666"
        />
      </svg>
    </SvgIcon>
  );
};

export default TagIcon;
