import React, { useEffect, useState } from 'react';
import { IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { CreateForm } from 'shared/uibuilder/form';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import EmployeeLinkWithNameLoading from 'erp/employee/shared/EmployeeLinkWithNameLoading';
import ShowDate from 'shared/uibuilder/ShowDate';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

export interface IconMenuItem {
  key: string;
  text: string;
  color: string;
  modal?: React.ReactNode;
  isManagedByProcess?: boolean;
  tooltipType?: string;
}

interface IconMenuProps {
  icon: React.ElementType;
  items: IconMenuItem[];
  defaultKey: string;
  onChange?: (selectedKey: string, payload: any) => void;
  tooltipData: any[];
}

const tooltipMappingConfig: {
  [fieldName: string]: {
    label: string;
    mapValue: (value: any) => React.ReactNode;
  };
} = {
  author: {
    label: 'Author:',
    mapValue: (value: string) => <EmployeeLinkWithNameLoading id={value} />,
  },
  interviewer: {
    label: 'Interviewer:',
    mapValue: (value: string) => <EmployeeLinkWithNameLoading id={value} />,
  },
  interviewDateTime: {
    label: 'Interview date:',
    mapValue: (value: string) => <ShowDate dateUTC={value} format={DATE_FORMAT.FULL_DATE} isDateTime={false} />,
  },
  dateTime: {
    label: 'Date:',
    mapValue: (value: string) => <ShowDate dateUTC={value} format={DATE_FORMAT.FULL_DATE} isDateTime={false} />,
  },
  firstWorkingDay: {
    label: 'First working day:',
    mapValue: (value: string) => <ShowDate dateUTC={value} format={DATE_FORMAT.FULL_DATE} isDateTime={false} />,
  },
  activityType: {
    label: 'Activity type:',
    mapValue: (value: string) => value,
  },
};

const IconMenu: React.FC<IconMenuProps> = ({ icon: Icon, items, defaultKey, onChange, tooltipData }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedKey, setSelectedKey] = useState<string>(defaultKey);
  const [pendingKey, setPendingKey] = useState<string | null>(null);
  const [ActiveModal, setActiveModal] = useState<ReactComponent<any> | null>(null);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (key: string, modal?: React.ReactNode) => {
    if (modal) {
      setActiveModal(() => modal);
      setPendingKey(key);
    } else {
      handleStatusChange(key);
    }
    handleClose();
  };

  const handleStatusChange = (key: string, data: any = null) => {
    setSelectedKey(key);
    if (onChange) {
      onChange(key, data);
    }
  };

  const closeDialog = () => {
    setActiveModal(null);
  };

  const currentItem = items.find(item => item.key === selectedKey);

  const renderTooltipData = () => {
    if (!tooltipData) {
      return <></>;
    }

    return tooltipData
      .filter((data: any) => data.type === currentItem?.tooltipType)
      .map((data: any) => {
        const { type, ...others } = data;
        return Object.entries(others).map(value => {
          const [key, val] = value;
          if (tooltipMappingConfig[key]) {
            return (
              <React.Fragment key={tooltipMappingConfig[key].label}>
                {tooltipMappingConfig[key].label} {tooltipMappingConfig[key].mapValue(val)}
                <br />
              </React.Fragment>
            );
          } else {
            return (
              <React.Fragment key={`${key}_${val}`}>
                {key}: {val as any}
                <br />
              </React.Fragment>
            );
          }
        });
      });
  };

  useEffect(() => {
    setSelectedKey(defaultKey);
  }, [defaultKey]);

  return (
    <div key={defaultKey}>
      <Tooltip
        title={
          <>
            <p>{currentItem?.text || ''}</p>
            <p>{renderTooltipData()}</p>
          </>
        }
        arrow
      >
        <IconButton onClick={handleClick} sx={{ padding: 0 }}>
          {currentItem && <Icon style={{ color: currentItem.color, width: 16, height: 16 }} />}
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {items.map(item => {
          const isCurrentItem = item.key === currentItem?.key;
          const { isManagedByProcess } = item;
          return (
            <Tooltip
              key={item.key}
              title={isManagedByProcess ? 'This action is managed by the process' : undefined}
              arrow
            >
              <span>
                <MenuItem
                  key={item.key}
                  disabled={isManagedByProcess}
                  selected={isCurrentItem}
                  onClick={!isCurrentItem ? () => handleMenuClick(item.key, item.modal) : undefined}
                  sx={{
                    cursor: isCurrentItem ? 'not-allowed' : 'pointer',
                  }}
                >
                  <ListItemIcon>
                    <Icon style={{ color: item.color, width: 18, height: 18 }} />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </MenuItem>
              </span>
            </Tooltip>
          );
        })}
      </Menu>

      {ActiveModal && (
        <ModalWindow
          key="modal-not-strict"
          modalSize="sm"
          backdrop="static"
          isOpen
          title="Additional info required"
          onToggle={closeDialog}
        >
          <CreateForm
            submitFormFunc={formData => {
              handleStatusChange(pendingKey!, formData);
              setPendingKey(null);
              return Promise.resolve();
            }}
            afterSubmit={{
              execute: () => setActiveModal(null),
            }}
          >
            <ActiveModal handleCancelClick={closeDialog} />
          </CreateForm>
        </ModalWindow>
      )}
    </div>
  );
};

export default IconMenu;
