/* istanbul ignore file */
import React from 'react';
import { Routes } from 'react-router-dom';
import { ErpRouter } from 'erp';
import { CrmRouter } from 'crm';
import { AuditRouter } from 'audit';
import { DavinciRouter } from 'davinci';
import { OneStopRouter, RedirectToOneStop } from 'oneStop';
import { MyActivityRouter } from 'myActivity';
import { HandbookRouter } from 'handbook';
import { ArtifactsRouter } from 'artifact';
import { CamundaRouter } from 'camunda';
import { ExternalFormRouter } from 'externalform';
import { SendEmailRouter } from 'sendEmail';
import { UserSettingsRouter } from 'user/Settings';
import ArtifactStorageRouter from 'artifactStorage/ArtifactStorageRouter';
import { AuthenticationRouter } from 'authentication';
import { Route } from 'shared/routing';
import Page404 from 'shared/uibuilder/Page404';
import Page from 'layout/Default';
import { ROUTE403, ROUTE404 } from 'shared/api/const';
import { PrivilegedRouter } from './privileged';
import { InstantFeedbackRouter } from 'instantFeedback';
import { SyberryTodayRouter } from 'syberryToday';
import Page403 from './shared/authorization/Page403';
import CandidateContextExtensionRouter from 'erp/recruitment/extension/CandidateContextExtensionRouter';
import PublicInterviewSchedulingRouter from './erp/recruitment/publicInterviewScheduling/PublicInterviewSchedulingRouter';
import SidepanelExtensionRouter from 'erp/candidate/applications/SidepanelExtensionRouter';

export const ROOT_PATH = '/';
export const APPLICATION_URL = process.env.REACT_APP_DAVINCI_FRONTEND_BASE_URL;

const ApplicationRouter = () => (
  <Routes>
    {/* Extension */}
    <Route path={SidepanelExtensionRouter.getRouterPath()} element={<SidepanelExtensionRouter />} />
    <Route path={CandidateContextExtensionRouter.getRouterPath()} element={<CandidateContextExtensionRouter />} />,
    {/* Public pages */}
    <Route path={ExternalFormRouter.getRouterPath()} element={<ExternalFormRouter />} />
    {/* TODO Delete after some time */}
    <Route path={PublicInterviewSchedulingRouter.getRouterPath()} element={<PublicInterviewSchedulingRouter />} />
    <Route path={ROUTE403} element={<Page403 />} />
    <Route path={ROUTE404} element={<Page404 />} />
    <Route element={<Page />}>
      {/* Base routes */}
      <Route path="/" element={<RedirectToOneStop />} />
      {AuthenticationRouter()}

      {/* Extension */}
      <Route path={ExternalFormRouter.getRouterPath()} element={<ExternalFormRouter />} />
      <Route path={SidepanelExtensionRouter.getRouterPath()} element={<SidepanelExtensionRouter />} />

      {/* App */}
      {ErpRouter()}
      <Route path={CrmRouter.getRouterPath()} element={<CrmRouter />} />
      <Route path={AuditRouter.getRouterPath()} element={<AuditRouter />} />
      <Route path={PrivilegedRouter.getRouterPath()} element={<PrivilegedRouter />} />
      <Route path={OneStopRouter.getRouterPath()} element={<OneStopRouter />} />
      <Route path={MyActivityRouter.getRouterPath()} element={<MyActivityRouter />} />
      <Route path={DavinciRouter.getRouterPath()} element={<DavinciRouter />} />
      <Route path={CamundaRouter.getRouterPath()} element={<CamundaRouter />} />
      <Route path={ArtifactsRouter.getRouterPath()} element={<ArtifactsRouter />} />
      <Route path={SendEmailRouter.getRouterPath()} element={<SendEmailRouter />} />
      <Route path={UserSettingsRouter.getRouterPath()} element={<UserSettingsRouter />} />
      <Route path={HandbookRouter.getRouterPath()} element={<HandbookRouter />} />
      <Route path={ArtifactStorageRouter.getRouterPath()} element={<ArtifactStorageRouter />} />
      <Route path={InstantFeedbackRouter.getRouterPath()} element={<InstantFeedbackRouter />} />
      <Route path={SyberryTodayRouter.getRouterPath()} element={<SyberryTodayRouter />} />

      <Route path={ROUTE404} element={<Page404 />} />
      <Route path="*" element={<Page404 />} />
    </Route>
  </Routes>
);

export default ApplicationRouter;
