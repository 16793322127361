import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, CircularProgress, Alert } from '@mui/material';
import { Link, useParams, Navigate } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Divider from '@mui/material/Divider';
import { useSalaryForksUrl } from '../routing';
import SalaryForkHistoryItem from './SalaryForkHistoryItem';
import useSalaryForkService, {
  EmployeeNamesMap,
  SalaryValuesMap,
  SalaryForkHistoryItem as HistoryItemType,
} from '../useSalaryForkService';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import TimelineRecordAuthor from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordAuthor';

interface HistoryDataType {
  historyItems: HistoryItemType[];
  specializationName: string;
  employeeNamesMap: EmployeeNamesMap;
  salaryValuesMap: SalaryValuesMap;
}
const initialData: HistoryDataType = {
  historyItems: [],
  specializationName: '',
  employeeNamesMap: {},
  salaryValuesMap: {},
};

const SalaryForkHistoryPage: React.FC = () => {
  const { specializationId } = useParams<{ specializationId: string }>();
  const { getListUrl } = useSalaryForksUrl();
  const { addMessage } = useMessageService();
  const { getSalaryForksHistory } = useSalaryForkService();
  const [historyData, setHistoryData] = useState<HistoryDataType>(initialData);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadHistoryData = async () => {
      if (!specializationId) {
        setError('No specialization ID provided');
        setLoading(false);
        return;
      }

      try {
        const data = await getSalaryForksHistory(specializationId);
        setHistoryData(data);
        setError(null);
      } catch (err) {
        addMessage(new ErrorMessage('Error fetching vacancies:'));
      } finally {
        setLoading(false);
      }
    };

    loadHistoryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!specializationId) {
    return <Navigate to={getListUrl()} />;
  }

  const renderContent = () => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', p: 4 }}>
          <CircularProgress />
        </Box>
      );
    }

    if (error) {
      return (
        <Alert severity="error" sx={{ my: 2 }}>
          {error}
        </Alert>
      );
    }

    if (historyData.historyItems.length === 0) {
      return (
        <Typography align="center" sx={{ p: 4 }}>
          No history records found for this specialization
        </Typography>
      );
    }

    return historyData.historyItems.map((item, index) => (
      <Box key={item.updatedAt} sx={{ mb: 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
          <TimelineRecordAuthor id={item.updatedBy} name={historyData.employeeNamesMap[item.updatedBy]} />

          <Typography variant="body2" color="text.secondary">
            {new Date(item.updatedAt || Date.now()).toLocaleString()}
          </Typography>
        </Box>

        <SalaryForkHistoryItem data={item} salaryValues={historyData.salaryValuesMap} />

        {index < historyData.historyItems.length - 1 && <Divider sx={{ my: 2 }} />}
      </Box>
    ));
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2 }}>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" sx={{ my: 2 }}>
        <Link to={getListUrl()} style={{ textDecoration: 'none', color: 'inherit' }}>
          Salary Ranges
        </Link>
        <Typography color="text.primary">History</Typography>
      </Breadcrumbs>

      <Typography variant="h6" sx={{ mb: 2 }}>
        {historyData.specializationName}
      </Typography>

      <Paper sx={{ p: 2, mb: 2 }}>{renderContent()}</Paper>
    </Box>
  );
};

export default SalaryForkHistoryPage;
