import React from 'react';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import AssingEmployeeButton from '../buttons/AssingEmployee/AssingEmployeeButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import DropdownMenu from 'shared/uibuilder/menu/DropdownMenu';
import { IconButton } from 'uibuilder/button';
import MenuItem from '@mui/material/MenuItem';
import Box from 'uibuilder/Box';

// TODO Impl get list of users and save locally (useStorageService)
const AssingMenu = () => {
  const { getValue } = useFieldHelper({ source: 'retentionRiskAssigneeAlias' });
  const assignAlias = getValue();

  const array = [
    {
      text: 'Assign to me',
      alias: assignAlias,
    },
    {
      text: 'Olga Vlasova',
      alias: 'Vlasova',
    },
    {
      text: 'Anna Orlova',
      alias: 'Orlova',
    },
    {
      text: 'Dmitry Fedorov',
      alias: 'Fedorov',
    },
    // {
    //   text: 'Vasily Ivanov',
    //   alias: 'Ivanov',
    // },
  ];

  return (
    <DropdownMenu
      menuPlacement="bottom-end"
      popupId="AssingMenu"
      sxList={{
        maxHeight: '150px',
        overflow: 'auto',
        p: '6px 8px',
      }}
      iconButton={
        <IconButton sx={{ p: 0 }}>
          <KeyboardArrowDownIcon sx={{ width: '20px', height: '20px' }} />
        </IconButton>
      }
    >
      {array.map(item => {
        return (
          <MenuItem
            key={item.alias}
            disabled={assignAlias === item.alias}
            onClick={() => {
              // TODO setData
            }}
            sx={{
              fontSize: '12px',
              p: '7px !important',
              borderBottom: '1px solid var(--mui-palette-TableCell-border)',
            }}
          >
            {item.text}
          </MenuItem>
        );
      })}
    </DropdownMenu>
  );
};

const AssingEmployee = () => {
  const { getValue } = useFieldHelper({ source: 'retentionRiskAssigneeAlias' });
  const assignAlias = getValue();

  return (
    <>
      {assignAlias ? (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <EmployeeLinkFieldWithNameLoading externalId={assignAlias} />
          <AssingMenu />
        </Box>
      ) : (
        <AssingEmployeeButton />
      )}
    </>
  );
};

export default AssingEmployee;
