import React, { useMemo, useState } from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';
import EditableBadge, { EditableBadgeValueProps } from 'shared/uibuilder/badge/EditableBadge';
import { RECRUITING_VACANCY_GROUP_UPDATE, VacancyStatus } from 'erp/recruitment/newVacancy/constants';
import { RECRUITING_CONTEXT } from 'erp/recruitment/RecruitingContext';
import useAuthorization from 'shared/authorization/authorizationService';
import useVacancyPublicationService from '../../vacancyPublicationService';
import { useVacancyContext } from 'erp/recruitment/newVacancy/VacancyContext';

export const VacancyStatusColor = {
  [VacancyStatus.OPEN]: 'success' as BasicColors,
  [VacancyStatus.DRAFT]: 'primary' as BasicColors,
  [VacancyStatus.CLOSED]: 'neutralLight' as BasicColors,
};

const VacancyStatuses = [
  {
    value: VacancyStatus.OPEN,
    bgcolor: 'var(--mui-palette-badge-green)' as BasicColors,
    menuOptions: [VacancyStatus.CLOSED],
  },
  {
    value: VacancyStatus.DRAFT,
    bgcolor: 'var(--mui-palette-badge-blue)' as BasicColors,
    menuOptions: [VacancyStatus.CLOSED, VacancyStatus.OPEN],
  },
  {
    value: VacancyStatus.CLOSED,
    bgcolor: 'var(--mui-palette-badge-lightGrey)' as BasicColors,
    textColor: 'black',
    menuOptions: [VacancyStatus.OPEN],
  },
];

interface StatusBadgeProps extends FieldProps {
  statusSource: string;
  idSource: string;
}

const StatusBadge = ({ idSource, statusSource }: StatusBadgeProps) => {
  const { getValue: getStatus } = useFieldHelper({ source: statusSource });
  const { getValue: getId } = useFieldHelper({ source: idSource });
  const { isGranted } = useAuthorization();
  const [status, setStatus] = useState<VacancyStatus>(getStatus() as VacancyStatus);
  const { updateStatus } = useVacancyPublicationService();
  const { arePublicationsUpdated, setArePublicationsUpdated } = useVacancyContext();

  const handleChange = async (newStatus: string) => {
    const vacancy = await updateStatus(getId(), newStatus);
    setStatus(vacancy.status);

    if (setArePublicationsUpdated) {
      setArePublicationsUpdated(!arePublicationsUpdated);
    }
  };

  const statuses = useMemo(() => {
    const findStatusMenuOptions = VacancyStatuses.find(({ value }) => value === status)?.menuOptions;
    return findStatusMenuOptions?.map(option => VacancyStatuses.find(({ value }) => value === option));
  }, [status]);

  return (
    <EditableBadge
      color={VacancyStatusColor[status] as BasicColors}
      values={statuses as EditableBadgeValueProps[]}
      onChange={handleChange}
      canUpdate={isGranted(RECRUITING_VACANCY_GROUP_UPDATE, RECRUITING_CONTEXT)}
      badgeSx={{ padding: '4px', border: '1px solid black' }}
      menuItemSx={{
        margin: '4px',
        borderRadius: '4px',
        color: 'white',
      }}
    >
      {status}
    </EditableBadge>
  );
};

export default StatusBadge;
