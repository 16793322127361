import React from 'react';
import useVacancySourcingService from '../useVacancySourcingService';
import DataGridLayout from 'shared/uibuilder/list/layout/DataGridLayout';
import RelationListLayout from 'shared/uibuilder/list/layout/RelationListLayout';
import TextField from 'shared/uibuilder/field/TextField';
import ListWithInfiniteScroll from 'shared/uibuilder/list/ListWithInfiniteScroll';
import DataGridInfiniteScrollPagingHandler from 'shared/uibuilder/list/layout/DataGridInfiniteScrollPagingHandler';
import BadgeField from 'shared/uibuilder/field/BadgeField';
import CandidateLinkFieldWithName from '../../../shared/fields/CandidateLinkFieldWithName';
import { CommaListField, EnumField } from 'shared/uibuilder/field';
import { MAP_ENGLISH_LEVEL_TO_SHORT } from 'erp/candidate/candidateService';
import SourcingCandidatesFilters from '../shared/filter/SourcingCandidatesFilters';
import VacancyLinkField from 'erp/recruitment/vacancy/shared/input/VacancyLinkField';
import { Stages } from 'erp/recruitment/recruitingBoard/constants';
import BlackListBadge from 'erp/candidate/shared/BlackListBadge';
import PotentialCandidateBadge from 'erp/candidate/shared/PotentialCandidateBadge';
import StudentBadge from 'erp/candidate/shared/StudentBadge';

const SourcingCandidatesList = () => {
  const { search } = useVacancySourcingService();

  return (
    <ListWithInfiniteScroll getDataMethod={search}>
      <RelationListLayout filter={<SourcingCandidatesFilters />}>
        <DataGridInfiniteScrollPagingHandler>
          <DataGridLayout>
            <BadgeField
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'center',
              }}
              badges={[
                <StudentBadge isVisible={({ isStudent }) => !!isStudent} />,
                <BlackListBadge isVisible={({ isInBlackList }) => !!isInBlackList} />,
                <PotentialCandidateBadge
                  isVisible={({ isMarkedAsPotentialCandidate }) => !!isMarkedAsPotentialCandidate}
                />,
              ]}
              label="Name"
              source="id"
              width="18%"
            >
              <CandidateLinkFieldWithName nameSource="name.fullName" source="id" />
            </BadgeField>
            <TextField label="Specialization" source="specialization" width="14%" />

            <TextField label="Level" source="level" width="6%" />

            <EnumField label="English" source="englishLevel" options={MAP_ENGLISH_LEVEL_TO_SHORT} width="6%" />
            <TextField label="Location" source="residenceCountry" width="10%" />
            <CommaListField label="Skills" source="skills" width="13%" />

            <VacancyLinkField source="lastAppliedVacancy" label="Last Applied Vacancy" width="13%" />

            <EnumField options={Stages} label="Last stage" source="lastStage" width="8%" />
            {/* TODO Processed button */}
          </DataGridLayout>
        </DataGridInfiniteScrollPagingHandler>
      </RelationListLayout>
    </ListWithInfiniteScroll>
  );
};

export default SourcingCandidatesList;
