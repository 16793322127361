import React from 'react';
import MarkdownEditor from 'shared/markdownEditor/MarkdownEditor';

interface OfferMarkdownEditorProps {
  value: string;
  onChange: (value: string) => void;
}

const OfferMarkdownEditor: React.FC<OfferMarkdownEditorProps> = ({ value, onChange }) => {
  const tooltip = <>
    <p>
      Markdown is a simple markup language. If you don&apos;t know how to use it, you can refer to<a target="_blank" rel="noopener noreferrer" href="https://www.markdownguide.org/cheat-sheet/">cheat sheet</a>.
    </p>

    <p>
      Also, in this editor you are able to use placeholders:
    </p>
    <ul>
      <li>&#123;&#123; candidateName &#125;&#125; - replaced with actual candidate name;</li>
      <li>&#123;&#123; vacancySpecialization &#125;&#125; - replaced with actual vacancy specialization;</li>
      <li>&#123;&#123; acceptanceDate &#125;&#125; - replaced with actual acceptance date for offer;</li>
      <li>&#123;&#123; finalAmountGross &#125;&#125;* - replaced with final amount gross value;</li>
      <li>&#123;&#123; probationAmountGross &#125;&#125;* - replaced with probation amount gross value;</li>
      <li>&#123;&#123; currency &#125;&#125;* - replaced with offer currency.</li>
    </ul>

    <p>
      * Text block with these placeholders must be wrapped in &#123;&#123; #compensationDetails &#125;&#125; ... &#123;&#123; /compensationDetails &#125;&#125;
    </p>
  </>;

  return <MarkdownEditor value={value} onChange={onChange} title="Markdown" tooltip={tooltip} />;
};

export default OfferMarkdownEditor;
