import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Stack, SxProps } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Draggable from 'shared/dnd/Draggable';
import { formatTime, getTaskStatusByDuration } from './utils';
import { useDateService } from 'shared/uibuilder/dateService';
import { MINUTE } from '../../constants';
import { TaskStyles } from '../../types';
import useRecruitingBoardService from '../../useRecruitingBoardService';
import { useContextMenuContext } from '../contextMenu/ContextMenuContext';
import CandidateCard from './CanditateCard';

interface DraggableItemProps {
  item: any;
  columnSettings: any;
  cardSx?: SxProps;
  isClosedOrRejected?: boolean;
}

const getCardStyles = (deadlineStyle: TaskStyles) => ({
  borderRadius: 1,
  borderStyle: 'solid',
  borderWidth: 1,
  borderColor: deadlineStyle.borderColor,
  background: deadlineStyle.background,
  minWidth: '195px',
});

const DragHandle = React.forwardRef<HTMLDivElement, { background: string }>(({ background }, ref) => (
  <div
    ref={ref}
    style={{
      minHeight: '100%',
      width: '10px',
      background,
      cursor: 'grab',
    }}
  >
    <DragIndicatorIcon sx={{ width: '10px', height: '100%' }} fontSize="small" />
  </div>
));

const DraggableItem: React.FC<DraggableItemProps> = ({ item, columnSettings, cardSx, isClosedOrRejected }) => {
  const { getCurrentDateTime, getTimeDifference, excludeWeekends } = useDateService();
  const [timeElapsed, setTimeElapsed] = useState(getCurrentDateTime());
  const { completeJobStep } = useRecruitingBoardService();
  const { handleContextMenu = () => {} } = useContextMenuContext();

  useEffect(() => {
    const intervalId = setInterval(() => setTimeElapsed(getCurrentDateTime()), MINUTE);
    return () => clearInterval(intervalId);
  }, [getCurrentDateTime]);

  const timeDifference = useMemo(() => {
    const rawDiff = getTimeDifference(item.eventTimestamp, timeElapsed);
    const weekendAdjustment = excludeWeekends(item.eventTimestamp, timeElapsed);
    return Math.max(0, rawDiff - weekendAdjustment);
  }, [getTimeDifference, item.eventTimestamp, timeElapsed, excludeWeekends]);

  const deadlineStyle = useMemo(
    () => getTaskStatusByDuration(timeDifference, columnSettings?.deadlinesForStage),
    [timeDifference, columnSettings?.deadlinesForStage],
  );

  const formattedTime = useMemo(() => formatTime(timeDifference), [timeDifference]);

  const renderPreview = useCallback(
    () => (
      <Card sx={getCardStyles(deadlineStyle)}>
        <Stack direction="row" alignItems="stretch">
          <DragHandle background={deadlineStyle.dragIconBackground} />
          <CandidateCard item={item} formattedTime={formattedTime} deadlineStyle={deadlineStyle} preview />
        </Stack>
      </Card>
    ),
    [deadlineStyle, formattedTime, item],
  );

  return (
    <Draggable canDrag={item.canDrag} isHandleRef preview={renderPreview()} initialData={item}>
      {({ draggableRef }) => (
        <div onContextMenu={e => handleContextMenu(e, item)} style={{ cursor: 'context-menu' }}>
          <Card sx={{ ...getCardStyles(deadlineStyle), opacity: item.canDrag ? 1 : 0.5, ...cardSx }}>
            <Stack direction="row" alignItems="stretch" minHeight="36px">
              <DragHandle
                ref={draggableRef}
                background={isClosedOrRejected ? '#666666' : deadlineStyle.dragIconBackground}
              />
              <CandidateCard
                completeJobStep={completeJobStep}
                item={item}
                formattedTime={formattedTime}
                deadlineStyle={deadlineStyle}
                isClosedOrRejected={isClosedOrRejected}
              />
            </Stack>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default React.memo(DraggableItem);
