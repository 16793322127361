// eslint-disable-next-line no-restricted-syntax
import Hotjar from '@hotjar/browser';

export default function initHotjar() {
  const siteId = process.env.REACT_APP_DAVINCI_FRONTEND_HOTJAR_SITE_ID;
  const HOTJAR_VERSION = 6;

  if (siteId) {
    Hotjar.init(siteId, HOTJAR_VERSION);
  }
}
