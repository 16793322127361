/* istanbul ignore file */
import React from 'react';
import SectionRow from 'shared/uibuilder/layout/SectionRow';
import { CapitalizedField, TextField } from 'shared/uibuilder/field';
import ContractTypeField from 'erp/contracts/shared/field/ContractTypeField';

export interface OfferCompensationDetailsFieldProps {
  value?: {
    baseAmountType?: string;
    currency?: string;
    calculatedAmountGross?: string;
    finalAmountGross?: string;
    probationAmountGross?: string;
    office?: string;
    contractType?: string;
    isAccepted?: boolean;
  };
  canViewBaseFinanceFields?: boolean;
  canViewCalculatedGrossField?: boolean;
  canViewProbationField?: boolean;
}

const CandidateOfferCompensationDetailsField = ({
  value,
  canViewBaseFinanceFields = true,
  canViewCalculatedGrossField = true,
  canViewProbationField = true,
}: OfferCompensationDetailsFieldProps) => {
  const visibleFieldsCount =
    2 + (canViewBaseFinanceFields ? 3 : 0) + (canViewCalculatedGrossField ? 1 : 0) + (canViewProbationField ? 1 : 0);

  const getColumnSizes = () => {
    if (visibleFieldsCount <= 2) return { colMd: 6, colXl: 6 };
    if (visibleFieldsCount <= 3) return { colMd: 4, colXl: 4 };
    if (visibleFieldsCount <= 4) return { colMd: 3, colXl: 3 };
    return { colMd: 4, colXl: 1.7 };
  };

  const { colMd, colXl } = getColumnSizes();

  return (
    <>
      <SectionRow
        colXs={12}
        colMd={colMd}
        colXl={colXl}
        spacing={1}
        containerProps={{
          sx: theme => {
            return {
              '& .field-label': {
                color: 'var(--mui-palette-primary-40) !important',
                fontSize: '12px',
                height: '36px',
                [theme.breakpoints.down('xl')]: {
                  height: 'auto',
                },
              },
            };
          },
        }}
      >
        <CapitalizedField value={value?.office} label="Office" />
        <ContractTypeField value={value?.contractType} label="Contract Type" />
        <CapitalizedField value={value?.baseAmountType} label="Base Amount Type" isVisible={canViewBaseFinanceFields} />
        <TextField value={value?.currency} label="Currency" isVisible={canViewBaseFinanceFields} />
        <TextField value={value?.finalAmountGross} label="Final Amount Gross" isVisible={canViewBaseFinanceFields} />
        <TextField
          value={value?.calculatedAmountGross}
          label="Calculated Amount Gross"
          isVisible={canViewCalculatedGrossField}
        />
        <TextField
          value={value?.probationAmountGross}
          label="Probation Amount Gross"
          isVisible={canViewProbationField}
        />
      </SectionRow>
    </>
  );
};

export default CandidateOfferCompensationDetailsField;
