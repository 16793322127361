/* istanbul ignore file */
// All logic is located in helpers
import React from 'react';
import { ListContextData, ListProvider } from 'shared/uibuilder/list/ListContext';
import useTimeline, {
  TimelineEntity,
  TimelineItemId,
  TimelineItem,
  TimelineResponseData,
} from 'shared/uibuilder/timeline/timelineHelper';

import { TimelineRegistry } from './timelineRegistryHelper';
import { ResourceData, ResourceId } from '../../crud/baseCrudService';
import { Filter } from '../list/filter/FilterContext';

export interface TimelineContextData extends ListContextData {
  isLoadingNextPage?: boolean;
  isLoadingPrevPage?: boolean;
  registries?: TimelineRegistry[];
  currentEditingId?: Nullable<StringOrNumber>;
  setCurrentEditingId?: (id: Nullable<StringOrNumber>) => void;
  updateInList?: (id: TimelineItemId, newEntity: TimelineEntity | TimelineItem) => void;
  wrapItem?: (item: TimelineItem, source: string) => TimelineEntity;
  addForm?: Nullable<React.ReactElement>;
  isHighlighted?: (arg: any) => boolean;
  isEntryViewMode?: boolean;
  pinTimelineAction?: (entityId: ResourceId, timelineId: ResourceId, pinStatus: boolean) => Promise<any>;
  countPinElements?: Nullable<number>;
}

export interface TimelineProps {
  getDataMethod: (request?: any) => Promise<TimelineResponseData>;
  registries: TimelineRegistry[];
  createTimelineItemMethod?: (itemType: string, data: ResourceData) => Nullable<Promise<TimelineItem>>;
  timelineEntryIdSource?: string;
  getPageByEntryIdMethod?: (id: StringOrNumber) => Promise<TimelineResponseData>;
  defaultFilter?: Filter;
  children?: React.ReactNode;
  pinTimelineAction?: (entityId: ResourceId, timelineId: ResourceId, pinStatus: boolean) => Promise<any>;
}

/**
 * Component receives the data and set the context with needed methods
 * @param getDataMethod function that accepts search request and returns promise to response
 * @param registries array of items registries
 * @param createTimelineItemMethod - method that accepts timeline
 * @param timelineEntryIdSource - name of routing parameter where timeline entry id is located.
 * item type and request and sends request to backend.
 * @param getPageByEntryIdMethod - method that accepts id of the timeline entry and returns page with this entry.
 * @param children
 * @param defaultFilter
 */
const Timeline = ({
  getDataMethod,
  registries,
  createTimelineItemMethod,
  timelineEntryIdSource = 'timelineEntryId',
  getPageByEntryIdMethod = undefined,
  children,
  defaultFilter,
  pinTimelineAction,
}: TimelineProps) => {
  const contextVariables = useTimeline({
    getDataMethod,
    registries,
    createTimelineItemMethod,
    timelineEntryIdSource,
    getPageByEntryIdMethod,
    defaultFilter,
    pinTimelineAction,
  });

  return <ListProvider value={contextVariables}>{children}</ListProvider>;
};

export default Timeline;
