import React, { Dispatch, SetStateAction, useCallback } from 'react';
import InterviewerLinkForm from './InterviewerLinkForm';
import Box from 'uibuilder/Box';
import AttributionIcon from '@mui/icons-material/Attribution';
import { IconButton } from 'uibuilder/button';
import Tooltip from 'uibuilder/Tooltip';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

const CopyLinkWithInterviewer = ({
  copyAction,
  getLink,
  isFormOpen,
  setIsFormOpen,
}: {
  copyAction: (str: string) => void;
  getLink: (values: FormFieldsData) => Promise<object | void>;
  isFormOpen: boolean;
  setIsFormOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const openCloseIssueForm = useCallback(() => {
    setIsFormOpen(true);
  }, [setIsFormOpen]);

  const closeModal = useCallback(() => {
    setIsFormOpen(false);
  }, [setIsFormOpen]);

  return (
    <Box>
      <IconButton
        sx={{
          fontSize: '12px',
          p: 0,
          mr: '5px',
        }}
        onClick={openCloseIssueForm}
      >
        <Tooltip placement="top" title="Select Interviewer">
          <AttributionIcon />
        </Tooltip>
      </IconButton>

      <InterviewerLinkForm getLink={getLink} copyAction={copyAction} closeModal={closeModal} modalOpen={isFormOpen} />
    </Box>
  );
};

export default CopyLinkWithInterviewer;
