import { useDateService, useDateTimeService } from 'shared/uibuilder/dateService';

export default () => {
  const { getCurrentDate } = useDateService();
  const { getCurrentTimezoneDateInUtc } = useDateTimeService();
  const minDate = getCurrentTimezoneDateInUtc(getCurrentDate().add(1, 'd'));
  return {
    followUpDate: {
      type: 'date',
      required: true,
      minDate: {
        value: minDate,
        message: 'Must be in future.',
      },
    },
    description: {
      type: 'string',
      required: true,
    },
  };
};
