/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import useFeatureToggle, { Features } from 'featuretoggle';
import { DefaultDropdownProps } from '../../../../../shared/uibuilder/form/input/dropdownHelper';

// eslint-disable-next-line react-hooks/rules-of-hooks
const isInternshipContractTypeEnabled = useFeatureToggle().isFeatureEnabled(Features.VIEW_INTERNSHIP_TYPE);

export const EMPLOYEE_CONTRACT_TYPES: any = isInternshipContractTypeEnabled
  ? {
      EC: 'Employment contract',
      B2B: 'B2B contract',
      CFS: 'Contract for services',
      INTERNSHIP: 'Internship',
    }
  : {
      EC: 'Employment contract',
      B2B: 'B2B contract',
      CFS: 'Contract for services',
    };

export const EMPLOYEE_CONTRACT_TYPES_ALIASES = Object.fromEntries(
  Object.entries(EMPLOYEE_CONTRACT_TYPES).map(([key]) => [key, key.toString()]),
);

const EmployeeContractTypeDropdown = ({ placeholder, ...restProps }: Omit<DefaultDropdownProps, 'options'>) => (
  <EnumDropdown
    {...restProps}
    options={EMPLOYEE_CONTRACT_TYPES}
    placeholder={placeholder !== undefined ? placeholder : 'Select contract type'}
  />
);

export default EmployeeContractTypeDropdown;
