import React from 'react';
import Box from 'uibuilder/Box';

const SmallFileUploaderLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        marginTop: '0 !important',
        '& .dropdown__multi-value': {
          backgroundColor: '#5954A8',
          color: '#ffffff',
          '&__label': {
            color: '#ffffff',
          },
        },
        '& .upload-block': {
          display: 'initial',
          '&>.photo-upload': {
            width: 'initial',
          },
        },
      }}
    >
      {children}
    </Box>
  );
};

export default SmallFileUploaderLayout;
