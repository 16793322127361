/* istanbul ignore file */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCandidateId, useCandidateUrl } from 'erp/candidate/CandidateRouter';

const CandidateIdPage = () => {
  const navigate = useNavigate();
  const id = useCandidateId();
  const { getProfileUrl } = useCandidateUrl();

  useEffect(() => {
    navigate(getProfileUrl(id), { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default CandidateIdPage;
