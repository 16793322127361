import { get } from 'lodash';
import { parseJson } from 'shared/uibuilder/list/builder/useListParams';
import removeEmptyValuesFromObject from 'shared/objectHelper';

export const SYCOM_UI_URL = process.env.REACT_APP_DAVINCI_FRONTEND_SYCOM_URL;

export const SOURCING_TYPE = {
  ACTIVE: 'ACTIVE',
  PASSIVE: 'PASSIVE',
};

export const SOURCING_TYPE_OPTIONS = {
  [SOURCING_TYPE.ACTIVE]: 'active_sourcing',
  [SOURCING_TYPE.PASSIVE]: 'passive_sourcing',
};

export const SOURCE_NAME = {
  RABOTA_BY: 'RABOTA_BY',
  JJIT: 'JJIT',
  PRACA_PL: 'PRACA_PL',
  INSTAGRAM: 'INSTAGRAM',
  LINKEDIN_A: 'LINKEDIN_A',
  LINKEDIN_P: 'LINKEDIN_P',
  TELEGRAM_A: 'TELEGRAM_A',
  TELEGRAM_P: 'TELEGRAM_P',
  REFERENCE: 'REFERENCE',
  NETWORKING: 'NETWORKING',
  AGENCY: 'AGENCY',
  OTHER_JOB_BOARDS: 'OTHER_JOB_BOARDS',
  OTHER: 'OTHER',
};

export const SOURCE_NAME_OPTIONS = {
  [SOURCE_NAME.RABOTA_BY]: 'Rabota.by',
  [SOURCE_NAME.JJIT]: 'JJiT',
  [SOURCE_NAME.PRACA_PL]: 'Praca.pl',
  [SOURCE_NAME.INSTAGRAM]: 'Instagram',
  [SOURCE_NAME.LINKEDIN_A]: 'LinkedIn(Active)',
  [SOURCE_NAME.LINKEDIN_P]: 'LinkedIn(Passive)',
  [SOURCE_NAME.TELEGRAM_A]: 'Telegram(Active)',
  [SOURCE_NAME.TELEGRAM_P]: 'Telegram(Passive)',
  [SOURCE_NAME.REFERENCE]: 'Reference',
  [SOURCE_NAME.NETWORKING]: 'Networking',
  [SOURCE_NAME.AGENCY]: 'Agency',
  [SOURCE_NAME.OTHER_JOB_BOARDS]: 'Other job boards',
  [SOURCE_NAME.OTHER]: 'Other',
};

export type CandidateData = {
  name?: {
    firstName?: string;
    lastName?: string;
    middleName?: string;
  };
  dateOfBirth?: string;
  personalPhoneNumber?: string;
  otherPhoneNumber?: string;
  personalEmail?: string;
  additionalContacts?: Dictionary<string>[];
  resume?: {
    resumeArtifactIds?: string[];
    description?: string;
  };
  photosIds?: string[];
  photo?: string;
  sourceComment?: string;
};

export const getCandidateFormData = (initialData: string | object, defaultData?: CandidateData) => {
  const data = (typeof initialData === 'string' ? parseJson(initialData) : initialData) || {};
  const defaultFirstName = get(defaultData, 'name.firstName');
  const defaultLastName = get(defaultData, 'name.lastName');
  const defaultMiddleName = get(defaultData, 'name.middleName');
  const defaultResumeArtifactIds = get(defaultData, 'resume.resumeArtifactIds');
  const defaultResumeDescription = get(defaultData, 'resume.description');

  const formData = {
    name: {
      firstName: get(data, 'name.firstName', defaultFirstName),
      lastName: get(data, 'name.lastName', defaultLastName),
      middleName: get(data, 'name.middleName', defaultMiddleName),
    },
    dateOfBirth: data.dateOfBirth || defaultData?.dateOfBirth,
    personalPhoneNumber: data.personalPhoneNumber || defaultData?.personalPhoneNumber,
    otherPhoneNumber: data.otherPhoneNumber || defaultData?.otherPhoneNumber,
    personalEmail: data.personalEmail || defaultData?.personalEmail,
    additionalContacts: data.additionalContacts || defaultData?.additionalContacts,
    resume: {
      resumeArtifactIds: get(data, 'resume.resumeArtifactIds', defaultResumeArtifactIds),
      description: get(data, 'resume.description', defaultResumeDescription),
    },
    photosIds: data.photosIds || defaultData?.photosIds,
  };

  return removeEmptyValuesFromObject(formData);
};

export interface ExtensionData {
  source: Nullable<Window>;
  origin: string;
}
