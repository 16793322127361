import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import { COUNTRIES } from 'shared/uibuilder/form/input/CountryDropdown';
import { SxProps } from '@mui/material/styles';
import InputLoader from 'shared/uibuilder/InputLoader';

type CountryBadgeProps = {
  country: string;
  sx?: SxProps;
};

const UNKNOWN_FLAG = 'un';

const COUNTRY_MAP_BY_NAME = COUNTRIES.reduce<Record<string, string>>((acc, { name, code }) => {
  acc[name.toLowerCase()] = code;
  return acc;
}, {});

const CountryBadge: React.FC<CountryBadgeProps> = ({ country, sx }) => {
  const [flagSrc, setFlagSrc] = useState<string | null>(null);
  const flag = useMemo(() => {
    if (typeof country === 'undefined') {
      return '';
    }
    const normalized = country.trim();
    return COUNTRY_MAP_BY_NAME[normalized.toLowerCase()] ?? country ?? UNKNOWN_FLAG;
  }, [country]);

  useEffect(() => {
    let flagImg = null;
    const loadFlag = async () => {
      try {
        flagImg = await import(`../../../../images/flags/${flag.toLowerCase()}.webp`);
        setFlagSrc(flagImg.default);
      } catch (error) {
        flagImg = await import(`../../../../images/flags/un.webp`);
        setFlagSrc(flagImg.default);
      }
    };

    loadFlag();
  }, [country, flag]);

  if (!flagSrc) {
    return <InputLoader className="files-list__files-loader" />;
  }

  return (
    <Box
      sx={{
        padding: 0,
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 20,
        ...sx,
      }}
    >
      <img
        src={flagSrc}
        width="24px"
        height={`${flag.toLowerCase() === UNKNOWN_FLAG.toLowerCase() || country === 'Unknown' ? '24px' : '20px'}`}
        alt={country}
      />
    </Box>
  );
};
export default CountryBadge;
