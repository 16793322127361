import React from 'react';
import Box from 'uibuilder/Box';
import { VACANCY_GROUP_PATH } from 'erp/recruitment/newVacancy/routing';
import style from './CandidateVacancySpecialization.module.scss';
import { VacancyGroupType } from 'erp/recruitment/newVacancy/types';

const CandidateVacancySpecialization = ({ vacancyData }: { vacancyData: VacancyGroupType }) => {
  const { id, specialization, details } = vacancyData;
  return (
    <Box sx={{ padding: '0px', display: 'flex', alignItems: 'center' }}>
      <a
        key={specialization + id}
        href={`${VACANCY_GROUP_PATH}/${id}`}
        target="_blank"
        rel="noreferrer"
        className={style.candidate_specialization}
        title={specialization}
      >
        {details.competencyLevelMin} {specialization}
      </a>
    </Box>
  );
};

export default CandidateVacancySpecialization;
