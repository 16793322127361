import React, { useState } from 'react';

import { ShowContextProvider } from 'shared/uibuilder/show/ShowContext';
import Loading from 'shared/uibuilder/Loading';
import useGetData from 'shared/useGetData';
import { ResourceData } from '../../crud/baseCrudService';
import ShowData from 'shared/uibuilder/show/ShowData';
import SmallLoader from 'shared/uibuilder/SmallLoader';

export type ShowDataInstance = any;

interface ShowProps {
  children: React.ReactNode | React.ReactElement;
  getDataMethod: () => Promise<ResourceData>;
  isSmallLoader?: boolean;
}

const Show = ({ children, getDataMethod, isSmallLoader }: ShowProps) => {
  const [data, setDataBase] = useState<ShowDataInstance>(null);
  const setData = (dataObject: object) => setDataBase(new ShowData(dataObject));
  const { loading } = useGetData(getDataMethod, setData);

  if (loading) {
    return isSmallLoader ? <SmallLoader /> : <Loading />;
  }

  return (
    <ShowContextProvider
      value={{
        data,
        setData,
      }}
    >
      {children}
    </ShowContextProvider>
  );
};

export default Show;
