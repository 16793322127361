/* istanbul ignore file */
import React from 'react';

const TimelineRecordFieldLayout = ({ value = null, label = null }) => {
  return (
    value && (
      <div className="mb-0">
        {label ? <b>{label}: </b> : null}
        {value}
      </div>
    )
  );
};

export default TimelineRecordFieldLayout;
