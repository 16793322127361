import { useRecruitmentApi } from 'api';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import useRecruitingFinanceService from '../useRecruitingFinanceService';

const useRecruiterExtension = () => {
  const { sendPostRequest } = useRecruitmentApi();
  const { saveBatchSensitiveRecruitingInfo } = useRecruitingFinanceService();

  const sendRejectionReason = async (data: FormFieldsData) => {
    const sensitiveCompensationData = data.expectedCompensationFrom
      ? await saveBatchSensitiveRecruitingInfo(
          [
            {
              value: data.expectedCompensationFrom,
              externalId: 'expectedCompensationFromId',
            },
            data.expectedCompensationTo && {
              value: data.expectedCompensationTo,
              externalId: 'expectedCompensationToId',
            },
          ].filter(Boolean),
        )
      : [];

    const expectedCompensationFromItem = sensitiveCompensationData.find(
      item => item.externalId === 'expectedCompensationFromId',
    );
    const expectedCompensationToItem = sensitiveCompensationData.find(
      item => item.externalId === 'expectedCompensationToId',
    );

    const response = await sendPostRequest('/candidate/rejection-reason', {
      vacancyGroupAlias: 'J757',
      location: 'BY',
      rejectionReason: 'NOT_INTERESTED',
      expectedCompensationFromId: expectedCompensationFromItem?.id,
      expectedCompensationToId: expectedCompensationToItem?.id,
      compensationCurrency: 'USD',
    });

    return response.json();
  };

  return {
    sendRejectionReason,
  };
};

export default useRecruiterExtension;
