import React from 'react';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import ModalForm from 'shared/uibuilder/form/ModalForm';
import VacancyGroupInput from '../input/VacancyGroupInput';
import VacancyInput from '../input/VacancyInput';
import { MapVacancyType } from '../../../../types';
import ViewVacancyContent from '../../ViewVacancyContent';
import ChangeRequestsList from '../changeRequests';

interface VacancyViewWrapperProps {
  vacancy: MapVacancyType;
  refreshData: () => Promise<void>;
  moveToGroup: (vacancyId: string, groupId: string | null) => Promise<void>;
  modalsState: Dictionary<boolean>;
  toggleModal: (modalKey: string) => void;
  vacancyGroupId: string;
}

/**
 * Wrapper component for viewing a vacancy and managing related modals
 */
const VacancyViewWrapper: React.FC<VacancyViewWrapperProps> = ({
  vacancy,
  refreshData,
  moveToGroup,
  modalsState,
  toggleModal,
  vacancyGroupId,
}) => {
  return (
    <>
      <ViewVacancyContent setGeneralContextData={refreshData} />

      <ModalWindow
        title="Move vacancy to another group"
        isOpen={modalsState.moveToGroup}
        onToggle={() => toggleModal('moveToGroup')}
      >
        <ModalForm
          closeModal={() => toggleModal('moveToGroup')}
          modalOpen={modalsState.moveToGroup}
          submitFormFunc={values => moveToGroup(vacancy.id, values.vacancyGroupId)}
          afterSubmit={{ execute: refreshData }}
        >
          <VacancyGroupInput label="Target Vacancy Group" source="vacancyGroupId" />
        </ModalForm>
      </ModalWindow>

      <ModalWindow isOpen={modalsState.removeFromGroup} onToggle={() => toggleModal('removeFromGroup')}>
        <ModalForm
          closeModal={() => toggleModal('removeFromGroup')}
          modalOpen={modalsState.removeFromGroup}
          submitFormFunc={() => moveToGroup(vacancy.id, null)}
          afterSubmit={{ execute: refreshData }}
        >
          <p>Are you sure that you want to remove this vacancy from group?</p>
        </ModalForm>
      </ModalWindow>

      <ModalWindow isOpen={modalsState.addVacancy} onToggle={() => toggleModal('addVacancy')}>
        <ModalForm
          closeModal={() => toggleModal('addVacancy')}
          modalOpen={modalsState.addVacancy}
          submitFormFunc={values => moveToGroup(values.vacancyId, vacancyGroupId)}
          afterSubmit={{ execute: refreshData }}
        >
          <VacancyInput ignoreVacancyId={vacancy.id} source="vacancyId" />
        </ModalForm>
      </ModalWindow>

      <ModalWindow
        title="Change Requests"
        isOpen={modalsState.changeRequests}
        onToggle={() => toggleModal('changeRequests')}
      >
        <ChangeRequestsList vacancyId={vacancy.id} refreshData={refreshData} />
      </ModalWindow>
    </>
  );
};

export default VacancyViewWrapper;
