/* istanbul ignore file */
import React from 'react';
import MultiSelectInputFilter from 'shared/uibuilder/list/filter/MultiSelectInputFilter';
import { ConditionProps } from 'shared/uibuilder/list/filter/condition/Condition';
import MultiSelectField from 'erp/shared/input/MultiSelectField';
import useCandidateService from 'erp/candidate/candidateService';
import { buildCandidateAutoSuggestLabel } from 'erp/candidate/shared/input/CandidateInput';

const CandidateMultiSelectFilter = ({ label, ...props }: Omit<ConditionProps, 'children'> & { label: string }) => {
  return (
    <MultiSelectInputFilter {...props}>
      <MultiSelectField
        {...props}
        label={label}
        alias="id"
        useService={useCandidateService}
        noOptionLabel="Candidate"
        buildLabelCallback={buildCandidateAutoSuggestLabel}
      />
    </MultiSelectInputFilter>
  );
};

export default CandidateMultiSelectFilter;
