import { Avatar, Box, CardHeader, IconButton, Typography } from '@mui/material';
import React from 'react';
import { renderValue } from './CandidateContextPage';
import { Email, Phone } from '@mui/icons-material';
import CandidateLink from 'erp/candidate/shared/CandidateLink';
import RejectPeopleIcon from 'uibuilder/AppIcon/Icons/RejectPeopleIcon';
import Tooltip from 'uibuilder/Tooltip';

interface ICandidateCardProps {
  candidatePhoto?: string;
  candidate: any;
  showVacancyRejectButton?: boolean;
  showVacancyReject?: boolean;
  togleVacancyReject?: () => void;
}

const CandidateCard: React.FC<ICandidateCardProps> = ({
  candidatePhoto,
  candidate,
  showVacancyRejectButton,
  showVacancyReject,
  togleVacancyReject,
}) => {
  return (
    <CardHeader
      sx={{ p: 1, '& .MuiCardHeader-avatar': { mr: 1 }, alignItems: 'flex-start' }}
      avatar={
        <Avatar
          src={candidatePhoto}
          alt="Candidate"
          sx={{
            mt: '10px',
            width: 75,
            height: 75,
            borderRadius: '50%',
          }}
        />
      }
      title={
        <Box display="flex" alignItems="baseline" flexDirection="column">
          <Box display="flex" justifyContent="space-between" width="100%" alignItems="baseline" gap={1}>
            {candidate?.id ? (
              <CandidateLink
                id={candidate?.id}
                name={renderValue(`${candidate?.name?.firstName ?? ''} ${candidate?.name?.lastName ?? ''}`)}
              />
            ) : (
              <Typography variant="caption" sx={{ fontSize: '14px', color: 'var(--mui-palette-primary-main)' }}>
                {renderValue(`${candidate?.name?.firstName ?? ''} ${candidate?.name?.lastName ?? ''}`)}
              </Typography>
            )}

            <Box>
              <Typography variant="body2" color="text.secondary">
                <IconButton>
                  <Email />
                </IconButton>
                {renderValue(candidate?.personalEmail)}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                <IconButton>
                  <Phone />
                </IconButton>
                {renderValue(candidate?.personalPhoneNumber)}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" width="100%" justifyContent="flex-end" height="35px">
            {showVacancyRejectButton ? (
              <Tooltip title="Сandidate's refusal">
                <IconButton sx={{ p: '6px' }} onClick={togleVacancyReject}>
                  <RejectPeopleIcon stroke={showVacancyReject ? '#666666' : '#EC221F'} />
                </IconButton>
              </Tooltip>
            ) : null}
          </Box>
        </Box>
      }
    />
  );
};

export default CandidateCard;
