import React from 'react';
import Badge from 'shared/uibuilder/badge/Badge';

interface CandidateProfileTagProps {
  tagText: string;
  backgroundColor?: string;
}

const CandidateProfileTag = ({ tagText, backgroundColor = '#862963' }: CandidateProfileTagProps) => {
  return (
    <Badge
      sx={{
        backgroundColor: { backgroundColor },
        color: '#ffffff',
        fontSize: '14px',
        margin: '0',
        padding: '1px 8px',
        lineHeight: '22.4px',
        fontWeight: '400',
        minWidth: '32px',
        '& .MuiChip-label': {
          padding: 0,
        },
      }}
    >
      {tagText}
    </Badge>
  );
};

export default CandidateProfileTag;
