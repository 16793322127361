import React from 'react';
import styles from './AvatarSection.module.scss';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';
import { omit } from 'lodash';
import CandidateCountry from '../CandidateCountry/CandidateCountry';
import SocialIcons from '../Badges/SocialIcons/SocialIcons';
import ShowData from 'shared/uibuilder/show/ShowData';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import CandidateProfileTag from '../Badges/CandidateProfileTag/CandidateProfileTag';
import CandidateResumeBadge from '../Badges/ResumeBadge/CandidateResumeBadge';
import CandidateAvatart from '../CandidateAvatart/CandidateAvatart';
import CandidateEnglishLevelLang from '../CandidateEnglishLevelLang/CandidateEnglishLevelLang';
import CandidateNativeLang from '../CandidateNativeLang/CandidateNativeLang';

export interface NameFieldValue {
  firstName?: string;
  lastName?: string;
  middleName?: string;
  maidenName?: string;
}

const AvatarSection = ({ ignoredNameParts = [], ...props }: FieldProps<NameFieldValue>) => {
  const { getValue } = useFieldHelper({ source: 'name' });
  const nameField = omit(getValue() || {}, ignoredNameParts) as any;

  const formContext = useFormContext();
  const showContext = useShowContext();
  const data = (showContext?.data.isNotEmpty() && showContext.data) || new ShowData(formContext?.data || {});

  return (
    <div className={styles.avatarSection__wrapper}>
      <div className={styles.avatar_image_wrapper}>
        <CandidateAvatart />
      </div>

      <div key={nameField.lastName} className={styles.user_fullname}>
        {nameField.fullName}
      </div>

      <div className={styles.contact_link_icons}>
        <CandidateCountry />
        <SocialIcons />
      </div>
      <div className={styles.languages}>
        <CandidateNativeLang />
        <CandidateEnglishLevelLang />
      </div>
      <div className={styles.recruting_status}>
        {data.getValueBySource('isStudent') && <CandidateProfileTag tagText="Student" backgroundColor="#E5A000" />}
        {!data.getValueBySource('isInBlackList') && data.getValueBySource('isMarkedAsPotentialCandidate') && (
          <CandidateProfileTag tagText="KIV" backgroundColor="#862963" />
        )}
        {data.getValueBySource('isInBlackList') && (
          <CandidateProfileTag tagText="Blacklist" backgroundColor="#000000" />
        )}
        {data.getValueBySource('isHidden') && <CandidateProfileTag tagText="Hidden" backgroundColor="darkgrey" />}
        {data.getValueBySource('duplicateOf') && <CandidateProfileTag tagText="Duplicate" backgroundColor="#EC221F" />}
      </div>

      <div className={styles.resume_link}>
        <CandidateResumeBadge />
      </div>
    </div>
  );
};

export default AvatarSection;
