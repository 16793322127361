/* istanbul ignore file */
import React, { useState } from 'react';
import { Button } from 'uibuilder/button';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/LinkOutlined';
import Tooltip from 'uibuilder/Tooltip';
import { copyToClipboard, copyByNavigator } from 'shared/clipboard';

interface Props {
  id: string;
  value: string;
  children: any;
  className?: string;
  isIcon?: boolean;
  sx?: {};
  isContentCopyIcon?: boolean;
  isCopyingFromModal?: boolean;
}

const STYLE = {
  outline: 'none',
  boxShadow: 'none',
  padding: 0,
};

const CopyToClipboardButton = ({
  id,
  value,
  children,
  className,
  isIcon = true,
  sx = {},
  isContentCopyIcon = false,
  isCopyingFromModal = false,
}: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const onClick = () => {
    if (isCopyingFromModal) {
      copyByNavigator(value);
    } else {
      copyToClipboard(value);
    }

    if (!isIcon) {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000);
    }
  };

  const wrapperClass = `d-inline-block${className ? ` ${className}` : ''}`;
  const buttonText = isCopied ? <>Copied!</> : children;

  return (
    <div className={wrapperClass}>
      <Button
        type="button"
        onClick={onClick}
        style={isIcon ? STYLE : {}}
        link={!!isIcon}
        outline
        color="neutral"
        sx={{ ...sx, minWidth: '1px' }}
      >
        {isIcon ? (
          <Tooltip title={children} placement="right" id={id}>
            {isContentCopyIcon ? (
              <ContentCopyIcon id={id} aria-label="Copy to clipboard" sx={{ fontSize: '21px' }} />
            ) : (
              <LinkIcon id={id} aria-label="Copy to clipboard" sx={{ fontSize: '21px' }} />
            )}
          </Tooltip>
        ) : (
          buttonText
        )}
      </Button>
    </div>
  );
};

export default CopyToClipboardButton;
