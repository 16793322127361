/* istanbul ignore file */
import React from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import { RECRUITING_VACANCY_APPROVE_VACANCY, VACANCY_STATUS } from 'erp/recruitment/newVacancy/constants';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { RECRUITING_CONTEXT } from '../../../RecruitingContext';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { Tooltip } from '@mui/material';
import { Compensation, Position, WorkingCondition } from 'erp/recruitment/newVacancy/types';

const ApproveVacancyButton = ({
  statusSource,
  setGeneralContextData,
}: {
  statusSource: string;
  setGeneralContextData: any;
}) => {
  const { isGranted } = useAuthorization();
  const { getValue } = useFieldHelper({ source: statusSource });
  const { approveVacancy } = useVacancyGroupService();

  const { data } = useShowContext();
  const { id } = data.getData();
  const status = getValue();

  const isCompensationFullyProvided = (compensation: Compensation) => {
    return compensation && compensation.salaryFromId && compensation.salaryToId && compensation.currency;
  };

  const defineDisabledReasons = () => {
    const isResponsiblePersonMissing = !data.responsiblePerson;
    const isAnyForecastMissing = data.positions.some((position: Position) => !position.forecastDate) as boolean;
    const isAnyCompensationMissing = data.workingConditions.some(
      (workingCondition: WorkingCondition) => !isCompensationFullyProvided(workingCondition.compensation),
    ) as boolean;

    return {
      disabled: isResponsiblePersonMissing || isAnyForecastMissing || isAnyCompensationMissing,
      disabledReason: (
        <ul>
          {isResponsiblePersonMissing ? <li>Can not approve this vacancy, as responsible person missing.</li> : <></>}
          {isAnyForecastMissing ? <li>Can not approve this vacancy, as some positions forecasts missing.</li> : <></>}
          {isAnyCompensationMissing ? (
            <li>Can not approve this vacancy, as some working conditions compensations missing.</li>
          ) : (
            <></>
          )}
        </ul>
      ),
    };
  };

  if (status !== VACANCY_STATUS.REQUIRES_REVIEW) {
    return null;
  }

  const disabledReasons = defineDisabledReasons();
  const approveButton = (
    <ButtonWithConfirmation
      displayMessage="Do you really want to approve this vacancy?"
      submitMethod={() => approveVacancy(id)}
      afterSubmit={{
        successMessage: 'The Vacancy has been successfully approved.',
        errorMessage: "Can't approve vacancy.",
        execute: () => {
          if (setGeneralContextData) {
            setGeneralContextData();
          }
        },
      }}
      permissionToCheck={null}
      disabled={disabledReasons.disabled}
    >
      Approve
    </ButtonWithConfirmation>
  );

  return isGranted(RECRUITING_VACANCY_APPROVE_VACANCY, RECRUITING_CONTEXT) ? (
    <>
      {disabledReasons.disabled && disabledReasons.disabledReason ? (
        <Tooltip title={disabledReasons.disabledReason}>
          <div>{approveButton}</div>
        </Tooltip>
      ) : (
        approveButton
      )}
    </>
  ) : null;
};

export default ApproveVacancyButton;
