import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { useParams } from 'react-router-dom';

export const VACANCY_GROUP_PATH = '/vacancy-group';

export const useVacancyGroupRoute = () => {
  const routers = useRoute({ listRoute: VACANCY_GROUP_PATH });

  return {
    ...routers,
    sourcingRoute: `${routers.singleEntityRoute}/sourcing`,
    publicationsRoute: `${routers.singleEntityRoute}/publications`,
    createPublicationsRoute: `${routers.singleEntityRoute}/publications/create`,
    updatePublicationRoute: `${routers.singleEntityRoute}/publications/:publicationId/edit`,
    publicationRoute: `${routers.singleEntityRoute}/publications/:publicationId`,
    historyRoute: `${routers.singleEntityRoute}/history`,
  };
};

export const useVacancyGroupUrl = () => {
  const urls = useEntityUrl({
    baseLink: VACANCY_GROUP_PATH,
  });

  return {
    ...urls,
    newDashboard: `${urls.getListUrl()}/new-dashboard`,
    getPublicationsUrl: (id: string) => `${urls.getSingleEntityUrl(id)}/publications`,
    getSourcingUrl: (id: string) => `${urls.getSingleEntityUrl(id)}/sourcing`,
    getCreatePublicationUrl: (id: string) => `${urls.getSingleEntityUrl(id)}/publications/create`,
    getUpdatePublicationUrl: (id: string, publicationId: string) =>
      `${urls.getSingleEntityUrl(id)}/publications/${publicationId}/edit`,
    getPublicationUrl: (id: string, publicationId: string) =>
      `${urls.getSingleEntityUrl(id)}/publications/${publicationId}`,
    getHistoryUrl: (id: string) => `${urls.getSingleEntityUrl(id)}/history`,
  };
};

export const usePublicationsUrl = () => {
  const { getSingleEntityUrl } = useVacancyGroupUrl();
  const { id } = useParams<Dictionary<string>>();

  return `${getSingleEntityUrl(id)}/publications`;
};

export const useVacancyGroupId = () => {
  const { id } = useParams<Dictionary<string>>();
  return id!;
};
