import { TimelineRegistry } from 'shared/uibuilder/timeline/timelineRegistryHelper';
import useAuthorization from 'shared/authorization/authorizationService';
import { MenuItem } from '@mui/material';
import React from 'react';
import AddNewActivityButtonMenu from './AddNewActivityButtonMenu';

type AddCandidateTimelineItemInputProps = {
  registries: TimelineRegistry[];
  selectItemCallback: (registry: TimelineRegistry) => void;
};

type RegistriesMap = Dictionary<TimelineRegistry>;

const AddCandidateTimelineItemInput = ({ registries, selectItemCallback }: AddCandidateTimelineItemInputProps) => {
  const { isGranted } = useAuthorization();
  const registriesMap: RegistriesMap = {};

  registries.forEach(registry => {
    registriesMap[registry.name] = registry;
  });

  const onMenuItemClick = (registryName: string) => {
    selectItemCallback(registriesMap[registryName]);
  };

  const getMenuItemLayout = ({ name, source }: TimelineRegistry) => {
    return (
      <MenuItem onClick={() => onMenuItemClick(name)} key={`item-${source}`}>
        {name}
      </MenuItem>
    );
  };

  const buttons = registries
    .filter(registry => !registry.permissionToCheck || isGranted(registry.permissionToCheck))
    .filter(registry => !registry.withoutAddFunctionality)
    .map(registry => getMenuItemLayout(registry));

  return <>{buttons.length ? <AddNewActivityButtonMenu>{buttons}</AddNewActivityButtonMenu> : null}</>;
};
export default AddCandidateTimelineItemInput;
