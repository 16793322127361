import React from 'react';
import { Checkbox, ListItemText } from '@mui/material';
import Box from 'uibuilder/Box';
import { ChipFilterInputProps } from '../useChipFilterLayout';

type AccordionCheckboxesFilterProps = {
  options: string[];
  isCustomChecked?: (value: string, curentSelectedVelues: string[]) => boolean;
  isCustomChange?: boolean;
  mapOption?: (value: string) => string;
} & ChipFilterInputProps;

const AccordionCheckboxesFilter = ({
  options,
  onChangeCallback,
  isCustomChecked,
  isCustomChange,
  mapOption,
  value = [],
}: AccordionCheckboxesFilterProps) => {
  const handleOptionClick = (option: string) => {
    if (isCustomChange) {
      onChangeCallback!(option);
      return;
    }

    const newValues = value?.includes(option) ? value.filter(i => i !== option) : [...(value || []), option];

    if (onChangeCallback) onChangeCallback!(newValues);
  };

  return (
    <>
      {options.map((option: string) => (
        <Box key={option} sx={{ display: 'flex', alignItems: 'center' }} onClick={() => handleOptionClick(option)}>
          <Checkbox
            sx={{ padding: 0, ml: '10px' }}
            size="small"
            checked={isCustomChecked ? isCustomChecked(option, value) : value.includes(option)}
          />
          <ListItemText sx={{ m: 0 }} primary={mapOption ? mapOption(option) : option} />
        </Box>
      ))}
    </>
  );
};

export default AccordionCheckboxesFilter;
