import { Chip, Typography } from '@mui/material';
import { useDateService } from 'shared/uibuilder/dateService';
import React from 'react';
import Box from 'uibuilder/Box';
import { commonChipStyles } from './WorkingStatusChip';

interface BirthdayInfoProps {
  dateOfBirth: string | Date;
}

const BirthdayInfo: React.FC<BirthdayInfoProps> = ({ dateOfBirth }) => {
  const { getToday, getMomentDateObj, getDaysBetween, getMonthsBetween } = useDateService();

  const today = getToday();
  const birthDate = getMomentDateObj(dateOfBirth);

  const age = today.diff(birthDate, 'years');

  const nextBirthday = birthDate.clone().year(today.year());

  if (nextBirthday.isBefore(today)) {
    nextBirthday.add(1, 'year');
  }

  const daysUntilBirthday = getDaysBetween(nextBirthday, today);

  let birthdayText = '';

  if (daysUntilBirthday === 0) {
    birthdayText = 'Birthday today';
  } else if (daysUntilBirthday <= 30) {
    birthdayText = `Birthday in ${daysUntilBirthday} day${daysUntilBirthday !== 1 ? 's' : ''}`;
  } else {
    const monthsUntilBirthday = Math.abs(Math.floor(getMonthsBetween(nextBirthday, today) || 0));
    birthdayText = `Birthday in ${monthsUntilBirthday} month${monthsUntilBirthday !== 1 ? 's' : ''}`;
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
      <Typography sx={{ fontSize: '12px' }}>{`${age} years old`}</Typography>
      <Box>
        <Chip sx={{ ...commonChipStyles, backgroundColor: 'var(--mui-palette-neutral-40)' }} label={birthdayText} />
      </Box>
    </Box>
  );
};

export default BirthdayInfo;
