/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import BaseCandidateTimelineRecordLayout from 'erp/candidate/Timeline/CandidateTimelineRecord/BaseCandidateTimelineRecordLayout';
import { candidateTimelineRecords } from 'erp/candidate/Timeline/shared/input/CandidateTimelineRecordTypeDropdown';
import { READ, UPDATE } from 'crm/crmService';
import Typography from 'uibuilder/Typography';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import FieldListLayout from 'uibuilder/layout/field/FieldListLayout';
import {
  INTERACTION_BASE_FINANCE_DATA,
  INTERACTION_CALCULATED_FINANCE_DATA,
  INTERACTION_PROBATION_FINANCE_DATA,
} from '../../candidateService';
import { CANDIDATE_CONTEXT } from '../../CandidateContext';
import Show from 'shared/uibuilder/show/Show';
import useAuthorization from 'shared/authorization/authorizationService';
import useCandidateOfferService, { RECRUITING_OFFER_READ_CALCULATED_AMOUNT_GROSS } from './candidateOfferService';
import CandidateOfferCompensationDetailsField from 'erp/candidate/offercompensation/Show/CandidateOfferCompensationDetailsField';
import Box from 'uibuilder/Box';
import TimelineOfferButtonGroup from './TimelineOfferButtonGroup';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import IconButton from 'uibuilder/button/IconButton';
import StatusChip from 'erp/candidate/offercompensation/createupdate/StatusChip';
import ShowDate from 'shared/uibuilder/ShowDate';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import { CANDIDATE_TIMELINE_OFFER_STATUSES } from '../constants';
import styles from '../CandidateTimeline.module.scss';

type OfferTimelineRecordLayoutProps = {
  onEdit: () => void;
  actionName: string;
};

const OfferTimelineRecordLayout = ({ onEdit, actionName, ...props }: OfferTimelineRecordLayoutProps) => {
  const { getById, getOfferPermissions } = useCandidateOfferService();

  const [isShown, setIsShown] = useState(false);

  const { isGranted } = useAuthorization();

  const { data: entity } = useShowContext();

  const [canEditStatus, setCanEditStatus] = useState(false);

  const buttons = [<EditTimelineRecordButton permissionToCheck={UPDATE} key="edit" onEdit={onEdit} />];

  useEffect(() => {
    if (isShown) {
      (async () => {
        const permissions = await getOfferPermissions(entity.offerId);
        if (permissions.includes('UPDATE_STATUS')) {
          setCanEditStatus(true);
        }
      })();
    }
  }, [isShown]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <BaseCandidateTimelineRecordLayout
      permissionToCheck={READ}
      actionName={actionName}
      timelineType={candidateTimelineRecords.OFFER}
      buttons={entity.offerStatus === CANDIDATE_TIMELINE_OFFER_STATUSES.DRAFT ? buttons : []}
      fields={
        <>
          <Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography>Offer was added</Typography>

              {entity.offerStatus === null ? (
                <>
                  <StatusChip status={entity.offerStatus} />
                  <Box p={2.5} />
                </>
              ) : (
                <>
                  {isShown ? (
                    <IconButton onClick={() => setIsShown(false)}>
                      <VisibilityOffOutlined />
                    </IconButton>
                  ) : (
                    <>
                      <StatusChip status={entity.offerStatus} />
                      <IconButton onClick={() => setIsShown(true)}>
                        <VisibilityOutlined />
                      </IconButton>
                    </>
                  )}
                </>
              )}
            </Box>
            {isShown ? (
              <Show getDataMethod={() => getById(entity.offerId)}>
                <Box display="flex" justifyContent="space-between" alignItems="center" pb={2}>
                  <StatusChip status={entity.offerStatus} />
                  {entity.updatedAt && (
                    <Typography sx={{ fontSize: '10px', color: 'gray', fontStyle: 'italic' }}>
                      Modified:
                      <ShowDate dateUTC={entity.getValueBySource('updatedAt')} format={DATE_FORMAT.FULL} />
                    </Typography>
                  )}
                </Box>
                <Box display="flex" flexDirection="row">
                  <FieldListLayout source="compensationDetails" className={styles.full_width_candidate_offer_layout}>
                    <CandidateOfferCompensationDetailsField
                      canViewBaseFinanceFields={isGranted(INTERACTION_BASE_FINANCE_DATA, CANDIDATE_CONTEXT)}
                      canViewProbationField={isGranted(INTERACTION_PROBATION_FINANCE_DATA, CANDIDATE_CONTEXT)}
                      canViewCalculatedGrossField={
                        isGranted(INTERACTION_CALCULATED_FINANCE_DATA, CANDIDATE_CONTEXT) &&
                        isGranted(RECRUITING_OFFER_READ_CALCULATED_AMOUNT_GROSS)
                      }
                    />
                  </FieldListLayout>
                </Box>
                {canEditStatus && <TimelineOfferButtonGroup status={entity.offerStatus} id={entity.offerId} />}
              </Show>
            ) : null}
          </Box>
        </>
      }
      {...props}
    />
  );
};

export default OfferTimelineRecordLayout;
