/* istanbul ignore file */
import React from 'react';
import Page404 from 'shared/uibuilder/Page404';
import { Route } from 'react-router-dom';
import { LeaveRouter } from 'erp/leave';
import { Interview } from 'erp/interview';
import { PayrollRouter } from 'erp/payroll';
import { EmployeesRouter } from 'erp/employee';
import CandidateRouter from 'erp/candidate/CandidateRouter';
import { ContractsRouter } from 'erp/contracts';
import { WorkingHoursRouter } from 'erp/workinghours';
import { CompetencyRouter } from 'erp/qualification/competency';
import { VacancyRouter } from 'erp/recruitment/vacancy';
import { ProjectRouter } from 'erp/project';
import { AssignmentRouter } from 'erp/assignment';
import { BudgetRouter } from 'erp/budget';
import { BudgetReportRouter } from 'erp/budget/report';
import { SkillBasedQueueRouter } from 'erp/skillbasedqueue';
import { VacancyPublicationRouter } from 'erp/recruitment/vacancy/publication';
import { PerformanceReportRouter } from 'erp/performanceReport';
import { ServiceRouter } from 'erp/service';
import { CostAccountingRouter } from 'erp/costaccounting';
import { LedgerRouter } from 'financialAnalytic/ledger';
import { JournalEntryRouter } from 'financialAnalytic/journalEntry';
import { InvoiceRouter } from 'financialAnalytic/invoice';
import { CustomerInvoiceRouter } from 'financialAnalytic/customerInvoices';
import { AssetRouter } from 'financialAnalytic/assets';
import { ExpenseRouter } from 'financialAnalytic/expenses';
import { PurchaseOrderRouter } from 'financialAnalytic/purchaseOrder';
import { NewCompetencyRouter } from 'erp/qualification/newCompetency';
import { ApplicationsRouter } from 'erp/recruitment/applications';
import { GeneratedPayrollsRouter } from 'erp/payroll/generatedPayrolls';
import { PayrollDistributionRouter } from 'erp/payroll/payrollDistribution';
import { ReconciliationRouter } from 'erp/costaccounting/reconcilation';
import { WasteRouter } from 'erp/costaccounting/waste';
import { AccountingCycleRouter } from 'erp/costaccounting/accountingCycle';
import { CorporateEventRouter } from 'financialAnalytic/corporateEvents';
import { RoomRouter } from 'erp/room';
import OfficeRouter from 'erp/office/OfficeRouter';
import SalaryForksRouter from './recruitment/salaryFork/SalaryForksRouter';
import { ServiceCatalogRouter } from 'erp/serviceCatalog';
import VacancyGroupRouter from './recruitment/newVacancy/VacancyGroupRouter';
import ReportRouter from './recruitment/report/ReportRouter';
import CandidateDuplicateScanRouter from './candidate/duplicateScan/CandidateDuplicateScanRouter';
import EmployeesDashboardRouter from './employee/dashboard/EmployeesDashboardRouter';

const ErpRouter = () => [
  <Route path={ServiceCatalogRouter.getRouterPath()} element={<ServiceCatalogRouter />} />,
  <Route path={LeaveRouter.getRouterPath()} element={<LeaveRouter />} />,
  <Route path={PayrollRouter.getRouterPath()} element={<PayrollRouter />} />,
  <Route path={CandidateRouter.getRouterPath()} element={<CandidateRouter />} />,
  <Route path={CandidateDuplicateScanRouter.getRouterPath()} element={<CandidateDuplicateScanRouter />} />,
  <Route path={VacancyRouter.getRouterPath()} element={<VacancyRouter />} />,
  <Route path={VacancyGroupRouter.getRouterPath()} element={<VacancyGroupRouter />} />,
  <Route path={ReportRouter.getRouterPath()} element={<ReportRouter />} />,
  <Route path={SalaryForksRouter.getRouterPath()} element={<SalaryForksRouter />} />,
  <Route path={VacancyPublicationRouter.getRouterPath()} element={<VacancyPublicationRouter />} />,
  <Route path={EmployeesDashboardRouter.getRouterPath()} element={<EmployeesDashboardRouter />} />,
  <Route path={EmployeesRouter.getRouterPath()} element={<EmployeesRouter />} />,
  <Route path={ContractsRouter.getRouterPath()} element={<ContractsRouter />} />,
  <Route path={WorkingHoursRouter.getRouterPath()} element={<WorkingHoursRouter />} />,
  <Route path={SkillBasedQueueRouter.getRouterPath()} element={<SkillBasedQueueRouter />} />,
  <Route path={BudgetReportRouter.getRouterPath()} element={<BudgetReportRouter />} />,
  <Route path={CostAccountingRouter.getRouterPath()} element={<CostAccountingRouter />} />,
  <Route path={NewCompetencyRouter.getRouterPath()} element={<NewCompetencyRouter />} />,
  <Route path={CompetencyRouter.getRouterPath()} element={<CompetencyRouter />} />,
  <Route path={ProjectRouter.getRouterPath()} element={<ProjectRouter />} />,
  <Route path={ServiceRouter.getRouterPath()} element={<ServiceRouter />} />,
  <Route path={BudgetRouter.getRouterPath()} element={<BudgetRouter />} />,
  <Route path={AssignmentRouter.getRouterPath()} element={<AssignmentRouter />} />,
  <Route path={PerformanceReportRouter.getRouterPath()} element={<PerformanceReportRouter />} />,
  <Route path={LedgerRouter.getRouterPath()} element={<LedgerRouter />} />,
  <Route path={JournalEntryRouter.getRouterPath()} element={<JournalEntryRouter />} />,
  <Route path={InvoiceRouter.getRouterPath()} element={<InvoiceRouter />} />,
  <Route path={CustomerInvoiceRouter.getRouterPath()} element={<CustomerInvoiceRouter />} />,
  <Route path={AssetRouter.getRouterPath()} element={<AssetRouter />} />,
  <Route path={CorporateEventRouter.getRouterPath()} element={<CorporateEventRouter />} />,
  <Route path={ExpenseRouter.getRouterPath()} element={<ExpenseRouter />} />,
  <Route path={PurchaseOrderRouter.getRouterPath()} element={<PurchaseOrderRouter />} />,
  <Route path={GeneratedPayrollsRouter.getRouterPath()} element={<GeneratedPayrollsRouter />} />,
  <Route path={ApplicationsRouter.getRouterPath()} element={<ApplicationsRouter />} />,
  <Route path={PayrollDistributionRouter.getRouterPath()} element={<PayrollDistributionRouter />} />,
  <Route path={ReconciliationRouter.getRouterPath()} element={<ReconciliationRouter />} />,
  <Route path={WasteRouter.getRouterPath()} element={<WasteRouter />} />,
  <Route path={AccountingCycleRouter.getRouterPath()} element={<AccountingCycleRouter />} />,
  <Route path={OfficeRouter.getRouterPath()} element={<OfficeRouter />} />,
  <Route path={RoomRouter.getRouterPath()} element={<RoomRouter />} />,
  <Route path={Interview.getRouterPath()} element={<Interview.Router />} />,
  <Route path="*" element={<Page404 />} />,
];

export default ErpRouter;
