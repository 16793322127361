import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const RejectPeopleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} stroke={props?.stroke || '#EC221F'}>
      <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13 16C13 13.79 10.314 12 7 12C3.686 12 1 13.79 1 16M15 11L17 9M17 9L19 7M17 9L15 7M17 9L19 11M7 9C5.93913 9 4.92172 8.57857 4.17157 7.82843C3.42143 7.07828 3 6.06087 3 5C3 3.93913 3.42143 2.92172 4.17157 2.17157C4.92172 1.42143 5.93913 1 7 1C8.06087 1 9.07828 1.42143 9.82843 2.17157C10.5786 2.92172 11 3.93913 11 5C11 6.06087 10.5786 7.07828 9.82843 7.82843C9.07828 8.57857 8.06087 9 7 9Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default RejectPeopleIcon;
