/* istanbul ignore file */
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import { UrlParams } from 'shared/routing/useEntityUrl';
import Page404 from 'shared/uibuilder/Page404';
import EF1CreatePage from 'externalform/ef1/create/EF1CreatePage';
import PublicLayout from 'layout/Public';
import { Router, Route } from 'shared/routing';

export const EXTERNAL_FORM_ID = 'formId';
export const EXTERNAL_FORMS_PATH = '/external-forms';

export const useExternalFormId = () => {
  const urlParams: UrlParams = useParams<Dictionary<string>>();

  return urlParams[EXTERNAL_FORM_ID];
};

const ExternalFormRouter: Router = () => {
  const createRoute = `/:${EXTERNAL_FORM_ID}`;

  return (
    <Routes>
      <Route
        path={createRoute}
        element={
          <PublicLayout sx={{ pddiongTop: '15px' }}>
            <EF1CreatePage />
          </PublicLayout>
        }
      />

      <Route path="*" element={<Page404 />} />
    </Routes>
  );
};

ExternalFormRouter.getRouterPath = () => `${EXTERNAL_FORMS_PATH}/*`;

export default ExternalFormRouter;
