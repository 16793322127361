/* istanbul ignore file */
import React from 'react';
import MuiStepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from 'uibuilder/button/Button';
import Check from '@mui/icons-material/Check';
import { StepIconProps } from '@mui/material';
import './Stepper.scss';
import { StepOwnProps } from '@mui/material/Step/Step';

const StepIcon: React.FC<StepIconProps> = ({ icon, completed, className }) => (
  <div className={className}>{completed ? <Check /> : icon}</div>
);

export enum ORIENTATION {
  HORIZONTAL = 'horizontal',
  VERTICAL = 'vertical',
}

type StepProps = {
  label: string;
  buttonLabel?: string;
  description?: React.ReactNode;
  isButtonDisabled?: boolean;
  id?: string;
  props?: StepOwnProps;
};

export interface StepperProps {
  activeStep: number;
  steps: StepProps[];
  handleNext?: () => void;
  handleBack?: () => void;
  isContent?: boolean;
  customStepComponent?: React.ElementType<StepIconProps, keyof JSX.IntrinsicElements>;
  orientation?: ORIENTATION;
  className?: string;
  stepLabelClassName?: string;
  alternativeLabel?: boolean;
  connector?: React.ReactElement;
}

const Stepper = ({
  steps,
  activeStep,
  handleNext,
  handleBack,
  isContent = true,
  orientation = ORIENTATION.VERTICAL,
  customStepComponent,
  className = 'stepper',
  stepLabelClassName = '',
  alternativeLabel = false,
  connector,
}: StepperProps) => {
  return steps.length ? (
    <MuiStepper
      alternativeLabel={alternativeLabel}
      activeStep={activeStep}
      orientation={orientation}
      className={className}
      connector={connector}
    >
      {steps.map(step => (
        <Step key={step.id || step.label} {...step.props}>
          <StepLabel className={stepLabelClassName} StepIconComponent={customStepComponent || StepIcon}>
            {step.label}
          </StepLabel>
          {isContent ? (
            <StepContent>
              {step.description}
              <div className="stepper__buttons-wrapper">
                <Button onClick={handleNext} className="stepper__next-button" disabled={step.isButtonDisabled}>
                  {step.buttonLabel}
                </Button>
                {activeStep ? (
                  <Button className="stepper__back-button" link onClick={handleBack}>
                    Back
                  </Button>
                ) : null}
              </div>
            </StepContent>
          ) : (
            <></>
          )}
        </Step>
      ))}
    </MuiStepper>
  ) : null;
};

export default Stepper;
