/* istanbul ignore file */
import React from 'react';
import classnames from 'classnames';
import Loading, { LoaderSize } from 'shared/uibuilder/Loading';

const SmallLoader = ({ className, size }: { className?: string; size?: string }) => {
  return (
    <div className={classnames('small-loader', className)}>
      <Loading size={size || LoaderSize.Small} />
    </div>
  );
};

export default SmallLoader;
