import React from 'react';
import { Card, Stack } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Box from 'uibuilder/Box';
import Typography from 'uibuilder/Typography';
import { BadgeField, NumberField, TextField } from 'shared/uibuilder/field';
import PriorityBadge from 'erp/recruitment/recruitingBoard/shared/badge/PriorityBadge';
import DateTimerField from 'shared/uibuilder/field/DateTimerField';
import StatusNotification from './field/StatusNotification';
import ExpiredNotification from './field/ExpiredNotification';
import People from 'uibuilder/AppIcon/Icons/People';
import VacancyGroupLinkField from '../../newVacancy/shared/VacancyGroupLinkField';
import ViewStatusNotification from './field/ViewStatusNotification';

const EntityHeader: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'sticky',
        zIndex: 100,
        p: '1px',
        left: 0,
        backgroundColor: '#F5F5F5',
      }}
    >
      <Card
        sx={{
          border: '1px solid var(--mui-palette-border-warning)',
          maxHeight: 'max-content',
          minHeight: '96px',
          borderRadius: 1,
          p: '8px',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',

          position: 'sticky',
          top: '64px',
        }}
      >
        <Stack direction="column">
          <Stack direction="row" alignItems="start" justifyContent="space-between" gap={1}>
            <Box sx={{ fontSize: 11, color: '#3f51b5' }}>
              <ViewStatusNotification />
              <VacancyGroupLinkField source="id" nameSource="name" />
            </Box>
            <BadgeField badges={[<PriorityBadge idSource="id" prioritySource="priority" />]} />
          </Stack>
          <Typography variant="subtitle1" sx={{ fontSize: 9, color: '#757575' }}>
            <TextField source="specificNote" />
          </Typography>
        </Stack>

        <Stack direction="row" alignItems="center" gap={2}>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <AccessTimeIcon sx={{ color: '#757575', fontSize: 11 }} />
            <Typography sx={{ color: '#424242', fontSize: 9 }}>
              <DateTimerField source="forecastDate" />
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <People sx={{ color: '#757575', fontSize: 11 }} />
            <Typography sx={{ color: '#424242', fontSize: 9 }}>
              <NumberField source="hired" /> / <NumberField source="needHire" />
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap={2} sx={{ marginTop: '8px' }}>
          <StatusNotification source="status" size="XS" />
          <ExpiredNotification source="isVacancyInsideGroupExpired" size="XS" />
        </Stack>
      </Card>
    </Box>
  );
};

export default EntityHeader;
