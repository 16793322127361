import { Template } from './MarkdownTypes';

// eslint-disable-next-line import/prefer-default-export
export const fetchTemplate = async (params: {
  templateFileName: string;
  basePath?: string;
}): Promise<Template | null> => {
  const { templateFileName, basePath = '/templates/' } = params;

  if (!templateFileName) {
    throw new Error('Template file name is required');
  }

  const response = await fetch(`${basePath}${templateFileName}`);
  if (!response.ok) {
    throw new Error(`Failed to load template: ${templateFileName}`);
  }

  const content = await response.text();
  return {
    name: templateFileName,
    content,
  };
};
