import React from 'react';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import useBudgetCategorySuggestionService, {
  isBudgetCategoryRequired,
} from 'erp/serviceCatalog/budgets/shared/service/budgetCatagorySuggestionService';
import { DefaultInputPropTypes } from 'shared/uibuilder/form/input';

const BudgetCategoryDropdown = ({
  dateValue,
  accountIdValue,
  ...props
}: DefaultInputPropTypes<string> & { dateValue: string; accountIdValue: string }) => {
  const { suggestBudgetCategories } = useBudgetCategorySuggestionService();
  const isFieldRequired = isBudgetCategoryRequired(accountIdValue);

  const loadBudgetCategorySuggestions = async () => {
    if (!dateValue || !accountIdValue) {
      return { result: [] };
    }

    try {
      const { result } = await suggestBudgetCategories(dateValue, accountIdValue);
      return { result };
    } catch (error) {
      return { result: [] };
    }
  };

  return (
    <LoadDropdown
      loadDataMethod={loadBudgetCategorySuggestions}
      mapResults={item => ({
        value: item,
        label: item,
      })}
      placeholder="Select Budget Category"
      showLoader
      deps={[dateValue, accountIdValue]}
      isClearValueByUpdateDeps
      isRequired={isFieldRequired}
      {...props}
    />
  );
};

export default BudgetCategoryDropdown;
