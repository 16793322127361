import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import style from './SkillsBadges.module.scss';
import { v4 as uuidv4 } from 'uuid';
import Stack from '@mui/material/Stack';

export interface AttachmentsListFieldProps {
  value?: string[];
  label?: string;
  isDownloadAvailable?: boolean;
  isOneArtifact?: boolean;
  width?: number;
}

const SkillsBadges = () => {
  const { getValue } = useFieldHelper({ source: 'skills' });
  const displayValueRaw = getValue();
  const displayValue = Array.isArray(displayValueRaw) ? displayValueRaw : [];
  const uniqueSet = new Set(displayValue);
  const uniqueArray = Array.from(uniqueSet);
  if (uniqueArray.length === 0) {
    return '';
  }
  return (
    <Stack
      sx={{
        marginTop: '0.5rem !important',
      }}
    >
      <div className={style.skillBadgeParent}>
        {uniqueArray.map((skill: string) => {
          const badgeId = uuidv4();
          return (
            <div key={badgeId} className={style.skillBadge}>
              {' '}
              {skill}
            </div>
          );
        })}
      </div>
    </Stack>
  );
};

export default SkillsBadges;
