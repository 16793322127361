import React, { useState } from 'react';
import useMessageService, { ErrorMessage, SuccessMessage } from '../message/messageService';
import Box from 'uibuilder/Box';
import { Button } from 'uibuilder/button';

interface PdfGeneratorProps {
  onGeneratePdf: () => Promise<Blob>;
  fileName: string;
  buttonText?: string;
  loadingText?: string;
  successMessage?: string;
  errorMessage?: string;
  buttonSx?: object;
  containerSx?: object;
}

const PdfGenerator: React.FC<PdfGeneratorProps> = ({
  onGeneratePdf,
  fileName,
  buttonText = 'Save PDF',
  loadingText = 'Generating PDF...',
  successMessage = 'PDF successfully generated and downloaded',
  errorMessage = 'Error generating PDF',
  buttonSx = {},
  containerSx = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'end',
    mt: 3,
  },
}) => {
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const { addMessage } = useMessageService();

  const handleSavePdf = async () => {
    try {
      setIsGenerating(true);

      const pdfBlob = await onGeneratePdf();

      const downloadLink = document.createElement('a');
      downloadLink.href = URL.createObjectURL(pdfBlob);
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      addMessage(new SuccessMessage(successMessage));
    } catch (error) {
      addMessage(new ErrorMessage(`${errorMessage}: ${error instanceof Error ? error.message : String(error)}`));
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <Box sx={containerSx}>
      <Button onClick={handleSavePdf} disabled={isGenerating} sx={buttonSx}>
        {isGenerating ? loadingText : buttonText}
      </Button>
    </Box>
  );
};

export default PdfGenerator;
