import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const LocationIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2045 17.474C11.163 16.6043 12.05 15.6591 12.857 14.6473C14.557 12.5115 15.5912 10.4057 15.6612 8.53317C15.6889 7.77217 15.5629 7.01339 15.2908 6.30215C15.0187 5.59092 14.606 4.94183 14.0774 4.39366C13.5489 3.84549 12.9152 3.40948 12.2143 3.11167C11.5135 2.81387 10.7598 2.66038 9.99828 2.66038C9.23677 2.66038 8.48308 2.81387 7.78221 3.11167C7.08135 3.40948 6.4477 3.84549 5.91912 4.39366C5.39053 4.94183 4.97786 5.59092 4.70575 6.30215C4.43363 7.01339 4.30767 7.77217 4.33536 8.53317C4.4062 10.4057 5.4412 12.5115 7.14036 14.6473C7.94742 15.6591 8.83444 16.6043 9.79286 17.474C9.88509 17.5573 9.9537 17.6179 9.9987 17.6557L10.2045 17.474ZM9.3837 18.4448C9.3837 18.4448 3.33203 13.3482 3.33203 8.33317C3.33203 6.56506 4.03441 4.86937 5.28465 3.61913C6.5349 2.36888 8.23059 1.6665 9.9987 1.6665C11.7668 1.6665 13.4625 2.36888 14.7127 3.61913C15.963 4.86937 16.6654 6.56506 16.6654 8.33317C16.6654 13.3482 10.6137 18.4448 10.6137 18.4448C10.277 18.7548 9.72286 18.7515 9.3837 18.4448ZM9.9987 10.6665C10.6175 10.6665 11.211 10.4207 11.6486 9.98309C12.0862 9.5455 12.332 8.95201 12.332 8.33317C12.332 7.71433 12.0862 7.12084 11.6486 6.68325C11.211 6.24567 10.6175 5.99984 9.9987 5.99984C9.37986 5.99984 8.78637 6.24567 8.34878 6.68325C7.9112 7.12084 7.66536 7.71433 7.66536 8.33317C7.66536 8.95201 7.9112 9.5455 8.34878 9.98309C8.78637 10.4207 9.37986 10.6665 9.9987 10.6665ZM9.9987 11.6665C9.11464 11.6665 8.2668 11.3153 7.64167 10.6902C7.01655 10.0651 6.66536 9.21723 6.66536 8.33317C6.66536 7.44912 7.01655 6.60127 7.64167 5.97615C8.2668 5.35103 9.11464 4.99984 9.9987 4.99984C10.8828 4.99984 11.7306 5.35103 12.3557 5.97615C12.9808 6.60127 13.332 7.44912 13.332 8.33317C13.332 9.21723 12.9808 10.0651 12.3557 10.6902C11.7306 11.3153 10.8828 11.6665 9.9987 11.6665Z"
          fill="#666666"
        />
      </svg>
    </SvgIcon>
  );
};

export default LocationIcon;
