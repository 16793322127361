import { useFinanceApi } from 'api';

export interface SensitiveRecruitingInfo {
  value: number;
  id: string;
}

export interface BatchSensitiveRecruitingInfo {
  value: number;
  externalId: string;
}

export interface SensitiveRecruitingInfoWithId extends BatchSensitiveRecruitingInfo {
  id: string;
}

const useRecruitingFinanceService = () => {
  const { sendPostRequest: sendFinancePostRequest } = useFinanceApi();

  const saveBatchSensitiveRecruitingInfo = async (
    sensitiveInfo: BatchSensitiveRecruitingInfo[],
  ): Promise<SensitiveRecruitingInfoWithId[]> => {
    const res = await sendFinancePostRequest('/recruiting-finance/batch', { commands: sensitiveInfo });
    return res.json();
  };

  const saveSensitiveRecruitingInfo = async (sensitiveInfo: StringOrNumber): Promise<SensitiveRecruitingInfo> => {
    const res = await sendFinancePostRequest('/recruiting-finance', { value: sensitiveInfo });

    return res.json();
  };

  const searchSensitiveRecruitingInfo = async (sensitiveInfo: string[]): Promise<SensitiveRecruitingInfo[]> => {
    const res = await sendFinancePostRequest('/recruiting-finance/search', { ids: sensitiveInfo });
    return res.json();
  };

  return {
    searchSensitiveRecruitingInfo,
    saveSensitiveRecruitingInfo,
    saveBatchSensitiveRecruitingInfo,
  };
};

export default useRecruitingFinanceService;
