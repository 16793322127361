import React from 'react';
import { Box } from '@mui/material';
import { SelectInput } from 'shared/uibuilder/form/input';
import useSalaryForkService from '../useSalaryForkService';

interface SalaryForkMenuProps {
  onSpecializationChange: (specializationId: string) => void;
  selectedSpecialization: string | null;
}

const SalaryForkMenu = ({ onSpecializationChange, selectedSpecialization }: SalaryForkMenuProps) => {
  const { getSpecializationOptions } = useSalaryForkService();
  const [options, setOptions] = React.useState([]);

  React.useEffect(() => {
    const loadOptions = async () => {
      const specializations = await getSpecializationOptions();
      setOptions(
        specializations.map((spec: any) => ({
          value: spec.alias,
          label: spec.name,
        })),
      );
    };
    loadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ mb: 2 }}>
      <SelectInput
        menuPlacement="auto"
        source="specialization"
        label=""
        value={selectedSpecialization}
        placeholder="Select Specialization"
        options={options}
        onChangeCallback={values => {
          if (values.specialization) {
            onSpecializationChange(values.specialization);
          }
        }}
      />
    </Box>
  );
};

export default SalaryForkMenu;
