/* istanbul ignore file */
// libs
import React from 'react';
import { Routes, useParams } from 'react-router-dom';
// components
import OpportunityContext from 'crm/opportunity/OpportunityContext';
import Page404 from 'shared/uibuilder/Page404';
import { ProtectedRouter } from 'routing';
import { Navigate, Route } from 'shared/routing';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import OpportunityViewPage from 'crm/opportunity/show';
import OpportunityTimelinePage from 'crm/opportunity/Timeline';
import OpportunitiesListPage from 'crm/opportunity/list';
import { CreateOpportunity, UpdateOpportunity } from 'crm/opportunity/createupdate';
// services
import { CREATE_OPPORTUNITY, READ_TIMELINE } from 'crm/crmService';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { READ_OPPORTUNITIES_LIST, READ_OPPORTUNITY } from 'crm/opportunity/shared/opportunityService';
import ShowOpportunityContext from 'crm/opportunity/show/ShowOpportunityContext';

export const OPPORTUNITY_PATH = '/crm/opportunities';

export const useOpportunityRoute = () => {
  const routes = useRoute({ listRoute: OPPORTUNITY_PATH, timelinePostfix: 'activities' });
  return {
    ...routes,
    presaleTimelineRoute: `${routes.singleEntityRoute}/presale-timeline/:timelineEntryId?`,
  };
};

export const useOpportunityUrl = () => {
  const baseUrls = useEntityUrl({
    baseLink: OPPORTUNITY_PATH,
    timelinePostfix: 'activities',
  });

  const getCreateUrl = (params?: Dictionary<string>) => {
    const urlParameters = new URLSearchParams(params).toString();

    return `${baseUrls.getCreateUrl()}${urlParameters ? `?${urlParameters}` : ''}`;
  };

  return {
    ...baseUrls,
    getCreateUrl,
  };
};

export const useOpportunityId = () => useParams<Dictionary<string>>().id!;

const OpportunityRouter = () => {
  const { createRoute, updateRoute, listRoute, singleEntityRoute, timelineRoute, presaleTimelineRoute } =
    useOpportunityRoute();

  return (
    <ProtectedRouter basePath={OPPORTUNITY_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_OPPORTUNITIES_LIST}>
              <OpportunitiesListPage />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={createRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={CREATE_OPPORTUNITY}>
              <CreateOpportunity />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={singleEntityRoute}
          element={
            <OpportunityContext permissionToCheck={READ_OPPORTUNITY}>
              <OpportunityViewPage />
            </OpportunityContext>
          }
        />

        <Route
          path={updateRoute}
          element={
            <OpportunityContext permissionToCheck="UPDATE">
              <UpdateOpportunity />
            </OpportunityContext>
          }
        />

        <Route
          path={timelineRoute}
          element={
            <OpportunityContext permissionToCheck={READ_TIMELINE}>
              <ShowOpportunityContext>
                <OpportunityTimelinePage />
              </ShowOpportunityContext>
            </OpportunityContext>
          }
        />
        <Route path={presaleTimelineRoute} element={<RedirectToTimeline />} />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

const RedirectToTimeline = () => {
  const { getTimelineUrl } = useOpportunityUrl();
  const { id: oppId, timelineEntryId } = useParams<Dictionary<string>>();
  const baseUrl = getTimelineUrl(oppId);
  const url = timelineEntryId ? `${baseUrl}/${timelineEntryId}` : baseUrl;

  return <Navigate to={url} replace />;
};

OpportunityRouter.getRouterPath = () => '/opportunities/*';

export default OpportunityRouter;
