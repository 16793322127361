/* istanbul ignore file */
import React from 'react';

import useCandidateNoteService from '../candidateNoteService';
import { FormTemplateProps } from 'shared/uibuilder/form/FormTemplate';
import CreateCandidateTimelineItemLayout from './CreateCandidateTimelineItemLayout';
import NoteForm from '../../shared/form/NoteForm';
import { CreateForm } from 'shared/uibuilder/form';

export interface CreateNoteFormProps extends FormTemplateProps {
  onCancel: () => void;
}

const CreateCandidateNoteForm = ({ submitFormFunc, onCancel, ...props }: CreateNoteFormProps) => {
  const { getValidationSchema } = useCandidateNoteService();

  return (
    <CreateForm submitFormFunc={submitFormFunc} getValidationSchemaFunc={getValidationSchema} {...props}>
      <CreateCandidateTimelineItemLayout onCancel={onCancel}>
        <NoteForm />
      </CreateCandidateTimelineItemLayout>
    </CreateForm>
  );
};

export default CreateCandidateNoteForm;
