import React from 'react';
import VacancyDropdown from 'erp/candidate/shared/input/VacancyDropdown';
import FormSection from 'shared/layout/form/FormSection';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import { Box } from '@mui/material';
import CurrencyDropdown from 'erp/employee/finance/shared/inputs/CurrencyDropdown';
import NumberInput from 'shared/uibuilder/form/input/NumberInput';
import CreateForm from 'shared/uibuilder/form/CreateForm';
import FormButtonsLayout from 'shared/layout/form/FormButtonsLayout';
import validationCandidateVacancyRejectForm from './validationCandidateVacancyRejectForm';
import { REGISTRATION_LOCATION } from 'erp/recruitment/recruitingBoard/types';
import useRecruiterExtension from '../../useRecruiterExtension';

const REJECTION_REASON_CANDIDATE = {
  NO_RESPONSE: 'No response from the candidate',
  NOT_INTERESTED: 'Candidate not interested',
  NOT_LOOKING_FOR_JOB: 'Candidate not looking for a job',
  LEGALIZATION_ISSUES: 'Issues with relocation, registration, or location',
};

const CandidateVacancyRejectForm = ({ togleVacancyReject }: any) => {
  const { sendRejectionReason } = useRecruiterExtension();

  return (
    <CreateForm
      submitFormFunc={sendRejectionReason}
      getValidationSchemaFunc={() => Promise.resolve(validationCandidateVacancyRejectForm)}
      afterSubmit={{
        message: 'You are awesome! The data has been saved.',
        execute: () => {
          togleVacancyReject();
        },
      }}
    >
      <FormSection
        titleVariant="subtitle1"
        titleStyle={{ marginBottom: '4px' }}
        formStyle={{ padding: 0, border: 'none', boxShadow: 'none', width: '100%' }}
      >
        <VacancyDropdown source="vacancyGroupAlias" placeholder="Vacancy *" label="" />

        <EnumDropdown options={REGISTRATION_LOCATION} placeholder="Location" label="" source="location" />

        <EnumDropdown
          options={REJECTION_REASON_CANDIDATE as Dictionary<string>}
          placeholder="Rejection reason *"
          label=""
          source="rejectionReason"
        />

        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          gap="10px"
          sx={{
            '.MuiFormControl-root': {
              height: '58px',
            },
          }}
        >
          <NumberInput
            label=""
            placeholder="Min salary expectation"
            source="expectedCompensationFrom"
            isRequired
            sx={{ flex: '0 0 35%' }}
          />
          <NumberInput
            tooltip="Can be empty"
            label=""
            placeholder="Max salary expectation"
            source="expectedCompensationTo"
            isRequired
            sx={{ flex: '0 0 35%' }}
          />
          <CurrencyDropdown label="" placeholder="Currency" source="compensationCurrency" />
        </Box>

        <FormButtonsLayout onCancelAction={togleVacancyReject} />
      </FormSection>
    </CreateForm>
  );
};

export default CandidateVacancyRejectForm;
