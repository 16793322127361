import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const SkillsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.72031 1.28125V1.28438C2.93906 1.38438 2.24844 2.28438 2.24844 3.43438C2.24844 4.07188 2.47031 4.63438 2.79531 5.025L3.11406 5.40625L2.62344 5.49687C2.19531 5.57812 1.87969 5.80625 1.61719 6.16875C1.35156 6.52812 1.15156 7.02812 1.01094 7.6C0.754688 8.65 0.701563 9.93438 0.695312 11.0531H2.07031L2.44219 16.0031H5.45156L5.77969 11.0531H7.08906C7.08906 9.92188 7.07969 8.62187 6.84531 7.5625C6.72031 6.98438 6.52656 6.4875 6.26094 6.12813C5.99219 5.77188 5.66094 5.54375 5.18281 5.47188L4.68281 5.39375L4.99844 5C5.31094 4.60938 5.52031 4.05625 5.52031 3.43438C5.52031 2.20938 4.74531 1.28438 3.88281 1.28438C3.79219 1.28438 3.73594 1.28125 3.72031 1.28125ZM13.7484 1.53125C13.2109 1.53125 12.7797 1.96156 12.7797 2.5C12.7797 3.03844 13.2109 3.46875 13.7484 3.46875C14.2859 3.46875 14.7172 3.03844 14.7172 2.5C14.7172 1.96156 14.2859 1.53125 13.7484 1.53125ZM9.71719 2.21875V3.71875H6.49844V4.28125H9.71719V9.21875H7.99844V9.78125H9.71719V13.2188H6.49844V13.7812H9.71719V14.7812H12.2422C12.2266 14.6906 12.2172 14.5969 12.2172 14.5C12.2172 14.4031 12.2266 14.3094 12.2422 14.2188H10.2797V9.78125H12.2422C12.2266 9.69063 12.2172 9.59688 12.2172 9.5C12.2172 9.40312 12.2266 9.30937 12.2422 9.21875H10.2797V2.78125H12.2422C12.2266 2.69 12.2172 2.59594 12.2172 2.5C12.2172 2.40406 12.2266 2.31 12.2422 2.21875H9.71719ZM13.7484 8.53125C13.2109 8.53125 12.7797 8.9625 12.7797 9.5C12.7797 10.0375 13.2109 10.4688 13.7484 10.4688C14.2859 10.4688 14.7172 10.0375 14.7172 9.5C14.7172 8.9625 14.2859 8.53125 13.7484 8.53125ZM13.7484 13.5312C13.2109 13.5312 12.7797 13.9625 12.7797 14.5C12.7797 15.0375 13.2109 15.4688 13.7484 15.4688C14.2859 15.4688 14.7172 15.0375 14.7172 14.5C14.7172 13.9625 14.2859 13.5312 13.7484 13.5312Z"
          fill="#666666"
        />
      </svg>
    </SvgIcon>
  );
};

export default SkillsIcon;
