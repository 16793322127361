import React, { useEffect, useState } from 'react';
import Box from 'uibuilder/Box';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import {
  getCandidateFormData,
  CandidateData,
  SOURCING_TYPE,
  SOURCING_TYPE_OPTIONS,
  SYCOM_UI_URL,
} from 'erp/recruitment/extension/candidateFromExtensionService';
import FormSection from 'shared/layout/form/FormSection';
import VacancyDropdown from 'erp/candidate/shared/input/VacancyDropdown';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import CopyToClipboardButton from 'shared/uibuilder/CopyToClipboardButton';
import { Typography } from '@mui/material';

const ApplyToVacancyForm = ({
  isParsingFinished,
  parsedProfileData,
}: {
  isParsingFinished?: boolean;
  parsedProfileData: CandidateData;
}) => {
  const { setFormData = () => {}, data } = useFormContext();

  const [vacancyAlias, setVacancyAlias] = useState<string>();
  const [sourcingType, setSourcingType] = useState<string>();
  const [applyVacancyLink, setApplyVacancyLink] = useState<string>();

  useEffect(() => {
    if (isParsingFinished) {
      setFormData(getCandidateFormData(parsedProfileData, data as CandidateData));
    }
    // Suppressed warnings because we only need to call useEffect callback after parsing data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParsingFinished]);

  useEffect(() => {
    if (data && data.sourceType) {
      setSourcingType(data.sourceType);
    }
  }, [isParsingFinished, data]);

  useEffect(() => {
    if (vacancyAlias && sourcingType) {
      const firstNameUrlParameter = data?.name?.firstName ? `&firstName=${data.name.firstName}` : '';
      const lastNameUrlParameter = data?.name?.lastName ? `&lastName=${data.name.lastName}` : '';
      const emailUrlParameter = data?.personalEmail ? `&personalEmail=${data.personalEmail}` : '';

      const phoneNumber = data?.personalPhoneNumber;
      const formattedPhoneNumber = encodeURIComponent(
        phoneNumber && !phoneNumber.startsWith('+') ? `+${phoneNumber}` : phoneNumber,
      );
      const phoneNumberUrlParameter = phoneNumber ? `&personalPhoneNumber=${formattedPhoneNumber}` : '';

      setApplyVacancyLink(
        `${SYCOM_UI_URL}/careers/vacancy/apply/?id=${vacancyAlias}&source=${SOURCING_TYPE_OPTIONS[sourcingType]}${firstNameUrlParameter}${lastNameUrlParameter}${emailUrlParameter}${phoneNumberUrlParameter}`,
      );
    } else {
      setApplyVacancyLink('');
    }
  }, [vacancyAlias, sourcingType, data]);

  const onVacancyAliasChangeCallback = (values: FormFieldsData) => {
    setVacancyAlias(values.vacancyAlias);
  };

  const onSourcingTypeChangeCallback = (values: FormFieldsData) => {
    setSourcingType(values.sourcingType);
  };

  return (
    <>
      <FormSection
        titleVariant="subtitle1"
        titleStyle={{ marginBottom: '4px' }}
        formStyle={{ padding: 0, border: 'none', boxShadow: 'none', width: '100%' }}
      >
        <VacancyDropdown
          source="vacancyAlias"
          placeholder="Applied to vacancy"
          label=""
          onChangeCallback={onVacancyAliasChangeCallback}
          value={vacancyAlias}
        />
        <EnumDropdown
          source="sourcingType"
          placeholder="Sourcing type"
          options={SOURCING_TYPE}
          label=""
          onChangeCallback={onSourcingTypeChangeCallback}
          value={sourcingType}
        />
        <Box
          sx={{
            width: '100%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '10px',
            border: '1px solid #828282',
            borderRadius: '4px',
          }}
        >
          {applyVacancyLink ? (
            [
              <Box key="copyButton" sx={{ position: 'absolute', right: '5px', top: '5px' }}>
                <CopyToClipboardButton
                  className="ml-1"
                  id="copyApplyVacancyLink"
                  value={applyVacancyLink}
                  isContentCopyIcon
                >
                  Copy the link
                </CopyToClipboardButton>
              </Box>,
              <Box key="copyText" sx={{ display: 'flex', alignItems: 'center' }}>
                <CopyToClipboardButton
                  className="ml-1"
                  id="copyApplyVacancyLink"
                  value={applyVacancyLink}
                  isIcon={false}
                  sx={{ border: '0px', color: '#5954A8' }}
                >
                  Copy the link
                </CopyToClipboardButton>
              </Box>,
            ]
          ) : (
            <Typography sx={{ color: '#8E8E93' }} variant="body2">
              The link will be generated after selecting the vacancy and sourcing type
            </Typography>
          )}
        </Box>
      </FormSection>
    </>
  );
};

export default ApplyToVacancyForm;
