import { Template } from 'shared/markdownEditor/MarkdownTypes';
import { CONTRACT_TYPES, OFFER_OFFICES, OFFER_TEMPLATE_FILE_NAMES } from '../../../constants';
import { fetchTemplate } from 'shared/markdownEditor/fetchTemplateService';

const getTemplateFileName = (office: string, contractType: string): string => {
  if (office === OFFER_OFFICES.KRAKOW) {
    if (contractType === CONTRACT_TYPES.B2B) {
      return OFFER_TEMPLATE_FILE_NAMES.OFFER_POLAND_B2B;
    } else if (contractType === CONTRACT_TYPES.EC) {
      return OFFER_TEMPLATE_FILE_NAMES.OFFER_POLAND_EC;
    }
  } else if (office === OFFER_OFFICES.MINSK) {
    return OFFER_TEMPLATE_FILE_NAMES.OFFER_BELARUS;
  }
  return '';
};

// eslint-disable-next-line import/prefer-default-export
export const fetchOfferTemplate = async (offerData: any): Promise<Template | null> => {
  const compensationDetail = offerData.compensationDetails?.[0] || {};
  const { contractType, office } = compensationDetail;

  const templateFileName = getTemplateFileName(office, contractType);

  if (!templateFileName) {
    throw new Error('Could not determine template file name for the given office and contract type');
  }

  return fetchTemplate({ templateFileName });
};
