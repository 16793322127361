/* istanbul ignore file */
import React from 'react';
import { useListContext, ListContextData } from 'shared/uibuilder/list/ListContext';
// @ts-ignore
import InfiniteScroll from 'react-infinite-scroller';
import SmallLoader, { LoaderSize } from 'shared/uibuilder/Loading';
import Box from 'uibuilder/Box';

const DEFAULT_LOAD_MORE_FUNCTION = () => {};

const loaderContainer = (
  <Box className="position-relative" sx={{ minHeight: '25px', marginTop: '12px' }}>
    <SmallLoader size={LoaderSize.Medium} />
  </Box>
);

export type DataGridInfiniteScrollPagingHandlerProps = {
  children: React.ReactElement;
};

const DataGridInfiniteScrollPagingHandler = ({ children }: DataGridInfiniteScrollPagingHandlerProps) => {
  const {
    loadNextPage = DEFAULT_LOAD_MORE_FUNCTION,
    hasMoreData = () => false,
    isLoadingPrevPage,
    isLoadingNextPage,
  } = useListContext() as ListContextData & {
    isLoadingPrevPage: boolean;
    isLoadingNextPage: boolean;
  };

  return (
    <InfiniteScroll
      pageStart={0}
      loadMore={loadNextPage}
      hasMore={hasMoreData()}
      initialLoad={false}
      useWindow
      loader={null}
      threshold={500}
    >
      {isLoadingPrevPage && loaderContainer}
      {React.cloneElement(children, { showPagination: false, showTotal: false })}
      {isLoadingNextPage && loaderContainer}
    </InfiniteScroll>
  );
};

export default DataGridInfiniteScrollPagingHandler;
