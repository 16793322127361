import ResumeTimelineRecordLayout from './ResumeTimelineRecordLayout';
import CreateResumeForm from 'erp/candidate/Timeline/resume/form/CreateResumeForm';
import UpdateResumeForm from 'erp/candidate/Timeline/resume/form/UpdateResumeForm';
import { CREATE_CANDIDATE_RESUME } from 'erp/candidate/Timeline/resume/resumeService';

const useCandidateResumeItem = () => ({
  AddForm: CreateResumeForm,
  UpdateForm: UpdateResumeForm,
  isApplicable: (item: { resume: any }) => item.resume,
  source: 'resume',
  urlPostfix: 'resumes',
  RecordLayout: ResumeTimelineRecordLayout,
  name: 'Resume',
  permissionToCheck: CREATE_CANDIDATE_RESUME,
});

export default useCandidateResumeItem;
