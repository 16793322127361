/* istanbul ignore file */
import { useListContext } from 'shared/uibuilder/list/ListContext';
// @ts-ignore
import InfiniteScroll from 'react-infinite-scroller';
import React from 'react';
import LoadMore from 'shared/uibuilder/timeline/paging/LoadMore';
import SmallLoader from 'shared/uibuilder/SmallLoader';
import { TimelineContextData } from 'shared/uibuilder/timeline/Timeline';

const DEFAULT_LOAD_MORE_FUNCTION = () => {};

const loaderContainer = (
  <div className="d-flex justify-content-center">
    <SmallLoader />
  </div>
);

export type TimelinePagingHandlerProps = {
  children?: React.ReactNode;
};

const TimelinePagingHandler = ({ children }: TimelinePagingHandlerProps) => {
  const {
    isEntryViewMode,
    loadNextPage = DEFAULT_LOAD_MORE_FUNCTION,
    hasMoreData = () => false,
    isLoadingPrevPage,
    isLoadingNextPage,
  }: TimelineContextData = useListContext();

  const hasMore = hasMoreData();

  if (isEntryViewMode) {
    return <LoadMore>{children}</LoadMore>;
  } else {
    return (
      <InfiniteScroll
        pageStart={0}
        loadMore={loadNextPage}
        hasMore={hasMore}
        initialLoad={false}
        useWindow
        loader={null}
      >
        {isLoadingPrevPage && loaderContainer}
        {children}
        {isLoadingNextPage && loaderContainer}
      </InfiniteScroll>
    );
  }
};

export default TimelinePagingHandler;
