import React, { FC, MutableRefObject } from 'react';
import Alert from 'uibuilder/Alert';
import Button from 'uibuilder/button/Button';
import RefreshIcon from '@mui/icons-material/RefreshOutlined';
import classnames from 'classnames';
import useHeight from 'shared/useHeight';
import Loading from 'shared/uibuilder/Loading';
import useCollapsibleHelper, { CollapsibleProps, DEFAULT_MAX_HEIGHT } from '../../field/Collapsible/collapsibleHelper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './TimelineCollapsible.scss';

const TimelineCollapsible: FC<CollapsibleProps> = (props: CollapsibleProps) => {
  const { children, maxHeight = DEFAULT_MAX_HEIGHT, getFullText } = props;
  const { ref, height } = useHeight();
  const {
    isCollapsible,
    isExpandableField,
    isExpanded,
    displayedValue,
    isLoading,
    toggleBlock,
    retryUploadData,
    error,
  } = useCollapsibleHelper({
    ...props,
    height,
  });
  const isBlockExpanded = isExpanded || !isCollapsible;

  const renderText = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (error) {
      return (
        <Alert className="mb-0" severity="error">
          <div>{error}</div>
          <Button variant="text" onClick={retryUploadData} startIcon={<RefreshIcon />} />
        </Alert>
      );
    }

    return (
      <div ref={ref as MutableRefObject<Nullable<HTMLDivElement>>}>
        {React.cloneElement(children, {
          value: displayedValue,
        })}
      </div>
    );
  };

  const determineMaxHeight = () => {
    if (isBlockExpanded || isExpandableField) {
      return getFullText ? height : 'inherit';
    }
    return maxHeight;
  };

  return (
    <>
      <div
        className={classnames('timeline-collapsible', { 'show-blur': !isBlockExpanded })}
        style={{
          maxHeight: determineMaxHeight(),
          height: isLoading ? maxHeight : 'inherit',
        }}
      >
        {renderText()}
      </div>
      {isCollapsible && (
        <Button
          type="button"
          className={classnames('timeline-collapsible-button', 'mb-3 d-flex w-100', {
            'mt-2': isBlockExpanded && !isLoading,
            'mt-1': !isBlockExpanded || isLoading,
          })}
          size="small"
          onClick={toggleBlock}
          disabled={isLoading}
        >
          {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </Button>
      )}
    </>
  );
};

export default TimelineCollapsible;
