import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Collapse from 'shared/uibuilder/Collapse';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import Box from 'uibuilder/Box';
import { EMPLOYEE_ZONES, EMPLOYEE_ZONES_ALIASES } from '../../constants';
import Typography from 'uibuilder/Typography';
import Alert from 'uibuilder/Alert';
import SmallLoader from 'shared/uibuilder/SmallLoader';
import EmployeeDashboardList from '../EmployeeDashboardList/EmployeeDashboardList';
import { DashboardEmployee, EmployeeZoneAlias } from '../../types';
// @ts-ignore
import InfiniteScroll from 'react-infinite-scroller';

import './employeesDashboardZone.scss';

const pageSize = 20;

const EMPLOYEE_ZONE_TEXT = {
  [EMPLOYEE_ZONES_ALIASES.SAFE]: 'No challenges',
  [EMPLOYEE_ZONES_ALIASES.MONITORING]: 'Not critical challenges',
  [EMPLOYEE_ZONES_ALIASES.CRITICAL]: 'Requires immediate action',
  [EMPLOYEE_ZONES_ALIASES.ESCALATION]: 'Emergency intervention is required',
};

type EmployeeStageContentProps = {
  zone: EmployeeZoneAlias;
};

const EmployeesDashboardCollapseZoneHeader = ({ zone, countElements = 0 }: { zone: string; countElements: number }) => {
  const { loading } = useListContext();

  return (
    <Box p="5px 5px 5px 28px " className="d-flex align-items-center justify-content-between">
      <Typography display="flex" sx={{ fontSize: '12px', fontWeight: 'bold' }}>
        {EMPLOYEE_ZONES[zone]}
        &nbsp;
        {loading ? <SmallLoader size="10px" /> : ` (${countElements})`}
      </Typography>

      <Typography fontSize="9px" variant="caption">
        {EMPLOYEE_ZONE_TEXT[zone]}
      </Typography>
    </Box>
  );
};

const EmployeesDashboardZone = ({ zone }: EmployeeStageContentProps) => {
  const { loading } = useListContext();
  const [collapse, setCollapse] = useState(false);
  const { data: { items = [] } = {}, updateInList } = useListContext();

  const elements = useMemo(
    () => items.filter((item: DashboardEmployee) => item.retentionRiskPriority === zone),
    [items, zone],
  );

  const [visibleItems, setVisibleItems] = useState<DashboardEmployee[]>([]);

  const updateEntityInList = (updatedEntity: DashboardEmployee) => {
    updateInList!(updatedEntity as any);
    setVisibleItems(prev =>
      prev.map(item => {
        if (updatedEntity.id === item.id) {
          return updatedEntity;
        } else {
          return item;
        }
      }),
    );
  };

  const initFetchData = useCallback(() => {
    setVisibleItems(elements.slice(0, pageSize));
  }, [elements]);

  const fetchMoreData = () => {
    const currentItemsCount = visibleItems.length;
    const nextItems = elements.slice(currentItemsCount, currentItemsCount + pageSize);

    if (nextItems.length > 0) {
      setVisibleItems(prevItems => [...prevItems, ...nextItems]);
    }
  };

  // Reset after filter
  useEffect(() => {
    if (loading) {
      setVisibleItems([]);
    } else {
      initFetchData();
    }
  }, [elements, initFetchData, loading]);

  return (
    <Collapse
      unmountOnExit
      header={<EmployeesDashboardCollapseZoneHeader countElements={elements.length} zone={zone} />}
      isExpanded={collapse}
      toggle={() => {
        setVisibleItems(prevItems => prevItems.slice(0, pageSize));
        setCollapse(prev => !prev);
      }}
      customWrapperClassName={`employee_dashboard_collapse_wrapper color-by-zone-${zone}`}
    >
      <Box sx={{ minHeight: '50px', position: 'relative', pt: '10px', overflow: 'auto' }}>
        {elements.length ? (
          <InfiniteScroll
            style={{
              overflow: 'hidden',
            }}
            loadMore={fetchMoreData}
            hasMore={visibleItems.length < elements.length}
            loader={<SmallLoader />}
          >
            <EmployeeDashboardList updateEntityInList={updateEntityInList} zone={zone} items={visibleItems} />
          </InfiniteScroll>
        ) : (
          <Alert sx={{ alignItems: 'center' }} color="info">
            <Typography fontSize="12px" variant="caption">
              No records found
            </Typography>
          </Alert>
        )}
      </Box>
    </Collapse>
  );
};

export default EmployeesDashboardZone;
