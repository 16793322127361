/* istanbul ignore file */
import React, { useState } from 'react';
import useAuthorization from 'shared/authorization/authorizationService';
import Button from 'uibuilder/button/Button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import {
  RECRUITING_VACANCY_REQUEST_CANCELLATION,
  RECRUITING_VACANCY_REQUEST_CHANGE,
  VACANCY_STATUS,
} from 'erp/recruitment/newVacancy/constants';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { SelectInput, TextArea } from 'shared/uibuilder/form/input';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { RECRUITING_CONTEXT } from '../../../RecruitingContext';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { Box, Typography } from '@mui/material';

const REQUEST_TYPES = {
  CANCELLATION: 'request_cancellation',
  CHANGE: 'change_request',
} as const;

const RequestVacancyCancellationButton = ({
  statusSource,
  setGeneralContextData,
}: {
  statusSource: string;
  setGeneralContextData: () => {};
}) => {
  const { requestVacancyCancellation, requestVacancyChange } = useVacancyGroupService();
  const { isGranted } = useAuthorization();
  const { addMessage } = useMessageService();

  const { data } = useShowContext();
  const { id } = data.getData();

  const [modalOpen, setModalOpen] = useState(false);
  const hasChangePermission = isGranted(RECRUITING_VACANCY_REQUEST_CHANGE, RECRUITING_CONTEXT);
  const hasCancellationPermission = isGranted(RECRUITING_VACANCY_REQUEST_CANCELLATION, RECRUITING_CONTEXT);

  // Set initial request type based on permissions
  const [requestType, setRequestType] = useState(
    hasChangePermission ? REQUEST_TYPES.CHANGE : REQUEST_TYPES.CANCELLATION,
  );
  const [reason, setReason] = useState<string>('');
  const [reasonErrors, setReasonErrors] = useState<string[]>([]);

  const { getValue } = useFieldHelper({ source: statusSource });
  const status = getValue();

  if (status !== VACANCY_STATUS.OPEN || (!hasChangePermission && !hasCancellationPermission)) {
    return null;
  }

  const closeModal = () => {
    setModalOpen(false);
    setReason('');
    setReasonErrors([]);
  };

  const onReasonChange = (values: any) => {
    const newReason = values?.reason || '';
    setReason(newReason);

    if (!newReason) {
      setReasonErrors(['Reason should be provided']);
    } else {
      setReasonErrors([]);
    }
  };

  const onRequestTypeChange = (values: any) => {
    setRequestType(values.requestType);
    setReason('');
    setReasonErrors([]);
  };

  const getModalTitle = () => {
    return requestType === REQUEST_TYPES.CANCELLATION ? 'Request Vacancy Cancellation' : 'Request Vacancy Change';
  };

  const getReasonLabel = () => {
    return requestType === REQUEST_TYPES.CANCELLATION ? 'Cancellation Reason' : 'Change Request Reason';
  };

  const getButtonLabel = () => {
    if (hasChangePermission && hasCancellationPermission) {
      return 'Change Request';
    }
    return hasChangePermission ? 'Change Request' : 'Request Cancellation';
  };

  const handleSubmit = async () => {
    if (!reason) {
      setReasonErrors(['Reason should be provided']);
      return;
    }

    try {
      if (requestType === REQUEST_TYPES.CANCELLATION) {
        await requestVacancyCancellation(id!, reason);
        addMessage(new ErrorMessage('Cancellation for vacancy has been successfully requested.'));
      } else {
        await requestVacancyChange(id!, reason);
        addMessage(new ErrorMessage('Change for vacancy has been successfully requested.'));
      }

      if (setGeneralContextData) {
        setGeneralContextData();
      }

      closeModal();
    } catch (error) {
      addMessage(
        new ErrorMessage(
          requestType === REQUEST_TYPES.CANCELLATION
            ? "Can't request vacancy cancellation."
            : "Can't request vacancy change.",
        ),
      );
    }
  };

  const selectOptions = [
    { value: REQUEST_TYPES.CHANGE, label: 'Change Request' },
    { value: REQUEST_TYPES.CANCELLATION, label: 'Request Cancellation' },
  ];

  const confirmButton = (
    <Button
      key="confirmBtn"
      onClick={handleSubmit}
      color={requestType === REQUEST_TYPES.CANCELLATION ? 'error' : 'primary'}
      disabled={!reason}
    >
      {requestType === REQUEST_TYPES.CANCELLATION ? 'Request Cancellation' : 'Request Change'}
    </Button>
  );

  return (
    <>
      <Button onClick={() => setModalOpen(true)} color={!hasChangePermission ? 'error' : 'primary'} variant="outlined">
        {getButtonLabel()}
      </Button>

      <ModalWindow
        backdrop="static"
        isOpen={modalOpen}
        title={getModalTitle()}
        onToggle={closeModal}
        buttons={confirmButton}
      >
        {hasChangePermission && hasCancellationPermission && (
          <Box sx={{ mb: 3 }}>
            <SelectInput
              source="requestType"
              label="Request Type"
              options={selectOptions}
              value={requestType}
              onChangeCallback={onRequestTypeChange}
            />
          </Box>
        )}

        <Box>
          <Typography variant="body2" sx={{ mb: 1 }}>
            {requestType === REQUEST_TYPES.CANCELLATION
              ? 'Do you really want to request cancellation for this vacancy? Please describe below.'
              : 'Please describe the changes needed for this vacancy.'}
          </Typography>

          <TextArea
            key={`textarea-${requestType}`}
            source="reason"
            label={getReasonLabel()}
            value={reason}
            onChangeCallback={onReasonChange}
            errorMessages={reasonErrors}
            isRequired
          />
        </Box>
      </ModalWindow>
    </>
  );
};

export default RequestVacancyCancellationButton;
