import useCrudService from 'shared/crud/baseCrudService';
import { useRecruitmentApi } from 'api';
import useEmployeeNamesService from 'erp/employee/shared/employeeNamesService';
import useEmployeeService from 'erp/employee/employeeService';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { VacancyHistoryItem, FormattedChange } from 'erp/recruitment/newVacancy/types';

export interface GroupedChanges {
  domainName: string;
  changes: FormattedChange[];
}

const HISTORY_API_PATH = '/vacancies';

const useVacancyHistoryService = () => {
  const { sendGetRequest } = useRecruitmentApi();
  const { getEmployeeNamesByAliases } = useEmployeeNamesService();
  const { getFullName } = useEmployeeService();
  const { addMessage } = useMessageService();

  const { search: baseSearch, ...baseCrudRequests } = useCrudService({
    listResourceUrl: HISTORY_API_PATH,
    singleResourceUrl: `${HISTORY_API_PATH}/:id`,
    apiService: useRecruitmentApi,
  });

  const getVacancyHistory = async (vacancyId: string): Promise<VacancyHistoryItem[]> => {
    try {
      const response = await sendGetRequest(`${HISTORY_API_PATH}/${vacancyId}/history`);

      const historyItems: VacancyHistoryItem[] = await response.json();

      const employeeAliases = Array.from(new Set(historyItems.map(item => item.createdBy).filter(Boolean)));

      const employeeData = await getEmployeeNamesByAliases(employeeAliases);

      const employeeMap: Record<string, any> = {};
      employeeData.forEach(employee => {
        if (employee && employee.alias) {
          employeeMap[employee.alias] = employee;
        }
      });

      const enrichedItems = historyItems.map(item => {
        const userInfo = employeeMap[item.createdBy] || null;
        return {
          ...item,
          fullName: userInfo?.nameEn ? getFullName(userInfo.nameEn) : '',
        };
      });

      return enrichedItems;
    } catch (error) {
      addMessage(new ErrorMessage('Error fetching vacancy history'));
      throw error;
    }
  };

  const searchVacanciesByGroup = async (groupId: string) => {
    try {
      const response = await baseSearch({
        filter: {
          'vacancyGroup.id': {
            eq: groupId,
          },
        },
        pageSize: 30,
      });
      return response;
    } catch (error) {
      addMessage(new ErrorMessage('Error searching vacancies for group'));
      throw error;
    }
  };

  const formatChanges = (item: VacancyHistoryItem): GroupedChanges[] => {
    const changesMap: Record<string, FormattedChange[]> = {};

    Object.entries(item.updatedDomains || {}).forEach(([domain, domainChanges]) => {
      if (!changesMap[domain]) {
        changesMap[domain] = [];
      }

      domainChanges.forEach(change => {
        changesMap[domain].push({
          id: `${domain}-${change.property}-${item.id}`,
          type: 'update',
          domain,
          property: change.property,
          oldValue: change.oldValue,
          newValue: change.newValue,
        });
      });
    });

    if (item.createdDomains?.length > 0) {
      item.createdDomains.forEach((domain, index) => {
        if (!changesMap[domain]) {
          changesMap[domain] = [];
        }

        changesMap[domain].push({
          id: `created-${index}-${item.id}`,
          type: 'create',
          domain,
        });
      });
    }

    if (item.deletedDomains?.length > 0) {
      item.deletedDomains.forEach((domain, index) => {
        if (!changesMap[domain]) {
          changesMap[domain] = [];
        }

        changesMap[domain].push({
          id: `deleted-${index}-${item.id}`,
          type: 'delete',
          domain,
        });
      });
    }

    return Object.entries(changesMap).map(([domainName, changes]) => ({
      domainName,
      changes,
    }));
  };

  return {
    getVacancyHistory,
    searchVacanciesByGroup,
    formatChanges,
    ...baseCrudRequests,
  };
};

export default useVacancyHistoryService;
