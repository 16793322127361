import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import { SearchInput, ValidationProps } from 'shared/uibuilder/form/input';
import { FormFieldsData } from 'validation-schema-library/build/validation/types';
import React from 'react';

export interface VacancyInputProps extends ValidationProps {
  source: string;
  label?: string;
  nameSource?: string;
  defaultFilter?: Dictionary<object>;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  disabled?: boolean | ((data: FormFieldsData) => boolean);
  props?: any;
  initialLabel?: string;
  onChangeCallback?: (values: FormFieldsData) => void;
  onInputChangeCallback?: (query: any) => void;
  valueDecorator?: (searchValue: string) => string;
  ignoreVacancyId?: string | number;
}

const VacancyInput = ({ ignoreVacancyId, ...props }: VacancyInputProps) => {
  const { searchVacancy } = useVacancyGroupService();

  const mapVacancy = (vacancy: any) => {
    if (vacancy.id === ignoreVacancyId) {
      return null;
    }
    return {
      id: vacancy.id,
      text: `${vacancy.specialization} (${vacancy.competency.minLevel} - ${vacancy.competency.maxLevel})`,
    };
  };

  return (
    <SearchInput
      searchRequest={searchVacancy}
      noResultMessage="No Vacancies found."
      mapResults={mapVacancy}
      {...props}
    />
  );
};

export default VacancyInput;
