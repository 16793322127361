import React, { useEffect, useMemo, useState } from 'react';
import { CONTACT_SUPPORT, SUPPORT_REQUEST_ID } from 'oneStop/OneStopRouterHelper';
import useAuthenticatedUserService, { UserName } from 'authentication/authenticatedUserService';
import FeedbackButton from 'oneStop/feedbackModal/FeedbackButton';
import useFeatureToggle, { Features } from 'featuretoggle';
import InstantFeedbackButton from 'instantFeedback/InstantFeedbackButton';
import Box from 'uibuilder/Box';
import HeaderMenuItems from 'shared/uibuilder/menu/HeaderMenu/HeaderMenuItems';
import CurrentUserActions from 'layout/Default/DefaultHeader/CurrentUserActions';
import CopyTokenButton from 'authentication/CopyTokenButton';
import LeadsNotificationHeaderButton from 'crm/lead/shared/LeadsNotificationHeaderButton/LeadsNotificationHeaderButton';
import useAuthorization from 'shared/authorization/authorizationService';
import { READ_LEAD_NOTIFICATION_FE } from 'crm/lead/shared/leadService';
import SupportRequestModalDecorator from 'oneStop/feedbackModal/SupportRequestModalDecorator';
import HeaderSearchCandidateSelect from 'erp/candidate/shared/input/headerSearch/HeaderSearchCandidateSelect';
import { useLocation } from 'react-router-dom';
import { VACANCY_GROUP_PATH } from 'erp/recruitment/newVacancy/routing';
import { IconButton, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { CANDIDATES_PATH } from 'erp/candidate/CandidateRouter';

const DefaultHeader = () => {
  const location = useLocation();
  const { isFeatureEnabled } = useFeatureToggle();
  const [userName, setUserName] = useState<Nullable<UserName>>(null);
  const { getUserName } = useAuthenticatedUserService();
  const { isGranted } = useAuthorization();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const isShowLeadNotification = useMemo(() => isGranted(READ_LEAD_NOTIFICATION_FE), [isGranted]);
  const isShowCandidateSearch = useMemo(
    () => location.pathname.includes(CANDIDATES_PATH) || location.pathname.includes(VACANCY_GROUP_PATH),
    [location.pathname],
  );

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    (async () => {
      const name = await getUserName();
      setUserName(name);
    })();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return userName === null ? null : (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: '100%' }}>
      <Box sx={{ width: { md: `calc(100% - ${isShowLeadNotification ? '550' : '470'}px)` }, marginRight: 'auto' }}>
        <HeaderMenuItems />
      </Box>

      {/* desktop */}
      <Box sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'center' }}>
        {isShowCandidateSearch && <HeaderSearchCandidateSelect />}
        {isShowLeadNotification && <LeadsNotificationHeaderButton />}
        {isFeatureEnabled(Features.INSTANT_FEEDBACK) && <InstantFeedbackButton />}
        {isFeatureEnabled(Features.SUPPORT_REQUEST_SELECTING) ? (
          <SupportRequestModalDecorator />
        ) : (
          <FeedbackButton formId={SUPPORT_REQUEST_ID} formTitle={CONTACT_SUPPORT} />
        )}
        <CopyTokenButton />
        <CurrentUserActions userName={userName} />
      </Box>

      {/* Mobile */}
      <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
        {isShowLeadNotification && (
          <MenuItem>
            <LeadsNotificationHeaderButton />
          </MenuItem>
        )}
        <IconButton onClick={handleMenuOpen}>
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          {isShowCandidateSearch && (
            <MenuItem>
              <HeaderSearchCandidateSelect />
            </MenuItem>
          )}
          {isFeatureEnabled(Features.INSTANT_FEEDBACK) && (
            <MenuItem
              sx={{
                '& > button': {
                  width: '100%',
                },
              }}
            >
              <InstantFeedbackButton />
            </MenuItem>
          )}
          <MenuItem
            sx={{
              '& > button': {
                width: '100%',
              },
            }}
          >
            {isFeatureEnabled(Features.SUPPORT_REQUEST_SELECTING) ? (
              <SupportRequestModalDecorator />
            ) : (
              <FeedbackButton formId={SUPPORT_REQUEST_ID} formTitle={CONTACT_SUPPORT} />
            )}
          </MenuItem>
          <MenuItem
            sx={{
              '& > button': {
                width: '100%',
              },
            }}
          >
            <CopyTokenButton />
          </MenuItem>
        </Menu>
        <CurrentUserActions userName={userName} />
      </Box>
    </Box>
  );
};

export default DefaultHeader;
