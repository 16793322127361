import React from 'react';
import Grid, { GridProps } from 'uibuilder/Grid';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { getVisibility } from 'shared/uibuilder/helper';
import Box from 'uibuilder/Box';
import { SxProps } from '@mui/system';

export interface FormRowProps extends GridProps {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
  colMd?: number;
  colXl?: number;
  // customSxChild is an object where the key is the index of the child and the value is the SxProps
  customSxChild?: {
    [key: string | number]: SxProps;
  };
}

const FormRow = ({ children, colMd = 6, colXl = 3, customSxChild, ...props }: FormRowProps) => {
  const { data: formData } = useFormContext();

  return (
    <Grid {...props} container columnGap={2}>
      {React.Children.toArray(children)
        .filter(child => child)
        .map((child, index) => {
          // @ts-ignore
          let { isVisible } = child.props;
          isVisible = getVisibility(formData, isVisible);

          return (
            isVisible && (
              <Box
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                sx={
                  customSxChild?.[index] || {
                    flex: {
                      xs: `0 0 calc(${100}% - 16px)`,
                      sm: `0 0 calc(${100}% - 16px)`,
                      md: `0 0 calc(${100 / (12 / colMd)}% - 16px)`,
                      xl: `0 0 calc(${100 / (12 / colXl)}% - 16px)`,
                    },
                    maxWidth: {
                      xs: `calc(${100}% - 16px)`,
                      sm: `calc(${100}% - 16px)`,
                      md: `calc(${100 / (12 / colMd)}% - 16px)`,
                      xl: `calc(${100 / (12 / colXl)}% - 16px)`,
                    },
                  }
                }
              >
                {child}
              </Box>
            )
          );
        })}
    </Grid>
  );
};

export default FormRow;
