import { Box, ListItem, ListItemButton, ListItemText, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { getMatchStyling } from './CandidateView';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';
import { ResourceData } from 'shared/crud/baseCrudService';
import { CandidateDuplicate } from 'erp/candidate/candidateService';
import { IconButton } from 'uibuilder/button';
import { VerifiedOutlined } from '@mui/icons-material';

interface ICandidateViewListItemProps {
  candidate: ResourceData;
  score: CandidateDuplicate['score'];
  setSimilarCandidates?: React.Dispatch<React.SetStateAction<Omit<CandidateDuplicate, 'url'>[]>>;
}

const CandidateViewListItem: React.FC<ICandidateViewListItemProps> = ({ candidate, score, setSimilarCandidates }) => {
  const { getProfileUrl } = useCandidateUrl();

  return (
    <ListItem disablePadding sx={{ my: 0, borderBottom: 1, borderColor: 'grey.300' }} key={candidate?.id}>
      <ListItemButton LinkComponent="a" href={getProfileUrl(candidate?.id)} target="_blank" sx={{ p: 0.5 }}>
        <ListItemText
          primary={
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}>
                <Typography variant="subtitle1" sx={{ fontSize: '14px', ...getMatchStyling(Boolean(score.name)) }}>
                  {candidate.name.fullName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    ...getMatchStyling(Boolean(score.personalEmail || candidate.otherEmail)),
                  }}
                >
                  {candidate.personalEmail || candidate.otherEmail || ''}
                </Typography>
                <Typography variant="body2" sx={{ ...getMatchStyling(Boolean(score.personalPhoneNumber)) }}>
                  {candidate.personalPhoneNumber || ''}
                </Typography>
              </Box>
              {setSimilarCandidates && (
                <Tooltip title="It is a match">
                  <span>
                    <IconButton
                      sx={{ fontSize: '10px' }}
                      onClick={(event: any) => {
                        event.preventDefault();
                        event.stopPropagation();
                        setSimilarCandidates([{ candidate, score }]);
                      }}
                    >
                      <VerifiedOutlined />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </Box>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};

export default CandidateViewListItem;
