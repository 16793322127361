/* istanbul ignore file */
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import VacancyLinkField from 'erp/recruitment/vacancy/shared/input/VacancyLinkField';
import BaseCandidateTimelineRecordLayout from 'erp/candidate/Timeline/CandidateTimelineRecord/BaseCandidateTimelineRecordLayout';

const ApplicationTimelineRecordLayout = (props: any) => {
  const { data } = useShowContext();

  return (
    <BaseCandidateTimelineRecordLayout
      fields={
        <>
          <VacancyLinkField source="vacancyAlias" label="Application for vacancy has been created" />
        </>
      }
      entity={data}
      timelineType="Application"
      buttons={[]}
      actions={[]}
      timelineFooter={() => null}
      {...props}
    />
  );
};

export default ApplicationTimelineRecordLayout;
