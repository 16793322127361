const positiveNumberRegEx = /^((?!^0+$|0+[.]0+$)0|[1-9]\d{0,5})(?:[.]\d{1,2})?$/;

export default {
  compensationFromValue: {
    type: 'string',
    required: true,
    regex: {
      value: positiveNumberRegEx,
      message: 'Must be a positive number (max 6 digits and 2 decimals)',
    },
  },
  compensationToValue: {
    type: 'string',
    required: true,
    minValue: {
      value: '$compensationFromValue',
    },
    regex: {
      value: positiveNumberRegEx,
      message: 'Must be a positive number (max 6 digits and 2 decimals)',
    },
  },
  compensationCurrency: {
    type: 'enum',
    required: true,
  },

  location: {
    type: 'enum',
    required: true,
  },
};
