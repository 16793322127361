export default {
  vacancyGroupAlias: {
    type: 'string',
    required: true,
  },
  rejectionReason: {
    type: 'string',
    required: true,
  },

  location: {
    type: 'enum',
    required: false,
  },

  expectedCompensationFrom: {
    type: 'number',
    maxValue: {
      value: '$expectedCompensationTo',
      message: 'Min value cannot exceed max.',
    },
    required: {
      value: true,
      when: {
        _or_: [
          {
            $expectedCompensationTo: {
              notEmpty: true,
            },
          },
          {
            $compensationCurrency: {
              notEmpty: true,
            },
          },
        ],
      },
    },
    regex: {
      value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
      message: 'Invalid number.',
    },
  },
  expectedCompensationTo: {
    type: 'number',
    required: false,
    minValue: {
      value: '$expectedCompensationFrom',
      message: 'Max value cannot be below min.',
    },
    regex: {
      value: /^((0|[1-9][0-9]*)(\.[0-9]+)?|\.[0-9]+)$/,
      message: 'Invalid number.',
    },
  },

  compensationCurrency: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $expectedCompensationFrom: {
          notEmpty: true,
        },
      },
    },
  },
};
