import React from 'react';
import { Routes } from 'react-router-dom';
import ProtectedRouter from 'routing/ProtectedRouter';
import { Route } from 'shared/routing';
import { useVacancyGroupRoute, VACANCY_GROUP_PATH } from 'erp/recruitment/newVacancy/routing';
import ViewVacancyPublication from './show/publications/Show';
import UpdateVacancyPublication from './show/publications/createupdate/Update';
import CreateVacancyGroupPublication from './show/publications/createupdate/Create';
import {
  RECRUITING_DASHBOARD_READ,
  RECRUITING_VACANCY_GROUP_READ,
  RECRUITING_VACANCY_PUBLICATION_CREATE,
  RECRUITING_VACANCY_PUBLICATION_READ,
  RECRUITING_VACANCY_PUBLICATION_UPDATE,
} from './constants';
import RecruitingContext from '../RecruitingContext';
import RecruitingBoard from '../recruitingBoard/List/RecruitingBoard';
import useFeatureToggle, { Features } from '../../../featuretoggle';
import ViewVacancyGroup from './show/vacancyGroup/ViewVacancyGroup';

const VacancyGroupRouter = () => {
  const {
    singleEntityRoute,
    sourcingRoute,
    publicationsRoute,
    createPublicationsRoute,
    updatePublicationRoute,
    publicationRoute,
    historyRoute,
  } = useVacancyGroupRoute();

  const { isFeatureEnabled } = useFeatureToggle();

  const viewNewDashboard = isFeatureEnabled(Features.NEW_RECRUITING_DASHBOARD);
  const viewHistory = isFeatureEnabled(Features.VACANCY_HISTORY);

  return (
    <ProtectedRouter basePath={VACANCY_GROUP_PATH}>
      <Routes>
        {viewNewDashboard ? (
          <Route
            path="/new-dashboard"
            element={
              <RecruitingContext permissionToCheck={RECRUITING_DASHBOARD_READ}>
                <RecruitingBoard />
              </RecruitingContext>
            }
          />
        ) : (
          <></>
        )}

        <Route
          path={singleEntityRoute}
          element={
            <RecruitingContext permissionToCheck={RECRUITING_VACANCY_GROUP_READ}>
              <ViewVacancyGroup activeTab={0} />
            </RecruitingContext>
          }
        />

        <Route
          path={publicationsRoute}
          element={
            <RecruitingContext permissionToCheck={RECRUITING_VACANCY_PUBLICATION_READ}>
              <ViewVacancyGroup activeTab={1} />
            </RecruitingContext>
          }
        />
        <Route
          path={publicationRoute}
          element={
            <RecruitingContext permissionToCheck={RECRUITING_VACANCY_PUBLICATION_READ}>
              <ViewVacancyPublication />
            </RecruitingContext>
          }
        />
        <Route
          path={createPublicationsRoute}
          element={
            <RecruitingContext permissionToCheck={RECRUITING_VACANCY_PUBLICATION_CREATE}>
              <CreateVacancyGroupPublication />
            </RecruitingContext>
          }
        />
        <Route
          path={updatePublicationRoute}
          element={
            <RecruitingContext permissionToCheck={RECRUITING_VACANCY_PUBLICATION_UPDATE}>
              <UpdateVacancyPublication />
            </RecruitingContext>
          }
        />

        <Route
          path={sourcingRoute}
          element={
            <RecruitingContext permissionToCheck={RECRUITING_VACANCY_GROUP_READ}>
              <ViewVacancyGroup activeTab={2} />
            </RecruitingContext>
          }
        />

        {viewHistory ? (
          <Route
            path={historyRoute}
            element={
              <RecruitingContext permissionToCheck={RECRUITING_VACANCY_GROUP_READ}>
                <ViewVacancyGroup activeTab={3} />
              </RecruitingContext>
            }
          />
        ) : (
          <></>
        )}
      </Routes>
    </ProtectedRouter>
  );
};

VacancyGroupRouter.getRouterPath = () => `${VACANCY_GROUP_PATH}/*`;

export default VacancyGroupRouter;
