import { Stages, VacancyPriority } from './constants';
import { Moment } from 'moment';

export type TaskStyles = {
  borderColor: string;
  background: string;
  dragIconBackground: string;
};

export type ApplicationPipelineState = {
  stage: Stages;
  jobsStates: string[];
  stageTransitionTimestamp: string;
  info: any[];
  undoStates: string[];
};

export type Application = {
  id: number;
  version: number;
  candidateId: number;
  candidateName: string;
  vacancyId: number;
  resumeId: string | null;
  createdBy: string | null;
  createdAt: string;
  updatedBy: string | null;
  updatedAt: string;
  applicationPipelineState: ApplicationPipelineState;
  source: string;
  sourceType: string;
  sourceComment: string | null;
  historySource: string;
  sourceEmployeeId: number | null;
};

export type VacancyItem = {
  id: string;
  alias: string;
  isAsap: boolean;
  level: string;
  priority: VacancyPriority;
  forecastDate: string;
  hired: number;
  isLegacy: boolean;
  isVacancyInsideGroupExpired: boolean;
  needHire: number;
  specialization: string;
  specificNote: string;
  status: string;
  viewStatus: string;
  areVacancyChangesRequested: boolean;
  applications: Application[];
};

export type MappedApplication = {
  id: number;
  version: number;
  entityId: string;
  candidateId: number;
  name: string;
  vacancyId: number;
  activeStates: string[];
  eventTimestamp: string | number | Date | Moment;
  stage: Stages;
  tooltipData: any[];
  undoStates: string[];
  canDrag: boolean;
};

export type MappedVacancyBoard = {
  id: string;
  name: string;
  hired: number;
  priority: string; // dependence on VacancyPriorityValues
  needHire: number;
  isLegacy: boolean;
  forecastDate: string;
  viewStatus: string;
  items: MappedApplication[];
  columnSettings?: any; // TODO Update type
};

export enum ColumnState {
  IDLE = 'idle',
  VALID_DROP = 'validDrop',
  INVALID_DROP = 'invalidDrop',
}

export type Candidate = {
  id: number;
  fullName: string;
  contacts: {
    [key: string]: string;
  };
  mainPhotoId: string | null;
  syberryEmail: string | null;
  personalEmail: string | null;
  otherEmail: string | null;
  notes: {
    id: number;
    noteText: string;
    createdAt: string | null;
    updatedAt: string | null;
    createdById: string | null;
    createdByName: string | null;
  }[];
  createdAt: string;
  updatedAt: string | null;
  createdById: string;
  updatedById: string | null;
};

export const REGISTRATION_LOCATION = {
  PL: 'PL',
  BY: 'BY',
  US: 'US',
};

export const ViewStatus = {
  NEW: 'NEW',
  UPDATED: 'UPDATED',
  VIEWED: 'VIEWED',
};
