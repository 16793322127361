import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import useDeviceDetectorService, { MAX_LG_WIDTH } from 'oneStop/deviceDetectorService';
import sidebarLinks from 'uibuilder/layout/sidebarLinks';
import useAuthorization from 'shared/authorization/authorizationService';
import { Permission } from 'shared/authorization/utils';
import { HANDBOOK_PATH } from 'handbook/HandbookRouter';
import { ARTIFACT_STORAGE_PATH } from 'artifactStorage/shared/artifactStorageService';

const localStorageParameter = 'sidebarOpenMenuList';
const notFoundItemIndex = -1;

export interface SidebarItem {
  name: string;
  url: string;
  icon?: React.ReactNode;
  isExact?: boolean;
  isActive?: boolean;
  permissions: Nullable<Permission[]>;
  children?: Array<SidebarItem>;
  exceptionUrls?: string[];
  isPageMenu?: boolean;
  collapsible?: boolean;
  shouldUseCurrentName?: boolean;
}

export interface Navigation {
  items: Array<SidebarItem>;
}

const checkException = (item: SidebarItem, exception: string) =>
  (typeof item.url === 'string' && item.url.startsWith(exception)) ||
  !!item.children?.find(child => typeof child.url === 'string' && child.url.startsWith(exception) && child.isPageMenu);

const useSidebarNav = () => {
  const { pathname } = useLocation();
  const { isGranted } = useAuthorization();
  const { isDesktop } = useDeviceDetectorService({ maxWidth: MAX_LG_WIDTH, defaultValue: false });

  const getOpenMenuList = () => {
    return JSON.parse(localStorage.getItem(localStorageParameter) ?? '[]');
  };

  const [openMenuList, setOpenMenuList] = useState<Array<string>>(getOpenMenuList());

  const updateOpenMenuList = (itemName: string, isCollapsed: boolean) => {
    const index = openMenuList.indexOf(itemName);
    const updatedArray = openMenuList;

    if (index === notFoundItemIndex && isCollapsed) {
      return;
    }

    if (index === notFoundItemIndex) {
      updatedArray.push(itemName);
    } else {
      updatedArray.splice(index, 1);
    }

    setOpenMenuList(updatedArray);
    localStorage.setItem(localStorageParameter, JSON.stringify(updatedArray));
  };

  const addToOpenMenuList = (itemName: string) => {
    const index = openMenuList.indexOf(itemName);
    const updatedArray = openMenuList;

    if (index === notFoundItemIndex) {
      updatedArray.push(itemName);
    }

    setOpenMenuList(updatedArray);
    localStorage.setItem(localStorageParameter, JSON.stringify(updatedArray));
  };

  const filterNav = (nav: Navigation): Array<SidebarItem> =>
    nav.items
      .map((item: SidebarItem) => {
        const isPermissionGranted = item.permissions ? isGranted(item.permissions) : true;

        if (!isPermissionGranted) {
          return false as any;
        }

        if (!item.children) {
          return item;
        }

        const filteredChildren = filterNav({ items: item.children });

        return {
          ...item,
          children: filteredChildren,
        };
      })
      .filter(item => item);

  const updateUrls = (nav?: Array<SidebarItem>): Array<SidebarItem> =>
    nav?.map((item: SidebarItem) => {
      const firstHeaderItem = item.children?.find(child => child.isPageMenu);
      const isCorrectUrl = !firstHeaderItem || firstHeaderItem?.url === item.url;
      const isCorrectName =
        !firstHeaderItem || (isCorrectUrl && item.children && item.children.length > 1) || item.shouldUseCurrentName;

      return {
        ...item,
        url: isCorrectUrl ? item.url : firstHeaderItem.url,
        name: isCorrectName ? item.name : firstHeaderItem.name,
        children: isCorrectName ? updateUrls(item.children) : null,
      };
    }) as Array<SidebarItem>;

  const getIsActive = (
    url: string,
    pathName: string,
    findMatchItem?: (items: Array<SidebarItem>, pathName: string) => SidebarItem,
    defaultValue = true,
  ): boolean => {
    let matchItem: any;

    if (findMatchItem) {
      matchItem = findMatchItem(sidebarLinks.items, pathName);
    } else {
      // @ts-ignore
      sidebarLinks.items.forEach((item: SidebarItem) => {
        if (item.url === url) {
          matchItem = item;
        } else if (item.children) {
          const findMatch = item.children.find(child => child.url === url);
          if (findMatch) {
            matchItem = findMatch;
          }
        }
      });
    }

    let isActive;
    if (matchItem) {
      const exceptionUrls = [...(matchItem.exceptionUrls || [])];

      if (!checkException(matchItem, HANDBOOK_PATH)) {
        exceptionUrls.push(HANDBOOK_PATH);
      }

      if (!checkException(matchItem, ARTIFACT_STORAGE_PATH)) {
        exceptionUrls.push(ARTIFACT_STORAGE_PATH);
      }

      isActive = exceptionUrls.every(
        (exceptionUrl: string) => !pathName.startsWith(exceptionUrl) && !checkException(matchItem, exceptionUrl),
      );
    }
    return isActive === undefined ? defaultValue : isActive;
  };

  const getIsActiveRoute = (url: string) => {
    const isActiveRoute = getIsActive(url, pathname);
    return !isActiveRoute ? isActiveRoute : pathname.indexOf(url) !== notFoundItemIndex;
  };

  const getHasActiveSubItem = (subItems?: Array<SidebarItem>): boolean => {
    if (!subItems) {
      return false;
    }

    return subItems.some(({ url, children }) => getIsActiveRoute(url) || getHasActiveSubItem(children));
  };

  const getIsActiveLink = (match: any, location: any, currentLinkUrl?: string) => {
    if (!match) {
      const findMatchItem = (items: any[], pathName: string) => {
        let matchItem: any;
        // @ts-ignore
        items?.forEach((item: SidebarItem) => {
          const isActive =
            item.url === currentLinkUrl &&
            item.children?.find(child => pathName?.startsWith(child.url) && child.isPageMenu);

          if (isActive || findMatchItem(item.children || [], pathName)) {
            matchItem = item;
          }
        });

        return matchItem;
      };

      return isDesktop ? getIsActive(currentLinkUrl || '', location.pathname, findMatchItem, false) : false;
    }
    return getIsActive(match.url, location.pathname);
  };

  const getNavWithOpenedActiveMenus = (items: Array<SidebarItem>): Array<SidebarItem> =>
    items.map((item: SidebarItem) => {
      const index = openMenuList.indexOf(item.name);
      const isItemInOpenMenuList = index !== notFoundItemIndex;
      const hasActiveSubItem = getHasActiveSubItem(item.children);
      const shouldItemBeOpened = isItemInOpenMenuList || hasActiveSubItem;

      return {
        ...item,
        ...(shouldItemBeOpened && { isActive: true }),
        children: item.children && getNavWithOpenedActiveMenus(item.children),
      };
    });

  const addActiveItemToOpenMenuList = () => {
    const getActiveItem = (items?: Array<SidebarItem>): Nullable<SidebarItem> =>
      items?.find(({ children }: SidebarItem) => {
        if (children) {
          return children.some(child => getIsActiveRoute(child.url) || getActiveItem(child.children));
        }

        return false;
      }) || null;

    const activeItem = getActiveItem(sidebarLinks.items);

    if (activeItem) {
      addToOpenMenuList(activeItem.name);
    }
  };

  const getNavigationItems = () => getNavWithOpenedActiveMenus(updateUrls(filterNav(sidebarLinks)));

  const getSidebarItems = (items: Array<SidebarItem>): Array<SidebarItem> =>
    items
      ?.map(item => {
        if (isDesktop && item.isPageMenu) {
          return null as any;
        }

        const filteredChildren = getSidebarItems(item.children as Array<SidebarItem>);

        return {
          ...item,
          children: filteredChildren,
        };
      })
      .filter(item => item);

  const getHeaderMenuData = (items: Array<SidebarItem>): Array<SidebarItem> => {
    return !isDesktop || !items
      ? []
      : items
          .map(item => {
            let isValid = false;
            const exceptionUrls = [...(item.exceptionUrls || [])];

            isValid = exceptionUrls.every((exceptionUrl: string) => !pathname?.startsWith(exceptionUrl));

            if (isValid && item.children?.find(child => pathname.startsWith(child.url) && child.isPageMenu)) {
              return item.children || [];
            }

            const filteredChildren = getHeaderMenuData(item.children || []);

            return filteredChildren.length ? filteredChildren : (null as any);
          })
          .filter(item => item)
          .flat();
  };

  return {
    openMenuList,
    updateOpenMenuList,
    addToOpenMenuList,
    getIsActiveLink,
    addActiveItemToOpenMenuList,
    getNavigationItems,
    getSidebarItems,
    getHeaderMenuData,
  };
};

export default useSidebarNav;
