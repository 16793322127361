import React from 'react';
import { Box, Typography } from '@mui/material';
import { DOMAIN_LABELS, getPropertyLabel, formatPropertyValue } from '../constants';
import { FormattedChange } from 'erp/recruitment/newVacancy/types';

interface ChangeItemProps {
  change: FormattedChange;
  showDomainHeader?: boolean;
}

const ChangeItem: React.FC<ChangeItemProps> = ({ change, showDomainHeader = true }) => {
  if (change.property === 'updatedAt' || change.property === 'createdAt') {
    return null;
  }
  const domainLabel = DOMAIN_LABELS[change.domain] || change.domain;

  const propertyLabel = change.property ? getPropertyLabel(change.domain, change.property) : '';

  const renderChangeText = () => {
    if (change.property === 'salaryToId' || change.property === 'salaryFromId') {
      return <> was changed</>;
    } else if (change.oldValue && change.newValue) {
      return (
        <>
          {' '}
          was changed from{' '}
          <Box component="span" sx={{ fontStyle: 'italic' }}>
            {formatPropertyValue(change.domain, change.property || '', change.oldValue)}
          </Box>{' '}
          to{' '}
          <Box component="span" sx={{ fontWeight: 500 }}>
            {formatPropertyValue(change.domain, change.property || '', change.newValue)}
          </Box>
        </>
      );
    } else if (!change.oldValue && change.newValue) {
      return (
        <>
          {' '}
          was set to{' '}
          <Box component="span" sx={{ fontWeight: 500 }}>
            {formatPropertyValue(change.domain, change.property || '', change.newValue)}
          </Box>
        </>
      );
    } else if (change.oldValue && !change.newValue) {
      return <> was removed</>;
    } else {
      return (
        <>
          {' '}
          was{' '}
          <Box component="span" sx={{ fontWeight: 500 }}>
            removed
          </Box>
        </>
      );
    }
  };

  if (change.type === 'update') {
    return (
      <Box sx={{ mb: 1 }}>
        {showDomainHeader && (
          <Typography component="div" sx={{ fontWeight: 'bold', mb: 0.5 }}>
            {domainLabel}:
          </Typography>
        )}
        <Box sx={{ ml: showDomainHeader ? 2 : 0 }}>
          <Typography variant="body2" component="div">
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              {propertyLabel}
            </Box>
            {renderChangeText()}
          </Typography>
        </Box>
      </Box>
    );
  }

  if (change.type === 'create') {
    return (
      <Box sx={{ mb: 1 }}>
        {showDomainHeader ? (
          <Typography component="div">Created:</Typography>
        ) : (
          <Typography variant="body2" component="div">
            <Box component="span" sx={{ fontWeight: 'bold' }}>
              New
            </Box>{' '}
            domain was created
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Box sx={{ mb: 1 }}>
      {showDomainHeader ? (
        <Typography component="div">Removed:</Typography>
      ) : (
        <Typography variant="body2" component="div">
          <Box component="span" sx={{ fontWeight: 'bold', textDecoration: 'line-through' }}>
            The domain was removed
          </Box>
        </Typography>
      )}
    </Box>
  );
};

export default ChangeItem;
