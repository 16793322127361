/* istanbul ignore file */
import React from 'react';
import RunMigrationButton from 'financialAnalytic/shared/button/RunMigrationButton';
import useLedgerService, { PERFORM_MIGRATION } from '../../useLedgerService';

const LOCAL_STORAGE_KEY = 'ledger-validation-data';

const RunLedgerValidationButton = () => {
  const { validateLedgerMigration } = useLedgerService();

  return (
    <RunMigrationButton
      displayMessage="Please, Fill out inputs below"
      localStorageKey={LOCAL_STORAGE_KEY}
      submitMethod={validateLedgerMigration}
      permissionToCheck={PERFORM_MIGRATION}
    >
      Run Validation
    </RunMigrationButton>
  );
};

export default RunLedgerValidationButton;
