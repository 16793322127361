import { useState } from 'react';
import { useRecruitmentApi } from 'api';
import { useCandidateId } from 'erp/candidate/CandidateRouter';
import { CANDIDATE_RESOURCE_URL } from 'erp/candidate/candidateService';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import useBaseCrudService from 'shared/crud/baseCrudService';
import { offerValidationSchema } from 'erp/candidate/offercompensation/createupdate/candidateOfferValidation';
import useVacancyGroupService from '../../../recruitment/newVacancy/useVacancyGroupService';
import { CANDIDATE_TIMELINE_OFFER_STATUSES } from '../constants';
import useRecruitingFinanceService, { BatchSensitiveRecruitingInfo } from 'erp/recruitment/useRecruitingFinanceService';

export const READ_CANDIDATE_OFFER_COMPENSATION = 'READ_CANDIDATE_OFFER_COMPENSATION';
export const RECRUITING_OFFER_CREATE = 'RECRUITING_OFFER_CREATE';
export const RECRUITING_OFFER_UPDATE = 'RECRUITING_OFFER_UPDATE';
export const RECRUITING_OFFER_DELETE = 'RECRUITING_OFFER_DELETE';
export const RECRUITING_OFFER_UPDATE_STATUS = 'RECRUITING_OFFER_UPDATE_STATUS';
export const RECRUITING_OFFER_READ_CALCULATED_AMOUNT_GROSS = 'RECRUITING_OFFER_READ_CALCULATED_AMOUNT_GROSS';

export const OFFER_URL = '/offers';

interface CompensationDetail {
  baseAmountType: 'MONTHLY' | 'HOURLY' | 'FIXED';
  currency: 'USD' | 'EUR' | 'BYN' | 'PLN';
  finalAmountGross?: number;
  probationAmountGross?: number;
  calculatedAmountGross: number;
  probationPeriodReduction?: number;
  office: 'AUSTIN' | 'MINSK' | 'KRAKOW';
  contractType: 'EC' | 'B2B' | 'CFS' | 'INTERNSHIP';
}

interface OfferData {
  id?: string;
  candidateId?: string;
  status?: (typeof CANDIDATE_TIMELINE_OFFER_STATUSES)[keyof typeof CANDIDATE_TIMELINE_OFFER_STATUSES];
  compensationDetails: CompensationDetail[];
}

type Dictionary<T> = { [key: string]: T };
type FormFieldsData = Dictionary<any>;

const fieldsToEncrypt = [
  'finalAmountGross',
  'probationAmountGross',
  'probationPeriodReduction',
  'calculatedAmountGross',
];

const useCandidateOfferService = () => {
  const [offerData, setOfferData] = useState<OfferData | null>(null);
  const { sendPostRequest, sendPutRequest, sendDeleteRequest, sendGetRequest } = useRecruitmentApi();
  const { saveBatchSensitiveRecruitingInfo } = useRecruitingFinanceService();
  const { retrieveSensitiveRecruitingInfo } = useVacancyGroupService();
  const candidateId = useCandidateId();
  const parentResourceUrl = `${CANDIDATE_RESOURCE_URL}`;

  const { addMessage } = useMessageService();

  const {
    getById: initialGetById,
    update: initialUpdate,
    deleteById,
    ...baseCrudRequests
  } = useBaseCrudService({
    singleResourceUrl: `${parentResourceUrl}${OFFER_URL}/:id`,
    listResourceUrl: `${parentResourceUrl}${OFFER_URL}`,
    apiService: useRecruitmentApi,
  });

  const getById = async (offerId: any) => {
    try {
      const offerResponse = await initialGetById(offerId);

      if (!offerResponse) {
        return null;
      }

      const allIds: string[] = offerResponse.compensationDetails
        .flatMap((details: any) => fieldsToEncrypt.map(fieldName => details[`${fieldName}Id`]))
        .filter(Boolean);

      let sensitiveInfoMap;
      try {
        sensitiveInfoMap = await retrieveSensitiveRecruitingInfo(allIds);
      } catch (error) {
        sensitiveInfoMap = new Map()
      }

      const updatedOfferResponse = {
        ...offerResponse,
        compensationDetails: offerResponse.compensationDetails.map((compensation: any) => {
          const updatedCompensation = { ...compensation };

          fieldsToEncrypt.forEach(fieldName => {
            const id = compensation[`${fieldName}Id`];
            updatedCompensation[fieldName] = sensitiveInfoMap.get(id) || '';
            // delete updatedCompensation[idField];
          });

          return updatedCompensation;
        }),
      };

      setOfferData(updatedOfferResponse);
      return updatedOfferResponse;
    } catch (error) {
      addMessage(new ErrorMessage('Error in getting offer'));
      throw error;
    }
  };

  const createOffer = async (formData: FormFieldsData) => {
    try {
      const compensationDetails = await Promise.all(
        formData.compensationDetails.map(async (details: any) => {
          const sensitiveData = fieldsToEncrypt
            .map(fieldName => {
              if (!details[fieldName]) {
                return false;
              }
              return {
                value: Number(details[fieldName]),
                externalId: fieldName,
              };
            })
            .filter(Boolean);

          const savedSensitiveData = await saveBatchSensitiveRecruitingInfo(
            sensitiveData as BatchSensitiveRecruitingInfo[],
          );

          const encryptedValues = Object.fromEntries(
            savedSensitiveData.map((savedData: any) => {
              return [`${savedData.externalId}Id`, savedData.id];
            }),
          );

          return {
            baseAmountType: details.baseAmountType,
            currency: details.currency,
            office: details.office,
            contractType: details.contractType,
            ...encryptedValues,
          };
        }),
      );
      const requestData = { candidateId, compensationDetails };
      const response = await sendPostRequest(`${parentResourceUrl}${OFFER_URL}`, requestData);
      return response.json();
    } catch (error) {
      addMessage(new ErrorMessage('Error during creating offer'));
      throw error;
    }
  };

  const update = async (offerId: StringOrNumber, formData: FormFieldsData) => {
    try {
      const currentData = offerData;

      const compensationDetails = await Promise.all(
        formData.compensationDetails.map(async (details: any, index: number) => {
          const oldCompensationDetails = currentData?.compensationDetails[index];
          const sensitiveData = fieldsToEncrypt
            .map(fieldName => {
              const newValue = details[fieldName];
              const oldValue = oldCompensationDetails?.[fieldName as keyof CompensationDetail];
              if (!newValue) {
                return false;
              }

              if (newValue === oldValue) {
                return {
                  externalId: fieldName,
                  id: oldCompensationDetails?.[`${fieldName}Id` as keyof CompensationDetail],
                  skipEncryption: true,
                };
              }

              return {
                value: newValue,
                externalId: fieldName,
              };
            })
            .filter(item => item && !item.skipEncryption);

          let encryptedValues: Record<string, number | string | undefined> = {};

          if (sensitiveData.length > 0) {
            const savedSensitiveData = await saveBatchSensitiveRecruitingInfo(
              sensitiveData as BatchSensitiveRecruitingInfo[],
            );

            encryptedValues = Object.fromEntries(
              savedSensitiveData.map((savedData: any) => [`${savedData.externalId}Id`, savedData.id]),
            );
          }

          fieldsToEncrypt.forEach(fieldName => {
            if (!encryptedValues[`${fieldName}Id`]) {
              encryptedValues[`${fieldName}Id`] = details[fieldName]
                ? oldCompensationDetails?.[`${fieldName}Id` as keyof CompensationDetail]
                : undefined;
            }
          });

          return {
            baseAmountType: details.baseAmountType,
            currency: details.currency,
            office: details.office,
            contractType: details.contractType,
            ...encryptedValues,
          };
        }),
      );

      const requestData = {
        id: offerId,
        candidateId,
        compensationDetails,
      };

      return await sendPutRequest(`${parentResourceUrl}${OFFER_URL}/${offerId}`, requestData).then(response =>
        getById(offerId),
      );
    } catch (error) {
      addMessage(new ErrorMessage('Error during updating offer'));
      throw error;
    }
  };

  const deleteOffer = async (offerId: any) => {
    try {
      return await sendDeleteRequest(`${parentResourceUrl}${OFFER_URL}/${offerId}`);
    } catch (error) {
      addMessage(new ErrorMessage('Failed to delete offer'));
      throw error;
    }
  };

  const cancelOffer = async (offerId: any) => {
    try {
      const response = await sendPostRequest(`${parentResourceUrl}${OFFER_URL}/${offerId}/cancel`);
      return response.json();
    } catch (error) {
      addMessage(new ErrorMessage('Failed to cancel offer'));
      throw error;
    }
  };

  const acceptOffer = async (offerId: any) => {
    try {
      const response = await sendPostRequest(`${parentResourceUrl}${OFFER_URL}/${offerId}/accept`);
      return response.json();
    } catch (error) {
      addMessage(new ErrorMessage('Failed to accept offer'));
      throw error;
    }
  };

  const rejectOffer = async (offerId: any) => {
    try {
      const response = await sendPostRequest(`${parentResourceUrl}${OFFER_URL}/${offerId}/reject`);
      return response.json();
    } catch (error) {
      addMessage(new ErrorMessage('Failed to reject offer'));
      throw error;
    }
  };

  const sendOffer = async (offerId: any) => {
    try {
      const response = await sendPostRequest(`${parentResourceUrl}${OFFER_URL}/${offerId}/send`);
      return response.json();
    } catch (error) {
      addMessage(new ErrorMessage('Failed to send offer'));
      throw error;
    }
  };

  const finalizeOffer = async (offerId: any) => {
    try {
      const response = await sendPostRequest(`${parentResourceUrl}${OFFER_URL}/${offerId}/finalize`);
      return response.json();
    } catch (error) {
      addMessage(new ErrorMessage('Failed to finalize offer'));
      throw error;
    };
  };

  const getOfferPermissions = async (offerId: any) => {
    try {
      const response = await sendGetRequest(`/permissions/Offer/${offerId}`);
      return response.json();
    } catch (error) {
      addMessage(new ErrorMessage('Failed to fetch permissions for offer.'));
      throw error;
    };
  };

  const getValidationSchema = () => Promise.resolve(offerValidationSchema);

  return {
    ...baseCrudRequests,
    getValidationSchema,
    createOffer,
    update,
    getById,
    sendOffer,
    rejectOffer,
    acceptOffer,
    cancelOffer,
    deleteOffer,
    finalizeOffer,
    getOfferPermissions,
  };
};

export default useCandidateOfferService;
