import React from 'react';
import AutosuggestDropdown from 'shared/uibuilder/form/input/AutosuggestDropdown';
import SearchIcon from '@mui/icons-material/SearchOutlined';
import useCandidateService from '../../../candidateService';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';

import './HeaderSearchSelect.scss';

const HeaderSearchCandidateSelect = () => {
  const { getSingleEntityUrl } = useCandidateUrl();
  const { search } = useCandidateService();

  return (
    <AutosuggestDropdown
      isInfinityLoading
      source="search"
      loadSuggestionsMethod={async (text: string, loadCurrentPage: number) => {
        const response = await search({
          pageSize: 30,
          pageNumber: loadCurrentPage,
          filter: {
            searchString: {
              ct: text,
            },
          },
          sort: {
            status: 'DESC',
          },
        });

        return response;
      }}
      mapResults={item => {
        return {
          id: item.id,
          text: item.name.fullName,
        };
      }}
      selecteSuggestionMethod={value => {
        if (value) {
          const url = getSingleEntityUrl(value);
          window.open(url, '_blank');
        }
      }}
      className="search-candidate-control"
      placeholder="Search candidate by ID, email, name"
      InputIcon={
        <SearchIcon
          sx={(theme: any) => ({
            position: 'absolute',
            zIndex: 10,
            width: '20px',
            top: '5px',
            left: '5px',
            color: theme.vars.palette.primary.main,
          })}
        />
      }
      searchRequest={search}
    />
  );
};

export default HeaderSearchCandidateSelect;

// const [value, setValue] = useState('');
