import { CREATE_CANDIDATE_APPLICATION } from 'erp/candidate/candidateService';
import ApplicationTimelineRecordLayout from 'erp/candidate/Timeline/application/ApplicationTimelineRecordLayout';

const useCandidateApplicationItem = () => ({
  isApplicable: (item: any) => item.candidateApplication,
  source: 'candidateApplication',
  urlPostfix: 'application',
  RecordLayout: ApplicationTimelineRecordLayout,
  name: 'Candidate Application',
  permissionToCheck: CREATE_CANDIDATE_APPLICATION,
  withoutAddFunctionality: true,
});

export default useCandidateApplicationItem;
