import React from 'react';
import { Box, Typography } from '@mui/material';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { NumberInput, SelectInput } from 'shared/uibuilder/form/input';
import { COMPETENCY_LEVEL_WITHOUT_NA } from '../../vacancy/shared/vacancyService';
import { COMPENSATION_CURRENCY_TYPES } from '../../newVacancy/constants';
import FlagBelarusIcon from 'uibuilder/AppIcon/Icons/FlagBelarus';
import FlagPolandIcon from 'uibuilder/AppIcon/Icons/FlagPoland';

const currencyOptions = Object.values(COMPENSATION_CURRENCY_TYPES).map(currency => ({
  value: currency,
  label: currency,
}));

const SalaryForkLevelsForm = () => {
  return (
    <FormSection>
      {(Object.values(COMPETENCY_LEVEL_WITHOUT_NA) as Array<keyof typeof COMPETENCY_LEVEL_WITHOUT_NA>).map(level => (
        <FormRow key={level}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', gap: 4 }}>
            {/* Level Label */}
            <Box sx={{ width: '60px' }}>
              <Typography variant="h5" sx={{ textTransform: 'uppercase', color: 'var(--mui-palette-primary-20)' }}>
                {level}
              </Typography>
            </Box>

            {/* BY Section */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <FlagBelarusIcon />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box sx={{ width: '100px' }}>
                    <NumberInput source={`${level}.bySalaryFromValue`} placeholder="min" label="" sx={{ mt: '20px' }} />
                  </Box>
                  <Typography>-</Typography>
                  <Box sx={{ width: '100px' }}>
                    <NumberInput source={`${level}.bySalaryToValue`} placeholder="max" label="" sx={{ mt: '20px' }} />
                  </Box>
                  <Box sx={{ width: '120px' }}>
                    <SelectInput
                      source={`${level}.byCurrency`}
                      options={currencyOptions}
                      label=""
                      sx={{ mt: '20px' }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>

            {/* PL Section */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flex: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <FlagPolandIcon />
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Box sx={{ width: '100px' }}>
                    <NumberInput source={`${level}.plSalaryFromValue`} placeholder="min" label="" sx={{ mt: '20px' }} />
                  </Box>
                  <Typography>-</Typography>
                  <Box sx={{ width: '100px' }}>
                    <NumberInput source={`${level}.plSalaryToValue`} placeholder="max" label="" sx={{ mt: '20px' }} />
                  </Box>
                  <Box sx={{ width: '120px' }}>
                    <SelectInput
                      source={`${level}.plCurrency`}
                      options={currencyOptions}
                      label=""
                      sx={{ mt: '20px' }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </FormRow>
      ))}
    </FormSection>
  );
};

export default SalaryForkLevelsForm;
