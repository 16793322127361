import React from 'react';
import Show from 'shared/uibuilder/show/Show';
import { useCandidateId } from 'erp/candidate/CandidateRouter';
import useCandidateService from '../../../candidateService';

const OfferTemplateWrapper = ({ children }: any) => {
  const { getById } = useCandidateService();
  const candidateId = useCandidateId();

  return <Show getDataMethod={() => getById(candidateId)}>{children}</Show>;
};

export default OfferTemplateWrapper;
