import React from 'react';
import { Stack, styled } from '@mui/material';
import CandidateResumeUploaderInput from 'artifact/shared/input/CandidateResumeUploaderInput';
import EmployeePhotoUploaderInput from 'artifact/shared/input/EmployeePhotoUploaderInput';
import { ENGLISH_LEVEL_OPTIONS } from 'erp/candidate/candidateService';
import AdditionalContactListInput from 'erp/shared/input/AdditionalContactListInput';
import AdditionalContactInputListLayout from 'erp/shared/input/AdditionalContactListInput/AdditionalContactInputListLayout/AdditionalContactInputListLayout';
import AdditionalContactWrapperProfile from 'erp/shared/input/AdditionalContactListInput/AdditionalContactWrapperProfile';
import { TextInput, CountryDropdown, Checkbox } from 'shared/uibuilder/form/input';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import NativeLanguageDropdown from 'shared/uibuilder/form/input/NativeLanguageDropdown';
import SkillsInput from 'shared/uibuilder/skillsInput/SkillsInput';
import DualInputField from '../EditComponents/DualInputField/DualInputField';
import Box from 'uibuilder/Box';
import useSkillsService from 'erp/candidate/skills/skillsService';
import SmallPhotoUploaderLayout from 'artifact/shared/input/layout/SmallPhotoUploaderLayout/SmallPhotoUploaderLayout';
import SmallFileUploaderLayout from 'artifact/shared/input/layout/SmallFileUploaderLayout/SmallFileUploaderLayout';

const StyledCheckboxStack = styled(Stack)(({ theme }: { theme: any }) => ({
  marginTop: '0 !important',
  '& .MuiFormControlLabel-labelPlacementEnd': { margin: '0' },
  '&>div': {
    marginBottom: 0,
    '&:first-child': { flexBasis: '110%' },
    '& label': {
      marginRight: 0,
      justifyContent: 'center',
    },
  },
  '&>div:first-of-type label': {
    justifyContent: 'start',
  },
  '&>div:last-of-type label': {
    justifyContent: 'end',
  },
  '& .MuiCheckbox-root': {
    padding: '1px',
  },
}));

const CandidateProfileEditFields = () => {
  const { searchByName } = useSkillsService();

  return (
    <Stack direction="column" gap="0.5rem">
      <Stack direction="row" gap="0.5rem">
        <SmallPhotoUploaderLayout>
          <EmployeePhotoUploaderInput
            label=""
            source="photosIds"
            maxElements={1}
            uploadBtnText="Upload photo"
            showBtnImage={false}
          />
        </SmallPhotoUploaderLayout>
        <Stack
          direction="column"
          gap="0.5rem"
          sx={{
            width: '100%',
            '&>div': {
              marginBottom: 0,
            },
          }}
        >
          <TextInput source="name.fullName" label="" placeholder="Full Name" />
          <CountryDropdown source="residenceCountry" label="" />
          <NativeLanguageDropdown source="nativeLanguage" label="" />
          <EnumDropdown
            source="englishLevel"
            label=""
            options={ENGLISH_LEVEL_OPTIONS}
            placeholder="Choose English Level"
          />
        </Stack>
      </Stack>
      <StyledCheckboxStack direction="row">
        <Checkbox
          disabled={formData => formData.isInBlackList}
          source="isMarkedAsPotentialCandidate"
          label="Keep in view"
        />
        <Checkbox source="isStudent" label="Student" />
        <Checkbox
          disabled={formData => formData.isMarkedAsPotentialCandidate}
          source="isInBlackList"
          label="Blacklist"
        />
        <Checkbox source="isHidden" label="Hidden" />
      </StyledCheckboxStack>
      <DualInputField
        primarySource="personalEmail"
        secondarySource="otherEmail"
        placeholder="Email"
        label=""
        trimComponent
      />
      <DualInputField
        primarySource="personalPhoneNumber"
        secondarySource="otherPhoneNumber"
        placeholder="Phone"
        label=""
      />
      <AdditionalContactWrapperProfile>
        <AdditionalContactListInput
          CustomInputListLayout={AdditionalContactInputListLayout}
          source="additionalContacts"
          maxFields={5}
        />
      </AdditionalContactWrapperProfile>
      <Box
        sx={{
          marginTop: '0 !important',
          '&>div': {
            marginBottom: '0px',
          },
          '& .dropdown__multi-value': {
            backgroundColor: '#5954A8',
            color: '#ffffff',
            '&__label': {
              color: '#ffffff',
            },
          },
        }}
      >
        <SkillsInput source="skills" label="" loadSuggestionsMethod={searchByName} />
      </Box>
      <SmallFileUploaderLayout>
        <CandidateResumeUploaderInput
          label=""
          source="lastUploadedResumeIds"
          maxElements={1}
          uploadBtnText="Upload or drag resume file"
        />
      </SmallFileUploaderLayout>
    </Stack>
  );
};

export default CandidateProfileEditFields;
