// libs
import React, { useState, useEffect, useRef, useCallback, ChangeEvent } from 'react';
// components
import UploadingQueueLayout from 'artifact/shared/input/layout/list/UploadingQueueLayout';
// services
import useUploadingQueueService from './uploadingQueueService';
import useArtifactService from 'artifact/artifactService';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import { mapExtensions } from 'shared/uibuilder/helper';
// static
import fileUploadImg from 'artifact/images/upload.svg';
import './FileUploader.scss';
import usePasteFromBuffer from 'shared/usePasteFromBuffer';

type FileUploaderProps = {
  validationCallback: (error: any) => void;
  validateFunc: (file: File[]) => any;
  uploadCallback: (id: string, file: File) => void;
  acceptExtensions: Nullable<string>;
  artifactTypeId: string;
  source: string;
  canAddFromClipboard?: boolean;
  showBtnImage?: boolean;
  uploadBtnText?: string;
  disabled?: boolean;
  uploadFile?: (file: File) => void;
};

const FileUploader = ({
  validationCallback = () => {},
  validateFunc = () => {},
  uploadCallback = () => {},
  acceptExtensions = null,
  artifactTypeId,
  source,
  canAddFromClipboard = false,
  showBtnImage = true,
  uploadBtnText = 'Upload or drag files',
  disabled = false,
  uploadFile,
}: FileUploaderProps) => {
  const { addToUploadingQueue, removeFromUploadingQueue, modifyLoadingProgress, getObjectsInQueue } =
    useUploadingQueueService();

  const { uploadArtifact } = useArtifactService({ uploadFile });
  const { setIsLoadingArtifact = () => {} } = useFormContext();

  const [uploadCount, _setUploadCount] = useState(0);
  const [isDragEnter, setDragEnter] = useState(false);
  const countRef = useRef(uploadCount);

  const setUploadCount = useCallback(
    (data: (arg0: number) => any) => {
      const result = typeof data === 'function' ? data(countRef.current) : data;
      countRef.current = result;
      _setUploadCount(result);
    },
    [_setUploadCount],
  );

  const upload = async (e: ChangeEvent<HTMLInputElement>) => {
    const input = e.target;
    const { files } = e.target;

    if (!files) {
      return;
    }

    const fileArray = Array.from(files);

    const error = validateFunc([...fileArray, ...getObjectsInQueue()]);

    if (error) {
      validationCallback(error);
      e.preventDefault();
      return;
    }

    const promises = Array.from(files).map(async file => {
      const { temporaryId, signal } = await addToUploadingQueue(file);

      const artifactId = await uploadArtifact({
        file,
        signal,
        artifactTypeId,
        onProgress: (loadingProgress: any) => {
          modifyLoadingProgress(temporaryId, loadingProgress);
        },
      });

      removeFromUploadingQueue(temporaryId);
      uploadCallback(artifactId, file);
    });

    setUploadCount(currentCount => currentCount + 1);

    Promise.all(promises.map(p => p.catch(er => er))).finally(() => {
      setUploadCount(currentCount => currentCount - 1);
    });

    input.value = '';
  };

  useEffect(() => {
    if (uploadCount === 0) {
      setIsLoadingArtifact(false);
    } else {
      setIsLoadingArtifact(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadCount]);

  useEffect(() => {
    return () => {
      setIsLoadingArtifact(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteFile = (id: string) => {
    removeFromUploadingQueue(id);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDragEnter(false);
    validationCallback(null);
    upload(e); // передаем событие дальше
  };

  const handleDragEnter = () => {
    setDragEnter(true);
  };

  const handleDragLeave = () => {
    setDragEnter(false);
  };

  const handlePaste = useCallback(
    ({ clipboardData: { files } }: any) => {
      if (countRef.current === 0 && files.length) {
        handleChange({ target: { files } } as ChangeEvent<HTMLInputElement>);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countRef.current, validateFunc],
  );

  usePasteFromBuffer(handlePaste, canAddFromClipboard);

  const className = !disabled ? `photo-upload` : 'photo-upload-disabled';
  const classNameForDrag = isDragEnter ? 'is-enter' : '';

  return (
    <>
      <UploadingQueueLayout
        loadingArtifactObjects={getObjectsInQueue()}
        onRemove={loadingArtifactObjects => deleteFile(loadingArtifactObjects.id)}
      />
      <label
        className={`${className} ${classNameForDrag}`}
        title={canAddFromClipboard ? 'Copy screenshot to clipboard and perform hotkey paste(Ctrl/Cmd + V)' : ''}
        htmlFor={source}
      >
        <input
          id={source}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          name={source}
          disabled={disabled}
          type="file"
          onChange={handleChange}
          accept={mapExtensions(acceptExtensions) as string}
          multiple
        />
        {showBtnImage && <img src={fileUploadImg} alt="Upload" />}
        <span>{uploadBtnText}</span>
      </label>
    </>
  );
};

export default FileUploader;
