import { EMPLOYEE_DASHBOARD_COLORS, EMPLOYEE_ZONES_ALIASES } from '../../constants';

export interface HealthcheckStatusConfig {
  textColor: string;
  buttonColor: string;
}

export const HEALTHCHECK_CONFIG: Record<keyof typeof EMPLOYEE_ZONES_ALIASES, HealthcheckStatusConfig> = {
  SAFE: {
    textColor: EMPLOYEE_DASHBOARD_COLORS.SAFE,
    buttonColor: EMPLOYEE_DASHBOARD_COLORS.SAFE,
  },
  MONITORING: {
    textColor: EMPLOYEE_DASHBOARD_COLORS.MONITORING,
    buttonColor: EMPLOYEE_DASHBOARD_COLORS.MONITORING,
  },
  CRITICAL: {
    textColor: EMPLOYEE_DASHBOARD_COLORS.CRITICAL,
    buttonColor: EMPLOYEE_DASHBOARD_COLORS.CRITICAL,
  },
  ESCALATION: {
    textColor: EMPLOYEE_DASHBOARD_COLORS.ESCALATION,
    buttonColor: EMPLOYEE_DASHBOARD_COLORS.ESCALATION,
  },
} as Record<keyof typeof EMPLOYEE_ZONES_ALIASES, HealthcheckStatusConfig>;

export const getStatusByMonths = (months: number) => {
  if (months >= 7) {
    return EMPLOYEE_ZONES_ALIASES.CRITICAL;
  } else if (months >= 6) {
    return EMPLOYEE_ZONES_ALIASES.MONITORING;
  } else {
    return EMPLOYEE_ZONES_ALIASES.SAFE;
  }
};
