import useDateService from 'shared/uibuilder/dateService/useDateService';
import useDateTimeService from 'shared/uibuilder/dateService/useDateTimeService';

const positiveNumberRegEx = /^\d{1,6}?$/;

const compensationRequiredCondition = {
  value: true,
  when: {
    $compensationToDefine: {
      eq: false,
    },
    $useExistingVacancy: {
      eq: false,
    },
  },
};

const vacancyDetailsRequiredCondition = {
  value: true,
  when: {
    $vacancyDetailsToDefine: {
      eq: false,
    },
    $useExistingVacancy: {
      eq: false,
    },
  },
};

const useExistingVacancyIsFalseCondition = {
  value: true,
  when: {
    $useExistingVacancy: {
      eq: false,
    },
  },
};

const useHiringValidationOneStop = () => {
  const { getCurrentDate } = useDateService();
  const { getCurrentTimezoneDateInUtc } = useDateTimeService();
  const expirationMinDate = getCurrentTimezoneDateInUtc(getCurrentDate().add(3, 'months'));
  const targetMinDate = getCurrentTimezoneDateInUtc(getCurrentDate().add(1, 'd'));

  return {
    useExistingVacancy: {
      type: 'boolean',
      required: false,
    },
    vacancy: {
      type: 'object',
      required: {
        value: true,
        when: {
          $useExistingVacancy: {
            eq: true,
          },
        },
      },
    },
    isAsap: {
      type: 'boolean',
      required: false,
    },
    positionsMin: {
      type: 'number',
      required: true,
      minValue: 1,
      maxValue: [
        {
          value: '$openPositions',
          message: "Min can't be greater than max.",
        },
      ],
    },
    openPositions: {
      type: 'number',
      required: true,
      minValue: [
        {
          value: '$positionsMin',
          message: 'Max cannot be less than min.',
        },
      ],
    },
    name: {
      type: 'string',
      required: {
        value: true,
        when: {
          $aliasPrefix: {
            eq: 'OTH',
          },
        },
      },
      maxSize: 255,
    },
    aliasPrefix: {
      type: 'enum',
      required: true,
    },
    targetDate: {
      type: 'date',
      required: {
        value: true,
        when: {
          $isAsap: {
            eq: false,
          },
        },
      },
      minDate: {
        value: targetMinDate,
        message: 'Must be in future.',
      },
    },
    expirationDate: {
      type: 'date',
      required: true,
      minDate: {
        value: expirationMinDate,
        message: 'Can`t be earlier than 3 months from the current date.',
      },
    },
    hiringMode: {
      type: 'enum',
      required: true,
    },
    competencyLevel: {
      type: 'enum',
      required: true,
    },
    vacancyDetails: {
      type: 'array',
      required: vacancyDetailsRequiredCondition,
      forEach: {
        type: 'object',
        _divisible: false,
        properties: {
          office: {
            type: 'array',
            required: vacancyDetailsRequiredCondition,
          },

          assignmentType: {
            type: 'array',
            required: vacancyDetailsRequiredCondition,
          },
          relocationOption: {
            type: 'enum',
            required: vacancyDetailsRequiredCondition,
          },
          hasProbation: {
            type: 'enum',
            required: vacancyDetailsRequiredCondition,
          },
          contractType: {
            type: 'array',
            required: vacancyDetailsRequiredCondition,
          },
          workplaceLocation: {
            type: 'array',
            required: vacancyDetailsRequiredCondition,
          },
          workingSchedule: {
            type: 'enum',
            required: vacancyDetailsRequiredCondition,
          },
        },
      },
    },
    expectedCompensation: {
      type: 'array',
      required: {
        ...compensationRequiredCondition,
        message: 'You must add at least one option',
      },
      maxSize: 10,
      forEach: {
        type: 'object',
        _divisible: false,
        properties: {
          office: {
            type: 'enum',
            required: compensationRequiredCondition,
          },
          contractType: {
            type: 'enum',
            required: compensationRequiredCondition,
          },
          valueFrom: {
            type: 'int',
            required: compensationRequiredCondition,
            maxValue: {
              value: '$.valueTo',
              message: "Minimal value can't be greater than maximal",
            },
            regex: {
              value: positiveNumberRegEx,
              message: 'Must be a positive number (max 6 digits)',
            },
          },
          valueTo: {
            type: 'int',
            required: compensationRequiredCondition,
            minValue: {
              value: '$.valueFrom',
              message: "Maximal value can't be less than minimal",
            },
            regex: {
              value: positiveNumberRegEx,
              message: 'Must be a positive number (max 6 digits)',
            },
          },
          currency: {
            type: 'enum',
            required: compensationRequiredCondition,
          },
        },
      },
    },
    description: {
      type: 'object',
      required: useExistingVacancyIsFalseCondition,
      properties: {
        responsibilities: {
          type: 'array',
          required: useExistingVacancyIsFalseCondition,
          forEach: {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                required: true,
                maxSize: 255,
              },
              description: {
                type: 'string',
                required: false,
                maxSize: 525,
              },
            },
          },
        },
        requirements: {
          type: 'array',
          required: useExistingVacancyIsFalseCondition,
          forEach: {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                required: true,
                maxSize: 255,
              },
              description: {
                type: 'string',
                required: false,
                maxSize: 525,
              },
            },
          },
        },
        optionalRequirements: {
          type: 'array',
          required: false,
          forEach: {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                required: true,
                maxSize: 255,
              },
              description: {
                type: 'string',
                required: false,
                maxSize: 525,
              },
            },
          },
        },
        personality: {
          type: 'array',
          required: false,
          forEach: {
            type: 'object',
            properties: {
              name: {
                type: 'string',
                required: true,
                maxSize: 255,
              },
              description: {
                type: 'string',
                required: false,
                maxSize: 525,
              },
            },
          },
        },
        projectText: {
          type: 'string',
          required: false,
          maxSize: 5000,
        },
      },
    },
  };
};

export default useHiringValidationOneStop;
