import React, { useEffect, useState } from 'react';
import Sticky from 'react-sticky-el';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import { scrollToHash } from 'shared/uibuilder/domUtils';
import { getAnchor } from 'shared/uibuilder/helper';
import FormButtonsLayout from 'shared/layout/form/FormButtonsLayout';
import InvalidSectionsMessage from 'uibuilder/form/InvalidSectionsMessage';
import classnames from 'classnames';

import './FloatingButtons.scss';

const isSectionInvalid = (formErrors: Record<string, any>, fieldSet: HTMLFieldSetElement) => {
  let isInvalid = false;
  if (fieldSet && fieldSet.outerHTML.includes('class="invalid-feedback"')) {
    return true;
  }

  const inputs = fieldSet.querySelectorAll('[name]');
  for (let j = 0; j < inputs.length; j += 1) {
    const name = inputs[j].getAttribute('name');

    const isInvalidInput = Object.entries(formErrors).some(entry => {
      const [source, errors] = entry;
      return source === name && errors && errors.length;
    });

    if (isInvalidInput) {
      isInvalid = true;
    }
  }

  return isInvalid;
};

/**
 * Big crutch. Traverse html dom tree, find sections and invalid inputs inside sections.
 */
export const getInvalidSections = (formErrors: Record<string, any>): any => {
  const fieldsSets = document.getElementsByTagName('fieldset');

  const invalidSections = [];

  for (let i = 0; i < fieldsSets.length; i += 1) {
    const fieldSet = fieldsSets[i];
    const sectionTitleElement = fieldSet.getElementsByTagName('legend')[0];

    const sectionTitle =
      (sectionTitleElement && sectionTitleElement.innerText) || sectionTitleElement?.textContent || '';

    if (isSectionInvalid(formErrors, fieldSet)) {
      invalidSections.push({
        anchor: getAnchor(sectionTitle),
        title: sectionTitle,
      });
    }
  }

  return invalidSections;
};

const scrollToTheFirstSection = (formErrors: FormFieldsData) => {
  const sections = getInvalidSections(formErrors);

  if (sections && sections[0]) {
    const firstErrorSection = sections[0];
    const id = firstErrorSection.anchor;
    scrollToHash(document.getElementById(id));
  }
};

interface FloatingButtonsProps {
  isCancelButtonVisible?: boolean;
  onCancelAction?: () => void;
  shouldScrollToErrorAfterLoading?: boolean;
  isViewInvalidSection?: boolean;
  className?: string;
  submitButtonText?: string;
  isCustomDisabledSubmit?: boolean;
}

/**
 * @param shouldScrollToErrorAfterLoading [CRUTCH] We have few use cases when we should display errors and scroll
 * to the first error section after form is loaded. Example: lead conversion, employee finalization.
 * If shouldScrollToErrorAfterLoading is true - user will be scrolled to the first section.
 */
const FloatingButtons: React.FC<FloatingButtonsProps> = ({
  shouldScrollToErrorAfterLoading = false,
  isCancelButtonVisible = true,
  onCancelAction = null,
  isViewInvalidSection = true,
  className,
  submitButtonText,
  isCustomDisabledSubmit,
}) => {
  const { formErrors } = useFormContext() || {};
  const [displayInvalidSections, setDisplayInvalidSection] = useState(shouldScrollToErrorAfterLoading);
  const [invalidSections, setInvalidSection] = useState([]);

  const [isScrolled, setScrolled] = useState(false);

  useEffect(() => {
    const areErrorsFound = Object.keys(formErrors as Record<string, any>).length > 0;

    if (!isScrolled && shouldScrollToErrorAfterLoading && areErrorsFound && formErrors) {
      scrollToTheFirstSection(formErrors);
      setScrolled(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldScrollToErrorAfterLoading, formErrors]);

  useEffect(() => {
    setInvalidSection(getInvalidSections(formErrors as Record<string, any>));
  }, [formErrors, isViewInvalidSection]);

  const messageComponent = (
    <InvalidSectionsMessage
      isViewInvalidSection={isViewInvalidSection}
      invalidSections={invalidSections}
      needToDisplay={displayInvalidSections}
    />
  );

  return (
    <Sticky mode="bottom" stickyClassName="sticky-buttons-element sticky">
      <div className={classnames('floating-btns', className)}>
        <FormButtonsLayout
          isCustomDisabledSubmit={isCustomDisabledSubmit}
          submitButtonText={submitButtonText}
          additionalOnSubmitAction={() => {
            setDisplayInvalidSection(true);
          }}
          messageComponent={messageComponent}
          isCancelButtonVisible={isCancelButtonVisible}
          onCancelAction={onCancelAction}
        />
      </div>
    </Sticky>
  );
};

export default FloatingButtons;
