import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs, Typography, Paper } from '@mui/material';
import moment from 'moment-timezone';
import { useVacancyGroupId } from 'erp/recruitment/newVacancy/routing';
import Loading from 'shared/uibuilder/Loading';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import useVacancyHistoryService from '../useVacancyHistoryService';
import { VacancyHistoryItem } from 'erp/recruitment/newVacancy/types';
import TimelineRecordAuthor from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordAuthor';
import { ChangesGroup } from '../components';

const VacancyHistoryList = () => {
  const groupId = useVacancyGroupId();
  const { getVacancyHistory, formatChanges } = useVacancyHistoryService();
  const { searchVacanciesByGroup } = useVacancyGroupService();
  const [vacancies, setVacancies] = useState<any[]>([]);
  const [selectedVacancyId, setSelectedVacancyId] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [historyItems, setHistoryItems] = useState<VacancyHistoryItem[]>([]);
  const { addMessage } = useMessageService();

  useEffect(() => {
    const fetchVacancies = async () => {
      try {
        setLoading(true);
        const data = await searchVacanciesByGroup(groupId);

        if (data?.result?.length > 0) {
          setVacancies(data.result);
          if (!selectedVacancyId) {
            setSelectedVacancyId(data.result[0].id);
          }
        }
      } catch (error) {
        addMessage(new ErrorMessage('Error fetching vacancies:'));
      } finally {
        setLoading(false);
      }
    };

    fetchVacancies();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  useEffect(() => {
    if (!selectedVacancyId) return;

    const fetchVacancyHistory = async () => {
      try {
        setLoading(true);
        const history = await getVacancyHistory(selectedVacancyId);
        setHistoryItems(history);
      } catch (error) {
        addMessage(new ErrorMessage('Error fetching vacancy history:'));
        setHistoryItems([]);
      } finally {
        setLoading(false);
      }
    };

    fetchVacancyHistory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVacancyId]);

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string) => {
    setHistoryItems([]);
    setSelectedVacancyId(newValue);
  };

  const formatDate = (dateString: string) => {
    try {
      return moment(dateString).format('MMM D, YYYY, HH:mm');
    } catch (e) {
      return 'Unknown date';
    }
  };

  if (!loading && vacancies.length === 0) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="h6">No vacancies found in this group</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs value={selectedVacancyId || ''} onChange={handleTabChange} variant="scrollable" scrollButtons="auto">
          {vacancies.map(vacancy => (
            <Tab key={vacancy.id} label={vacancy.title} value={vacancy.id} />
          ))}
        </Tabs>
      </Box>

      {loading ? (
        <Loading />
      ) : (
        <>
          {historyItems.length === 0 ? (
            <Typography variant="body1" sx={{ textAlign: 'center', p: 4 }}>
              No history entries for this vacancy
            </Typography>
          ) : (
            historyItems.map(item => {
              const groupedChanges = formatChanges(item);

              if (groupedChanges.length === 0) return null;

              return (
                <Paper key={`${item.id}-${item.createdAt}`} elevation={2} sx={{ p: 3, mb: 3 }}>
                  <Box sx={{ display: 'flex', gap: 1, mb: 2 }}>
                    <Typography variant="body2" color="text.secondary">
                      {formatDate(item.createdAt)}
                    </Typography>
                    {item.createdBy && <TimelineRecordAuthor id={item.createdBy} name={item.fullName || ''} />}
                  </Box>

                  <Typography variant="h6" sx={{ mb: 2, fontSize: '1.15rem' }}>
                    Changes to vacancy:
                  </Typography>

                  <Box>
                    {groupedChanges.map(group => (
                      <ChangesGroup
                        key={`${item.id}-${group.domainName}`}
                        domainName={group.domainName}
                        changes={group.changes}
                      />
                    ))}
                  </Box>
                </Paper>
              );
            })
          )}
        </>
      )}
    </Box>
  );
};

export default VacancyHistoryList;
