import React, { useCallback, useState } from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import { usePublicationsUrl, useVacancyGroupId, useVacancyGroupUrl } from 'erp/recruitment/newVacancy/routing';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import VacanciesWrapper from '../vacancy/VacanciesWrapper';
import VacancyGroupInfo from './VacancyGroupInfo';
import RelatedPublicationsList from '../publications/List/RelatedPublicationsList';
import VacancyGroupTabContent from './VacancyGroupTabContent';
import { useNavigate } from 'react-router-dom';
import VacancyGroupPublicationBreadcrumbs from 'erp/recruitment/newVacancy/show/publications/VacancyGroupPublicationBreadcrumbs';
import { VacancyContextProvider } from 'erp/recruitment/newVacancy/VacancyContext';
import SourcingCandidatesList from '../sourcing/List/SourcingCandidatesList';
import useFeatureToggle, { Features } from 'featuretoggle';
import GenerateLinkForSycomButton from 'erp/recruitment/newVacancy/shared/button/GenerateLinkForSycomButton';
import VacancyHistoryList from '../history/List/VacancyHistoryList';
import FormShowTemplate from 'shared/uibuilder/FormShowContexts/FormShowTemplate';

export interface TabProps {
  index: number;
  label: string;
  onChangeTabCallback: (index: number, route: string) => void;
  content: React.ReactNode;
  route: string;
}

interface ViewVacancyGroupProps {
  activeTab: number;
}

const ViewVacancyGroup: React.FC<ViewVacancyGroupProps> = ({ activeTab = 0 }) => {
  const id = useVacancyGroupId()!;
  const { getVacancyGroupById, visitGroup, updateVacancyGroup, mapDataForForm } = useVacancyGroupService();
  const publicationsUrl = usePublicationsUrl();
  const { getSingleEntityUrl, getSourcingUrl, getHistoryUrl } = useVacancyGroupUrl();
  const { isFeatureEnabled } = useFeatureToggle();
  const viewHistory = isFeatureEnabled(Features.VACANCY_HISTORY);

  const [areVacanciesUpdated, setAreVacanciesUpdated] = useState(false);
  const [arePublicationsUpdated, setArePublicationsUpdated] = useState(false);

  const [currentTab, setCurrentTab] = useState<number>(activeTab);
  const navigate = useNavigate();

  const onChangeTabCallback = useCallback(
    (index: number, route: string) => {
      setCurrentTab(index);
      navigate(route, { replace: true });
    },
    [navigate],
  );

  const vacancyGroupTabs: TabProps[] = [
    {
      index: 0,
      label: 'Information',
      onChangeTabCallback,
      content: <VacanciesWrapper />,
      route: getSingleEntityUrl(id),
    },
    {
      index: 1,
      label: 'Publications',
      onChangeTabCallback,
      content: <RelatedPublicationsList />,
      route: publicationsUrl,
    },
    {
      index: 2,
      label: 'Sourcing',
      onChangeTabCallback,
      content: <SourcingCandidatesList />,
      route: getSourcingUrl(id),
    },
    viewHistory && {
      index: 3,
      label: 'History',
      onChangeTabCallback,
      content: <VacancyHistoryList />,
      route: getHistoryUrl(id),
    },
  ].filter(Boolean) as TabProps[];

  visitGroup(id);

  return (
    <FormShowTemplate
      getDataFunc={() => getVacancyGroupById(id)}
      submitFormFunc={values => updateVacancyGroup(id, values)}
      mapDataForForm={mapDataForForm}
      afterSubmit={{
        message: 'You are awesome! The Vacancy Group has been successfully updated.',
      }}
    >
      <VacancyContextProvider
        value={{
          areVacanciesUpdated,
          arePublicationsUpdated,
          setAreVacanciesUpdated,
          setArePublicationsUpdated,
        }}
      >
        <ShowPageLayout
          hasSeparateSections
          breadcrumbs={
            <VacancyGroupPublicationBreadcrumbs
              entity={vacancyGroupTabs.find(tab => tab.index === currentTab)?.label}
              tabs={vacancyGroupTabs}
              setCurrentTab={setCurrentTab}
            />
          }
          breadcrumbsButtons={[<GenerateLinkForSycomButton isUsingFromVacancyPage />]}
        >
          <VacancyGroupInfo />
          {vacancyGroupTabs.map(({ index, content }) => (
            <VacancyGroupTabContent index={index} currentTab={currentTab}>
              {content}
            </VacancyGroupTabContent>
          ))}
        </ShowPageLayout>
      </VacancyContextProvider>
    </FormShowTemplate>
  );
};

export default ViewVacancyGroup;
