import React from 'react';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';
import { FieldProps, LinkField } from 'shared/uibuilder/field';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';

const CandidateLinkFieldWithName = ({ source, nameSource, ...props }: FieldProps) => {
  const { getValue: getId } = useFieldHelper({ source });
  const { getValue: getName } = useFieldHelper({ source: nameSource });
  const { getSingleEntityUrl } = useCandidateUrl();

  const id = getId();
  const value = id && getSingleEntityUrl(id);

  return <LinkField {...props} linkText={nameSource ? getName() : id} value={value as string} isInternal={false} />;
};

export default CandidateLinkFieldWithName;
