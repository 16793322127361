import React from 'react';
import { Notifications, PriorityHigh } from '@mui/icons-material';
import ChangeRequestIcon from 'uibuilder/AppIcon/Icons/ChangeRequestIcon';
import Tooltip from 'uibuilder/Tooltip';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { ViewStatus } from '../../types';

const ViewStatusNotification: React.FC = () => {
  const { getValue: getViewStatus } = useFieldHelper({ source: 'viewStatus' });
  const { getValue: getHasChangeRequests } = useFieldHelper({ source: 'areVacancyChangesRequested', value: false });
  const viewStatus = getViewStatus();
  const hasChangeRequests = getHasChangeRequests();

  const renderStatusIcon = () => {
    if (viewStatus === ViewStatus.NEW) {
      return (
        <Tooltip title="This vacancy is new and you didn't see it yet.">
          <PriorityHigh sx={{ fontSize: 10 }} />
        </Tooltip>
      );
    } else if (viewStatus === ViewStatus.UPDATED) {
      return (
        <Tooltip title="This vacancy was updated and you didn't see the changes yet.">
          <Notifications sx={{ fontSize: 10 }} />
        </Tooltip>
      );
    }
    return null;
  };

  const renderChangeRequestIcon = () => {
    if (hasChangeRequests) {
      return (
        <Tooltip title="This vacancy has change requests.">
          <span>
            <ChangeRequestIcon sx={{ fontSize: 10, fill: 'currentcolor', mr: 0.5 }} />
          </span>
        </Tooltip>
      );
    }
    return null;
  };

  return (
    <>
      {renderStatusIcon()}
      {renderChangeRequestIcon()}
    </>
  );
};

export default ViewStatusNotification;
