import React, { useMemo } from 'react';
import Grid from 'uibuilder/Grid';
import Box from 'uibuilder/Box';
import Typography from 'uibuilder/Typography';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import ScrollToSectionAnchor from 'shared/uibuilder/Anchor/ScrollToSectionAnchor';
import Data from 'shared/uibuilder/Data';
import { styled, SxProps } from '@mui/material/styles';

const StyledBox = styled(Box)(({ theme }: { theme: any }) => ({
  marginBottom: '20px',
  '& h4': {
    color: (theme.vars?.palette.primary as any)[20],
    display: 'flex',
    alignItems: 'flex-start',
  },
  '& p': {
    margin: 0,
  },
}));

interface ShowSectionProps {
  title?: string | React.ReactElement | ((data: Data) => string | React.ReactElement);
  titleId?: string;
  children: React.ReactElement | React.ReactElement[];
  isVisible?: boolean | ((data: Data) => boolean);
  hasAnchorLink?: boolean;
  titleVariant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5';
  actions?: React.ReactNode;
  justifyContent?: string;
  sx?: SxProps;
}

const ShowSection = ({
  title,
  titleId,
  children,
  isVisible = true,
  hasAnchorLink = false,
  titleVariant = 'h4',
  actions,
  justifyContent = 'flex-start',
  sx,
}: ShowSectionProps) => {
  const { data } = useShowContext();

  const isSectionsVisible = useMemo(
    () => (typeof isVisible === 'function' ? isVisible(data) : isVisible),
    [isVisible, data],
  );

  const titleHeader = typeof title === 'function' ? title(data) : title;

  return isSectionsVisible ? (
    <StyledBox sx={sx} component="section" className="section-details__wrapper">
      {titleHeader && (
        <Grid container sx={{ marginBottom: { xs: '28px', sm: '30px' } }}>
          <Grid
            item
            xs={12}
            sx={
              actions
                ? {
                    display: { xs: 'block', sm: 'flex' },
                    justifyContent,
                  }
                : {}
            }
          >
            <Typography variant={titleVariant} className="c-title">
              {titleHeader}
              <ScrollToSectionAnchor
                titleId={titleId || (typeof titleHeader === 'string' ? titleHeader : '')}
                isLinkDiplayed={hasAnchorLink}
              />
            </Typography>
            {actions ? (
              <Box
                className="section-details__actions"
                sx={{
                  display: 'flex',
                  justifyContent: { xs: 'flex-start', sm: 'flex-end' },
                  alignItems: 'center',
                  mt: { xs: '20px', sm: '-4px' },
                  mb: { xs: 0, sm: '-4px' },
                  ml: { xs: 0, sm: 3 },
                  'button,a:not(:last-child)': {
                    mr: '10px',
                  },
                }}
              >
                {actions}
              </Box>
            ) : null}
          </Grid>
        </Grid>
      )}
      <div className="view-single-entry">{children}</div>
    </StyledBox>
  ) : null;
};

export default ShowSection;
