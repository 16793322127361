import React from 'react';
import styles from './SocialIcons.module.scss';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import ChainIcon from 'uibuilder/AppIcon/Icons/ChainIcon';
import StackoverflowIcon from 'uibuilder/AppIcon/Icons/StackoverflowIcon';
import GithubIcon from 'uibuilder/AppIcon/Icons/GithubIcon';
import WhatsappIcon from 'uibuilder/AppIcon/Icons/WhatsappIcon';
import LinkedinIcon from 'uibuilder/AppIcon/Icons/LinkedinIcon';
import TelegramIcon from 'uibuilder/AppIcon/Icons/TelegramIcon';
import SkypeIcon from 'uibuilder/AppIcon/Icons/SkypeIcon';
import ViberIcon from 'uibuilder/AppIcon/Icons/ViberIcon';
import DevByIcon from 'uibuilder/AppIcon/Icons/DevByIcon';
import RabotaByIcon from 'uibuilder/AppIcon/Icons/RabotaByIcon';
import { convertTelegramLink } from 'erp/candidate/candidateService';
import { ADDITIONAL_CONTACTS_OPTIONS } from 'erp/shared/input/AdditionalContactListInput/useAdditionalContactsHelper';

interface ISocialIcon {
  type: string;
  value: string;
}

const socialIconsSvg: Record<string, JSX.Element> = {
  telegram: <TelegramIcon sx={{ width: '21px', height: '21px', marginLeft: '-2px' }} />,
  linkedin: <LinkedinIcon sx={{ width: '16px', height: '17px' }} />,
  whatsapp: <WhatsappIcon sx={{ width: '21px', height: '21px' }} />,
  github: <GithubIcon sx={{ width: '18px', height: '21px' }} />,
  stackoverflow: <StackoverflowIcon sx={{ width: '21px', height: '21px' }} />,
  skype: <SkypeIcon sx={{ width: '21px', height: '21px' }} />,
  viber: <ViberIcon sx={{ width: '22px', height: '22px' }} />,
  'dev.by': <DevByIcon sx={{ width: '25px', height: '25px' }} />,
  'jobs.tut.by': <RabotaByIcon sx={{ width: '25px', height: '25px' }} />,
  defaultSvg: <ChainIcon sx={{ width: '21px', height: '21px' }} />,
};

const SocialIcons = () => {
  const { getValue } = useFieldHelper({ source: 'additionalContacts' });

  const contacts: ISocialIcon[] = (getValue() || []) as any[];

  return (
    <>
      {contacts.map((link: ISocialIcon) => {
        const linkType = link.type.toLowerCase();
        let linkValue = link.value;
        const svg =
          typeof socialIconsSvg[linkType] === 'undefined' ? socialIconsSvg.defaultSvg : socialIconsSvg[linkType];
        if (linkType === ADDITIONAL_CONTACTS_OPTIONS.Telegram.toLowerCase()) {
          linkValue = convertTelegramLink(link.value);
        }

        return (
          <a
            key={linkValue}
            href={linkValue}
            target="_blank"
            rel="noreferrer"
            title={linkType}
            className={`${styles.social_link_badge} ${linkType}_link`}
          >
            {svg}
          </a>
        );
      })}
    </>
  );
};

export default SocialIcons;
