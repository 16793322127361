import React, { useState } from 'react';
import { IconButton } from 'uibuilder/button';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import Loading, { LoaderSize } from 'shared/uibuilder/Loading';
import FeedbackForm from 'oneStop/feedbackModal/FeedbackForm';
import { CONTACT_SUPPORT, SUPPORT_REQUEST_ID } from 'oneStop/OneStopRouterHelper';
import ContactSupportIcon from 'uibuilder/AppIcon/Icons/ContactSupportIcon';
import Tooltip from 'uibuilder/Tooltip';

import './SupportRequestModalDecorator.scss';
import { generateLinkByEmail } from 'shared/teamsHelper/useTeamsHelper';

const SupportRequestModalDecorator = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);

  const loader = (
    <div className="spinner" role="status" aria-hidden="true">
      <Loading size={LoaderSize.Small} />
    </div>
  );

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const openSupportForm = () => {
    setIsModalOpen(false);
    setIsFormOpen(true);
  };
  const closeSupportForm = () => setIsFormOpen(false);

  const handleTeamsButton = () => {
    const supportUserEmail = 'davinci-support@syberry.com';
    const teamsLink = generateLinkByEmail(supportUserEmail);

    window.open(teamsLink, '_blank');

    closeModal();
  };

  return (
    <>
      <IconButton className="feedback-button" onClick={openModal}>
        <Tooltip title="Contact support">
          <div>
            <ContactSupportIcon />
          </div>
        </Tooltip>
      </IconButton>
      <ModalWindow
        modalSize={false}
        className="contact-modal"
        isOpen={isModalOpen}
        onToggle={closeModal}
        hasCancelButton={false}
        title="Contact Support"
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px' }}>
          <div style={{ textAlign: 'left', marginBottom: '20px', maxWidth: '600px', margin: '0 auto' }}>
            <p style={{ marginBottom: '15px', fontWeight: 'bold' }}>
              Are you facing a problem that is blocking your work?
            </p>
            <ul style={{ listStyleType: 'none', padding: 0 }}>
              <li style={{ marginBottom: '10px' }}>
                <strong>
                  • Use{' '}
                  <span role="presentation" className="link" onClick={openSupportForm}>
                    OneStop request
                  </span>{' '}
                  (default method): {isLoaderVisible && loader}
                </strong>
                <br />
                <span style={{ marginLeft: '10px' }}>To submit a default help request for assistance.</span>
              </li>
              <li style={{ marginBottom: '10px' }}>
                <strong>
                  • Use{' '}
                  <span role="presentation" className="link" onClick={handleTeamsButton}>
                    the support chat
                  </span>
                  :
                </strong>
                <br />
                <span style={{ marginLeft: '10px' }}>
                  For urgent and important time-sensitive request that require resolution within minutes.
                </span>
              </li>
            </ul>
          </div>
        </div>
      </ModalWindow>
      {isFormOpen && (
        <FeedbackForm
          formId={SUPPORT_REQUEST_ID}
          formTitle={CONTACT_SUPPORT}
          setIsLoaderVisible={setIsLoaderVisible}
          closeFeedbackForm={closeSupportForm}
        />
      )}
    </>
  );
};

export default SupportRequestModalDecorator;
