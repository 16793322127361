import React from 'react';
import styles from './CandidateNativeLang.module.scss';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { LANGUAGES } from 'shared/uibuilder/form/input/NativeLanguageDropdown';

const CandidateNativeLang = () => {
  const { getValue: getLang } = useFieldHelper({ source: 'nativeLanguage' });
  const userLang = getLang();
  if (typeof userLang === 'undefined') {
    return '';
  }
  const currCountry = LANGUAGES && LANGUAGES.find(el => el.name.toLowerCase() === userLang.toLowerCase());
  if (currCountry && currCountry.short) {
    return (
      <div className={styles.CandidateNativeLang} key={userLang}>
        {currCountry.short}
      </div>
    );
  } else {
    return '';
  }
};

export default CandidateNativeLang;
