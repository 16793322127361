/* istanbul ignore file */
import React from 'react';
import Paper from 'uibuilder/Paper';
import { ReactComponent as Logo } from 'images/logo.svg';
import { ReactComponent as LogoWhite } from 'images/logo-white.svg';
import Box from 'uibuilder/Box';

const ExtensionPageLayout = ({
  children,
  isWhiteLogo = false,
}: {
  children: React.ReactNode;
  isWhiteLogo?: boolean;
}) => {
  return (
    <Paper className="extension-layout" sx={{ ...(isWhiteLogo && { p: '0 !important' }) }}>
      {isWhiteLogo ? (
        <Box
          sx={(theme: any) => ({
            display: 'flex',
            alignItems: 'center',
            p: 2,
            background: theme.vars.palette.common.sidebarMenuBg,
          })}
        >
          <LogoWhite width={100} />
        </Box>
      ) : (
        <Logo width={100} className="mb-3 pb-2" />
      )}
      {children}
    </Paper>
  );
};

export default ExtensionPageLayout;
