import React from 'react';
import styles from './CandidateAvatart.module.scss';
import useAsyncValue from 'shared/asyncHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useArtifactService from 'artifact/artifactService';
import { API_FILE_TYPE, CROP_SIZES } from 'artifact/const';
import { ArtifactType } from 'artifact/shared/field/layout/AttachmentFieldLayout/ArtifactPreviewWrapper';
import DefaultUserAvatar from 'uibuilder/AppIcon/Icons/DefaultUserAvatar';

const CandidateAvatart = () => {
  const { data } = useShowContext();
  const attachmentsIds = (data && data.getValueBySource('photosIds')) || [];
  const isOneArtifact = Boolean(attachmentsIds.length !== 1);
  const { getArtifactsDataByIds } = useArtifactService();
  const attachments = useAsyncValue(async () =>
    getArtifactsDataByIds(isOneArtifact ? [attachmentsIds] : attachmentsIds),
  );

  const images = attachments && attachments.filter((file: ArtifactType) => file.type === API_FILE_TYPE.image.alias);
  if (!!images && images.length > 0) {
    let thumbnail = images[0].thumbnails && images[0].thumbnails[CROP_SIZES.SIZE_100];
    if (typeof thumbnail === 'undefined') {
      thumbnail = images[0].filePreview;
    }
    const { id, title } = images[0];

    return (
      <div className={styles.candidate_avatar__wrapper}>
        <img src={thumbnail} alt={title} title={title} id={id} data-testid="artifact-image" />
      </div>
    );
  } else {
    return (
      <div className={styles.candidate_avatar__wrapper}>
        <DefaultUserAvatar sx={{ width: '100%', height: '100%' }} />
      </div>
    );
  }
};

export default CandidateAvatart;
