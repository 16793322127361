import React, { useEffect, useMemo, useState } from 'react';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import BaseInputErrors from 'uibuilder/layout/form/input/BaseInputErrors';
import { DropDownFilterLayoutType } from 'shared/uibuilder/list/filter/DropDownFilter';
import { Option as OptionType } from 'shared/uibuilder/form/input/dropdownHelper';
import CandidateTimelineFilterActions from './CandidateTimelineFilterActions';
import SelectLayout from 'shared/uibuilder/list/filter/layout/ListFilterLayout/filter/DropDownFilterInputLayout/SelectLayout';
import IconButton from 'uibuilder/button/IconButton';
import { FilterAltOutlined } from '@mui/icons-material';
import styles from './CandidateTimelineRecordTypeDropdown.module.scss';
import Box from 'uibuilder/Box';
import { Menu } from '@mui/material';

const CandidateDropDownFilterButtonLayout: DropDownFilterLayoutType = props => {
  const {
    options: initialOptions = [],
    errorMessages,
    multiple = true,
    value: initialValue = '',
    source,
    onChangeCallback,
  } = props;
  const { apply: applyFilters } = useFilterContext();
  const [shouldApplyFilter, setShouldApplyFilter] = useState(false);
  const [selectedValues, setSelectedValues] = useState<OptionType[]>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const options = useMemo(
    () => initialOptions.map(({ value, ...optionProps }) => ({ ...optionProps, value: value ? String(value) : value })),
    [initialOptions],
  );
  const isContainValue = (value: string, allValues: string | string[]) =>
    multiple ? allValues?.includes(value) : String(allValues) === value;

  const { resetFilter } = useFilterContext();
  const [isMenuOpened, setIsMenuOpen] = useState(false);

  const toggleMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (isMenuOpened) {
      resetFilter(source);
    }
    setIsMenuOpen(prevValue => !prevValue);
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (!multiple && isMenuOpened && shouldApplyFilter) {
      closeMenu();
      applyFilters();
      setShouldApplyFilter(false);
    }

    setSelectedValues(options.filter(({ value }) => isContainValue(value as string, initialValue)));
    // Suppressed warnings because we only need to call useEffect callback if updated dropdown value
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue, options, setShouldApplyFilter]);

  return (
    <Box className={styles.container}>
      <IconButton
        onClick={toggleMenu}
        color={selectedValues?.some(Boolean) ? 'primary' : 'default'}
        aria-haspopup="true"
        aria-controls={isMenuOpened ? 'candidate-filter-menu' : undefined}
        aria-expanded={isMenuOpened ? 'true' : undefined}
      >
        <FilterAltOutlined />
      </IconButton>

      {isMenuOpened && (
        <Menu
          id="candidate-filter-menu"
          open={isMenuOpened}
          onClose={closeMenu}
          className="filter-input"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Box className={styles.dropdown_container}>
            <SelectLayout
              {...props}
              options={options}
              selectedValues={selectedValues}
              onChangeCallback={(value: any) => {
                onChangeCallback(value);
                if (!multiple) {
                  setShouldApplyFilter(true);
                }
              }}
            />

            <BaseInputErrors errorMessages={errorMessages} />
            {multiple && <CandidateTimelineFilterActions onChangeCallback={toggleMenu} />}
          </Box>
        </Menu>
      )}
    </Box>
  );
};

export default CandidateDropDownFilterButtonLayout;
