import {
  Assignment,
  ChatOutlined,
  DescriptionOutlined,
  EditNote,
  SettingsOutlined,
  SvgIconComponent,
  UpcomingOutlined,
} from '@mui/icons-material';

export const NOTE = 'Note';
export const SYSTEM_NOTE = 'SystemNote';
export const APPLICATION = 'Application';
export const OFFER = 'Offer';
export const CANDIDATE_RESUME = 'Resume';
export const COMMUNICATION = 'Communication';

export const CANDIDATE_TIMELINE_RECORD_TYPES = {
  [NOTE]: NOTE,
  [SYSTEM_NOTE]: SYSTEM_NOTE,
  [APPLICATION]: APPLICATION,
  [OFFER]: OFFER,
  [CANDIDATE_RESUME]: CANDIDATE_RESUME,
  [COMMUNICATION]: COMMUNICATION,
} as const;

export type CandidateTimelineType =
  (typeof CANDIDATE_TIMELINE_RECORD_TYPES)[keyof typeof CANDIDATE_TIMELINE_RECORD_TYPES];

export const CANDIDATE_TIMELINE_ICONS: Record<CandidateTimelineType, SvgIconComponent> = {
  [NOTE]: EditNote,
  [APPLICATION]: UpcomingOutlined,
  [CANDIDATE_RESUME]: DescriptionOutlined,
  [SYSTEM_NOTE]: SettingsOutlined,
  [COMMUNICATION]: ChatOutlined,
  [OFFER]: Assignment,
} as const;

export const OFFER_OFFICES = {
  MINSK: 'MINSK',
  KRAKOW: 'KRAKOW',
};

export const CANDIDATE_TIMELINE_OFFER_STATUSES = {
  DRAFT: 'DRAFT',
  FINALIZED: 'FINALIZED',
  SENT: 'SENT',
  ACCEPTED: 'ACCEPTED',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
};

export const CONTRACT_TYPES = {
  EC: 'EC',
  B2B: 'B2B',
  CFS: 'CFS',
};

export const OFFER_TEMPLATE_FILE_NAMES = {
  OFFER_POLAND_B2B: 'offer-poland-b2b.md',
  OFFER_POLAND_EC: 'offer-poland-ec.md',
  OFFER_BELARUS: 'offer-belarus.md',
};

export const COMPANY_UPDATE_SERVICE_NOTES = 'COMPANY_UPDATE_SERVICE_NOTES';
