export default {
  specialization: {
    type: 'string',
    required: true,
    maxSize: 255,
  },
  competencyLevel: {
    type: 'string',
    required: {
      value: true,
    },
  },
  peopleToHireMin: {
    type: 'number',
    required: true,
    minValue: 1,
    maxValue: [
      {
        value: '$peopleToHireMax',
        message: "Min can't be greater than max.",
      },
    ],
  },
  peopleToHireMax: {
    type: 'number',
    required: true,
    minValue: [
      {
        value: '$peopleToHireMin',
        message: 'Max cannot be less than min.',
      },
    ],
  },

  targetHiringDate: {
    type: 'date',
    required: {
      value: true,
      when: {
        $isAsap: {
          eq: false,
        },
      },
    },
    minDate: {
      value: '$TODAY',
      message: 'Must be in future.',
    },
  },

  expirationPeriodInMonth: {
    type: 'enum',
    required: true,
  },

  hiringMode: {
    type: 'enum',
    required: true,
  },

  workingConditions: {
    type: 'array',
    required: true,
    minSize: 1,
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        hiringLocation: {
          type: 'enum',
          required: true,
        },
        employmentTypes: {
          type: 'array',
          required: true,
        },
        contractTypes: {
          type: 'array',
          required: true,
        },
        isRelocationAvailable: {
          type: 'enum',
          required: true,
        },
      },
    },
  },

  projects: {
    type: 'array',
    required: false,
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        name: {
          type: 'string',
          required: false,
        },
        description: {
          type: 'string',
          required: false,
        },
      },
    },
  },

  responsibilities: {
    type: 'string',
    required: true,
  },

  mandatoryRequirements: {
    type: 'object',
    required: true,
    _divisible: false,
    properties: {
      description: {
        type: 'string',
        required: true,
      },
      skills: {
        type: 'array',
        required: false,
      },
      requiredExperienceInYears: {
        type: 'number',
        required: true,
      },
      englishLevel: {
        type: 'enum',
        required: true,
      },
    },
  },

  optionalRequirements: {
    type: 'object',
    required: false,
    _divisible: false,
    properties: {
      description: {
        type: 'string',
        required: false,
      },
      skills: {
        type: 'array',
        required: false,
      },
    },
  },

  notes: {
    type: 'object',
    required: false,
    _divisible: false,
    properties: {
      description: {
        type: 'string',
        required: false,
      },
      attachmentIds: {
        type: 'array',
        maxSize: 10,
      },
    },
  },
};
