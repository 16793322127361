import React from 'react';
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import DeleteTimelineRecordButton from 'uibuilder/layout/timeline/buttons/DeleteTimelineRecordButton';
import useCrmNoteService, {
  DELETE_CRM_NOTE_PERMISSION,
  UPDATE_CRM_NOTE_PERMISSION,
} from 'crm/shared/timeline/note/crmNoteService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import PinTimelineButton from 'crm/shared/buttons/PinTimelineButton';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { TimelineContextData } from 'shared/uibuilder/timeline/Timeline';
import { useParams } from 'react-router';

const NoteTimelineRecordLayout = ({ onEdit, isPinned, ...props }: any) => {
  const opportunityId = useParams<Dictionary<string>>().id!;
  const { deleteById } = useCrmNoteService();
  const { data } = useShowContext();
  const id = data.getValueBySource('id');
  const timelineId = data.getValueBySource('timelineId');
  const { updateInList, pinTimelineAction }: TimelineContextData = useListContext();

  const buttons = [
    <EditTimelineRecordButton permissionToCheck={UPDATE_CRM_NOTE_PERMISSION} key="edit" onEdit={onEdit} />,
    <DeleteTimelineRecordButton
      permissionToCheck={DELETE_CRM_NOTE_PERMISSION}
      deleteMethod={deleteById}
      key="add"
      id={id}
      timelineId={timelineId}
      entityName="note"
    />,
  ];

  return (
    <BaseTimelineRecordLayout
      actions={[
        <PinTimelineButton
          pinStatus={isPinned}
          pinMethod={async () => {
            const response = await pinTimelineAction!(opportunityId, timelineId, !isPinned);
            updateInList!(response?.id, response);
          }}
        />,
      ]}
      timelineType={TimelineType.NOTE}
      buttons={buttons}
      {...props}
    />
  );
};

export default NoteTimelineRecordLayout;
