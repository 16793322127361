import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const InlineEnglishIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13 2.5H3C2.44772 2.5 2 2.94772 2 3.5V13.5C2 14.0523 2.44772 14.5 3 14.5H13C13.5523 14.5 14 14.0523 14 13.5V3.5C14 2.94772 13.5523 2.5 13 2.5Z"
          stroke="#666666"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.9987 6.1665H4.33203V10.8332H6.83203M4.33203 8.49984H6.83203M8.66536 6.83317V10.8332V8.6665C8.66536 8.26868 8.8234 7.88715 9.1047 7.60584C9.38601 7.32454 9.76754 7.1665 10.1654 7.1665C10.5632 7.1665 10.9447 7.32454 11.226 7.60584C11.5073 7.88715 11.6654 8.26868 11.6654 8.6665V10.8332"
          stroke="#666666"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default InlineEnglishIcon;
