/* istanbul ignore file */
import React from 'react';
import RunMigrationButton from 'financialAnalytic/shared/button/RunMigrationButton';
import useLedgerService, { PERFORM_MIGRATION } from '../../useLedgerService';

const LOCAL_STORAGE_KEY = 'ledger-export-data';

const RunLedgerExportButton = () => {
  const { exportLedger } = useLedgerService();

  return (
    <RunMigrationButton
      displayMessage="Please, Fill out inputs below"
      localStorageKey={LOCAL_STORAGE_KEY}
      submitMethod={exportLedger}
      permissionToCheck={PERFORM_MIGRATION}
    >
      Run Export
    </RunMigrationButton>
  );
};

export default RunLedgerExportButton;
