/* istanbul ignore file */
import React from 'react';
import { ActivitiesMenuItem, SummaryMenuItem } from 'uibuilder/menuitem';
import { SidebarStickyMenu } from 'shared/uibuilder/menu';
import { useOpportunityId, useOpportunityUrl } from 'crm/opportunity/OpportunityRouter';

const OpportunityMenu = () => {
  const id = useOpportunityId();
  const { getTimelineUrl, getSingleEntityUrl } = useOpportunityUrl();

  return (
    <SidebarStickyMenu>
      <SummaryMenuItem linkUrl={getSingleEntityUrl(id)} exact />
      <ActivitiesMenuItem linkUrl={getTimelineUrl(id)} />
    </SidebarStickyMenu>
  );
};

export default OpportunityMenu;
