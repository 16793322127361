export const RECRUITING_CANDIDATE_READ = 'READ';
export const RECRUITING_CANDIDATE_CONFIRM_SPECIALIZATION = 'RECRUITING_CANDIDATE_CONFIRM_SPECIALIZATION';
export const RECRUITING_CANDIDATE_UPDATE = 'UPDATE';
export const GLOBAL_RECRUITING_CANDIDATE_CREATE = 'RECRUITING_CANDIDATE_CREATE';
export const GLOBAL_RECRUITING_CANDIDATE_LIST_READ = 'RECRUITING_CANDIDATE_LIST_READ';

export const CANDIDATE_SPECIALIZATION_QUALIFICATION_LEVEL = {
  N_A: 'N_A',
  L1: 'L1',
  L2: 'L2',
  L3: 'L3',
  L4: 'L4',
  L5: 'L5',
  L6: 'L6',
};

export const CANDIDATE_SPECIALIZATION_QUALIFICATION_LEVEL_OPTIONS = {
  [CANDIDATE_SPECIALIZATION_QUALIFICATION_LEVEL.N_A]: 'N/A',
  [CANDIDATE_SPECIALIZATION_QUALIFICATION_LEVEL.L1]: 'L1',
  [CANDIDATE_SPECIALIZATION_QUALIFICATION_LEVEL.L2]: 'L2',
  [CANDIDATE_SPECIALIZATION_QUALIFICATION_LEVEL.L3]: 'L3',
  [CANDIDATE_SPECIALIZATION_QUALIFICATION_LEVEL.L4]: 'L4',
  [CANDIDATE_SPECIALIZATION_QUALIFICATION_LEVEL.L5]: 'L5',
  [CANDIDATE_SPECIALIZATION_QUALIFICATION_LEVEL.L6]: 'L6',
};
