import { Stack } from '@mui/material';
import CandidateLink from 'erp/candidate/shared/CandidateLink';
import React, { useCallback, useState } from 'react';
import { getShortName } from './utils';
import IconMenu from '../IconMenu';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Typography from 'uibuilder/Typography';
import { stepConfigurations } from '../../constants';
import useRecruitingBoardService from '../../useRecruitingBoardService';
import useArtifactThumbnailService from 'artifact/artifactThumbnailService';
import { CROP_SIZES } from 'artifact/const';
import { Candidate } from '../../types';
import CandidateMenu from './CandidateMenu';

interface CandidateCardProps {
  item: any;
  formattedTime: string;
  deadlineStyle: any;
  completeJobStep?: any;
  preview?: boolean;
  isClosedOrRejected?: boolean;
}

const CandidateCard: React.FC<CandidateCardProps> = ({
  item,
  formattedTime,
  deadlineStyle,
  completeJobStep,
  preview = false,
  isClosedOrRejected = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { getCandidateDetailsById } = useRecruitingBoardService();
  const { getThumbnails } = useArtifactThumbnailService();
  const [candidateDetails, setCandidateDetails] = useState<Candidate | null>(null);
  const [candidatePhoto, setCandidatePhoto] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const open = Boolean(anchorEl);

  const getCandidateDetails = useCallback(async () => {
    setIsLoading(true);

    try {
      const candidateDetailsResponse = await getCandidateDetailsById(item.candidateId);
      setCandidateDetails(candidateDetailsResponse);

      if (candidateDetailsResponse?.mainPhotoId) {
        const thumbnailsResponse = await getThumbnails(candidateDetailsResponse?.mainPhotoId);
        setCandidatePhoto(thumbnailsResponse[CROP_SIZES.SIZE_100]);
      }
    } finally {
      setIsLoading(false);
    }
  }, [getCandidateDetailsById, getThumbnails, item.candidateId]);

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      getCandidateDetails();
    },
    [getCandidateDetails],
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      sx={{ padding: '2px 5px 2px 4px', fontSize: '10px' }}
      direction="column"
      alignItems="start"
      justifyContent="space-between"
      flex={1}
    >
      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
        <CandidateLink
          color="#4360E5"
          style={{
            display: 'inline-block',
            maxWidth: '110px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
          id={item?.candidateId}
          name={getShortName(item?.name)}
          onClick={handleClick}
        />
        {!preview && (
          <CandidateMenu
            candidateDetails={candidateDetails}
            candidatePhoto={candidatePhoto}
            anchorEl={anchorEl}
            open={open}
            handleClose={handleClose}
            isLoading={isLoading}
            currentApplicationId={item.id}
            currentStage={item.stage}
          />
        )}

        <Stack direction="row" gap="2px">
          {stepConfigurations[item.stage]?.map(config => (
            <IconMenu
              key={config.job}
              icon={config?.icon}
              items={config?.states}
              tooltipData={item.tooltipData}
              defaultKey={
                config?.states?.find(state => item.activeStates.includes(state.key))?.key || config.states[0].key
              }
              onChange={(key, payload) => {
                const event = config.states.find(state => state.key === key)?.event;
                completeJobStep(item.id, item.version, config.job, event, payload);
              }}
            />
          ))}
        </Stack>
      </Stack>
      {!isClosedOrRejected && (
        <Stack direction="row" gap="2px" alignItems="center">
          <AccessTimeIcon sx={{ color: deadlineStyle.borderColor, width: '10px', height: '10px' }} />
          <Typography sx={{ color: deadlineStyle.borderColor, fontSize: '9px', lineHeight: 1.6 }}>
            {formattedTime}
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

export default CandidateCard;
