import React from 'react';
import SortButton from './SortButton';
import getLabel from 'shared/uibuilder/helper';
import MuiTableHead from '@mui/material/TableHead';
import MuiTableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

export interface TableHeadProps {
  children: React.ReactElement | React.ReactElement[] | React.ReactNode;
  buttons?: React.ReactElement[];
  isShowHeader?: boolean;
}

const TableHead = ({ children, buttons = [], isShowHeader }: TableHeadProps) => {
  return (
    <MuiTableHead sx={{ display: isShowHeader ? 'table-header-group' : 'none' }}>
      <MuiTableRow>
        {React.Children.map(children, (child: any, index) => {
          if (!child) {
            return null;
          }

          const { source, sortSource, label, isSortable, isBold, width } = child.props;
          const key = label || source || index;
          const text = getLabel(label, source);

          const content: any = isSortable ? <SortButton source={sortSource || source} label={text} /> : text;

          return (
            <TableCell
              className={isSortable || isBold ? '' : 'th-normal'}
              key={key}
              style={
                width && {
                  width,
                }
              }
            >
              {content}
            </TableCell>
          );
        })}
        {React.Children.map(buttons, () => (
          <TableCell className="th-buttons" />
        ))}
      </MuiTableRow>
    </MuiTableHead>
  );
};

export default TableHead;
