/* istanbul ignore file */

import CreateContractRequestForm from 'crm/opportunity/presale/contract/form/CreateContractRequestForm';
import UpdateContractRequestForm from 'crm/opportunity/presale/contract/form/UpdateContractRequestForm';
import ContractOutputTimelineRecordLayout from 'crm/opportunity/presale/contract/ContractOutputTimelineRecordLayout';
import { CREATE_CONTRACT_REQUEST, PRESALE_ITEMS_TYPE } from 'crm/opportunity/shared/opportunityService';
import Data from 'shared/uibuilder/Data';
import { TimelineItem } from 'shared/uibuilder/timeline/timelineHelper';

const useContractRequestItem = () => ({
  AddForm: CreateContractRequestForm,
  UpdateForm: UpdateContractRequestForm,
  isApplicable: (item: any) => item?.contractRequest,
  source: 'contractRequest',
  urlPostfix: PRESALE_ITEMS_TYPE.CONTRACT_REQUEST,
  RecordLayout: ContractOutputTimelineRecordLayout,
  name: 'Contract Request',
  permissionToCheck: CREATE_CONTRACT_REQUEST,
  additionalAfterAddSubmit: (item: TimelineItem, parentContext?: Data) => {
    const oppState = parentContext?.getValueBySource('stateStep');
    if (oppState === 'PROPOSAL') {
      window.location.reload(); // reload page for fetch actual state
    }
  },
});

export default useContractRequestItem;
