/* istanbul ignore file */
import React from 'react';
import { useTokenProvider } from 'authentication/DefaultTokenProvider';
import { copyToClipboard } from 'shared/clipboard';
import { IconButton } from 'uibuilder/button';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import useAuthorization from 'shared/authorization/authorizationService';
import Tooltip from 'uibuilder/Tooltip';

const CopyTokenButton = () => {
  const { getToken } = useTokenProvider();
  const { isGranted } = useAuthorization();
  const hasPermissions = isGranted('EMPLOYEE_COPY_JWT_TOKEN');

  const onClick = async () => {
    const token = await getToken();

    if (token) {
      copyToClipboard(token);
    }
  };

  if (!hasPermissions) {
    return null;
  }

  return (
    <>
      <IconButton onClick={onClick}>
        <Tooltip title="Copy JWT">
          <CopyIcon />
        </Tooltip>
      </IconButton>
    </>
  );
};

export default CopyTokenButton;
