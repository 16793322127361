/* istanbul ignore file */
import React from 'react';
import { READ_CANDIDATE_NOTES_PERMISSION } from 'erp/candidate/Timeline/note/note/candidateNoteService';
import { HtmlField } from 'shared/uibuilder/field';
import CandidateNoteTag from 'erp/candidate/Timeline/note/shared/NoteTimelineRecordLayout/CandidateNoteTag';
import BaseCandidateTimelineRecordLayout from 'erp/candidate/Timeline/CandidateTimelineRecord/BaseCandidateTimelineRecordLayout';
import TimelineCollapsible from 'shared/uibuilder/timeline/collapsible/TimelineCollapsible';

type NoteTimelineRecordLayoutProps = {
  actionName: string;
};

const SystemNoteTimelineRecordLayout = ({ actionName, ...props }: NoteTimelineRecordLayoutProps) => {
  return (
    <BaseCandidateTimelineRecordLayout
      permissionToCheck={READ_CANDIDATE_NOTES_PERMISSION}
      actionName={actionName}
      buttons={[]}
      timelineType="SystemNote"
      fields={
        <TimelineCollapsible>
          <HtmlField source="noteText" label="" />
        </TimelineCollapsible>
      }
      timelineFooter={rest => (
        <div className="d-flex justify-content-between align-items-center feedback-timeline-footer">
          <CandidateNoteTag {...rest} />
        </div>
      )}
      {...props}
    />
  );
};

export default SystemNoteTimelineRecordLayout;
