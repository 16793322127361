import React from 'react';
import TemplatePreview from 'shared/markdownEditor/TemplatePreview';

interface OfferTemplatePreviewProps {
  html: string;
}

const OfferTemplatePreview: React.FC<OfferTemplatePreviewProps> = ({ html }) => {
  return <TemplatePreview html={html} title="Preview" />;
};

export default OfferTemplatePreview;
