import { FilterCondition } from 'shared/uibuilder/list/filter/FilterContext';

const DEFAULT_TIMEZONE = 'Europe/Minsk';
const DAY_START = 'start';
const DAY_END = 'end';
const DATE_FORMAT = {
  INPUT_VALUE: 'YYYY-MM-DD',
  INPUT_MONTH_VALUE: 'YYYY-MM',
  FULL: 'MMM D, YYYY, HH:mm',
  FULL_DATE: 'MMM D, YYYY',
  FULL_WITH_TIMEZONE: 'MMM D, YYYY, HH:mm Z',
  FULL_WITH_SECONDS: 'MMM D, YYYY, HH:mm:ss',
  FULL_WITH_SECONDS_AND_TIMEZONE: 'MMM D, YYYY, HH:mm:ss Z',
  SIMPLE: 'DD.MM.YYYY',
  SIMPLE_MONTH: 'MM.YYYY',
  API: 'YYYY-MM-DD HH:mm:ss',
  API_ONLY_DATE: 'YYYY-MM-DD',
  API_ONLY_MONTH: 'YYYY-MM',
  NATURAL: 'MMMM DD, YYYY',
  REDMINE_DATE: 'MM/DD/YYYY',
  REDMINE_FORM_DATE_TIME: 'YYYY-MM-DDTHH:mm',
  REDMINE_DATE_TIME: 'MM/DD/YYYY HH:mm',
  MONTH: 'MMM YYYY',
  DAY_TIME: 'MMM D, HH:mm:ss',
  MONTH_DAY_WEEKDAY: 'MMM D (ddd)',
  FULL_WITH_WEEKDAY: 'MMM D, YYYY (ddd)',
  HOURS_MINUTES: 'HH:mm',
};

const FILTER_DATE_ERROR = 'Please choose valid date in past';

const GREATER_OR_EQUAL = 'ge' as FilterCondition;
const LESS_OR_EQUAL = 'le' as FilterCondition;

const DEFAULT_FORMAT = DATE_FORMAT.INPUT_VALUE;
const DEFAULT_DATE_TIME_FORMAT = DATE_FORMAT.API;
const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

export {
  DEFAULT_TIMEZONE,
  DAY_START,
  DAY_END,
  DATE_FORMAT,
  FILTER_DATE_ERROR,
  GREATER_OR_EQUAL,
  LESS_OR_EQUAL,
  DEFAULT_FORMAT,
  DEFAULT_DATE_TIME_FORMAT,
  DAY,
};
