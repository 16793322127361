import React, { useState } from 'react';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { Button } from 'uibuilder/button';
import Box from 'uibuilder/Box';
import { useDateService } from 'shared/uibuilder/dateService';
import HealthcheckStatus from './HealthcheckStatus';
import HealthcheckButton from './HealthcheckButton';
import CalendarRightDateInput from 'erp/employee/Timeline/feedback/shared/input/CalendarRightDateInput';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';

const Healthcheck = () => {
  const { data } = useShowContext();
  const initialDate = data.lastHealthCheck;
  const { getToday } = useDateService();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [healthcheckDate, setHealthcheckDate] = useState(initialDate);
  const [tempHealthcheckDate, setTempHealthcheckDate] = useState(null);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
    if (!isModalOpen) {
      setTempHealthcheckDate(healthcheckDate);
    }
  };

  const handleDateChange = (date: any) => {
    setTempHealthcheckDate(date.healthcheckDate);
  };

  const handleSubmit = () => {
    if (tempHealthcheckDate) {
      setHealthcheckDate(tempHealthcheckDate);
      toggleModal();
    }
  };

  return (
    <>
      <Box>
        <HealthcheckStatus iconSx={{ fontSize: 11 }} textSx={{ fontSize: 12 }} />
        <HealthcheckButton
          lastHealthcheckDate={healthcheckDate}
          onClick={toggleModal}
          style={{ fontSize: 11, marginTop: 1 }}
        />
      </Box>
      <ModalWindow
        buttons={
          <Button color="primary" variant="contained" onClick={handleSubmit}>
            Submit
          </Button>
        }
        hasCancelButton
        isOpen={isModalOpen}
        onToggle={toggleModal}
      >
        <CalendarRightDateInput
          source="healthcheckDate"
          placeholder="Healthcheck date"
          value={tempHealthcheckDate}
          onChangeCallback={handleDateChange}
          maxDate={getToday()}
        />
      </ModalWindow>
    </>
  );
};

export default Healthcheck;
