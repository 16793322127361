import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const MobileIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.70768 1.29199H3.29102C1.9103 1.29199 0.791016 2.41128 0.791016 3.79199V14.2087C0.791016 15.5894 1.9103 16.7087 3.29102 16.7087H8.70768C10.0884 16.7087 11.2077 15.5894 11.2077 14.2087V3.79199C11.2077 2.41128 10.0884 1.29199 8.70768 1.29199Z"
          stroke="#5954A8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.16602 13.792H6.83268"
          stroke="#5954A8"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
};

export default MobileIcon;
