import CountryBadge from 'shared/uibuilder/form/input/CountryBadge';
import React from 'react';
import Box from 'uibuilder/Box';
import Tooltip from 'uibuilder/Tooltip';

type OfficeAndAccommodationIconsProps = {
  registrations: string[];
  accommodations: string[];
};

const OfficeAndAccommodationIcons = ({ accommodations, registrations }: OfficeAndAccommodationIconsProps) => {
  return (
    <Box display="flex" gap={3}>
      <Tooltip title="Registration">
        <span>
          <Box display="flex" gap={1}>
            {registrations.map((item: string) => (
              <CountryBadge country={item} />
            ))}
          </Box>
        </span>
      </Tooltip>

      <Tooltip title="Accommodation">
        <span>
          <Box display="flex" gap={1}>
            {accommodations.map((item: string) => (
              <React.Fragment key={`accommodation-${item}`}>
                {item === 'ANYWHERE' ? (
                  <i className="fa fa-globe" style={{ fontSize: '20px', color: '#5b75c8' }} aria-hidden="true" />
                ) : (
                  <CountryBadge country={item} />
                )}
              </React.Fragment>
            ))}
          </Box>
        </span>
      </Tooltip>
    </Box>
  );
};

export default OfficeAndAccommodationIcons;
