/* istanbul ignore file */
import React from 'react';
import RunMigrationButton from 'financialAnalytic/shared/button/RunMigrationButton';
import useExpenseService, { PERFORM_MIGRATION } from '../../useExpenseService';

const LOCAL_STORAGE_KEY = 'expenses-export-data';

const RunExpensesMigrationButton = () => {
  const { runExpensesMigration } = useExpenseService();

  return (
    <RunMigrationButton
      displayMessage="Please, Fill out inputs below"
      localStorageKey={LOCAL_STORAGE_KEY}
      submitMethod={runExpensesMigration}
      permissionToCheck={PERFORM_MIGRATION}
    >
      Run Export
    </RunMigrationButton>
  );
};

export default RunExpensesMigrationButton;
