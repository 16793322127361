import React from 'react';
import useDuplicateScanService from '../duplicateScanService';
import { UpdateForm } from 'shared/uibuilder/form';
import UpdatePageLayout from 'shared/layout/form/UpdatePageLayout';
import CandidateDuplicateScanForm from './CandidateDuplicateScanForm';

const CandidateDuplicateScanProcessPage = () => {
  const { getById, process, redirectCallback } = useDuplicateScanService();

  return (
    <UpdateForm
      submitFormFunc={process}
      getDataFunc={getById}
      getValidationSchemaFunc={() => Promise.resolve({})}
      afterSubmit={{
        execute: () => {
          return redirectCallback();
        },
      }}
    >
      <UpdatePageLayout onCancelAction={redirectCallback} title="Duplicate Scan Process">
        <CandidateDuplicateScanForm />
      </UpdatePageLayout>
    </UpdateForm>
  );
};

export default CandidateDuplicateScanProcessPage;
