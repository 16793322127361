import React from 'react';
import { Button } from 'uibuilder/button';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { InputListLayoutProps, InputListLayoutType } from 'shared/uibuilder/form/input/InputList/InputList';
import AddIcon from '@mui/icons-material/AddOutlined';
import RemoveIcon from '@mui/icons-material/RemoveOutlined';
import './AdditionalContactInputListLayout.scss';
import BaseInputLayout, {
  COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  getBaseInputLayoutProps,
} from 'uibuilder/layout/form/input/BaseInputLayout';

/**
 * Purpose of this method:
 * Behavior: Added inputs should be visible, but not included in value
 * until something is entered
 * Problem: Since value doesn't include some inputs from view, this inputs not validated
 * e.g. there are 2 inputs on the view, and only 2nd one filled in with data
 * in this case validation method have no information about view, therefore validation message for second
 * input will be located at index 0.
 * Solution: calculate index in validation messages by ignoring null-values
 * @param value
 * @param index
 * @returns {number}
 */
export const getNotNullIndex = (value: (string | object)[], index: number): number => {
  let notNullIndex = 0;
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < index; i++) {
    if (value[i]) {
      // eslint-disable-next-line no-plusplus
      notNullIndex++;
    }
  }

  return notNullIndex;
};

const AdditionalContactInputListLayout: InputListLayoutType = ({
  fields,
  addMethod,
  removeMethod,
  inputTemplate: InputComponent,
  onChangeCallback,
  addText = '',
  deleteText = '',
  maxFields,
  minFields,
  source,
  className,
  simpleLayout = true,
  inputProps,
  value,
  disabled,
  disabledRemove,
  isAddable = true,
  isDeletable = true,
  ...otherProps
}: InputListLayoutProps) => {
  const props = {
    ...COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
    ...otherProps,
  };
  const removeBtn = (index: number, fieldValue: any) => {
    return (
      <Button
        id={`remove-${index}`}
        size="small"
        color="primary"
        onClick={() => removeMethod(index)}
        disabled={fields.length <= minFields || disabled || (disabledRemove && disabledRemove(fieldValue))}
        className="add-info-btn input-list-rm-btn"
        sx={{ padding: deleteText ? '5px 17px 5px 10px' : '4px' }}
      >
        <RemoveIcon sx={{ marginRight: deleteText ? '7px' : 0 }} />
        {deleteText}
      </Button>
    );
  };
  const renderAddButton = () => (
    <Button
      outline
      size="small"
      startIcon={<AddIcon />}
      id="add"
      disabled={fields.length >= maxFields || disabled}
      type="button"
      onClick={addMethod}
      sx={{
        backgroundColor: '#5954A8',
        color: '#ffffff',
        minWidth: 'initial',
        margin: '8px 0 0 10px',
        padding: '3px',
        height: '26px',
        '&:disabled': { display: 'none' },
        '& span': { margin: 0 },
      }}
    >
      {addText}
    </Button>
  );

  const renderRemoveButton = (index: any, fieldValue: any) =>
    simpleLayout && isDeletable && removeBtn(index, fieldValue);

  // TODO: temporary logs
  if (!InputComponent) {
    // eslint-disable-next-line no-console
    console.error('There are no InputComponent in ', source);
  }

  return (
    <BaseInputLayout {...getBaseInputLayoutProps({ ...props, source, disabled })}>
      <div>
        {fields.map((fieldValue, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div className="removable-input" key={`input-${index}`}>
            {!simpleLayout && isDeletable && removeBtn(index, fieldValue)}
            <InputComponent
              {...inputProps}
              label=""
              isLast={fields.length === index + 1}
              source={fieldValue && `${source}.${getNotNullIndex(fields, index)}`}
              value={fieldValue}
              onChangeCallback={(newValue: FormFieldsData) => {
                onChangeCallback(index, newValue);
              }}
              className={className || ''}
            />
            {isAddable && index === fields.length - 1 ? renderAddButton() : renderRemoveButton(index, fieldValue)}
            {index + 1 === maxFields && renderRemoveButton(index, fieldValue)}
          </div>
        ))}
      </div>
    </BaseInputLayout>
  );
};

export default AdditionalContactInputListLayout;
