/* istanbul ignore file */
// libs
import React, { useEffect } from 'react';
// styles
import styles from './CandidateTimelineRecord.module.scss';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Box from '@mui/material/Box';
import useTimelineLayoutHelper from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import CandidateTimelineRecordHeader from './CandidateTimelineRecordHeader';
import StepContent from '@mui/material/StepContent';
import CandidateTimelineRecordActions from './CandidateTimelineRecordActions';
import HtmlField from 'shared/uibuilder/field/HtmlField';
import AttachmentsListField from 'artifact/shared/field/AttachmentsListField';
import { EVALUATION_CRITERION, EVALUATION_CRITERION_ALIAS } from 'instantFeedback/input/EvaluationCriterionFrom';
import TimelineRecordFooter from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/TimelineRecordFooter';
import TimelineCollapsible from 'shared/uibuilder/timeline/collapsible/TimelineCollapsible';
import { CANDIDATE_TIMELINE_ICONS, CandidateTimelineType, SYSTEM_NOTE } from 'erp/candidate/Timeline/constants';
import { scrollToHash } from 'shared/uibuilder/domUtils';

const SCROLL_OFFSET = 100;

interface FeedbackGrade {
  description?: string;
  attachmentsIds?: string[];
}

interface FeedbackGrades {
  [key: string]: FeedbackGrade;
}

interface BaseCandidateTimelineRecordLayoutProps {
  fields?: React.ReactNode;
  actionName: string;
  actions?: React.ReactNode[];
  buttons?: React.ReactNode[];
  updateComponent?: React.ReactNode;
  isEditing?: boolean;
  isHighlighted?: boolean;
  artifactsSource?: string;
  permissionToCheck: Nullable<string | string[]>;
  basePathname?: string;
  timelineHeader?: React.ElementType;
  timelineFooter?: React.ElementType;
  timelineActions?: React.ElementType;
  CandidateTimelineType?: CandidateTimelineType | ((entity: any) => CandidateTimelineType);

  [p: string]: any;
}

const BaseCandidateTimelineRecordLayout: React.FC<BaseCandidateTimelineRecordLayoutProps> = ({
  fields = null,
  actionName,
  actions = null,
  updateComponent = null,
  isEditing = false,
  isHighlighted = false,
  artifactsSource = 'attachmentsIds',
  buttons = null,
  basePathname = '',
  timelineHeader: TimeLineHeader = CandidateTimelineRecordHeader,
  timelineActions: TimelineActions = CandidateTimelineRecordActions,
  timelineFooter: TimeLineFooter = TimelineRecordFooter,
  timelineType: initialTimelineType = 'note',
}) => {
  const { data: entity } = useShowContext();
  const subject = entity.getValueBySource('subject');
  const description = entity.getValueBySource('description');
  const timelineId = entity.getValueBySource('timelineId');
  const timelineType = typeof initialTimelineType === 'function' ? initialTimelineType(entity) : initialTimelineType;
  const feedbackGrades = (entity.getValueBySource('feedbackGrades') || {}) as FeedbackGrades;

  const attachmentsIds = entity.getValueBySource(artifactsSource);

  const { getButtons } = useTimelineLayoutHelper();

  const IconComponent = CANDIDATE_TIMELINE_ICONS[timelineType as CandidateTimelineType];

  const entryRef = React.createRef();

  useEffect(() => {
    if (isHighlighted) {
      scrollToHash(entryRef.current, SCROLL_OFFSET);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHighlighted]);

  return (
    <Step sx={{ width: '100%' }} key={`entry${timelineId}`} ref={entryRef as React.RefObject<HTMLDivElement>} active>
      <StepLabel
        sx={{ padding: 0 }}
        icon={
          <Box
            className={`${
              timelineType === SYSTEM_NOTE ? styles.system_notes_icon_container : styles.icon_container
            } ${isHighlighted ? styles.highlighted : ''}`}
          >
            <IconComponent />
          </Box>
        }
      >
        <Box
          className={`${styles.content_container} ${isHighlighted ? styles.highlighted : ''}`}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
            backgroundColor: timelineType === SYSTEM_NOTE ? 'lightgray' : 'lavender',
          }}
        >
          <>
            <Box title={timelineType} />
            <TimeLineHeader actionName={actionName} timelineId={timelineId} entity={entity} />
            <TimelineActions
              entity={entity}
              buttons={getButtons(buttons as React.ReactElement[])}
              actions={actions}
              timelineId={timelineId || entity.id}
              basePathname={basePathname}
            />
          </>
        </Box>
      </StepLabel>
      <StepContent className={styles.step_content_container}>
        {isEditing && updateComponent ? (
          updateComponent
        ) : (
          <>
            <div className="timeline-text-container">
              {subject?.trim() || fields ? (
                <div>
                  {subject ? (
                    <h3 className="timeline__entry-heading">
                      <HtmlField value={subject} />
                    </h3>
                  ) : null}
                  {fields}
                </div>
              ) : null}
              {description && (
                <div>
                  <TimelineCollapsible>
                    <HtmlField value={description} />
                  </TimelineCollapsible>
                </div>
              )}
            </div>
            {attachmentsIds && attachmentsIds.length ? <AttachmentsListField value={attachmentsIds} /> : null}
            {feedbackGrades ? (
              <div className="timeline__feedback-grades">
                {Object.entries(feedbackGrades).map(([key, value]) => {
                  const evaluationCriteria = EVALUATION_CRITERION.find(
                    item => item.value === key && item.value !== EVALUATION_CRITERION_ALIAS.NOT_ABOUT_CRITERION,
                  );
                  return value.description ? (
                    <div className="timeline__feedback-grade">
                      <b>{evaluationCriteria?.label}</b>
                      {value.description ? <HtmlField label="" value={value.description} /> : null}
                      {value.attachmentsIds && value.attachmentsIds.length ? (
                        <AttachmentsListField value={value.attachmentsIds} />
                      ) : null}
                    </div>
                  ) : null;
                })}
              </div>
            ) : null}
            <TimeLineFooter entity={entity} noTopIndent={!attachmentsIds || !attachmentsIds.length} />
          </>
        )}
      </StepContent>
    </Step>
  );
};

export default BaseCandidateTimelineRecordLayout;
