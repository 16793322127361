/* istanbul ignore file */
import BaseTimelineItemFormLayout, {
  BaseTimelineItemFormLayoutProps,
} from 'shared/uibuilder/timeline/layout/form/BaseTimelineItemFormLayout';
import React from 'react';

const CreateCandidateTimelineItemLayout = ({ children, onSubmitAction, onCancel }: BaseTimelineItemFormLayoutProps) => (
  <BaseTimelineItemFormLayout isCreateForm onCancel={onCancel} onSubmitAction={onSubmitAction}>
    {children}
  </BaseTimelineItemFormLayout>
);

export default CreateCandidateTimelineItemLayout;
