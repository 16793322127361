import { useBusinessEngineApi, useKernelApi, useRecruitmentApi } from 'api';
import candidateValidation from 'erp/candidate/createupdate/candidateValidation';
import candidateValidationInlineVersion from 'erp/candidate/createupdate/candidateValidationInlineVersion';
import useTimelineService from 'shared/crud/timelineService';
import { DESC } from 'shared/uibuilder/list/baseListHelper';
import useCrudService from 'shared/crud';
import { ResourceData, ResourceId } from 'shared/crud/baseCrudService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { useCandidateUrl } from 'erp/candidate/CandidateRouter';
import { cloneDeep, get, set } from 'lodash';
import { ENGLISH_LEVEL_SHORT } from 'erp/recruitment/newVacancy/constants';
import { Stages } from 'erp/recruitment/recruitingBoard/constants';
import useRecruitingBoardService from 'erp/recruitment/recruitingBoard/useRecruitingBoardService';
import { ADDITIONAL_CONTACTS_OPTIONS } from 'erp/shared/input/AdditionalContactListInput/useAdditionalContactsHelper';

export const READ_CANDIDATES_LIST = 'FE_READ_CANDIDATES_LIST';

export const CREATE_CANDIDATE = 'CREATE_CANDIDATE';

export const READ_TIMELINE = 'READ_TIMELINE';

export const READ_SKILLS = 'READ_SKILLS';

export const START_REC1_PROCESS = 'START_REC1_PROCESS';

export const READ_LIST_CANDIDATE_OFFER = 'READ_LIST_CANDIDATE_OFFER';

export const CREATE_CANDIDATE_OFFER = 'CREATE_CANDIDATE_OFFER';

export const UPDATE_CANDIDATE_OFFER_ATTACHMENTS = 'UPDATE_CANDIDATE_OFFER_ATTACHMENTS';

export const INTERACTION_BASE_FINANCE_DATA = 'INTERACTION_BASE_FINANCE_DATA';

export const INTERACTION_PROBATION_FINANCE_DATA = 'INTERACTION_PROBATION_FINANCE_DATA';

export const INTERACTION_CALCULATED_FINANCE_DATA = 'INTERACTION_CALCULATED_FINANCE_DATA';

export const READ_COMMUNICATION = `READ_COMMUNICATION`;

export const SEND_EMAIL = 'SEND_EMAIL';

export const CANDIDATE_ICON = 'fa-address-book';

export const CREATE_CANDIDATE_APPLICATION = 'CREATE_CANDIDATE_APPLICATION';

export const READ_LIST_CANDIDATE_APPLICATIONS = 'READ_LIST_CANDIDATE_APPLICATIONS';

export const CANDIDATES_ROUTE = '/candidates';

export enum CandidateStatus {
  DEFAULT = 'DEFAULT',
  TEMPORARY = 'TEMPORARY',
  CANDIDATE = 'CANDIDATE',
  INTERVIEW = 'INTERVIEW',
  OFFER = 'OFFER',
  ACCEPTED = 'ACCEPTED',
}

export const ENGLISH_LEVEL_ALIAS = {
  BEGINNER: 'Beginner',
  ELEMENTARY: 'Elementary',
  INTERMEDIATE: 'Intermediate',
  UPPER_INTERMEDIATE: 'Upper Intermediate',
  ADVANCED: 'Advanced',
  PROFICIENCY: 'Proficiency',
};

export const ENGLISH_LEVEL_OPTIONS = {
  [ENGLISH_LEVEL_ALIAS.BEGINNER]: 'Beginner',
  [ENGLISH_LEVEL_ALIAS.ELEMENTARY]: 'Elementary',
  [ENGLISH_LEVEL_ALIAS.INTERMEDIATE]: 'Intermediate',
  [ENGLISH_LEVEL_ALIAS.UPPER_INTERMEDIATE]: 'Upper Intermediate',
  [ENGLISH_LEVEL_ALIAS.ADVANCED]: 'Advanced',
  [ENGLISH_LEVEL_ALIAS.PROFICIENCY]: 'Proficiency',
};

export const MAP_ENGLISH_LEVEL_TO_SHORT = {
  [ENGLISH_LEVEL_ALIAS.BEGINNER]: ENGLISH_LEVEL_SHORT.A1,
  [ENGLISH_LEVEL_ALIAS.ELEMENTARY]: ENGLISH_LEVEL_SHORT.A2,
  [ENGLISH_LEVEL_ALIAS.INTERMEDIATE]: ENGLISH_LEVEL_SHORT.B1,
  [ENGLISH_LEVEL_ALIAS.UPPER_INTERMEDIATE]: ENGLISH_LEVEL_SHORT.B2,
  [ENGLISH_LEVEL_ALIAS.ADVANCED]: ENGLISH_LEVEL_SHORT.C1,
  [ENGLISH_LEVEL_ALIAS.PROFICIENCY]: ENGLISH_LEVEL_SHORT.C2,
};

export const MAP_SHORT_ENGLISH_LEVEL = {
  [ENGLISH_LEVEL_SHORT.A1]: ENGLISH_LEVEL_ALIAS.BEGINNER,
  [ENGLISH_LEVEL_SHORT.A2]: ENGLISH_LEVEL_ALIAS.ELEMENTARY,
  [ENGLISH_LEVEL_SHORT.B1]: ENGLISH_LEVEL_ALIAS.INTERMEDIATE,
  [ENGLISH_LEVEL_SHORT.B2]: ENGLISH_LEVEL_ALIAS.UPPER_INTERMEDIATE,
  [ENGLISH_LEVEL_SHORT.C1]: ENGLISH_LEVEL_ALIAS.ADVANCED,
  [ENGLISH_LEVEL_SHORT.C2]: ENGLISH_LEVEL_ALIAS.PROFICIENCY,
};

export type CandidateDuplicate = {
  url: string;
  candidate: ResourceData;
  // TODO: need add types for LinkedIn, Skype, Telegram, Whatsapp and Viber after implementing them on BE
  score: {
    name: number;
    personalPhoneNumber: number;
    personalEmail: number;
    otherEmail: number;
    additionalContacts?: {
      LinkedIn?: number;
    };
    total?: number;
  };
};

export const CANDIDATE_RESOURCE_URL = '/candidates';

const BLACKLIST_FILTER_KEY = 'filter.isInBlackList.eq';
const POTENTIAL_CANDIDATE_FILTER_KEY = 'filter.isMarkedAsPotentialCandidate.eq';

export function convertTelegramLink(input: string): string {
  const telegramUrlPattern = /^(https?:\/\/)?(www\.)?t\.me\/[a-z0-9_]+$/i;
  const simplifiedUrlPattern = /^t\.me\/[a-z0-9_]+$/i;

  if (telegramUrlPattern.test(input) || simplifiedUrlPattern.test(input)) {
    return input.toLowerCase().startsWith('http') ? input : `https://${input}`;
  }

  if (/^@[a-z0-9_]+$/i.test(input)) {
    const username = input.substring(1);
    return `https://t.me/${username.toLowerCase()}`;
  }

  return input;
}

/**
 * Candidate service.
 */
const useCandidateService = () => {
  const { sendPostRequest, sendGetRequest } = useKernelApi();
  const { getProfileUrl } = useCandidateUrl();
  const { sendPostRequest: sendRecPostRequest, sendPutRequest } = useRecruitmentApi();
  const { sendPostRequest: sendPostRequestBusinessEngineApi } = useBusinessEngineApi();
  const { moveApplicationOfCandidate } = useRecruitingBoardService();

  const {
    search: baseTimelineSearch,
    create: createTimelineItem,
    getTimelinePageById,
  } = useTimelineService({
    parentEntityUrl: `/candidates/:id`,
    apiService: useRecruitmentApi,
  });

  const getKernelPermissions = async (id: ResourceId) => {
    const response = await sendGetRequest(`/candidates/${id}/permissions`);
    return response.json();
  };

  const searchTimelineItems = async (candidateId: ResourceId, request: any) => {
    return baseTimelineSearch(candidateId, {
      ...request,
      sort: {
        ...request.sort,
        id: DESC,
      },
    });
  };

  const { search: baseSearch, ...baseCrudRequests } = useCrudService({
    singleResourceUrl: '/candidates/:id',
    listResourceUrl: CANDIDATE_RESOURCE_URL,
    apiService: useRecruitmentApi,
  });

  const search = (request: any) => {
    const blackListFilterValue = get(request, BLACKLIST_FILTER_KEY);
    let blackListFilter = null;

    if (blackListFilterValue === 'YES') {
      blackListFilter = true;
    } else if (blackListFilterValue === 'NO') {
      blackListFilter = false;
    }

    const potentialCandidateFilterValue = get(request, POTENTIAL_CANDIDATE_FILTER_KEY);
    let potentialCandidateFilter = null;

    if (potentialCandidateFilterValue === 'YES') {
      potentialCandidateFilter = true;
    } else if (potentialCandidateFilterValue === 'NO') {
      potentialCandidateFilter = false;
    }

    const params = cloneDeep(request);

    if (blackListFilter !== null) {
      set(params, BLACKLIST_FILTER_KEY, blackListFilter);
    }

    if (potentialCandidateFilter !== null) {
      set(params, POTENTIAL_CANDIDATE_FILTER_KEY, potentialCandidateFilter);
    }

    return baseSearch(params);
  };

  const visit = async (id: ResourceId) => {
    const url = `/candidates/${id}/visit`;
    return sendPostRequest(url, id);
  };

  const getValidationSchema = () => Promise.resolve(candidateValidation);
  const getInlineValidationSchema = () => Promise.resolve(candidateValidationInlineVersion);

  const getDuplicates = async (data: FormFieldsData) => {
    const response = await sendPostRequest(`/candidates/matches`, data);
    const duplicates = (await response.json()) || [];

    return duplicates.map((duplicate: CandidateDuplicate) => ({
      ...duplicate,
      url: getProfileUrl(duplicate.candidate.id),
    }));
  };

  const canSearchDuplicates = (data: FormFieldsData) => {
    return data?.name?.firstName && data?.name?.lastName;
  };

  const createCandidateAndLeftNote = async (formData: any) => {
    const { mainData, ...additionalData } = formData;
    const { candidateId } = mainData;
    const { timelineNote, resume } = additionalData;

    if (candidateId) {
      let response;

      if (timelineNote?.noteText) {
        response = await createTimelineItem(candidateId, 'notes', { ...timelineNote });
      }

      if (resume?.resumeArtifactIds?.length) {
        response = await createTimelineItem(candidateId, 'resumes', { ...resume });
      }

      return {
        ...response,
        isUpdate: true,
        candidateId,
      };
    }

    const { create: createNewCandidate } = baseCrudRequests;
    const newCandidateResponse = await createNewCandidate(additionalData);
    const newCandidateId = newCandidateResponse.id;

    if (timelineNote) {
      await createTimelineItem(newCandidateId, 'notes', { ...timelineNote });
    }

    return newCandidateResponse;
  };

  const sendSubmissionDuplicateReport = async (data: any) => {
    const duplicates = data.foundDuplicates;
    let result = [];
    if (duplicates && duplicates.length > 0) {
      const request = {
        requestId: duplicates[0].requestId,
        chosenCandidateId: data.candidateId,
        matches: duplicates.map((duplicateRecord: any) => {
          return {
            candidateId: duplicateRecord.candidate.id,
            score: duplicateRecord.score.total,
          };
        }),
      };
      const response = await sendRecPostRequest('/candidates/metrics/search-duplicate/submit-action', request);
      result = await response.json();
    }
    return result;
  };

  const reportWrongDuplicateResult = async (data: any) => {
    const request = {
      candidateId: data.candidate.id,
      requestId: data.requestId,
      matchScore: data.score.total,
    };
    const response = await sendRecPostRequest('/candidates/metrics/search-duplicate/wrong-report', request);
    const json = await response.json();
    return json;
  };

  const confirmCandidateSpecialization = async (
    candidateId: StringOrNumber,
    specialization: string,
    values: FormFieldsData,
  ) => {
    const res = await sendRecPostRequest(`/candidates/${candidateId}/confirm-specialization`, {
      specialization,
      ...values,
    });

    return res.json();
  };

  const updateCandidateProfile = async (id: StringOrNumber, values: FormFieldsData) => {
    try {
      const nameParts = values.name.fullName.split(' ');
      const firstNamePart = nameParts[0];
      const lastNamePart = [...nameParts.slice(1)].join(' ');

      const { additionalContacts } = values;
      const putObject = {
        ...values,
        name: {
          ...values.name,
          firstName: firstNamePart,
          lastName: lastNamePart,
        },
        additionalContacts: additionalContacts
          .filter((item: any) => item.value)
          .map((el: any) => {
            if (el.type === ADDITIONAL_CONTACTS_OPTIONS.Telegram) {
              return { ...el, value: convertTelegramLink(el.value) };
            }
            return el;
          }),
      };

      const resumeObj = { resumeArtifactIds: values.lastUploadedResumeIds };
      await sendPostRequest(`${CANDIDATES_ROUTE}/${id}/resumes`, resumeObj);

      const res = await sendPutRequest(`${CANDIDATES_ROUTE}/${id}`, putObject);

      return res.json();
    } catch (error) {
      throw new Error('Error with updating candidate profile');
    }
  };

  const getCandidateApplications = async (candidateId: StringOrNumber) => {
    try {
      const url = `/candidate-applications/search`;
      const response = await sendPostRequestBusinessEngineApi(url, {
        filter: {
          candidateId: {
            eq: candidateId,
          },
        },
      });

      return response.json();
    } catch (error) {
      return false;
    }
  };

  const changeCandidateApplicationPiplineStep = async (
    candidateId: StringOrNumber,
    moveToStageName: Stages,
    payload: any = null,
  ) => {
    let candidateApplicationId = null;
    if (payload?.resumeId?.length) {
      // workaround to correctly pass single artifact
      // eslint-disable-next-line no-param-reassign, prefer-destructuring
      payload.resumeId = payload.resumeId[0];
    }
    if (payload?.screeningMaterialsId) {
      // same as above (workaround to correctly pass single artifact)
      // eslint-disable-next-line no-param-reassign, prefer-destructuring
      payload.screeningMaterialsId = payload.screeningMaterialsId[0];
    }
    try {
      const res = await getCandidateApplications(candidateId);
      if (!res.result || res.result.length === 0) {
        throw new Error('No candidate applications found.');
      }
      candidateApplicationId = res.result[0]?.candidateApplication?.id;
    } catch (error) {
      return false;
    }
    if (candidateApplicationId) {
      const res = await moveApplicationOfCandidate(candidateApplicationId, moveToStageName, payload);
      if (res === false) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };

  return {
    search,
    visit,
    getKernelPermissions,
    getValidationSchema,
    searchTimelineItems,
    createTimelineItem,
    getTimelinePageById,
    ...baseCrudRequests,
    getDuplicates,
    canSearchDuplicates,
    createCandidateAndLeftNote,
    sendSubmissionDuplicateReport,
    reportWrongDuplicateResult,
    confirmCandidateSpecialization,
    updateCandidateProfile,
    getInlineValidationSchema,
    changeCandidateApplicationPiplineStep,
    getCandidateApplications,
  };
};

export default useCandidateService;
