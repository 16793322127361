import React from 'react';
import IconButton from 'uibuilder/button/IconButton';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useFormShowContext } from 'shared/uibuilder/FormShowContexts/FormShowContext';
import SmallLoader from 'shared/uibuilder/SmallLoader';

export const EditGroupButton = () => {
  const { toggleEditMode } = useFormShowContext();
  return (
    <IconButton onClick={toggleEditMode}>
      <AppRegistrationIcon color="primary" />
    </IconButton>
  );
};

export const SaveGroupButton = () => {
  const { submitForm = () => {}, isSubmitEnabled } = useFormContext();
  const saveEdit = async () => {
    submitForm();
  };

  return isSubmitEnabled ? (
    <IconButton onClick={saveEdit}>
      <CheckIcon color="success" />
    </IconButton>
  ) : (
    <SmallLoader />
  );
};

export const CloseEditGroupButton = () => {
  const { isSubmitEnabled } = useFormContext();
  const { toggleEditMode } = useFormShowContext();

  return (
    <IconButton onClick={toggleEditMode} disabled={!isSubmitEnabled}>
      <CloseIcon color="error" />
    </IconButton>
  );
};
