import CtCondition from 'shared/uibuilder/list/filter/condition/CtCondition';
import React, { useEffect, useState } from 'react';
import useVacancyService from 'erp/recruitment/vacancy/shared/vacancyService';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

type CandidateSpecializationFilterProps = {
  source: string;
  label: string;
};

const EmployeeDashboardSpecializationsFilter = ({ source, ...props }: CandidateSpecializationFilterProps) => {
  const [options, setOptions] = useState<any>([]);
  const { getPositionOptions } = useVacancyService();

  useEffect(() => {
    (async () => {
      const positions = await getPositionOptions();

      const fullNameOptions = Object.entries(positions).reduce((acc, [, value]) => {
        acc[value as string] = value;
        return acc;
      }, {} as any);

      setOptions(fullNameOptions);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CtCondition source={source}>
      <EnumDropdown source={source} options={options} label="Specialization" placeholder="Select Specialization" />
    </CtCondition>
  );
};

export default EmployeeDashboardSpecializationsFilter;
