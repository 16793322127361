import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import useCandidateOfferService from './candidateOfferService';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { CANDIDATE_TIMELINE_OFFER_STATUSES, OFFER_OFFICES } from '../constants';
import { useNavigate, useParams } from 'react-router-dom';
import { useCandidateUrl } from '../../CandidateRouter';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import Tooltip from 'uibuilder/Tooltip';

const TimelineOfferButtonGroup = ({ status, id }: any) => {
  const { cancelOffer, sendOffer, acceptOffer, rejectOffer } = useCandidateOfferService();
  const { reloadData } = useListContext();
  const navigate = useNavigate();
  const { getProfileUrl } = useCandidateUrl();
  const { candidateId } = useParams();

  const { data: offerData } = useShowContext();
  const office =
    offerData?.compensationDetails && offerData.compensationDetails[0] ? offerData.compensationDetails[0].office : null;

  const shouldShowGetOfferButton = office && (office === OFFER_OFFICES.MINSK || office === OFFER_OFFICES.KRAKOW);

  const onGetOffer = () => {
    navigate(`${getProfileUrl(candidateId)}/${id}/edit`);
  };

  const onCancelOffer = async () => {
    await cancelOffer(id);
    reloadData?.();
  };

  const onSentOffer = async () => {
    await sendOffer(id);
    reloadData?.();
  };

  const onAcceptOffer = async () => {
    await acceptOffer(id);
    reloadData?.();
  };

  const onRejectOffer = async () => {
    await rejectOffer(id);
    reloadData?.();
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
        {status === CANDIDATE_TIMELINE_OFFER_STATUSES.DRAFT && (
          <>
            {shouldShowGetOfferButton && (
              <Button variant="outlined" sx={{ mr: 1 }} onClick={onGetOffer}>
                Get Offer
              </Button>
            )}
            <Button sx={{ backgroundColor: 'var(--mui-palette-neutral-40)', mr: 1 }} onClick={onCancelOffer}>
              Cancel
            </Button>
            <Tooltip title="In order to finalize offer, candidate's application must be moved to offer stage." placement='right'>
              <span>
                <Button disabled sx={{ backgroundColor: 'var(--mui-palette-primary-main)', mr: 1 }}>
                  Finalize
                </Button>
              </span>
            </Tooltip>
          </>
        )}
        {status === CANDIDATE_TIMELINE_OFFER_STATUSES.FINALIZED && (
          <>
            {shouldShowGetOfferButton && (
                <Button variant="outlined" sx={{ mr: 1 }} onClick={onGetOffer}>
                  Get Offer
                </Button>
            )}
            <Button sx={{ backgroundColor: 'var(--mui-palette-neutral-40)', mr: 1 }} onClick={onCancelOffer}>
              Cancel
            </Button>
            <Button sx={{ backgroundColor: 'var(--mui-palette-primary-main)', mr: 1 }} onClick={onSentOffer}>
              Sent
            </Button>
          </>
        )}
        {status === CANDIDATE_TIMELINE_OFFER_STATUSES.SENT && (
          <>
            <Button sx={{ backgroundColor: 'var(--mui-palette-neutral-40)', mr: 1 }} onClick={onCancelOffer}>
              Cancel
            </Button>
            <Button sx={{ backgroundColor: 'var(--mui-palette-neutral-40)', mr: 1 }} onClick={onRejectOffer}>
              Reject
            </Button>
            <Button sx={{ backgroundColor: 'var(--mui-palette-success-main)' }} onClick={onAcceptOffer}>
              Accept
            </Button>
          </>
        )}
        {status === CANDIDATE_TIMELINE_OFFER_STATUSES.ACCEPTED && (
          <Button sx={{ backgroundColor: 'var(--mui-palette-neutral-40)', mr: 1 }} onClick={onRejectOffer}>
            Reject
          </Button>
        )}
      </Box>
    </>
  );
};

export default TimelineOfferButtonGroup;
