/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import useAssetService, { PERFORM_ASSET_COST_PRORATING } from 'financialAnalytic/assets/useAssetService';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import moment from 'moment';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DateInput } from 'shared/uibuilder/form/input';

const PerformAssetCostProratingButton = ({ disabledInput = false, initialValue = '', ...props }) => {
  const { performAssetCostProrating } = useAssetService();
  const { reloadData } = useListContext();
  const [dateErrors, setDateErrors] = useState<string[]>([]);
  const { getToday } = useDateService();
  const maxDate = getToday().subtract(1, 'month');
  const [date, setDate] = useState(initialValue || maxDate);

  const onDateChange = (values: FormFieldsData) => {
    setDate(values.date);
    const isValid = moment(values.date, DATE_FORMAT.API_ONLY_MONTH, true).isValid();

    if (!values.date) {
      setDateErrors(['Field is required']);
    } else if (!isValid) {
      setDateErrors(['Please, choose the correct date']);
    } else if (moment(values.date, DATE_FORMAT.API_ONLY_MONTH, true).isAfter(maxDate)) {
      setDateErrors(['Please, choose date earlier than current month']);
    } else {
      setDateErrors([]);
    }
  };

  useEffect(() => {
    if (initialValue) {
      onDateChange({ date: initialValue });
    }
    // Suppressed warnings because we only need to call useEffect callback if currentFilterValue is changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValue]);

  return (
    <ButtonWithConfirmation
      modalChildren={
        <div className="mt-5">
          <DateInput
            source="date"
            value={date as any}
            label="Cost Prorating Date"
            onChangeCallback={onDateChange}
            errorMessages={dateErrors}
            isRequired
            dateFormat={DATE_FORMAT.INPUT_MONTH_VALUE}
            maxDate={maxDate}
            disabled={disabledInput}
          />
        </div>
      }
      submitMethod={() => performAssetCostProrating(moment(date).format(DATE_FORMAT.API_ONLY_MONTH))}
      variant="outlined"
      confirmBtnProps={{ disabled: !!dateErrors.length }}
      displayMessage="Do you really want to perform asset cost prorating? Please fill out the input below."
      afterSubmit={{
        successMessage: 'The asset cost prorating have been successfully performed.',
        errorMessage: "Can't perform asset cost prorating.",
        execute: () => {
          if (reloadData) {
            reloadData();
          }
        },
      }}
      inputLabel="Cost Prorating Date"
      permissionToCheck={PERFORM_ASSET_COST_PRORATING}
      {...props}
    >
      Perform asset cost prorating
    </ButtonWithConfirmation>
  );
};

export default PerformAssetCostProratingButton;
