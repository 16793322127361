/* istanbul ignore file */
import React from 'react';
import EmailFields from 'erp/candidate/communication/email/EmailTimelineRecordLayout/EmailFields';
import ReplyButton from 'erp/candidate/communication/shared/button/ReplyButton';
import { useCandidateId, useCandidateUrl } from 'erp/candidate/CandidateRouter';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import BaseCandidateTimelineRecordLayout from '../../../Timeline/CandidateTimelineRecord/BaseCandidateTimelineRecordLayout';

const EmailTimelineRecordLayout = (props: any) => {
  const { data } = useShowContext();
  const id = useCandidateId();
  const { getCommunicationUrl } = useCandidateUrl();

  return (
    <BaseCandidateTimelineRecordLayout
      fields={<EmailFields />}
      entity={data}
      timelineType={data.getValueBySource('syberrySender') ? 'syberry_sender' : 'candidate_sender'}
      buttons={[]}
      actions={[<ReplyButton />]}
      timelineFooter={() => null}
      basePathname={getCommunicationUrl(id)}
      {...props}
    />
  );
};

export default EmailTimelineRecordLayout;
