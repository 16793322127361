import React, { useEffect } from 'react';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import useFormValidation from 'shared/uibuilder/form/useFormValidation';
import useInputList from 'shared/uibuilder/form/input/InputList/inputListHelper';

import CandidateInputListLayout from './CandidateInputListLayout';
import { InputListProps } from 'shared/uibuilder/form/input/InputList/InputList';

const CandidateInputList: React.FC<InputListProps> = ({
  inputTemplate,
  addText,
  deleteText = '',
  maxFields: initialMaxFields = null,
  minFields: initialMinFields = null,
  defaultValue = '',
  ...props
}: InputListProps) => {
  const inputHelper = useInputHelper(props);
  const value = inputHelper.getValue() || '';
  const source = inputHelper.getSource();
  const validationCallback = inputHelper.getValidationCallback();
  const { getMaxElements, getMinElements } = useFormValidation(source);
  const maxFields = initialMaxFields || getMaxElements();
  const minFields = initialMinFields || getMinElements();

  const { onChangeCallback, addInput, removeInput } = useInputList({
    maxFields: initialMaxFields,
    minFields: initialMinFields,
    defaultValue,
    ...props,
  });

  useEffect(() => {
    validationCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(value as object[]).length]);

  return (
    <CandidateInputListLayout
      {...props}
      value={inputHelper.getValue()}
      className={inputHelper.getClassName()}
      isVisible={inputHelper.isVisible()}
      maxFields={maxFields}
      minFields={minFields}
      addMethod={addInput}
      removeMethod={removeInput}
      inputTemplate={inputTemplate}
      fields={(value as object[]) || []}
      onChangeCallback={onChangeCallback}
      addText={addText}
      deleteText={deleteText}
      isRequired={inputHelper.getIsRequired()}
      errorMessages={inputHelper.getErrorMessages()}
      disabled={!!inputHelper.getIsDisabled()}
    />
  );
};

export default CandidateInputList;
