/* istanbul ignore file */

import NoteTimelineRecordLayout from 'erp/candidate/Timeline/note/shared/NoteTimelineRecordLayout';
import { CREATE_CANDIDATE_NOTE_PERMISSION } from 'erp/candidate/Timeline/note/note/candidateNoteService';
import UpdateNoteForm from './form/UpdateNoteForm';
import CreateCandidateNoteForm from './form/CreateCandidateNoteForm';
import { CANDIDATE_TIMELINE_RECORDS_ALIAS } from 'erp/candidate/Timeline/shared/input/CandidateTimelineRecordTypeDropdown';

const useCandidateNoteItem = () => ({
  AddForm: CreateCandidateNoteForm,
  UpdateForm: UpdateNoteForm,
  isApplicable: (item: any) => item.note && item.type !== CANDIDATE_TIMELINE_RECORDS_ALIAS.SYSTEM_NOTE && true,
  source: 'note',
  urlPostfix: 'notes',
  RecordLayout: NoteTimelineRecordLayout,
  name: 'Note',
  permissionToCheck: CREATE_CANDIDATE_NOTE_PERMISSION,
});

export default useCandidateNoteItem;
