import React, { useMemo } from 'react';
import { useDateService } from 'shared/uibuilder/dateService';
import { getStatusByMonths, HEALTHCHECK_CONFIG } from './constants';
import { Button } from 'uibuilder/button';
import { EMPLOYEE_ZONES_ALIASES } from '../../constants';

interface HealthcheckButtonProps {
  lastHealthcheckDate: string | Date | null;
  onClick: () => void;
  label?: string;
  size?: 'small' | 'medium' | 'large';
  style?: React.CSSProperties;
}

type HealthcheckStatusType = keyof typeof EMPLOYEE_ZONES_ALIASES;

const HealthcheckButton: React.FC<HealthcheckButtonProps> = ({
  lastHealthcheckDate,
  onClick,
  label = 'Conduct Healthcheck',
  size = 'small',
  style,
}) => {
  const { getToday, getMomentDateObj, getMonthsBetween } = useDateService();

  const statusType = useMemo(() => {
    if (!lastHealthcheckDate) {
      return EMPLOYEE_ZONES_ALIASES.SAFE as HealthcheckStatusType;
    }

    const today = getToday();
    const lastCheckDate = getMomentDateObj(lastHealthcheckDate);
    const monthsDiff = getMonthsBetween(today, lastCheckDate) || 0;

    return getStatusByMonths(monthsDiff);
  }, [lastHealthcheckDate, getToday, getMomentDateObj, getMonthsBetween]);

  const { buttonColor } = HEALTHCHECK_CONFIG[statusType as keyof typeof EMPLOYEE_ZONES_ALIASES];

  return (
    <Button
      onClick={onClick}
      variant="outlined"
      size={size}
      sx={{ ...style, borderColor: buttonColor, color: buttonColor }}
    >
      {label}
    </Button>
  );
};

export default HealthcheckButton;
