import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import { EMPLOYEE_ZONES } from '../../constants';

type CandidateSpecializationFilterProps = {
  source: string;
  label: string;
};

const EmployeeByRetentionRiskStatusFilter = ({ source, ...props }: CandidateSpecializationFilterProps) => {
  return (
    <DropDownFilter source={source} {...props}>
      <EnumDropdown source={source} placeholder="Risk status" options={EMPLOYEE_ZONES} {...props} />
    </DropDownFilter>
  );
};

export default EmployeeByRetentionRiskStatusFilter;
