import React, { useEffect, useMemo, useState } from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps } from 'shared/uibuilder/field';
import EditableBadge, { EditableBadgeValueProps } from 'shared/uibuilder/badge/EditableBadge';
import {
  RECRUITING_VACANCY_GROUP_CLOSE_VACANCY_GROUP,
  VACANCY_GROUP_STATUS,
  VACANCY_GROUP_STATUS_OPTIONS,
} from 'erp/recruitment/newVacancy/constants';
import { RECRUITING_CONTEXT } from 'erp/recruitment/RecruitingContext';
import useAuthorization from 'shared/authorization/authorizationService';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import Badge from 'shared/uibuilder/badge/Badge';
import { useRecruitmentApi } from 'api';
import useVacancyPublicationService from 'erp/recruitment/newVacancy/show/publications/vacancyPublicationService';
import InputLoader from 'shared/uibuilder/InputLoader';
import { useVacancyContext } from 'erp/recruitment/newVacancy/VacancyContext';

export const VacancyGroupStatusColor = {
  [VACANCY_GROUP_STATUS.REQUIRES_REVIEW]: 'warning' as BasicColors,
  [VACANCY_GROUP_STATUS.OPEN]: 'success' as BasicColors,
  [VACANCY_GROUP_STATUS.CLOSED]: 'warning' as BasicColors,
};

const VacancyGroupStatuses = [
  {
    value: VACANCY_GROUP_STATUS.REQUIRES_REVIEW,
    bgcolor: 'var(--mui-palette-badge-orange)' as BasicColors,
    menuOptions: [],
  },
  {
    value: VACANCY_GROUP_STATUS.OPEN,
    bgcolor: 'var(--mui-palette-badge-green)' as BasicColors,
    menuOptions: [VACANCY_GROUP_STATUS.CLOSED],
  },
  {
    value: VACANCY_GROUP_STATUS.CLOSED,
    bgcolor: 'var(--mui-palette-badge-orange)' as BasicColors,
    menuOptions: [],
  },
];

interface VacancyGroupStatusBadgeProps extends FieldProps {
  statusSource: string;
  idSource: string;
  isEdit: boolean;
}

const VacancyGroupStatusBadge = ({ idSource, statusSource, isEdit }: VacancyGroupStatusBadgeProps) => {
  const { getValue: getStatus } = useFieldHelper({ source: statusSource });
  const { getValue: getId } = useFieldHelper({ source: idSource });
  const { getValue: getAlias } = useFieldHelper({ source: 'alias' });

  const { isGranted } = useAuthorization();
  const { areVacanciesUpdated, arePublicationsUpdated } = useVacancyContext();

  const [status, setStatus] = useState(getStatus);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const [hasActiveApplications, setHasActiveApplications] = useState(true);
  const [hasActivePublications, setHasActivePublications] = useState(true);
  const [hasActiveVacancies, setHasActiveVacancies] = useState(true);

  // TODO: Use appropriate service instead of API drirectly, when implemented
  const { sendPostRequest } = useRecruitmentApi();
  const { searchWithFilters } = useVacancyPublicationService();
  const { closeVacancyGroup, searchVacancy } = useVacancyGroupService();

  const retrieveVacancies = async () => {
    const vacancies = await searchVacancy({
      filter: {
        'vacancyGroup.id': {
          eq: getId(),
        },
        status: {
          eq: 'OPEN',
        },
      },
      pageSize: 1,
    } as any);

    setHasActiveVacancies(vacancies.totalElements !== 0);
  };

  const retrievePublications = async () => {
    const publications = await searchWithFilters({
      filter: {
        'vacancyGroup.id': {
          eq: getId(),
        },
        status: {
          eq: 'OPEN',
        },
      },
      pageSize: 1,
    } as any);

    setHasActivePublications(publications.totalElements !== 0);
  };

  const retrieveApplications = async () => {
    const applications = await sendPostRequest('/candidates/applications/search', {
      filter: {
        vacancyGroupAlias: {
          in: [getAlias()],
        },
        currentPipelineStage: {
          not_in: ['Rejected', 'Closed', 'Hired'],
        },
      },
      pageSize: 1,
    });
    const result = await applications.json();

    setHasActiveApplications(result.totalElements !== 0);
  };

  useEffect(() => {
    setIsLoading(true);

    (async () => {
      try {
        await retrieveVacancies();
        await retrievePublications();
        await retrieveApplications();
      } finally {
        setIsLoading(false);
        setIsInitialLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isInitialLoading) {
      setIsLoading(true);

      (async () => {
        try {
          await retrieveVacancies();
        } finally {
          setIsLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areVacanciesUpdated]);

  useEffect(() => {
    if (!isInitialLoading) {
      setIsLoading(true);

      (async () => {
        try {
          await retrievePublications();
        } finally {
          setIsLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arePublicationsUpdated]);

  const handleChange = async () => {
    const vacancyGroup = await closeVacancyGroup(getId());
    setStatus(vacancyGroup.details.status);
  };

  const statuses = useMemo(() => {
    const defineDisabledReasons = (option: string) => {
      return option === VACANCY_GROUP_STATUS.CLOSED
        ? {
            disabled: hasActiveVacancies || hasActiveApplications || hasActivePublications,
            disabledReason: (
              <ul>
                {hasActiveVacancies ? <li>The group cannot be closed if it contains active vacancies.</li> : <></>}
                {hasActivePublications ? <li>The group cannot be closed if it has active publications.</li> : <></>}
                {hasActiveApplications ? <li>The group cannot be closed if it has active applications.</li> : <></>}
              </ul>
            ),
          }
        : {
            disabled: false,
            disabledReason: undefined,
          };
    };

    if (isLoading) {
      return [];
    }

    const vacancyGroupStatus = VacancyGroupStatuses.find(({ value }) => value === status);

    if (!vacancyGroupStatus || vacancyGroupStatus.menuOptions.length === 0) {
      return [];
    }

    const vacancyGroupStatusMenuOptions = vacancyGroupStatus.menuOptions;

    return vacancyGroupStatusMenuOptions.map(option => {
      const vacancyGroupStatusBadgeProps = VacancyGroupStatuses.find(({ value }) => value === option);
      const disabledReasons = defineDisabledReasons(option);

      return {
        ...vacancyGroupStatusBadgeProps,
        disabled: disabledReasons.disabled,
        disabledReason: disabledReasons.disabledReason,
      };
    });
  }, [status, isLoading, hasActiveVacancies, hasActivePublications, hasActiveApplications]);

  if (isLoading) {
    return <InputLoader />;
  }

  return !statuses.length || isEdit ? (
    <>
      <Badge
        color={VacancyGroupStatusColor[status] as BasicColors}
        sx={{ padding: '4px', display: 'flex', color: 'white' }}
      >
        {VACANCY_GROUP_STATUS_OPTIONS[status]}
      </Badge>
    </>
  ) : (
    <EditableBadge
      color={VacancyGroupStatusColor[status] as BasicColors}
      values={statuses as EditableBadgeValueProps[]}
      onChange={handleChange}
      canUpdate={isGranted(RECRUITING_VACANCY_GROUP_CLOSE_VACANCY_GROUP, RECRUITING_CONTEXT)}
      badgeSx={{ padding: '4px', border: '1px solid black', display: 'flex' }}
      menuItemSx={{
        margin: '4px',
        borderRadius: '4px',
        color: 'white',
      }}
    >
      {status}
    </EditableBadge>
  );
};

export default VacancyGroupStatusBadge;
