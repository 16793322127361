import React from 'react';
import { Notifications } from '@mui/icons-material';
import { Stack } from '@mui/material';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import Tooltip from 'uibuilder/Tooltip';
import Typography from 'uibuilder/Typography';
import { DATE_FORMAT, useDateService } from 'shared/uibuilder/dateService';
import Box from 'uibuilder/Box';
import { RetentionRiskFollowUp } from '../../types';

const FollowUpEmployeeDashboardField = () => {
  const { getValue } = useFieldHelper<RetentionRiskFollowUp[]>({ source: 'retentionRiskFollowUps' });

  const followUps = getValue();
  const { formatDate } = useDateService();

  const tooltipContent = followUps.length ? (
    <Box>
      {followUps.map(followUp => (
        <Box mb={2} key={followUp.id}>
          <b>{formatDate(followUp.followUpDate, DATE_FORMAT.NATURAL)} - </b>
          {followUp.description}
        </Box>
      ))}
    </Box>
  ) : null;

  return followUps.length ? (
    <Tooltip title={tooltipContent} placement="top">
      <Stack direction="row" alignItems="center" sx={{ color: '#5954A8' }}>
        <Notifications sx={{ fontSize: 14, mr: '5px' }} />
        <Typography variant="caption" color="var(--mui-palette-primary-main)">
          {formatDate(followUps[0].followUpDate, DATE_FORMAT.NATURAL)}
        </Typography>
      </Stack>
    </Tooltip>
  ) : null;
};

export default FollowUpEmployeeDashboardField;
