import { useKernelApi } from 'api';
import { ResourceId } from 'shared/crud/baseCrudService';
import presaleOutputValidation from 'crm/opportunity/presale/output/form/presaleOutputValidation';
import usePositionService from 'erp/position/positionService';

export const SIMPLE_QUALIFICATION_ALIASES = {
  JUNIOR: 'JUNIOR',
  MIDDLE: 'MIDDLE',
  SENIOR: 'SENIOR',
};

export const SIMPLE_QUALIFICATION = {
  [SIMPLE_QUALIFICATION_ALIASES.JUNIOR]: 'Junior',
  [SIMPLE_QUALIFICATION_ALIASES.MIDDLE]: 'Middle',
  [SIMPLE_QUALIFICATION_ALIASES.SENIOR]: 'Senior',
};

export const OPPORTUNITY_PROJECT_TYPE_ALIASES = {
  DISCOVERY: 'DISCOVERY',
  DEVELOPMENT: 'DEVELOPMENT',
  MAINTENANCE_AND_SUPPORT: 'MAINTENANCE_AND_SUPPORT',
};

export const OPPORTUNITY_PROJECT_TYPE = {
  [OPPORTUNITY_PROJECT_TYPE_ALIASES.DISCOVERY]: 'Discovery',
  [OPPORTUNITY_PROJECT_TYPE_ALIASES.DEVELOPMENT]: 'Development',
  [OPPORTUNITY_PROJECT_TYPE_ALIASES.MAINTENANCE_AND_SUPPORT]: 'Maintenance and Support',
};

const usePresaleOutputService = () => {
  const { sendPostRequest, sendPutRequest, sendGetRequest } = useKernelApi();
  const { findAll: findAllPositions } = usePositionService();

  const createOutput = async (opportunityId: ResourceId, request: any) => {
    const response = await sendPostRequest(`/crm/opportunities/${opportunityId}/presale-outputs`, request);

    return response.json();
  };

  const updateOutput = async (opportunityId: ResourceId, outputId: ResourceId, request: any) => {
    const response = await sendPutRequest(`/crm/opportunities/${opportunityId}/presale-outputs/${outputId}`, request);

    return response.json();
  };

  const getLastPresaleOutput = async (opportunityId: ResourceId) => {
    const response = await sendGetRequest(`/crm/opportunities/${opportunityId}/presale-outputs/last`);

    return response.json();
  };

  const getPositionOptions = async () => {
    const positions = await findAllPositions();

    const resourcePositions = positions.reduce((specializations: Dictionary<string>, position: Dictionary<string>) => {
      return { ...specializations, [position.id]: position.name };
    }, {});

    return resourcePositions;
  };

  return {
    createOutput,
    updateOutput,
    getLastPresaleOutput,
    getPositionOptions,
    getValidationSchema: () => Promise.resolve(presaleOutputValidation),
  };
};

export default usePresaleOutputService;
