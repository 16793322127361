import getAzureDomain from 'erp/config/emailConfiguration';

const domainName = getAzureDomain();
const phoneNumberRegex = /^\+\d+$/;
const atLeastOneSpaceRegex = /\b\w+\s+\w+\b/;

/* istanbul ignore file */
export default {
  name: {
    type: 'object',
    required: true,
    _divisible: false,
    properties: {
      firstName: {
        type: 'string',
        maxSize: 255,
        required: true,
      },
      middleName: {
        type: 'string',
        maxSize: 255,
      },
      lastName: {
        type: 'string',
        maxSize: 255,
        required: true,
      },
      maidenName: {
        type: 'string',
        maxSize: 255,
      },
      fullName: {
        type: 'string',
        regex: {
          value: atLeastOneSpaceRegex,
          message: 'Full name must contain at least one space',
        },
      },
    },
  },

  dateOfBirth: {
    type: {
      value: 'date',
      message: 'Please, enter a valid date',
    },
    maxDate: {
      value: '$NOW',
      message: 'Must be in the past',
    },
  },
  photosIds: {
    type: 'array',
    maxSize: 3,
  },
  status: {
    type: 'enum',
    required: {
      value: true,
      when: {
        $_IS_CREATE_REQUEST: {
          eq: false,
        },
      },
    },
  },
  syberryEmail: {
    type: 'string',
    maxSize: 255,
    required: {
      value: true,
      when: {
        $isStudent: {
          eq: true,
        },
      },
    },
    regex: {
      value: `^.+@${domainName}$`,
      message: `Email must be within ${domainName} domain`,
    },
  },
  personalPhoneNumber: {
    type: 'string',
    regex: {
      value: phoneNumberRegex,
      message: 'Please enter phone number in international format (+xxxxxxxxxxxx), max. 15 characters',
    },
    maxSize: 15,
  },
  otherPhoneNumber: {
    type: 'string',
    regex: {
      value: phoneNumberRegex,
      message: 'Please enter phone number in international format (+xxxxxxxxxxxx), max. 15 characters',
    },
    maxSize: 15,
  },
  personalEmail: {
    type: 'string',
    email: true,
    maxSize: 255,
  },
  otherEmail: {
    type: 'string',
    email: true,
    maxSize: 255,
  },
  additionalContacts: {
    type: 'array',
    maxSize: 10,
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        type: {
          required: true,
          maxSize: 255,
        },
        value: {
          required: true,
          maxSize: 255,
        },
      },
    },
  },
  resume: {
    type: 'object',
    properties: {
      resumeArtifactIds: {
        type: 'array',
        maxSize: 5,
      },
      description: {
        type: 'string',
        maxSize: 65000,
      },
    },
  },
};
