import React from 'react';
import styles from './CandidateEnglishLevelLang.module.scss';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { MAP_ENGLISH_LEVEL_TO_SHORT } from '../../../candidateService';

const CandidateEnglishLevelLang = () => {
  // Temporarly we have only one English language.
  const { getValue: getLang } = useFieldHelper({ source: 'englishLevel' });
  const userLang = getLang();
  const userLanguages = [{ name: 'Eng', level: MAP_ENGLISH_LEVEL_TO_SHORT[userLang] }];
  return (
    <div className={styles.languages__wrapper}>
      {userLanguages.map(lang => {
        const langName = typeof lang.name !== 'undefined' ? lang.name : '';
        const langLevel = typeof lang.level !== 'undefined' ? lang.level : '';
        const langCssClass = typeof lang.level !== 'undefined' ? styles[langLevel] : '';
        return (
          <div className={`${langCssClass} ${styles.language_icon}`} key={lang.name + lang.level}>
            {langName} {langLevel}
          </div>
        );
      })}
    </div>
  );
};

export default CandidateEnglishLevelLang;
