import React from 'react';
import { Routes } from 'react-router-dom';
import ProtectedRouter from 'routing/ProtectedRouter';
import { Route } from 'shared/routing';
import RecruitingContext from '../RecruitingContext';
import { SALARY_FORKS_PATH, useSalaryForksPath } from './routing';
import { SALARY_FORKS_VIEW_READ } from './useSalaryForkService';
import useFeatureToggle, { Features } from '../../../featuretoggle';
import SalaryForkListPage from './List';
import SalaryForkHistoryPage from './History';

const SalaryForksRouter = () => {
  const { listRoute, historyRoute } = useSalaryForksPath();
  const { isFeatureEnabled } = useFeatureToggle();

  const viewSalaryForks = isFeatureEnabled(Features.SALARY_FORKS);

  return (
    <ProtectedRouter basePath={SALARY_FORKS_PATH}>
      <Routes>
        {viewSalaryForks ? (
          <>
            <Route
              path={listRoute}
              element={
                <RecruitingContext permissionToCheck={SALARY_FORKS_VIEW_READ}>
                  <SalaryForkListPage />
                </RecruitingContext>
              }
            />
            <Route
              path={historyRoute}
              element={
                <RecruitingContext permissionToCheck={SALARY_FORKS_VIEW_READ}>
                  <SalaryForkHistoryPage />
                </RecruitingContext>
              }
            />
          </>
        ) : (
          <></>
        )}
      </Routes>
    </ProtectedRouter>
  );
};

SalaryForksRouter.getRouterPath = () => `${SALARY_FORKS_PATH}/*`;

export default SalaryForksRouter;
