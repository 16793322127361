/* istanbul ignore file */
// libs
import React from 'react';
// components
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { TextInput, DateInput, CurrencyInput } from 'shared/uibuilder/form/input';
import BigFormRow from 'shared/layout/form/BigFormRow';
import { AccountInput } from 'crm/account';
import OpportunityCheckbox from 'crm/lead/shared/field/OpportunityCheckbox';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import OpportunityChecklist from 'crm/shared/checklist/OpportunityChecklist';

const ConvertLeadForm = () => {
  const { data } = useFormContext();
  const createOpportunity = Boolean(data?.createOpportunity);

  return (
    <>
      <FormSection title="Account">
        <AccountInput idSource="existingAccountName" nameSource="accountName" source="" />
      </FormSection>

      <FormSection title="Contact" subtitle="Create new contact">
        <FormRow>
          <TextInput source="title" />
          <TextInput source="firstName" />
          <TextInput source="middleName" />
          <TextInput source="lastName" />
        </FormRow>
      </FormSection>

      <FormSection title="Opportunity">
        <BigFormRow>
          <OpportunityCheckbox nameSource="createOpportunity" label="Do not create a new opportunity upon conversion" />
        </BigFormRow>
        <FormRow>
          <TextInput source="opportunityName" disabled={createOpportunity} />
          <DateInput source="closeDate" disabled={createOpportunity} />
          <CurrencyInput source="amount" label="Opportunity Amount (USD)" maxInteger={9} maxDecimal={2} suffix="$" />
        </FormRow>
      </FormSection>

      {!createOpportunity && <OpportunityChecklist source="opportunityChecklist" />}
    </>
  );
};

export default ConvertLeadForm;
