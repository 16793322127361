import React from 'react';
import { Routes, Route } from 'react-router';
import { ProtectedRouter } from 'routing';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import Page404 from 'shared/uibuilder/Page404';
import { EMPLOYEES_DASHBOARD_PATH } from './constants';
import { useEmployeesDashboardRoute } from './routing';
import { GET_EMPLOYEES_LIST } from '../employeeService';
import EmployeesDashboard from './List/EmployeesDashboard';

// TODO Jump to Employees router if /employee is removed
const EmployeesDashboardRouter = () => {
  const { listRoute } = useEmployeesDashboardRoute();

  return (
    <ProtectedRouter basePath={EMPLOYEES_DASHBOARD_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={GET_EMPLOYEES_LIST}>
              <EmployeesDashboard />
            </GlobalProtectedRoute>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

EmployeesDashboardRouter.getRouterPath = () => `${EMPLOYEES_DASHBOARD_PATH}/*`;

export default EmployeesDashboardRouter;
