import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import { UPDATE_PRESALE_OUTPUT } from 'crm/opportunity/shared/opportunityService';
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import Collapsible from 'shared/uibuilder/field/Collapsible';
import PresaleOutputFields from 'crm/opportunity/presale/output/PresaleOutputTimelineRecordLayout/PresaleOutputFields';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import PinTimelineButton from 'crm/shared/buttons/PinTimelineButton';
import { useOpportunityId } from 'crm/opportunity/OpportunityRouter';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { TimelineContextData } from 'shared/uibuilder/timeline/Timeline';

const PresaleOutputTimelineRecordLayout = ({ onEdit, isPinned, ...props }: any) => {
  const opportunityId = useOpportunityId();
  const { data } = useShowContext();
  const timelineId = data.getValueBySource('timelineId');
  const { updateInList, pinTimelineAction }: TimelineContextData = useListContext();

  const buttons = [<EditTimelineRecordButton onEdit={onEdit} permissionToCheck={UPDATE_PRESALE_OUTPUT} />];

  return (
    <BaseTimelineRecordLayout
      fields={
        <Collapsible>
          <PresaleOutputFields />
        </Collapsible>
      }
      entity={data}
      timelineType={TimelineType.NOTE}
      buttons={buttons}
      actions={[
        <PinTimelineButton
          pinStatus={isPinned}
          pinMethod={async () => {
            const response = await pinTimelineAction!(opportunityId, timelineId, !isPinned);
            updateInList!(response?.id, response);
          }}
        />,
      ]}
      timelineFooter={() => null}
      {...props}
    />
  );
};

export default PresaleOutputTimelineRecordLayout;
