import React from 'react';
import PdfGenerator from 'shared/markdownEditor/PdfGenerator';
import { PlaceholderValues } from 'shared/markdownEditor/MarkdownTypes';
import { generatePdf } from 'shared/markdownEditor/generatePdfService';
import { CONTRACT_TYPES } from '../../constants';

interface JobOfferPdfGeneratorProps {
  editedContent: string;
  placeholderValues: PlaceholderValues;
  firstName: string;
  fullName: string;
}

export const getRenderingApiUrl = (): string => {
  return process.env.REACT_APP_DAVINCI_FRONTEND_PDF_RENDERING_URL!;
};

const JobOfferPdfGenerator: React.FC<JobOfferPdfGeneratorProps> = ({ editedContent, placeholderValues, firstName, fullName }) => {
  const handleGeneratePdf = async () => {
    const { contractType, finalAmountGross, currency, office, probationAmountGross } = placeholderValues;

    const isEC = contractType === CONTRACT_TYPES.EC;
    const isB2B = contractType === CONTRACT_TYPES.B2B;

    const viewData = {
      candidateName: firstName,
      vacancySpecialization: placeholderValues.vacancySpecialization,
      acceptanceDate: placeholderValues.acceptanceDate,
      office,
      compensationDetails: [
        {
          contractType,
          isEC,
          isB2B,
          finalAmountGross,
          probationAmountGross,
          currency,
        },
      ],
    };

    return generatePdf(getRenderingApiUrl(), editedContent, viewData);
  };

  return (
    <PdfGenerator
      onGeneratePdf={handleGeneratePdf}
      fileName={`job offer ${fullName}.pdf`}
      successMessage="Job offer PDF successfully generated"
    />
  );
};

export default JobOfferPdfGenerator;
