import { useState, useEffect } from 'react';
import { flushSync } from 'react-dom';
import { useFilterContext } from '../../FilterContext';

const STRICTS = 'stricts';

export interface ChipFilter {
  [index: string]: string[];
}

export type ChipFilterInputWrapperProps = {
  title: string;
  children: React.ReactElement;
  source: string;
  mapValueForChange?: (value: any, filterState: ChipFilter) => void;
  mapViewChipText?: (text: any[]) => string;
  [otherProp: string]: any;
};

export type ChipFilterInputProps = {
  source: string;
  allSelectedData?: ChipFilter;
  onChangeCallback?: (value: any) => void;
  value?: string[];
};

const useChipFilterLayout = ({ defaultFilter }: { defaultFilter: ChipFilter }) => {
  const { filters, quickFilter } = useFilterContext();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [filterState, setFilters] = useState<ChipFilter>(defaultFilter);
  const [appliedFilters, setAppliedFilters] = useState<ChipFilter>(defaultFilter);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const updateFilters = (key: string, value: string[]) => {
    setFilters(prev => ({
      ...prev,
      [key]: value,
    }));
  };

  const updateAppliedFilters = (key: string, value: string[]) => {
    setAppliedFilters(prev => ({
      ...prev,
      [key]: value,
    }));
    quickFilter({
      ...appliedFilters,
      [key]: value,
    });
  };

  const setFiltersStatesByKey = (key: string, value: string[]) => {
    updateFilters(key, value);
    updateAppliedFilters(key, value);
  };

  const handleRemoveFilter = (key: string, item: string) => {
    const newValue = filterState[key].filter(i => i !== item);
    setFiltersStatesByKey(key, newValue);
  };

  const handleClickAwayMenu = () => {
    setIsMenuOpen(false);
    setFilters(appliedFilters);
  };

  const handleApply = () => {
    flushSync(() => {
      setAppliedFilters(filterState);
    });

    setIsMenuOpen(false);
    quickFilter(filterState);
  };

  const forceFilter = (filter: any) => {
    setFilters(filter);
    setAppliedFilters(filter);
    setIsMenuOpen(false);
    quickFilter(filter);
  };

  const handleStrictToggle = (filterId: string) => {
    const { stricts = [] } = filterState;

    const newStricts = stricts.includes(filterId)
      ? stricts.filter((i: string) => i !== filterId)
      : [...stricts, filterId];
    updateFilters(STRICTS, newStricts);
    updateAppliedFilters(STRICTS, newStricts);
  };

  useEffect(() => {
    if (filters) {
      setFilters(prev => ({
        ...prev,
        ...(filters as ChipFilter),
      }));

      setAppliedFilters(prev => ({
        ...prev,
        ...(filters as ChipFilter),
      }));
    }
  }, [filters]);

  return {
    isMenuOpen,
    filterState,
    appliedFilters,
    toggleMenu,
    updateFilters,
    updateAppliedFilters,
    setFiltersStatesByKey,
    handleRemoveFilter,
    handleApply,
    handleStrictToggle,
    handleClickAwayMenu,
    forceFilter,
  };
};

export default useChipFilterLayout;
