import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const FlagBelarusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_3962_3796)">
          <path
            d="M22.2222 3.47217H2.77778C2.04107 3.47217 1.33453 3.76483 0.813592 4.28576C0.292658 4.80669 0 5.51323 0 6.24995L0 12.4999H25V6.24995C25 5.51323 24.7073 4.80669 24.1864 4.28576C23.6655 3.76483 22.9589 3.47217 22.2222 3.47217Z"
            fill="#EEEEEE"
          />
          <path
            d="M0 18.75C0 19.4867 0.292658 20.1933 0.813592 20.7142C1.33453 21.2351 2.04107 21.5278 2.77778 21.5278H22.2222C22.9589 21.5278 23.6655 21.2351 24.1864 20.7142C24.7073 20.1933 25 19.4867 25 18.75V12.5H0V18.75Z"
            fill="#DC143C"
          />
        </g>
        <defs>
          <filter
            id="filter0_i_3962_3796"
            x="0"
            y="0"
            width="25"
            height="29"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3962_3796" />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default FlagBelarusIcon;
