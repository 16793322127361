import React from 'react';
import InterviewScheduledForm from './InterviewScheduledForm';
import generalInterviewFormValidationSchema from './generalInterviewFormValidationSchema';

interface GeneralInterviewScheduledFormProps {
  handleCancelClick: () => void;
}

const GeneralInterviewScheduledForm = (props: GeneralInterviewScheduledFormProps) => {
  return <InterviewScheduledForm validationSchema={generalInterviewFormValidationSchema} isGeneral {...props} />;
};

export default GeneralInterviewScheduledForm;
