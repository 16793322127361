import React, { useEffect } from 'react';
import Button from 'uibuilder/button/Button';
import { SubmitButton } from 'uibuilder/button';
import Alert from 'uibuilder/Alert';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import VacancyByGroupDropdown from 'erp/recruitment/newVacancy/shared/inputs/VacancyByGroupDropdown';
import hiredFormValidationSchema from './hiredFormValidationSchema';

interface HiredStageFormProps {
  handleCancelClick: () => void;
  vacancyGroupId: string | null;
  entity: any;
}

const HiredStageForm = ({ handleCancelClick, vacancyGroupId, entity }: HiredStageFormProps) => {
  const { submitForm, isSubmitEnabled, isLoadingArtifact, setValidationSchemaAction } = useFormContext();
  const isHiringLimitReached = entity.hired >= entity.needHire;

  useEffect(() => {
    if (setValidationSchemaAction) {
      setValidationSchemaAction(hiredFormValidationSchema);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <VacancyByGroupDropdown
        source="vacancyId"
        label="Vacancy"
        vacancyGroupId={vacancyGroupId}
        placeholder="Select Vacancy..."
        helpText="Empty dropdown indicates that the vacancy needs to be migrated to the new Recruiting service first."
      />
      {isHiringLimitReached && (
        <Alert variant="outlined" severity="warning">
          {`Cannot hire more candidates. Current: ${entity.hired}, Maximum: ${entity.needHire}`}
        </Alert>
      )}

      <div className="btn-area">
        <SubmitButton
          key="submit-modal-btn"
          onClick={submitForm}
          isLoading={!isSubmitEnabled}
          disabled={isLoadingArtifact || isHiringLimitReached}
        />
        <Button
          outline
          key="cancel-modal-btn"
          onClick={handleCancelClick}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Cancel
        </Button>
      </div>
    </>
  );
};

export default HiredStageForm;
