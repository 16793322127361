/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import { FilterCondition, useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import FilterInputDecorator from 'shared/uibuilder/list/filter/FilterInputDecorator';
import DropDownFilter from 'shared/uibuilder/list/filter/DropDownFilter';
import CandidateTimelineRecordTypeDropdown, {
  CANDIDATE_TIMELINE_RECORDS_ALIAS,
} from '../input/CandidateTimelineRecordTypeDropdown';
import styles from './CandidateTimelineSwitch.module.scss';
import { Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import _ from 'lodash';

interface CandidateTimelineRecordTypeFilterProps {
  cleanableFilters: any[];
  source: string;
}

const CandidateTimelineRecordTypeFilter = ({
  cleanableFilters = [],
  source,
  ...props
}: CandidateTimelineRecordTypeFilterProps) => {
  const { filterBy, quickFilter, clearAndApplyFilter, filters: contextFilters } = useFilterContext();
  const [isChecked, setIsChecked] = useState(true);

  const onChangeCallback = (filter: any) => {
    const filters = {
      ...filter,
    };

    cleanableFilters.forEach(clearableFilter => {
      filters[clearableFilter] = {};
    });
    filterBy(filters);
  };

  const defaultTimelineTypes: string[] = [
    CANDIDATE_TIMELINE_RECORDS_ALIAS.NOTE_CREATED,
    CANDIDATE_TIMELINE_RECORDS_ALIAS.COMMUNICATION,
  ];

  useEffect(() => {
    if (_.isEmpty(contextFilters?.type?.[FilterCondition.IN])) {
      setIsChecked(true);
    } else setIsChecked(false);
  }, [contextFilters]);

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;

    if (checked) {
      clearAndApplyFilter([...cleanableFilters, source]);
    } else {
      quickFilter({
        type: {
          [FilterCondition.IN]: defaultTimelineTypes,
        },
      });
    }
  };
  return (
    <>
      <div className={styles.candidate_timeline_show_all_activities}>
        <Typography>Show all activities</Typography>
        <Switch checked={isChecked} onChange={handleSwitchChange} size="small" />
      </div>
      <DropDownFilter {...props} source={source} isSingle={false} onChangeCallback={onChangeCallback}>
        <FilterInputDecorator cleanableFilters={[...cleanableFilters, source]}>
          <CandidateTimelineRecordTypeDropdown onChange={onChangeCallback} />
        </FilterInputDecorator>
      </DropDownFilter>
    </>
  );
};

export default CandidateTimelineRecordTypeFilter;
