import { useBusinessEngineApi, useKernelApi } from 'api';
import { ResourceId } from 'shared/crud/baseCrudService';
import contractRequestValidation from 'crm/opportunity/presale/contract/form/contractRequestValidation';

export const TEMPLATE_TYPES = {
  SYBERRY: 'Syberry template',
  CUSTOMER: 'Customer template',
};

const useContractRequestService = () => {
  const { sendPostRequest } = useBusinessEngineApi();
  const { sendPutRequest } = useKernelApi();

  const create = async (opportunityId: ResourceId, request: any) => {
    const response = await sendPostRequest(`/crm/opportunities/${opportunityId}/contract-requests`, request);

    return response.json();
  };

  const update = async (opportunityId: ResourceId, contractId: ResourceId, request: any) => {
    const response = await sendPutRequest(
      `/crm/opportunities/${opportunityId}/contract-requests/${contractId}`,
      request,
    );

    return response.json();
  };

  return {
    create,
    update,
    getValidationSchema: () => Promise.resolve(contractRequestValidation),
  };
};

export default useContractRequestService;
