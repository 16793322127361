import useFormValidation from 'shared/uibuilder/form/useFormValidation';
import { bool, func, oneOfType, string, number, element } from 'prop-types';
import getLabel, { getValue } from 'shared/uibuilder/helper';
import { useFormContext, FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { resolveValueByPath, transformPathForLodash } from 'validation-schema-library';
import React, { useEffect } from 'react';
import { Pastable, DefaultInputPropTypes, Sizeable } from 'shared/uibuilder/form/input';
import { SxProps, Theme } from '@mui/material';

const DEFAULT_NUMBER_OF_ROWS = 3;

function instanceOfParsable(object: any): object is Pastable<any> {
  return 'onPasteCallback' in object;
}

function instanceOfSizeable(object: any): object is Sizeable {
  return 'rows' in object || 'height' in object;
}

export interface InputHelperData<ValueType> {
  getValue: () => ValueType;
  getLabel: () => string | JSX.Element | (() => string | JSX.Element) | null | undefined;
  getOnChangeCallback: () => (event: any) => void;
  getValidationCallback: () => () => void;
  getClearValidationCallback: () => () => void;
  getSource: () => string;
  isVisible: () => boolean;
  getIsRequired: () => boolean;
  getErrorMessages: () => Nullable<string[]>;
  getRawOnChangeCallback: () => ((values: FormFieldsData, isFieldInitializing?: boolean) => void) | undefined;
  getLabelHint: () => Nullable<string>;
  getCollectionOnChangeCallback: () => void;
  getIsDisabled: () => Nullable<boolean>;
  getHelpText: () => Nullable<string>;
  initializeValue: (value: ValueType) => void;
  getTooltip: () => Nullable<React.ReactElement | string>;
  getClassName: () => Nullable<string>;
  getNumberOfRows: () => Nullable<number>;
  getOnPasteCallback: () => Nullable<(event: any) => void>;
  setFormFieldErrors: (errors: object) => void;
  getHeight: () => Nullable<number>;
  getPlaceholder: () => string | undefined;
}

function useInputHelper<ValueType>(inputProps: DefaultInputPropTypes<ValueType>): {
  getIsDisabled: () => any;
  getLabel: () => string | JSX.Element | (() => string | JSX.Element) | null | undefined;
  getClearValidationCallback: () => () => void;
  getHelpText: () => any;
  getPlaceholder: () => string | undefined;
  setFormFieldErrors: (errors: object) => void;
  isVisible: () => any;
  getLabelHint: () => any;
  getOnPasteCallback: () => any | null;
  getCollectionOnChangeCallback: () => any;
  getValue: () => any;
  getHeight: () => any | null;
  getRawOnChangeCallback: () => ((values: FormFieldsData, isFieldInitializing?: boolean) => void) | undefined;
  getTooltip: () => any;
  getOnChangeCallback: () => (event: any) => void;
  getSource: () => string;
  getIsRequired: () => any;
  getNumberOfRows: () => number;
  getErrorMessages: () => any;
  getClassName: () => any;
  getValidationCallback: () => () => void;
  initializeValue: (value: ValueType) => void;
  getSX: () => SxProps<Theme>;
} {
  const {
    onChangeCallback: contextOnChangeCallback,
    collectionOnChangeCallback,
    data,
    formErrors,
    setFieldErrors,
    setFormErrors = () => {},
  } = useFormContext();
  const { isRequired, validate } = useFormValidation(inputProps.source);

  /**
   * Returns value based on form context data and value attribute.
   * @returns {null}
   */
  const getValueHelper = () => {
    const { value: inputValue, source } = inputProps;

    return getValue(inputValue, src => resolveValueByPath(data as Nullable<object>, src as string), source, '');
  };

  /**
   * Returns label based on source and label attribute.
   * @returns {*}
   */
  const getLabelHelper = () => {
    const { label: inputLabel, source } = inputProps;

    return getLabel(inputLabel, source);
  };

  const getIsRequired = () => {
    const { isRequired: inputIsRequired, source } = inputProps;

    return inputIsRequired || (source && isRequired());
  };

  const getErrorMessages = () => {
    const { source, errorMessages } = inputProps;

    return errorMessages || (formErrors && formErrors[source]);
  };

  /**
   * Returns raw callback: either from context or from props.
   */
  const getRawOnChangeCallback = () => {
    const { onChangeCallback: inputOnChangeCallback } = inputProps;

    return inputOnChangeCallback || contextOnChangeCallback;
  };

  /**
   * Returns source.
   * @returns {*}
   */
  const getSource = () => inputProps.source;

  const getTransformedSource = () => {
    return transformPathForLodash(inputProps.source);
  };

  /**
   * Returns collection callback: either from context or from props.
   */
  const getCollectionOnChangeCallback = () => {
    const { onChangeCallback: inputOnChangeCallback } = inputProps;

    return (
      inputOnChangeCallback ||
      ((mappingFunc: (values: ValueType[]) => ValueType[]) =>
        collectionOnChangeCallback && collectionOnChangeCallback(getTransformedSource(), mappingFunc))
    );
  };

  /**
   * Returns onChangeCallback based on context data and component attribute.
   * @returns {function(...[*]=)}
   */
  const getOnChangeCallback = () => {
    const { onChangeCallback: inputOnChangeCallback, source } = inputProps;

    const onChangeCallback = (inputOnChangeCallback || contextOnChangeCallback) as (values: FormFieldsData) => void;

    return (event: any) => {
      onChangeCallback({
        [source]: event.target.value,
      });
    };
  };

  /**
   * Returns boolean isVisible value
   * @returns {boolean}
   */
  const isVisible = () => {
    const { isVisible: isVisibleInput = true } = inputProps;

    let result;
    if (typeof isVisibleInput === 'function') {
      result = isVisibleInput(data || {});
    } else {
      result = isVisibleInput;
    }

    return result;
  };

  const getIsDisabled = () => {
    const { disabled: propsIsDisabled = null } = inputProps;

    let result;
    if (typeof propsIsDisabled === 'function') {
      result = propsIsDisabled(data || {});
    } else {
      result = propsIsDisabled;
    }

    return result;
  };

  /**
   * Returns validation callback.
   * @returns {Requireable<(...args: any[]) => any> | TextInput.defaultProps.validationCallback}
   */
  const getValidationCallback = () => {
    const { validationCallback: customCallback } = inputProps;

    const validationCallback = () => {
      const { source } = inputProps;
      const errors = (data && validate()) || [];

      if (source && setFieldErrors) {
        setFieldErrors(source, errors as object);
      }
    };

    return customCallback || validationCallback;
  };

  const setFormFieldErrors = (errors: object) => {
    const { source } = inputProps;

    if (setFieldErrors) {
      setFieldErrors(source, errors);
    }
  };

  const getClearValidationCallback = () => {
    const { clearValidationCallback: customClearValidationCallback } = inputProps;

    const clearValidationCallback = () => {
      const { source } = inputProps;

      if (source && setFieldErrors) {
        setFieldErrors(source, []);
      }
    };

    return customClearValidationCallback || clearValidationCallback;
  };

  const getLabelHint = () => {
    const { labelHint = null } = inputProps;

    return labelHint;
  };

  const getHelpText = () => {
    const { helpText = null } = inputProps;

    return helpText;
  };

  const initializeValue = (value: ValueType) => {
    const onChangeCallback = getRawOnChangeCallback();

    if (onChangeCallback) {
      onChangeCallback(
        {
          [getSource()]: value,
        },
        true,
      );
    }
  };

  const getTooltip = () => {
    const { tooltip = null } = inputProps;

    return tooltip;
  };

  const getClassName = () => {
    const { className = null } = inputProps;

    return typeof className === 'function' ? className(getSource()) : className;
  };

  const getOnPasteCallback = () => {
    if (instanceOfParsable(inputProps)) {
      const { onPasteCallback = null } = inputProps;

      return onPasteCallback;
    } else {
      return null;
    }
  };

  const getNumberOfRows = () => {
    if (instanceOfSizeable(inputProps)) {
      const { rows = DEFAULT_NUMBER_OF_ROWS } = inputProps;

      return rows;
    } else {
      return DEFAULT_NUMBER_OF_ROWS;
    }
  };

  const getHeight = () => {
    if (instanceOfSizeable(inputProps)) {
      const { height = null } = inputProps;

      return height;
    } else {
      return null;
    }
  };

  const getSX = () => {
    const { sx = null } = inputProps as any;
    return sx;
  };

  const getPlaceholder = () => {
    const { placeholder } = inputProps;

    return placeholder;
  };

  useEffect(() => {
    const { errorSource, source } = inputProps;

    if (formErrors && errorSource && formErrors[errorSource]) {
      const errors: Dictionary<string> = { ...formErrors, [source]: formErrors[errorSource] };

      delete errors[errorSource];

      setFormErrors(errors);
    }
    // Suppressed warnings because we only need to call useEffect callback if formErrors or errorSource are updated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors, inputProps.errorSource]);

  return {
    getValue: getValueHelper,
    getLabel: getLabelHelper,
    getOnChangeCallback,
    getValidationCallback,
    getClearValidationCallback,
    getSource,
    isVisible,
    getIsRequired,
    getErrorMessages,
    getRawOnChangeCallback,
    getLabelHint,
    getCollectionOnChangeCallback,
    getIsDisabled,
    getHelpText,
    initializeValue,
    getTooltip,
    getClassName,
    getNumberOfRows,
    getOnPasteCallback,
    setFormFieldErrors,
    getHeight,
    getPlaceholder,
    getSX,
  };
}

export default useInputHelper;

export const DEFAULT_INPUT_PROP_TYPES = {
  label: oneOfType([string, func]),
  source: string,
  className: string,
  validationCallback: func,
  value: oneOfType([string, number, func]),
  onChangeCallback: func,
  isVisible: oneOfType([func, bool]),
  disabled: oneOfType([func, bool]),
  tooltip: oneOfType([element, func, string]),
};
