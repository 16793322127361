/* istanbul ignore file */
import React from 'react';
import { DefaultInputPropTypes, Dropdown, TextInput } from 'shared/uibuilder/form/input';
import { OPPORTUNITY_STAGES, PROBABILITY_MAP } from 'crm/opportunity/shared/opportunityService';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import MediumFormRow from 'shared/layout/form/MediumFormRow';

const OpportunityStateInput = (props: DefaultInputPropTypes<any>) => {
  const { getValue } = useInputHelper(props);
  const value = getValue();

  return (
    <MediumFormRow>
      <Dropdown
        placeholder="Choose Opportunity State"
        options={Object.entries(OPPORTUNITY_STAGES).map(entry => {
          const [key, label] = entry;

          return {
            value: key,
            label,
          };
        })}
        label="Opportunity State"
        {...props}
      />
      <TextInput source="" value={`${PROBABILITY_MAP[value] || 0}%`} label="Probability" disabled />
    </MediumFormRow>
  );
};

export default OpportunityStateInput;
