/* istanbul ignore file */
import React from 'react';
import FormRow from 'shared/layout/form/FormRow';
import BaseAmountTypeDropdown from 'erp/employee/finance/shared/inputs/BaseAmountTypeDropdown';
import CurrencyDropdown from 'erp/employee/finance/shared/inputs/CurrencyDropdown';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import ProbationPeriodReductionInput from 'erp/employee/finance/shared/inputs/ProbationPeriodReductionInput';
import OfficeDropdown from 'erp/employee/shared/input/OfficeDropdown';
import EmployeeContractTypeDropdown from 'erp/employee/contracts/shared/input/EmployeeContractTypeDropdown';
import Box from 'uibuilder/Box';
import { OFFICE_NAMES } from 'erp/employee/office/officeService';

interface OfferCompensationDetailsInputProps {
  source: string;
  canViewBaseFinanceFields?: boolean;
  canViewCalculatedGrossField?: boolean;
  canViewProbationField?: boolean;
}

const CandidateOfferCompensationDetailsInput = ({
  source,
  canViewBaseFinanceFields = true,
  canViewCalculatedGrossField = true,
  canViewProbationField = true,
  ...props
}: OfferCompensationDetailsInputProps) => {
  return (
    <Box sx={{ width: { xs: '100%', sm: '100%', md: '100%', xl: '100%' } }}>
      <FormRow colXl={4}>
        <BaseAmountTypeDropdown
          source={`${source}.baseAmountType`}
          placeholder=" "
          label="Base Amount Type"
          isVisible={canViewBaseFinanceFields}
        />
        <CurrencyDropdown
          source={`${source}.currency`}
          label="Currency"
          placeholder=" "
          isVisible={canViewBaseFinanceFields}
        />
        <TextInput
          source={`${source}.finalAmountGross`}
          label="Final Amount Gross"
          isVisible={canViewBaseFinanceFields}
        />

        <TextInput
          source={`${source}.probationAmountGross`}
          label="Probation Amount Gross"
          isVisible={canViewProbationField}
        />
        <TextInput
          source={`${source}.calculatedAmountGross`}
          label="Calculated Amount Gross"
          tooltip="Amount calculated by qualification model"
          isVisible={canViewCalculatedGrossField}
        />
        <ProbationPeriodReductionInput
          saveToContext
          source={`${source}.probationPeriodReduction`}
          probationAmountSource={`${source}.probationAmountGross`}
          baseAmountSource={`${source}.finalAmountGross`}
          label="Probation Period Reduction, %"
          isVisible={canViewProbationField}
        />

        <OfficeDropdown
          mapResults={(office: any) => ({
            value: OFFICE_NAMES[office.name].toUpperCase(),
            label: OFFICE_NAMES[office.name],
          })}
          source={`${source}.office`}
          label="Office"
          placeholder=" "
        />
        <EmployeeContractTypeDropdown source={`${source}.contractType`} label="Contract Type" placeholder=" " />
      </FormRow>
    </Box>
  );
};

export default CandidateOfferCompensationDetailsInput;
