import React from 'react';
import useAuthenticatedUserService from 'authentication/authenticatedUserService';
import { DashboardEmployee } from 'erp/employee/dashboard/types';
import useEmployeesDashboardService from 'erp/employee/dashboard/useEmployeesDashboardService';
import useAsyncValue from 'shared/asyncHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { Button } from 'uibuilder/button';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { DEFAULT_ERROR_MESSAGE } from 'shared/uibuilder/form/formHelper';

const AssingEmployeeButton = () => {
  const { addMessage } = useMessageService();
  const { getUserAlias } = useAuthenticatedUserService();
  const { assigneEmployeeAction } = useEmployeesDashboardService();
  const userAlias = useAsyncValue(() => getUserAlias());
  const { data, setData } = useShowContext();
  const { retentionRiskId: riskId } = data.getData() as DashboardEmployee;

  const assignCallback = async () => {
    try {
      await assigneEmployeeAction(riskId, { assigneeAlias: userAlias });
      setData!({
        ...data.getData(),
        retentionRiskAssigneeAlias: userAlias,
      });
    } catch {
      addMessage(new ErrorMessage(DEFAULT_ERROR_MESSAGE));
    }
  };

  return (
    <Button
      onClick={assignCallback}
      sx={{
        padding: '2px 10px',
        fontSize: '9px',
      }}
      color="primary"
    >
      Assign to me
    </Button>
  );
};

export default AssingEmployeeButton;
