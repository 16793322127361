/* istanbul ignore file */
import React from 'react';
import RunMigrationButton from 'financialAnalytic/shared/button/RunMigrationButton';
import useLedgerService, { PERFORM_MIGRATION } from '../../useLedgerService';

const LOCAL_STORAGE_KEY = 'ledger-migration-data';

const RunLedgerMigrationButton = () => {
  const { migrateLedger } = useLedgerService();

  return (
    <RunMigrationButton
      displayMessage="Please, Fill out inputs below"
      localStorageKey={LOCAL_STORAGE_KEY}
      submitMethod={migrateLedger}
      permissionToCheck={PERFORM_MIGRATION}
    >
      Run Migration
    </RunMigrationButton>
  );
};

export default RunLedgerMigrationButton;
