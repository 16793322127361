import EnumDropdown, { EnumDropdownProps } from 'shared/uibuilder/form/input/EnumDropdown';
import React from 'react';
import { REGISTRATION_LOCATION } from '../../types';

const RegistrationLocationDropdown = (props: Omit<EnumDropdownProps, 'options'> & { options?: Dictionary<string> }) => {
  const options = { ...REGISTRATION_LOCATION };

  return <EnumDropdown options={options} placeholder="Select location" {...props} />;
};

export default RegistrationLocationDropdown;
