import React from 'react';
import Box from 'uibuilder/Box';

const SmallPhotoUploaderLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box
      sx={{
        width: '30%',
        minWidth: '100px',
        '& #photosIds': {
          padding: '5px',
        },
        '@media (max-width:575.98px)': {
          minWidth: '50px',
        },
        '& span.help-block': {
          display: 'none',
        },
        '& .upload-block': {
          height: '100%',
          '&:has([data-testid="CloseOutlinedIcon"]) .photo-upload ': {
            display: 'none',
          },
          '&>.photo-upload': {
            width: '100%',
            height: '25px',
            backgroundColor: '#5954A8',
            borderRadius: '3px',
            '& span': {
              color: '#FFFFFF',
              marginTop: 0,
              fontWeight: 500,
            },
          },
        },
        '&>div': {
          marginBottom: 0,
          height: '100%',
        },
        '& .photo-preview': {
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      {children}
    </Box>
  );
};

export default SmallPhotoUploaderLayout;
