/* istanbul ignore file */
import React from 'react';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';

export const CANDIDATE_TIMELINE_RECORDS_ALIAS = {
  NOTE_CREATED: 'NOTE_CREATED',
  RESUME_ADDED: 'RESUME_ADDED',
  APPLICATION_CREATED: 'APPLICATION_CREATED',
  SYSTEM_NOTE: 'SYSTEM_NOTE',
  COMMUNICATION: 'COMMUNICATION',
  OFFER: 'OFFER',
};

export const candidateTimelineRecords = {
  NOTE_CREATED: 'Note',
  RESUME_ADDED: 'Resume',
  APPLICATION_CREATED: 'Applications',
  SYSTEM_NOTE: 'System activities',
  COMMUNICATION: 'Communication',
  OFFER: 'Offer',
};

const CandidateTimelineRecordTypeDropdown = (props: any) => {
  return <EnumDropdown {...props} options={candidateTimelineRecords} />;
};

export default CandidateTimelineRecordTypeDropdown;
