import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const TeamsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="17" height="15" viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.1791 5.59375H15.3189C15.7101 5.59375 16.0271 5.91085 16.0271 6.30195V10.0728C16.0271 11.5102 14.8618 12.6756 13.4244 12.6756H13.4121C11.9746 12.6757 10.8091 11.5106 10.809 10.0731V5.96394C10.8089 5.91532 10.8185 5.86718 10.8371 5.82226C10.8557 5.77734 10.8829 5.73652 10.9173 5.70214C10.9517 5.66776 10.9925 5.6405 11.0374 5.6219C11.0823 5.6033 11.1305 5.59373 11.1791 5.59375Z"
          fill="#5059C9"
        />
        <path
          d="M13.9772 4.84845C14.9035 4.84845 15.6545 4.09751 15.6545 3.17117C15.6545 2.24484 14.9035 1.4939 13.9772 1.4939C13.0509 1.4939 12.2999 2.24484 12.2999 3.17117C12.2999 4.09751 13.0509 4.84845 13.9772 4.84845Z"
          fill="#5059C9"
        />
        <path
          d="M8.75903 4.84838C10.0971 4.84838 11.1818 3.76369 11.1818 2.42566C11.1818 1.08762 10.0971 0.00292969 8.75903 0.00292969C7.421 0.00292969 6.3363 1.08762 6.3363 2.42566C6.3363 3.76369 7.421 4.84838 8.75903 4.84838Z"
          fill="#7B83EB"
        />
        <path
          d="M11.9894 5.59375H5.15594C4.76947 5.60333 4.46377 5.92406 4.47272 6.31053V10.6114C4.41875 12.9305 6.25355 14.8551 8.57266 14.9119C10.8918 14.8551 12.7266 12.9305 12.6727 10.6114V6.31053C12.6816 5.92406 12.3759 5.60333 11.9894 5.59375Z"
          fill="#7B83EB"
        />
        <path
          opacity="0.1"
          d="M8.94536 5.59375V11.6207C8.94447 11.7559 8.90366 11.8878 8.82807 11.9999C8.75247 12.1119 8.64546 12.1992 8.52046 12.2506C8.43907 12.2851 8.35161 12.3028 8.26327 12.3028H4.8006C4.75214 12.1798 4.70744 12.0568 4.67013 11.9301C4.53965 11.5024 4.47309 11.0578 4.4726 10.6106V6.3094C4.46365 5.92356 4.76886 5.60333 5.1547 5.59381L8.94536 5.59375Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M8.57261 5.59375V11.9935C8.57261 12.0818 8.55489 12.1693 8.52045 12.2506C8.46899 12.3756 8.38174 12.4827 8.26967 12.5583C8.1576 12.6339 8.02569 12.6747 7.89051 12.6756H4.97589C4.91253 12.5525 4.85287 12.4295 4.80072 12.3028C4.75061 12.181 4.70706 12.0566 4.67025 11.9301C4.53977 11.5024 4.47321 11.0578 4.47273 10.6106V6.3094C4.46377 5.92356 4.76898 5.60333 5.15482 5.59381L8.57261 5.59375Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M8.57267 5.59375V11.248C8.56985 11.6235 8.26615 11.9273 7.89058 11.9301H4.67025C4.53977 11.5024 4.47321 11.0578 4.47273 10.6106V6.3094C4.46377 5.92356 4.76898 5.60333 5.15482 5.59381L8.57267 5.59375Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M8.19998 5.59375V11.248C8.1971 11.6235 7.89339 11.9273 7.51788 11.9301H4.67025C4.53977 11.5024 4.47321 11.0578 4.47273 10.6106V6.3094C4.46377 5.92356 4.76898 5.60333 5.15482 5.59381L8.19998 5.59375Z"
          fill="black"
        />
        <path
          opacity="0.1"
          d="M8.94543 3.66671V4.84076C8.88208 4.84452 8.82241 4.84828 8.75906 4.84828C8.6957 4.84828 8.63603 4.84452 8.57268 4.84076C8.44688 4.83241 8.32212 4.81246 8.19998 4.78116C7.82789 4.69304 7.48194 4.51809 7.19042 4.27063C6.8989 4.02317 6.67009 3.71022 6.52271 3.35738C6.47125 3.23718 6.43131 3.11237 6.40344 2.98462H8.26334C8.63942 2.98606 8.94399 3.29057 8.94543 3.66671Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M8.57261 4.03945V4.84081C8.44681 4.83246 8.32205 4.81251 8.19992 4.78121C7.82784 4.69307 7.4819 4.51812 7.19039 4.27066C6.89888 4.0232 6.67008 3.71026 6.52271 3.35742H7.89052C8.26666 3.3588 8.57117 3.66338 8.57261 4.03945Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M8.57261 4.03945V4.84081C8.44681 4.83246 8.32205 4.81251 8.19992 4.78121C7.82784 4.69307 7.4819 4.51812 7.19039 4.27066C6.89888 4.0232 6.67008 3.71026 6.52271 3.35742H7.89052C8.26666 3.3588 8.57117 3.66338 8.57261 4.03945Z"
          fill="black"
        />
        <path
          opacity="0.2"
          d="M8.19998 4.03951V4.78127C7.82788 4.69314 7.48193 4.51818 7.19041 4.27071C6.89889 4.02324 6.67008 3.71028 6.52271 3.35742H7.51789C7.89403 3.35886 8.19854 3.66344 8.19998 4.03951Z"
          fill="black"
        />
        <path
          d="M0.683219 3.35748H7.51667C7.89405 3.35748 8.19995 3.66338 8.19995 4.0407V10.8742C8.19995 11.2515 7.89405 11.5574 7.51673 11.5574H0.683219C0.305893 11.5574 0 11.2515 0 10.8742V4.04077C0 3.66331 0.305893 3.35748 0.683219 3.35748Z"
          fill="url(#paint0_linear_173_1526)"
        />
        <path d="M5.89803 5.95841H4.53197V9.67821H3.66168V5.95841H2.302V5.23682H5.89803V5.95841Z" fill="white" />
        <defs>
          <linearGradient
            id="paint0_linear_173_1526"
            x1="142.449"
            y1="-50.0246"
            x2="677.551"
            y2="876.737"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#5A62C3" />
            <stop offset="0.5" stopColor="#4D55BD" />
            <stop offset="1" stopColor="#3940AB" />
          </linearGradient>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default TeamsIcon;
