/* istanbul ignore file */
import contactValidation from 'crm/contact/createupdate/contactValidation';
import useTimelineService from 'shared/crud/timelineService';
import useCrudService from 'shared/crud';
import { useKernelApi } from 'api';
import contactFilterValidation from 'crm/contact/list/contactFilterValidation';
import { ResourceId } from 'shared/crud/baseCrudService';

export const RESOURCE_URL = '/crm/contacts';

export const CREATE_CONTACT = 'CREATE_ACCOUNT';
export const UPDATE_CONTACT_FROM_LIST_PAGE = 'CREATE_ACCOUNT';
export const READ_CONTACTS_LIST = 'READ_CONTACTS_LIST';
export const READ_FULL_CONTACTS_LIST = 'READ_FULL_CONTACTS_LIST';

/**
 * Contact service.
 *
 * @returns {{getById: (function(*=): *), deleteById: ((function(*=): *)|Promise<any>), create: (function(*=): *), update: (function(*=, *=): *)}}
 */

const useContactService = () => {
  const { sendPatchRequest } = useKernelApi();

  const mapContact = (contact: { emails?: never[] | undefined }) => {
    const { emails = [] } = contact;
    return {
      ...contact,
      emails: emails.filter(e => e),
    };
  };

  const baseCrudRequests = useCrudService({
    singleResourceUrl: `${RESOURCE_URL}/:id`,
    listResourceUrl: RESOURCE_URL,
    apiService: useKernelApi,
  });

  const getValidationSchema = () => Promise.resolve(contactValidation);

  const { create, update } = baseCrudRequests;

  const {
    search,
    create: createTimelineItem,
    getTimelinePageById,
  } = useTimelineService({
    parentEntityUrl: `${RESOURCE_URL}/:id`,
    apiService: useKernelApi,
  });

  const pinContactTimeline = async (contactId: ResourceId, timelineId: ResourceId, pinStatus: boolean) => {
    const request = await sendPatchRequest(`${RESOURCE_URL}/${contactId}/timeline/${timelineId}`, {
      isPinned: pinStatus,
    });

    return request.json();
  };

  const searchTimelineItems = async (contractId: StringOrNumber, requestData: any) => {
    const pinRespoce = await search(contractId, {
      ...requestData,
      filter: {
        ...requestData?.filter,
        isPinned: {
          eq: true,
        },
      },
    });

    const unPinRespoce = await search(contractId, {
      ...requestData,
      filter: {
        ...requestData?.filter,
        isPinned: {
          eq: false,
        },
      },
    });

    return {
      ...unPinRespoce,
      result: [...pinRespoce.result, ...unPinRespoce.result],
    };
  };

  const getFilterValidationSchema = () => Promise.resolve(contactFilterValidation);

  return {
    getValidationSchema,
    ...baseCrudRequests,
    create: async (contact: any) => {
      return create(mapContact(contact));
    },
    update: async (id: StringOrNumber, contact: any) => {
      return update(id, mapContact(contact));
    },
    searchTimelineItems,
    createTimelineItem,
    getTimelinePageById,
    getFilterValidationSchema,
    pinContactTimeline,
  };
};

export default useContactService;
