import React from 'react';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import PhoneField from 'shared/uibuilder/field/PhoneField';
import SearchResultField from 'shared/uibuilder/field/SearchResultField';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { FieldProps } from 'shared/uibuilder/field';

const PhoneFieldLayout = ({ phone, phoneExtension, label }: FieldProps) => {
  const { BaseFieldLayout } = useUiTheme();
  const { search } = useListContext();

  const displayValue = phone ? (
    <>
      <a href={`tel:${phone}`}>
        <SearchResultField value={search ? phone.replace(/[ +)(-]/g, '') : phone} />
      </a>
      {phoneExtension && <span className="extension">ext {phoneExtension}</span>}
    </>
  ) : null;

  return <BaseFieldLayout value={displayValue} label={label} />;
};

const PhoneSearchField = (props: FieldProps) => {
  return (
    <UiThemeOverrider
      overriddenLayouts={{
        PhoneFieldLayout,
      }}
    >
      <PhoneField {...props} />
    </UiThemeOverrider>
  );
};

export default PhoneSearchField;
