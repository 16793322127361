import React from 'react';
import { Routes, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import GlobalProtectedRoute from 'shared/authorization/GlobalProtectedRoute';
import { READ_VACANCIES_LIST, READ_VACANCY, VACANCY_PATH } from 'erp/recruitment/vacancy/shared/vacancyService';
import VacanciesList from 'erp/recruitment/vacancy/List';
import VacancyContext from 'erp/recruitment/vacancy/VacancyContext';
import ViewVacancy from 'erp/recruitment/vacancy/Show';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

export const useVacancyRoute = () => {
  const routers = useRoute({ listRoute: VACANCY_PATH });

  return {
    ...routers,
    copyEntityRoute: `${routers.singleEntityRoute}/copy`,
  };
};

export const useVacancyUrl = () => {
  const { getSingleEntityUrl, ...restUrls } = useEntityUrl({
    baseLink: VACANCY_PATH,
  });

  const getCopyEntityUrl = (id: StringOrNumber): string => `${getSingleEntityUrl(id)}/copy`;
  const getHiringRequestsUrl = (id: StringOrNumber): string => `${getSingleEntityUrl(id)}/requests`;

  return {
    ...restUrls,
    getSingleEntityUrl,
    getCopyEntityUrl,
    getHiringRequestsUrl,
  };
};

export const useVacancyId = () => {
  const { id } = useParams<Dictionary<string>>();
  return id!;
};

const VacancyRouter = () => {
  const { listRoute, singleEntityRoute } = useVacancyRoute();

  return (
    <ProtectedRouter basePath={VACANCY_PATH}>
      <Routes>
        <Route
          path={listRoute}
          element={
            <GlobalProtectedRoute globalPermissionToCheck={READ_VACANCIES_LIST}>
              <VacanciesList />
            </GlobalProtectedRoute>
          }
        />

        <Route
          path={singleEntityRoute}
          element={
            <VacancyContext permissionToCheck={READ_VACANCY}>
              <ViewVacancy />
            </VacancyContext>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

VacancyRouter.getRouterPath = () => `${VACANCY_PATH}/*`;

export default VacancyRouter;
