import React, { useState } from 'react';
import classnames from 'classnames';
import BaseCollapse from '@mui/material/Collapse';
import './Collapse.scss';

interface CollapseProps {
  header: React.ReactNode;
  children: React.ReactNode;
  isExpanded?: boolean;
  toggle?: () => void;
  customWrapperClassName?: string;
  customHeaderWrapperClassName?: string;
  unmountOnExit?: boolean;
}

const Collapse = ({
  header,
  children,
  isExpanded,
  toggle: baseToggle,
  customWrapperClassName = '',
  unmountOnExit = false,
}: CollapseProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const isContentShown = isExpanded || isOpen;

  const toggle = (event: React.MouseEvent<HTMLElement>) => {
    (event.target as HTMLInputElement).blur();

    if (baseToggle) {
      baseToggle();
    } else {
      setIsOpen(!isOpen);
    }
  };

  return (
    <div
      className={classnames('collapse-wrapper', customWrapperClassName, { 'collapse-wrapper--open': isContentShown })}
    >
      <div
        role="button"
        tabIndex={0}
        onKeyPress={() => {}}
        className={classnames('collapse-header-wrapper', {
          'collapse-header-wrapper--open': isContentShown,
        })}
        onClick={toggle}
      >
        {header}
      </div>
      <BaseCollapse in={isContentShown} unmountOnExit={unmountOnExit}>
        {children}
      </BaseCollapse>
    </div>
  );
};

export default Collapse;
