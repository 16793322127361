import { Chip } from '@mui/material';
import React from 'react';
import { CANDIDATE_TIMELINE_OFFER_STATUSES } from 'erp/candidate/Timeline/constants';

type StatusType = keyof typeof CANDIDATE_TIMELINE_OFFER_STATUSES | null;

interface StatusChipProps {
  status: StatusType;
}

const STATUS_CONFIG: Record<string, { label: string; color: string }> = {
  [CANDIDATE_TIMELINE_OFFER_STATUSES.DRAFT]: { label: 'Draft', color: 'var(--mui-palette-neutral-40)' },
  [CANDIDATE_TIMELINE_OFFER_STATUSES.FINALIZED]: { label: 'Finalized', color: 'var(--mui-palette-info-main)' },
  [CANDIDATE_TIMELINE_OFFER_STATUSES.SENT]: { label: 'Sent', color: 'var(--mui-palette-primary-main)' },
  [CANDIDATE_TIMELINE_OFFER_STATUSES.CANCELED]: { label: 'Cancelled', color: 'var(--mui-palette-neutral-40)' },
  [CANDIDATE_TIMELINE_OFFER_STATUSES.ACCEPTED]: { label: 'Accepted', color: 'var(--mui-palette-success-main)' },
  [CANDIDATE_TIMELINE_OFFER_STATUSES.REJECTED]: { label: 'Rejected', color: 'var(--mui-palette-neutral-40)' },
  null: { label: 'Deleted', color: 'var(--mui-palette-neutral-60)' },
};

const commonChipStyles = {
  color: 'white',
  pl: 1,
  pr: 1,
  borderRadius: '3px',
  fontSize: '10px',
};

const StatusChip: React.FC<StatusChipProps> = ({ status }) => {
  const config = STATUS_CONFIG[status as string];

  if (!config) return null;

  return (
    <Chip
      sx={{
        ...commonChipStyles,
        backgroundColor: config.color,
      }}
      size="small"
      label={config.label}
    />
  );
};

export default StatusChip;
