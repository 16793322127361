/* istanbul ignore file */
import React from 'react';
import { InputListProps } from 'shared/uibuilder/form/input/InputList/InputList';
import CandidateInputList from './CandidateInputList';

const CandidateOfferListInput = ({ inputTemplate, ...props }: InputListProps) => {
  return <CandidateInputList {...props} inputTemplate={inputTemplate} defaultValue={{}} />;
};

export default CandidateOfferListInput;
