import React, { useState } from 'react';
import { IconButton } from 'uibuilder/button';
import InstantFeedbackModalForm from './InstantFeedbackForm';
import useAuthorization from '../shared/authorization/authorizationService';
import FeedbackIcon from 'uibuilder/AppIcon/Icons/FeedbackIcon';
import Tooltip from 'uibuilder/Tooltip';

const InstantFeedbackButton = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const { isGranted } = useAuthorization();
  const hasPermissions = isGranted('EMPLOYEE_FEEDBACK_CREATE_INSTANT_FEEDBACK_FE');

  const openFeedbackForm = () => setIsFormOpen(true);

  const closeFeedbackForm = () => setIsFormOpen(false);

  if (!hasPermissions) {
    return null;
  }

  return (
    <>
      <IconButton onClick={openFeedbackForm}>
        <Tooltip title="Leave feedback for colleague">
          <div>
            <FeedbackIcon />
          </div>
        </Tooltip>
      </IconButton>
      {isFormOpen && <InstantFeedbackModalForm closeFeedbackForm={closeFeedbackForm} />}
    </>
  );
};

export default InstantFeedbackButton;
