import { READ_CANDIDATE_NOTES_PERMISSION } from 'erp/candidate/Timeline/note/note/candidateNoteService';
import SystemNoteTimelineRecordLayout from './SystemNoteTimelineRecordLayout';
import UpdateNoteForm from '../note/form/UpdateNoteForm';

const useCandidateSystemNoteItem = () => ({
  UpdateForm: UpdateNoteForm,
  isApplicable: (item: any) => item.note && item.type === 'SYSTEM_NOTE',
  source: 'note',
  urlPostfix: 'system-notes',
  RecordLayout: SystemNoteTimelineRecordLayout,
  name: 'SystemNote',
  permissionToCheck: READ_CANDIDATE_NOTES_PERMISSION,
});

export default useCandidateSystemNoteItem;
