/* istanbul ignore file */
import React from 'react';
import useFiltersVisibilityHelper from 'shared/uibuilder/list/filter/layout/useFiltersVisibilityHelper';
import UiThemeOverrider from 'shared/uibuilder/UiThemeOverrider';
import { FiltersLayoutProps } from 'shared/uibuilder/list/filter/layout';
import CandidateDropDownFilterButtonLayout from './CandidateDropdownFilterButtonLayout';

const CandidateListFiltersLayout = ({ children }: FiltersLayoutProps) => {
  const { getFilteredChildren } = useFiltersVisibilityHelper({ children });

  return (
    <UiThemeOverrider
      overriddenLayouts={{
        DropdownLayout: CandidateDropDownFilterButtonLayout,
      }}
    >
      {getFilteredChildren()}
    </UiThemeOverrider>
  );
};

export default CandidateListFiltersLayout;
