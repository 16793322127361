import React, { Children } from 'react';
import { Button } from 'uibuilder/button';
import MuiBox from '@mui/material/Box';
import Box from 'uibuilder/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import SelectedChipFiltersWithAdd from './SelectedChipFiltersWithAdd';
import useChipFilterLayout, { ChipFilter, ChipFilterInputWrapperProps } from './useChipFilterLayout';

const ChipFilterLayout = ({
  children,
  defaultFilter,
  additionalButtons = [],
}: {
  children: React.ReactElement<ChipFilterInputWrapperProps>[] | React.ReactElement<ChipFilterInputWrapperProps>;
  defaultFilter: ChipFilter;
  additionalButtons?: React.ReactNode | React.ReactNode[];
}) => {
  const {
    isMenuOpen,
    filterState,
    appliedFilters,
    handleApply,
    handleRemoveFilter,
    handleStrictToggle,
    setFiltersStatesByKey,
    toggleMenu,
    updateFilters,
    handleClickAwayMenu,
  } = useChipFilterLayout({
    defaultFilter,
  });

  const filtersConfig = Children.map(children, child => {
    if (React.isValidElement(child)) {
      return {
        key: (child.props as any)?.source,
        ...(child.props || {}),
        FilterElement: child,
      };
    }
    return null;
  }).filter(Boolean);

  return (
    <Box sx={{ position: 'relative' }}>
      <SelectedChipFiltersWithAdd
        toggleMenu={toggleMenu}
        appliedFilters={appliedFilters}
        handleRemoveFilter={handleRemoveFilter}
        setFiltersStatesByKey={setFiltersStatesByKey}
        handleStrictToggle={handleStrictToggle}
        filtersConfig={filtersConfig.map(({ FilterElement: _, ...item }) => ({ ...item }))}
        additionalButtons={additionalButtons}
      />

      {isMenuOpen && (
        <ClickAwayListener onClickAway={handleClickAwayMenu}>
          <MuiBox
            sx={{
              width: '400px',
              position: 'absolute',
              top: 50,
              right: 0,
              border: '1px solid #ccc',
              borderRadius: 1,
              backgroundColor: 'white',
              zIndex: 1,
              boxShadow: 2,
              p: 2,
            }}
          >
            {filtersConfig.map(({ key, source, FilterElement, mapValueForChange }: any) => {
              return React.cloneElement(FilterElement, {
                key,
                ...FilterElement.props,
                allSelectedData: filterState,
                value: filterState[source],
                onChangeCallback: (value: any) => {
                  const newValue = mapValueForChange ? mapValueForChange(value, filterState) : value;
                  updateFilters(source, newValue);
                },
              });
            })}

            <Box sx={{ mt: 2 }}>
              <Button data-testid="apply-button" sx={{ padding: 0 }} variant="contained" onClick={handleApply}>
                Apply
              </Button>
            </Box>
          </MuiBox>
        </ClickAwayListener>
      )}
    </Box>
  );
};

export default ChipFilterLayout;
