import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import TextInput from 'shared/uibuilder/form/input/TextInput';
import TrimTextInput from 'shared/uibuilder/form/input/TrimTextInput';
import style from './DualInputField.module.scss';
import PlusIcon from 'uibuilder/AppIcon/Icons/PlusIcon';
import MinusIcon from 'uibuilder/AppIcon/Icons/MinusIcon';
import { FormFieldsData } from 'validation-schema-library/build/validation/types';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';

const DualInputField = ({
  primarySource,
  secondarySource,
  placeholder,
  label,
  trimComponent = false,
}: {
  primarySource: string;
  secondarySource: string;
  placeholder: string;
  label: string;
  trimComponent?: boolean;
}) => {
  const inputHelper = useInputHelper({ source: secondarySource });
  const parentOnChangeCallback = inputHelper.getRawOnChangeCallback();

  const { data } = useShowContext();

  const InputComponent = trimComponent ? TextInput : TrimTextInput;

  const [visibleSecField, setVisibleSecField] = useState<boolean>(Boolean(data.getValueBySource(secondarySource)));

  const hideFieldHandler = async (values: FormFieldsData) => {
    setVisibleSecField(false);
    if (parentOnChangeCallback) {
      parentOnChangeCallback({
        [secondarySource]: null,
      });
    } else {
      // eslint-disable-next-line no-console
      console.error('parentOnChangeCallback is undefined');
    }
  };

  const onChangeCallback = (eventValue: any) => {
    if (parentOnChangeCallback) {
      parentOnChangeCallback({
        [secondarySource]: eventValue[secondarySource],
      });
    } else {
      // eslint-disable-next-line no-console
      console.error('parentOnChangeCallback is undefined');
    }
  };

  return (
    <Stack
      direction="column"
      gap="0.5rem"
      sx={{
        marginTop: '0 !important',
        marginBottom: 0,
        '& div': {
          marginBottom: 0,
        },
      }}
    >
      <Stack direction="row" alignItems="center">
        <InputComponent source={primarySource} label="" placeholder={placeholder} />
        {!visibleSecField && (
          <button className={style.plus_btn} type="button" onClick={() => setVisibleSecField(true)}>
            <PlusIcon />
          </button>
        )}
      </Stack>
      {visibleSecField && (
        <Stack direction="row" alignItems="center">
          <InputComponent
            source={secondarySource}
            label=""
            placeholder={`Additional ${placeholder}`}
            onChangeCallback={onChangeCallback}
            isVisible={visibleSecField}
          />

          <button className={style.minus_btn} type="button" onClick={hideFieldHandler}>
            <MinusIcon />
          </button>
        </Stack>
      )}
    </Stack>
  );
};
export default DualInputField;
