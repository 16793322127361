import React from 'react';
import { Box, Paper, Typography } from '@mui/material';
import DOMPurify from 'dompurify';
import { SxProps } from '@mui/system';
import SanitizedHtml from '../security/SanitizedHtml';

interface TemplatePreviewProps {
  html: string;
  title?: string;
  showTitle?: boolean;
  sanitize?: boolean;
  minHeight?: string | number;
  maxHeight?: string | number;
  paperSx?: SxProps;
  containerSx?: SxProps;
  titleSx?: SxProps;
  sanitizeOptions?: DOMPurify.Config;
  className?: string;
}

const TemplatePreview: React.FC<TemplatePreviewProps> = ({
  html,
  title = 'Preview',
  showTitle = true,
  sanitize = true,
  minHeight = '300px',
  maxHeight = '70vh',
  paperSx = { p: 2, boxShadow: 'none', overflow: 'hidden' },
  containerSx = {},
  titleSx = {
    pb: '20px',
    textAlign: 'end',
    color: 'var(--mui-palette-primary-main)',
  },
  sanitizeOptions = {},
  className,
}) => {
  const contentHtml = sanitize ? DOMPurify.sanitize(html, sanitizeOptions) : html;

  return (
    <Paper sx={{ ...paperSx }} className={className}>
      {showTitle && <Typography sx={{ ...titleSx }}>{title}</Typography>}
      <Box
        sx={{
          p: 2,
          border: '1px solid #ddd',
          borderRadius: 1,
          minHeight,
          maxHeight,
          overflowY: 'auto',
          ...containerSx,
        }}
      >
        <SanitizedHtml html={contentHtml} />
      </Box>
    </Paper>
  );
};

export default TemplatePreview;
