/* istanbul ignore file */
import React, { useEffect, useState } from 'react';
import LoadDropdown from 'shared/uibuilder/form/input/LoadDropdown';
import { ResultResourceData } from 'shared/crud/baseCrudService';
import { useFormContext } from 'shared/uibuilder/form/FormContext';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import { FormFieldsData } from 'validation-schema-library/build/validation/types';

export interface VacancyDropdownProps {
  source: string;
  label?: string;
  showLoader?: boolean;
  placeholder?: string;
  idAsValue?: boolean;
  onChangeCallback?: (values: FormFieldsData) => void;
  value?: string;
}

const VacancyDropdown = (props: VacancyDropdownProps) => {
  const { baseSearchAll: searchAllNewVacancyGroup } = useVacancyGroupService();
  const { data: formData } = useFormContext();
  const [lastOption, setLastOption] = useState();
  const { idAsValue } = props;

  const mapResults = (vacancy: ResultResourceData) => ({
    value: idAsValue ? vacancy.id : vacancy.alias,
    label: `${vacancy.searchString}`,
  });

  const loadVacancies = async () => {
    const newVacancies = await searchAllNewVacancyGroup({
      filter: {
        'specification:vacancyGroupByStatus': {
          in: ['OPEN'],
        },
      },
    });

    return {
      result: [...newVacancies],
    };
  };

  const vacancyAlias = formData?.vacancyAlias;
  useEffect(() => {
    if (vacancyAlias != null) {
      setLastOption(vacancyAlias);
    }
  }, [vacancyAlias]);

  return (
    <LoadDropdown
      loadDataMethod={loadVacancies}
      mapResults={mapResults}
      placeholder="Select the vacancy"
      value={lastOption}
      {...props}
    />
  );
};

export default VacancyDropdown;
