import React from 'react';
import CodeMirror, { EditorView } from '@uiw/react-codemirror';
import { markdown } from '@codemirror/lang-markdown';
import { Extension } from '@codemirror/state';
import { Box } from '@mui/material';
import { SxProps } from '@mui/system';
import Paper from '../../uibuilder/Paper';
import Typography from '../../uibuilder/Typography';
import Tooltip from 'uibuilder/Tooltip';

interface MarkdownEditorProps {
  value: string;
  onChange: (value: string) => void;
  title?: string;
  tooltip?: string | React.ReactNode;
  height?: string;
  showTitle?: boolean;
  extensions?: Extension[];
  editorSx?: React.CSSProperties;
  containerSx?: SxProps;
  paperSx?: SxProps;
  titleSx?: SxProps;
  readOnly?: boolean;
}

const MarkdownEditor: React.FC<MarkdownEditorProps> = ({
  value,
  onChange,
  title = 'Markdown',
  tooltip = '',
  height = '600px',
  showTitle = true,
  extensions = [],
  editorSx = {},
  containerSx = {},
  paperSx = { p: 2, boxShadow: 'none' },
  titleSx = {
    pb: '20px',
    textAlign: 'end',
    color: 'var(--mui-palette-primary-main)',
  },
  readOnly = false,
}) => {
  const defaultExtensions: Extension[] = [markdown(), EditorView.lineWrapping];
  const allExtensions = [...defaultExtensions, ...extensions];

  return (
    <Paper sx={{ ...paperSx }}>
      {showTitle && <Typography sx={{ ...titleSx }}>
        <Tooltip title={tooltip}>
          <span>{title}</span>
        </Tooltip>
      </Typography>}
      <Box
        sx={{
          border: '1px solid #ddd',
          fontSize: '12px',
          borderRadius: '4px',
          overflow: 'hidden',
          ...containerSx,
        }}
      >
        <CodeMirror
          style={{ overflowY: 'auto', ...editorSx }}
          value={value}
          height={height}
          extensions={allExtensions}
          onChange={onChange}
          readOnly={readOnly}
        />
      </Box>
    </Paper>
  );
};

export default MarkdownEditor;
