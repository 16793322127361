/* istanbul ignore file */
import React from 'react';
import Box from 'uibuilder/Box';
import { CreateForm } from 'shared/uibuilder/form';
import { TextInput } from 'shared/uibuilder/form/input';
import CreatePageLayout from 'shared/layout/form/CreatePageLayout';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { usePrivilegedCandidateId } from '../../PrivilegedRouter';
import { useExternalAccountsService } from '../externalAccountsService';
import { useCandidateUrl } from '../../../erp/candidate/CandidateRouter';
import '../AccountsCreationForm.css';
import InputTooltip from 'uibuilder/layout/form/input/InputTooltip';
import useCandidateService from '../../../erp/candidate/candidateService';

const InternAccountsDeprovisionForm = () => {
  const candidateId = usePrivilegedCandidateId();
  const { getById } = useCandidateService();
  const { getProfileEditUrl } = useCandidateUrl();
  const { deprovisionExternalAccounts } = useExternalAccountsService();

  const submitFormFunc = () => {
    return deprovisionExternalAccounts({ candidateId });
  };

  return (
    <CreateForm
      getDataFunc={() => getById(candidateId)}
      submitFormFunc={submitFormFunc}
      afterSubmit={{
        execute: () => {},
      }}
    >
      <CreatePageLayout title="Deprovision: Azure, Redmine and GitLab accounts">
        <FormSection title="Intern account information">
          <FormRow>
            <TextInput label="First name" source="name.firstName" disabled />
            <TextInput label="Last name" source="name.lastName" disabled />
          </FormRow>
          <FormRow>
            <TextInput label="Syberry Email" source="syberryEmail" disabled />
            <TextInput label="Azure ID" source="azureId" disabled />
          </FormRow>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: '20px' }}>
            <a href={getProfileEditUrl(candidateId)} target="_blank" rel="noreferrer">
              Open candidate profile
            </a>
            <InputTooltip
              message="Reload page to get profile changes"
              source=""
              size="small"
              sx={{ marginLeft: '10px' }}
            />
          </Box>
        </FormSection>
      </CreatePageLayout>
    </CreateForm>
  );
};

export default InternAccountsDeprovisionForm;
