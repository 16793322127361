/* istanbul ignore file */
import React from 'react';
import Box from 'uibuilder/Box';
import Container from '@mui/material/Container';
import DefaultFooter from 'layout/Default/DefaultFooter';
import { SxProps } from '@mui/material';

const PublicLayout = ({
  children = '',
  header,
  sx = {},
}: {
  children?: React.ReactNode;
  header?: React.ReactNode;
  sx?: SxProps;
}) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', main: { flexGrow: 1 } }}>
    <main className="main">
      {header && header}
      <Container sx={{ ...sx }} className="animated fadeIn">
        {children}
      </Container>
    </main>
    <DefaultFooter />
  </Box>
);

export default PublicLayout;
