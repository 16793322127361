import { useParams } from 'react-router-dom';
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';

export const INTERVIEW_SCHEDULING_PATH = '/interview-scheduling';

export const useInterviewSchedulingRoute = () => {
  const routers = useRoute({ listRoute: INTERVIEW_SCHEDULING_PATH });

  return {
    ...routers,
  };
};

export const useInterviewSchedulingUrl = () => {
  const urls = useEntityUrl({
    baseLink: INTERVIEW_SCHEDULING_PATH,
  });

  return {
    ...urls,
  };
};

export const useInterviewSchedulingToken = () => {
  const { '*': token } = useParams<Dictionary<string>>();

  return token!;
};
