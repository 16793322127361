import {
  VACANCY_STATUS_OPTIONS,
  EMPLOYEE_CONTRACT_TYPES,
  POSITION_TYPE_OPTIONS,
  COMPENSATION_CURRENCY_TYPES,
} from '../../constants';

export const VACANCY_PROPERTY_LABELS: Record<string, string> = {
  title: 'Job Title',
  status: 'Status',
  isAsap: 'ASAP Hiring',
  targetHiringDate: 'Target Hiring Date',
  hiringMode: 'Hiring Mode',
  responsibilities: 'Responsibilities',
  vacancyGroup: 'Vacancy Group',
  areAllHired: 'All Hired',
  responsiblePerson: 'Responsible Person',
  cancelReason: 'Cancellation Reason',
  salaryFromId: 'Salary From',
  salaryToId: 'Salary To',
  skills: 'Skills',
  createdAt: 'Created date',
  updatedAt: 'Updated date',
};

export const WORKING_CONDITIONS_PROPERTY_LABELS: Record<string, string> = {
  registration: 'Registration Country',
  accommodation: 'Work Location',
  employmentTypes: 'Employment Type',
  contractTypes: 'Contract Type',
  isRelocationAvailable: 'Relocation Available',
  compensation: 'Compensation',
};

export const COMPENSATION_PROPERTY_LABELS: Record<string, string> = {
  salaryFromId: 'Salary From',
  salaryToId: 'Salary To',
  currency: 'Currency',
};

export const POSITION_PROPERTY_LABELS: Record<string, string> = {
  type: 'Position Type',
  status: 'Position Status',
  isCandidateRejected: 'Candidate Rejected',
  forecastDate: 'Forecast Date',
  openDate: 'Open Date',
  closeDate: 'Close Date',
  startWorkingDate: 'Start Working Date',
  id: 'ID',
  applicationId: 'Application ID',
};

export const NOTES_PROPERTY_LABELS: Record<string, string> = {
  description: 'Description',
  attachmentIds: 'Attachments',
};

export const MANDATORY_REQUIREMENTS_PROPERTY_LABELS: Record<string, string> = {
  description: 'Description',
  skills: 'Skills',
  requiredExperienceInYears: 'Required Experience in Years',
  englishLevel: 'English Level',
};

export const OPTIONAL_REQUIREMENTS_PROPERTY_LABELS: Record<string, string> = {
  description: 'Description',
  skills: 'Skills',
};

export const PROJECT_PROPERTY_LABELS: Record<string, string> = {
  name: 'Name',
  description: 'Description',
};

export const DOMAIN_LABELS: Record<string, string> = {
  Vacancy: 'Vacancy',
  WorkConditions: 'Working Conditions',
  Compensation: 'Compensation',
  Position: 'Position',
  MandatoryRequirements: 'Mandatory Requirements',
  OptionalRequirements: 'Optional Requirements',
  Notes: 'Notes',
  Projects: 'Projects',
};

export const FORMAT_ENUM_VALUES: Record<string, string> = {
  // Status
  REQUIRES_REVIEW: VACANCY_STATUS_OPTIONS.REQUIRES_REVIEW,
  OPEN: VACANCY_STATUS_OPTIONS.OPEN,
  CANCELLATION_REQUESTED: VACANCY_STATUS_OPTIONS.CANCELLATION_REQUESTED,
  CANCELLED: VACANCY_STATUS_OPTIONS.CANCELLED,
  CLOSED: VACANCY_STATUS_OPTIONS.CLOSED,
  EXPIRED: VACANCY_STATUS_OPTIONS.EXPIRED,

  // Hiring Mode
  ACTIVE: 'Active',
  BAR_RAISER: 'Bar Raiser',

  // Registration
  BY: 'Belarus',
  PL: 'Poland',
  US: 'United States',

  // Accommodation
  EU: 'European Union',
  ANYWHERE: 'Remote (Anywhere)',

  // Employment Types
  FULL_TIME: 'Full-time',
  PART_TIME: 'Part-time',

  // Contract Types
  EMPLOYMENT_CONTRACT: EMPLOYEE_CONTRACT_TYPES.EMPLOYMENT_CONTRACT,
  B2B_CONTRACT: EMPLOYEE_CONTRACT_TYPES.B2B_CONTRACT,
  CONTRACT_FOR_SERVICES: EMPLOYEE_CONTRACT_TYPES.CONTRACT_FOR_SERVICES,
  INTERNSHIP: 'Internship',

  // Position Types
  REQUIRED: POSITION_TYPE_OPTIONS.REQUIRED,
  ADDITIONAL: POSITION_TYPE_OPTIONS.ADDITIONAL,

  // Position Status
  HIRED: 'Hired',

  // Currency
  USD: COMPENSATION_CURRENCY_TYPES.USD,
  BYN: COMPENSATION_CURRENCY_TYPES.BYN,
  PLN: COMPENSATION_CURRENCY_TYPES.PLN,
  EUR: 'EUR',
};

// Function to format boolean values
export const formatBoolean = (value: boolean | string | null | undefined): string => {
  if (value === null || value === undefined) return 'Not specified';

  if (typeof value === 'string') {
    const lowerValue = value.toLowerCase().trim();
    if (lowerValue === 'true') return 'Yes';
    if (lowerValue === 'false') return 'No';

    try {
      const parsedValue = JSON.parse(lowerValue);
      if (typeof parsedValue === 'boolean') {
        return parsedValue ? 'Yes' : 'No';
      }
    } catch (e) {
      return value;
    }

    return value;
  }

  return value ? 'Yes' : 'No';
};

// Function to format date values
export const formatDate = (dateString: string | null | undefined): string => {
  if (!dateString) return 'Not specified';

  try {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  } catch (e) {
    return dateString;
  }
};

// Function to format array values
export const formatArray = (array: string[] | string): string => {
  if (typeof array === 'string') {
    if (array === '[]' || array === '') return 'None';

    try {
      const parsedArray = JSON.parse(array);
      if (Array.isArray(parsedArray)) {
        if (parsedArray.length === 0) return 'None';
        return parsedArray.map(item => FORMAT_ENUM_VALUES[item] || item).join(', ');
      }
    } catch (e) {
      return array;
    }

    return array;
  }

  if (array.length === 0) return 'None';
  return array.map(item => FORMAT_ENUM_VALUES[item] || item).join(', ');
};

// Function to get a property label based on domain and property name
export const getPropertyLabel = (domain: string, property: string): string => {
  const domainKey = domain.replace(/s$/, '');

  if (/^Position\s*\d*$/.test(domainKey)) {
    return POSITION_PROPERTY_LABELS[property] || property;
  }
  if (/^Working Condition\s*\d*$/.test(domainKey)) {
    return WORKING_CONDITIONS_PROPERTY_LABELS[property] || property;
  }
  switch (domainKey) {
    case 'Vacancy':
      return VACANCY_PROPERTY_LABELS[property] || property;
    case 'Compensation':
      return COMPENSATION_PROPERTY_LABELS[property] || property;
    case 'Note':
      return NOTES_PROPERTY_LABELS[property] || property;
    case 'Mandatory Requirement':
      return MANDATORY_REQUIREMENTS_PROPERTY_LABELS[property] || property;
    case 'Optional Requirement':
      return OPTIONAL_REQUIREMENTS_PROPERTY_LABELS[property] || property;
    case 'Project':
      return PROJECT_PROPERTY_LABELS[property] || property;
    default:
      return property;
  }
};

const isStringAnArray = (str: string) => {
  try {
    const parsed = JSON.parse(str);

    return Array.isArray(parsed);
  } catch (error) {
    return false;
  }
};

export const formatPropertyValue = (domain: string, property: string, value: any): string => {
  if (value === null || value === undefined) return 'Not specified';

  // Boolean properties
  if (
    property === 'isAsap' ||
    property === 'areAllHired' ||
    property === 'isRelocationAvailable' ||
    property === 'isCandidateRejected'
  ) {
    return formatBoolean(value as boolean);
  }

  // Date properties
  if (property.includes('Date')) {
    return formatDate(value as string);
  }

  // Array properties
  if (isStringAnArray(value)) {
    return formatArray(value as string[]);
  }

  if (typeof value === 'string' && value in FORMAT_ENUM_VALUES) {
    return FORMAT_ENUM_VALUES[value];
  }

  return String(value);
};
