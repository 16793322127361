import React from 'react';
import CandidateLinkFieldWithName from 'erp/recruitment/newVacancy/shared/fields/CandidateLinkFieldWithName';
import { FieldProps } from 'shared/uibuilder/field';
import Checkbox from 'shared/uibuilder/form/input/Checkbox';
import Box from 'uibuilder/Box';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import Tooltip from 'uibuilder/Tooltip';

import './candidateLinkFieldWithNameWithCheckbox.scss';

const CandidateLinkFieldWithNameWithCheckbox = (props: FieldProps) => {
  const { data } = useShowContext();
  const { getValue, getRawOnChangeCallback } = useInputHelper<string[]>({ source: 'actualDuplicateIds' });
  const rowData = data.getData();
  const value = getValue() as string[];
  const parentOnChange = getRawOnChangeCallback()!;

  const onChangeCallback = () => {
    if (value.includes(rowData.id)) {
      parentOnChange({
        actualDuplicateIds: value.filter(item => item !== rowData.id),
      });
    } else {
      parentOnChange({
        actualDuplicateIds: [...value, rowData.id],
      });
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',

        span: {
          flex: '0 0 85%',
        },
      }}
    >
      <CandidateLinkFieldWithName {...props} />
      <Tooltip placement="top" title="Duplicate">
        <div>
          <Checkbox
            value={value.includes(rowData.id)}
            onChangeCallback={onChangeCallback}
            className="candidate-field-with-checkbox"
            label=""
            source="test"
          />
        </div>
      </Tooltip>
    </Box>
  );
};

export default CandidateLinkFieldWithNameWithCheckbox;
