import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import SmallCopyIcon from 'uibuilder/AppIcon/Icons/SmallCopyIcon';
import Typography from 'uibuilder/Typography';
import CopyLinkWithInterviewer from './CopyLinkWithInterviewer';
import Box from 'uibuilder/Box';
import useInterviewSchedulerLink from './useInterviewSchedulerLink';
import InputLoader from 'shared/uibuilder/InputLoader';
import { keyframes } from '@mui/material';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import Tooltip from 'uibuilder/Tooltip';

const shake = keyframes`
  0% { transform: translateX(0); }
  25% { transform: translateX(-5px); }
  50% { transform: translateX(5px); }
  75% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
`;

const CopyInterviewSchedulerLink = ({
  currentApplicationId,
  personalEmail,
}: {
  currentApplicationId: number;
  personalEmail?: Nullable<string>;
}) => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [error, setError] = useState(false);
  const { data } = useShowContext();
  const { copyAction, isCopied, getLink, isLoading } = useInterviewSchedulerLink(currentApplicationId);
  const { isCustomPostion } = data.getData();

  const handleClick = async () => {
    if (isCopied || !personalEmail) return;

    if (isCustomPostion) {
      setIsFormOpen(true);
      return;
    }

    try {
      const hash = await getLink();
      await copyAction(String(hash));
      setError(false);
    } catch {
      setError(true);
      setTimeout(() => setError(false), 600);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          color: error ? 'red' : '#4D4C59',
          transition: 'color 0.3s ease-in-out',
          animation: error ? `${shake} 0.3s ease-in-out` : 'none',
        }}
        onClick={handleClick}
      >
        <IconButton
          sx={{
            p: 0,
            mr: '5px',
            width: '22px',
            backgroundColor: error ? 'rgba(255, 0, 0, 0.2)' : 'transparent',
            transition: 'background-color 0.3s ease-in-out',
          }}
        >
          {isLoading ? <InputLoader /> : <SmallCopyIcon sx={{ width: '18px' }} />}
        </IconButton>
        {!personalEmail && <Typography variant="caption">Candidate doesn`t have an email.</Typography>}

        {personalEmail && (
          <Tooltip
            placement="top"
            title={personalEmail && isCustomPostion && 'Select an interviewer for custom specializations.'}
          >
            <div>
              <Typography variant="caption">{isCopied ? 'Copied!' : 'Link to Interview Scheduler'}</Typography>
            </div>
          </Tooltip>
        )}
      </Box>

      {personalEmail && (
        <CopyLinkWithInterviewer
          isFormOpen={isFormOpen}
          setIsFormOpen={setIsFormOpen}
          getLink={getLink}
          copyAction={copyAction}
        />
      )}
    </Box>
  );
};

export default CopyInterviewSchedulerLink;
