/* istanbul ignore file */
const positiveNumberRegEx = /^((?!^0+$|0+[.]0+$)0|[1-9]\d{0,5})(?:[.]\d{1,2})?$/;

export const editOfferOnlyAttachmentValidation = {
  attachmentIds: {
    type: 'array',
    required: false,
    maxSize: 15,
  },
};

export const offerValidationSchema = {
  compensationDetails: {
    type: 'array',
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        baseAmountType: {
          type: 'enum',
          required: false,
          values: ['MONTHLY', 'HOURLY', 'FIXED'],
        },
        currency: {
          type: 'enum',
          required: true,
          values: ['USD', 'EUR', 'BYN', 'PLN'],
        },
        finalAmountGross: {
          type: 'number',
          required: false,
          minValue: {
            value: '$probationAmountGross',
          },
          regex: {
            value: positiveNumberRegEx,
            message: 'Must be a positive number (max 6 digits and 2 decimals)',
          },
        },
        probationAmountGross: {
          type: 'number',
          required: false,
          maxValue: {
            value: '$finalAmountGross',
            message: "Probation period value can't be greater than base amount value",
          },
          regex: {
            value: positiveNumberRegEx,
            message: 'Must be a positive number (max 6 digits and 2 decimals)',
          },
        },
        calculatedAmountGross: {
          type: 'number',
          required: false,
          regex: {
            value: positiveNumberRegEx,
            message: 'Must be a positive number (max 6 digits and 2 decimals)',
          },
        },
        probationPeriodReduction: {
          type: 'number',
          required: false,
          minValue: {
            value: 0,
            message: "Probation period reduction can't be less than 0",
          },
          maxValue: {
            value: 100,
            message: "Probation period reduction can't be greater than 100",
          },
          regex: {
            value: positiveNumberRegEx,
            message: 'Must be a number between 0 and 100',
          },
        },
        office: {
          type: 'string',
          required: true,
          values: ['AUSTIN', 'MINSK', 'KRAKOW'],
        },
        contractType: {
          type: 'enum',
          required: true,
          values: ['EC', 'B2B', 'CFS', 'INTERNSHIP'],
        },
      },
    },
  },
};

export default {
  offerCompensations: {
    type: 'array',
    forEach: {
      type: 'object',
      _divisible: false,
      properties: {
        baseAmountType: {
          type: 'enum',
          required: false,
        },
        currency: {
          type: 'enum',
          required: true,
        },
        grossAmount: {
          type: 'string',
          required: false,
          minValue: {
            value: '$grossProbationAmount',
          },
          regex: {
            value: positiveNumberRegEx,
            message: 'Must be a positive number (max 6 digits and 2 decimals)',
          },
        },
        grossProbationAmount: {
          type: 'string',
          maxValue: {
            value: '$grossAmount',
            message: "Probation period value can't be greater than base amount value",
          },
          regex: {
            value: positiveNumberRegEx,
            message: 'Must be a positive number (max 6 digits and 2 decimals)',
          },
        },
        calculatedGrossAmount: {
          type: 'string',
          regex: {
            value: positiveNumberRegEx,
            message: 'Must be a positive number (max 6 digits and 2 decimals)',
          },
        },
        officeId: {
          type: 'number',
          required: true,
        },
        contractType: {
          type: 'enum',
          required: true,
        },
      },
    },
  },
};
