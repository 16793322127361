import React from 'react';
import { UpdateForm } from 'shared/uibuilder/form';
import useVacancyGroupService from '../../useVacancyGroupService';
import { VACANCIES_MODES } from '../../show/vacancy/VacanciesWrapper';
import UpdateVacancyForm from './UpdateVacancyForm';
import FloatingButtons from 'uibuilder/form/FloatingButtons';
import { VACANCY_STATUS } from 'erp/recruitment/newVacancy/constants';

type UpdateVacancyProps = {
  vacancy: any;
  setTabsState: React.Dispatch<React.SetStateAction<{ currentActiveIndex: number; mode: string }>>;
  setGeneralContextData: any;
};

const UpdateVacancy = ({ vacancy, setTabsState, setGeneralContextData }: UpdateVacancyProps) => {
  const { updateVacancy, getUpdateValidationSchema, getUpdateRequiresReviewValidationSchema } =
    useVacancyGroupService();

  const onCancelAction = () => {
    setTabsState(prev => ({ ...prev, mode: VACANCIES_MODES.VIEW }));
  };

  return (
    <UpdateForm
      submitFormFunc={(_, values) => updateVacancy(vacancy.id, values)}
      getDataFunc={async () => ({
        ...vacancy,
      })}
      getValidationSchemaFunc={
        vacancy.status === VACANCY_STATUS.REQUIRES_REVIEW
          ? getUpdateRequiresReviewValidationSchema
          : getUpdateValidationSchema
      }
      afterSubmit={{
        message: 'You are awesome! The Vacancy has been successfully updated.',
        execute: async () => {
          setTabsState(prev => ({ ...prev, mode: VACANCIES_MODES.VIEW }));
          await setGeneralContextData();
        },
      }}
    >
      <UpdateVacancyForm />

      <FloatingButtons
        isViewInvalidSection={false}
        shouldScrollToErrorAfterLoading={false}
        onCancelAction={onCancelAction}
        isCancelButtonVisible
      />
    </UpdateForm>
  );
};

export default UpdateVacancy;
