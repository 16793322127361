import { Box, List, Pagination, Typography } from '@mui/material';
import { getCandidateFormData } from 'erp/candidate/applications/shared/candidateApplicationFromExtensionService';
import { CandidateData } from 'erp/recruitment/extension/candidateFromExtensionService';
import { CandidateDuplicate } from 'erp/candidate/candidateService';
import React, { useEffect, useMemo, useState } from 'react';
import { Button } from 'uibuilder/button';
import ApplyToVacancyForm from 'erp/recruitment/extension/ApplyToVacancyForm';
import CandidateViewListItem from './CandidateViewListItem';
import CandidateCard from '../Show/CandidateCard';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import CandidateVacancyRejectForm from './CandidateVacancyRejectForm/CandidateVacancyRejectForm';
import ShowSection from 'shared/uibuilder/layout/ShowSection';

interface ISimilarCandidatesProps {
  duplicates: Omit<CandidateDuplicate, 'url'>[];
  isParsingFinished: boolean;
  parsedProfileData: CandidateData;
}

const ITEMS_PER_PAGE = 7;

export const getSimilarCandidates = (candidates: Omit<CandidateDuplicate, 'url'>[]) => {
  const maxTotal = Math.max(...candidates.map(({ score }) => score?.total as number));
  const topCandidates = candidates.filter(({ score }) => score?.total === maxTotal);

  if (topCandidates.length === 1) return topCandidates;

  const filteredByContacts = topCandidates.filter(
    ({ score }) => score?.additionalContacts?.LinkedIn || score?.personalEmail || score?.otherEmail,
  );

  if (filteredByContacts.length === 1) return filteredByContacts;

  return candidates;
};

export const getMatchStyling = (isMatch: boolean) => (isMatch ? { fontWeight: 'bold' } : {});

const CandidateView: React.FC<ISimilarCandidatesProps> = ({
  duplicates,
  isParsingFinished = true,
  parsedProfileData,
}) => {
  const [showVacancyReject, setShowVacancyReject] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [parsedData, setParsedData] = useState<Nullable<CandidateData>>(null);
  const [similarCandidates, setSimilarCandidates] = useState<Omit<CandidateDuplicate, 'url'>[]>([]);
  const startIndex = (page - 1) * ITEMS_PER_PAGE;
  const paginatedData = similarCandidates.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  const togleVacancyReject = () => {
    setShowVacancyReject(prev => !prev);
  };

  useEffect(() => {
    if (isParsingFinished) {
      setParsedData(getCandidateFormData(parsedProfileData));
      const similarCandidatesData = getSimilarCandidates(duplicates);
      setSimilarCandidates(similarCandidatesData as CandidateDuplicate[]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isParsingFinished, duplicates]);

  return (
    <ShowPageLayout>
      <ShowSection sx={{ mb: '10px !important', pb: '0px !important' }}>
        <CandidateCard
          candidatePhoto={parsedData?.photo}
          candidate={parsedData}
          showVacancyReject={showVacancyReject}
          togleVacancyReject={togleVacancyReject}
          showVacancyRejectButton={similarCandidates.length === 1 || similarCandidates.length === 0}
        />
      </ShowSection>

      {useMemo(() => {
        if (showVacancyReject) {
          return (
            <ShowSection>
              <CandidateVacancyRejectForm togleVacancyReject={togleVacancyReject} />
            </ShowSection>
          );
        }

        if (similarCandidates.length === 1) {
          const [similarCandidate] = similarCandidates;
          return (
            <ShowSection>
              <Box sx={{ p: 1, pt: 2 }}>
                <Typography variant="h5" sx={{ fontSize: '18px' }}>
                  Exact Match ({similarCandidates.length})
                </Typography>
                <CandidateViewListItem candidate={similarCandidate.candidate} score={similarCandidate.score} />
                <ApplyToVacancyForm parsedProfileData={parsedProfileData} isParsingFinished={isParsingFinished} />
              </Box>
            </ShowSection>
          );
        }

        if (similarCandidates.length) {
          return (
            <ShowSection>
              <Box sx={{ p: 1, pt: 2 }}>
                <Typography variant="h5" sx={{ fontSize: '18px' }}>
                  Similar Candidates ({duplicates.length})
                </Typography>
                <List>
                  {paginatedData.map(({ candidate, score }) => (
                    <CandidateViewListItem
                      candidate={candidate}
                      score={score}
                      setSimilarCandidates={setSimilarCandidates}
                    />
                  ))}
                </List>
                <Box display="flex" justifyContent="flex-end" mt={2}>
                  <Button
                    link
                    sx={{ fontSize: '12px' }}
                    onClick={() => {
                      setSimilarCandidates([]);
                    }}
                  >
                    Couldn&apos;t find the match?
                  </Button>
                  <Pagination
                    count={Math.ceil(duplicates.length / ITEMS_PER_PAGE)}
                    page={page}
                    onChange={(_, value) => setPage(value)}
                    color="primary"
                    sx={{
                      '& .MuiPaginationItem-root': {
                        fontSize: '12px',
                        height: '24px',
                        minWidth: '24px',
                      },
                    }}
                  />
                </Box>
              </Box>
            </ShowSection>
          );
        }

        return (
          <ShowSection>
            <Box sx={{ p: 1, pt: 2 }}>
              <Typography variant="h5" sx={{ fontSize: '18px' }}>
                No matches found
              </Typography>
              <Typography sx={{ mt: 1, mb: 2 }}>
                The candidate was not found. Please generate a link to the vacancy and send it to the candidate so they
                can apply directly through the link by
              </Typography>
              <ApplyToVacancyForm parsedProfileData={parsedProfileData} isParsingFinished={isParsingFinished} />
            </Box>
          </ShowSection>
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [parsedData, similarCandidates, paginatedData, showVacancyReject])}
    </ShowPageLayout>
  );
};

export default CandidateView;
