export default {
  screeningMaterialsId: {
    type: 'array',
    required: false,
    maxSize: 1,
  },
  resumeId: {
    type: 'array',
    required: true,
    maxSize: 1,
  },
};
