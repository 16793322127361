import useEntityUrl from 'shared/routing/useEntityUrl';
import useRoute from 'shared/routing/useRoute';
import { EMPLOYEES_DASHBOARD_PATH } from './constants';

export const useEmployeesDashboardRoute = () => {
  return useRoute({ listRoute: EMPLOYEES_DASHBOARD_PATH });
};

export const useEmployeesDashboardUrl = () => {
  return useEntityUrl({
    baseLink: EMPLOYEES_DASHBOARD_PATH,
  });
};
