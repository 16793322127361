import React from 'react';
import { applyPhoneSchema, splitPhone } from 'shared/uibuilder/phoneUtils';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { FieldProps, BaseFieldLayoutType, TextField } from 'shared/uibuilder/field';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { EmailFieldLayoutType } from 'shared/uibuilder/field/EmailField';
import style from './ContactField.module.scss';
import MobileIcon from 'uibuilder/AppIcon/Icons/MobileIcon';
import EmailIcon from 'uibuilder/AppIcon/Icons/EmailIcon';
import SupervisorAccountOutlinedIcon from '@mui/icons-material/SupervisorAccountOutlined';
import CandidateLink from 'erp/candidate/shared/CandidateLink';

export interface PhoneFieldLayoutProps {
  label?: string;
  phone?: string;
  phoneExtension?: string;
}

export const CandidatePhoneField = (props: FieldProps) => {
  const { getValue } = useFieldHelper(props);
  const value = splitPhone(getValue());
  const phone = value && applyPhoneSchema(value.phone);
  const extension = value && value.extension;

  const { BaseFieldLayout } = useUiTheme<BaseFieldLayoutType>();

  const displayValue = phone ? (
    <div key={phone} className={`${style.contactField}`}>
      <MobileIcon sx={{ width: '20px', height: '20px' }} />
      <a href={`tel:${phone}`}>{phone}</a>
      {extension && <span className="extension">ext {extension}</span>}
    </div>
  ) : undefined;
  if (typeof displayValue === 'undefined') {
    return '';
  }
  return <BaseFieldLayout value={displayValue} label="" />;
};

export const CandidateEmailField = (props: FieldProps) => {
  const { getValue } = useFieldHelper(props);
  const { EmailFieldLayout } = useUiTheme<EmailFieldLayoutType>();
  const { className } = props;
  const email = getValue();
  if (typeof email === 'undefined') {
    return '';
  }
  return (
    <div key={email} className={`${style.contactField}`}>
      <EmailIcon sx={{ width: '20px', height: '20px' }} />
      <EmailFieldLayout className={className} value={email} />
    </div>
  );
};

export const CandidateDuplicateOfField = ({
  duplicateOfId,
  duplicateFullName,
}: {
  duplicateOfId: StringOrNumber;
  duplicateFullName: string;
}) => {
  return (
    <div key={duplicateOfId} className={`${style.contactField}`}>
      <SupervisorAccountOutlinedIcon fontSize="medium" sx={{ color: '#5954A8' }} />
      <TextField label="" value={<CandidateLink id={duplicateOfId} name={duplicateFullName} />} />
    </div>
  );
};
