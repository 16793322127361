import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { ListResponse, InputData } from '../../list/builder/useListCrud';
import { TimelineItems } from '../timelineHelper';
import { ListContextData, LoadingParams } from '../../list/ListContext';

type TimelineResponse = ListResponse<TimelineItems>;

interface TimelinePagingParams {
  currentPage: number;
  loadData: (page: number) => Promise<TimelineResponse>;
  setData: Dispatch<SetStateAction<InputData>>;
  data: InputData;
  loadingParams: LoadingParams;
}

type TimelinePagingService = (props: TimelinePagingParams) => Partial<ListContextData>;

const useTimelinePaging: TimelinePagingService = ({ currentPage, loadData, setData, data, loadingParams }) => {
  const isFirstRender = useRef(true);
  const [maxLoadedPage, setMaxLoadedPage] = useState(currentPage);
  const [minLoadedPage, setMinLoadedPage] = useState(currentPage);

  const isLastPage = maxLoadedPage >= data.totalPages - 1;
  const isFirstPage = minLoadedPage === 0;

  const [isMoreDataLoading, setMoreDataLoading] = useState(false);
  const [isLoadingNextPage, setIsLoadingNextPage] = useState(false);
  const [isLoadingPrevPage, setIsLoadingPrevPage] = useState(false);

  useEffect(() => {
    setMaxLoadedPage(currentPage);
    setMinLoadedPage(currentPage);
  }, [currentPage]);

  const hasMoreData = () => {
    return !isLastPage && !isMoreDataLoading;
  };

  const hasLessData = () => {
    return !isFirstPage && !isMoreDataLoading;
  };

  const loadMoreData = async (page: number) => {
    setMoreDataLoading(true);
    setIsLoadingNextPage(true);
    const response = await loadData(page);

    setMaxLoadedPage(response.pageNumber);
    setData(prevData => ({
      items: [...prevData.items, ...response.result],
      totalPages: response.totalPages,
      totalElements: response?.totalElements,
    }));
    setMoreDataLoading(false);
    setIsLoadingNextPage(false);
  };

  const loadPreviousPage = async () => {
    if (!isFirstPage && !isMoreDataLoading) {
      setMoreDataLoading(true);
      setIsLoadingPrevPage(true);
      const response = await loadData(minLoadedPage - 1);

      setMinLoadedPage(response.pageNumber);

      setData(prevData => ({
        items: [...response.result, ...prevData.items],
        totalPages: response.totalPages,
        totalElements: response?.totalElements,
      }));
      setMoreDataLoading(false);
      setIsLoadingPrevPage(false);
    }
  };

  const loadNextPage = () => {
    loadMoreData(maxLoadedPage + 1);
  };

  const resetPaging = () => {
    setMaxLoadedPage(0);
    setMinLoadedPage(0);
  };

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    resetPaging();
  }, [loadingParams]);

  return {
    hasMoreData,
    loadMoreData,
    hasLessData,
    loadNextPage,
    loadPreviousPage,
    isLoadingNextPage,
    isLoadingPrevPage,
    resetPaging,
  };
};

export default useTimelinePaging;
