// libs
import React, { useEffect, useState } from 'react';
// components
import FileUploadLayout from 'artifact/shared/input/layout/preview/FileUploadLayout';
import FileUploader from 'artifact/shared/input/ArtifactUploaderInput/FileUploader';
import FilePreviewListLayout from 'artifact/shared/input/layout/list/FilePreviewListLayout';
// services
import useArtifactInputHelper from './artifactsInputHelper';
import useArtifactValidationService from './artifactValidationService';
import { ErrorMessage } from '../../../../uibuilder/layout/form/input/BaseInputErrors';
import { FormFieldsData } from 'validation-schema-library/build/validation/types';

const concatErrors = (errors1: null[], errors2: Nullable<string[]>) => {
  const toArr = (errors: null[] | Nullable<string[]>) => errors || [];
  return [...toArr(errors1), ...toArr(errors2)].filter(v => v);
};

export type ArtifactUploaderInputProps = {
  canAddFromClipboard?: boolean;
  showBtnImage?: boolean;
  uploadBtnText?: string;
  disabled?: boolean;
  uploadFile?: (file: File) => void;
  label?: string;
  source?: string;
  artifactTypeId?: number;
  isVisible?: boolean | ((data: FormFieldsData) => boolean);
  maxElements?: number;
  value?: any;
  isRequired?: boolean;
};

const ArtifactUploaderInput: React.FC<ArtifactUploaderInputProps> = ({
  canAddFromClipboard = false,
  showBtnImage = true,
  uploadBtnText = 'Upload or drag files',
  disabled = false,
  uploadFile,
  ...props
}) => {
  const [errorMsg, setErrorMsg] = useState(null);

  const {
    getLabel,
    getValue,
    getAcceptExtensions,
    addArtifactToContext,
    initializeValue,
    removeArtifactFromContext,
    getTooltip,
    getHelpText,
    getSource,
    getMaxElements,
    getArtifactType,
    getMaxMb,
    getIsRequired,
    getErrorMessages,
    getClearValidationCallback,
    isVisible,
  } = useArtifactInputHelper(props);

  const clearValidation = getClearValidationCallback();

  const artifactsIdsList = (getValue() || []) as any[];

  /*
   * On create page input should have [] value by default.
   */
  useEffect(() => {
    if (!getValue()) {
      initializeValue([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { validate } = useArtifactValidationService({
    previewsCount: artifactsIdsList?.length,
    source: getSource(),
    maxMb: getMaxMb(),
    fileTypes: getAcceptExtensions(),
  });
  const isAllowedToAddMoreFiles =
    (!getMaxElements() && getMaxElements() !== 0) || getMaxElements() - artifactsIdsList.length > 0;

  return (
    <FileUploadLayout
      label={getLabel() as string}
      error={concatErrors([errorMsg], getErrorMessages()) as Nullable<ErrorMessage[]>}
      isRequired={getIsRequired()}
      tooltip={getTooltip() as string}
      source={getSource()}
      helpText={getHelpText()}
      disabled={disabled}
      isVisible={isVisible()}
      previews={
        <FilePreviewListLayout
          artifactsIdsList={artifactsIdsList}
          onRemove={id => removeArtifactFromContext(id)}
          disabled={disabled}
        />
      }
      fileUploder={
        isAllowedToAddMoreFiles ? (
          <FileUploader
            disabled={disabled}
            canAddFromClipboard={canAddFromClipboard}
            artifactTypeId={getArtifactType()}
            uploadCallback={(id, file) => {
              addArtifactToContext(id, file);
              clearValidation();
            }}
            acceptExtensions={getAcceptExtensions()}
            validateFunc={validate}
            validationCallback={error => {
              setErrorMsg(error);
            }}
            source={getSource()}
            uploadFile={uploadFile}
            uploadBtnText={uploadBtnText}
            showBtnImage={showBtnImage}
          />
        ) : null
      }
    />
  );
};

export default ArtifactUploaderInput;
