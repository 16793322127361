import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const DownloadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.99935 8.98067L3.05018 6.03234L3.64018 5.43234L5.58268 7.37484V0.166504H6.41602V7.37484L8.35768 5.43317L8.94852 6.03234L5.99935 8.98067ZM1.51268 11.8332C1.12879 11.8332 0.808515 11.7048 0.551849 11.4482C0.295182 11.1915 0.166571 10.8709 0.166016 10.4865V8.46734H0.999349V10.4865C0.999349 10.6148 1.05268 10.7326 1.15935 10.8398C1.26602 10.9471 1.38352 11.0004 1.51185 10.9998H10.4868C10.6146 10.9998 10.7321 10.9465 10.8393 10.8398C10.9466 10.7332 10.9999 10.6154 10.9993 10.4865V8.46734H11.8327V10.4865C11.8327 10.8704 11.7043 11.1907 11.4477 11.4473C11.191 11.704 10.8705 11.8326 10.486 11.8332H1.51268Z"
          fill="white"
        />
      </svg>
    </SvgIcon>
  );
};

export default DownloadIcon;
