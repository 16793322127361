import React from 'react';
import Box from '@mui/material/Box';
import Typography from 'uibuilder/Typography';
import EmployeeAvatar from './EmployeeAvatar';
import BirthdayInfo from './BirthdayInfo';
import { useEmployeeSummary } from './SummaryService';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import WorkingStatusChip from './WorkingStatusChip';
import { useEmployeeUrl } from '../../EmployeesRouter';
import { Link } from 'react-router-dom';
import FollowUpSummaryButton from '../shared/buttons/FollowUpSummaryButton/FollowUpSummaryButton';

const EmployeeMainInfo = () => {
  const { data: employee } = useShowContext();
  const { getProfileUrl } = useEmployeeUrl();
  const { fullName, employeeAvatar, specialization, birthday } = useEmployeeSummary(employee);
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box sx={{ objectFit: 'cover' }}>
        <EmployeeAvatar avatarId={employeeAvatar} />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', ml: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <Link style={{ fontSize: '16px' }} target="_blank" to={getProfileUrl(employee.alias)}>
            {fullName}
          </Link>
          <Box>
            <WorkingStatusChip />
          </Box>
        </Box>
        <Typography sx={{ fontSize: '12px' }}>{specialization}</Typography>
        <BirthdayInfo dateOfBirth={birthday} />
      </Box>

      <FollowUpSummaryButton />
    </Box>
  );
};

export default EmployeeMainInfo;
