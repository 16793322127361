/* istanbul ignore file */
// libs
import React from 'react';
// components
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { CurrencyInput, DateInput, TextArea, TextInput } from 'shared/uibuilder/form/input';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import BigFormRow from 'shared/layout/form/BigFormRow';
import CrmAttachmentUploaderInput from 'artifact/shared/input/CrmAttachmentUploaderInput';
import OpportunityTypeDropdown from 'crm/opportunity/shared/input/OpportunityTypeDropdown';
import OpportunityStageInput from 'crm/opportunity/shared/input/OpportunityStageInput';
import OpportunityOwnerInput from 'crm/opportunity/shared/input/OpportunityOwnerInput';
import { AccountInput } from 'crm/account';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import FormInputGroup from 'shared/layout/form/FormInputGroup';
// constants
import { CLOSED_LOST, CLOSED_WON, POSTPONED } from 'crm/opportunity/shared/opportunityService';
import ClosedReasonDropdown from 'crm/opportunity/shared/input/ClosedReasonDropdown';
import useFeatureToggle, { Features } from 'featuretoggle';
import BooleanDropdownOptionInput from 'shared/uibuilder/form/input/BooleanDropdownOptionInput';
import RelatedDiscoveryOpportunityInputDropdown from 'crm/opportunity/shared/input/RelatedDiscoveryOpportunityInputDropdown';
import OpportunityChecklist from 'crm/shared/checklist/OpportunityChecklist';

interface OpportunityFormProps {
  isCreateForm?: boolean;
}

const customSxChild = {
  2: {
    flex: {
      xs: `0 0 calc(100% - 16px)`,
      sm: `0 0 calc(100% - 16px)`,
      md: `0 0 calc(100% - 16px)`,
      xl: `0 0 calc(50% - 16px)`,
    },
    maxWidth: {
      xs: `calc(100% - 16px)`,
      sm: `calc(100% - 16px)`,
      md: `calc(100% - 16px)`,
      xl: `calc(50% - 16px)`,
    },
  },
};

const OpportunityForm: React.FC<OpportunityFormProps> = ({ isCreateForm }) => {
  const { isFeatureEnabled } = useFeatureToggle();

  const isClosedReasonInputsVisible = (opportunity: any) =>
    isFeatureEnabled(Features.OPPORTUNITY_CLOSED_REASON) &&
    opportunity?.stateStep &&
    (opportunity?.stateStep?.toString() === CLOSED_WON.toString() ||
      opportunity?.stateStep?.toString() === CLOSED_LOST.toString());

  return (
    <>
      <FormSection title="Opportunity Information">
        <FormRow customSxChild={customSxChild}>
          <TextInput source="name" label="Opportunity Name" isRequired />
          <OpportunityTypeDropdown source="type" isRequired />
          <OpportunityStageInput source="stateStep" disabled={isCreateForm} />
        </FormRow>

        <FormRow>
          <DateInput source="closeDate" />
          <ClearAfterUnmountWrapper isVisible={opportunity => opportunity.stateStep === POSTPONED}>
            <DateInput source="postponedUntil" />
          </ClearAfterUnmountWrapper>
          <BooleanDropdownOptionInput
            source="isContractRenewal"
            placeholder="Please, select!"
            label="Is Contract Renewal?"
          />
          <OpportunityOwnerInput source="ownerId" />
        </FormRow>

        <MediumFormRow>
          <ClearAfterUnmountWrapper isVisible={isClosedReasonInputsVisible}>
            <ClosedReasonDropdown
              type="PRIMARY"
              source="closedReason.primaryReason"
              stageSource="stateStep"
              label="Primary Reason for Closed Status"
              placeholder="Choose Primary Closed Reason"
              tooltip="Select the primary reason of why the Opportunity has been Won (Lost)."
            />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper isVisible={isClosedReasonInputsVisible}>
            <ClosedReasonDropdown
              type="SECONDARY"
              source="closedReason.secondaryReason"
              stageSource="stateStep"
              label="Secondary Reason for Closed Status"
              placeholder="Choose Secondary Closed Reason"
              tooltip="Select the secondary reason of why the Opportunity has been Won (Lost)."
            />
          </ClearAfterUnmountWrapper>
        </MediumFormRow>
        <MediumFormRow>
          <ClearAfterUnmountWrapper isVisible={isClosedReasonInputsVisible}>
            <TextArea
              label="Closed Summary"
              source="closedReason.summary"
              tooltip="Provide a summary of why the Opportunity was Won (Lost). Quote prospect's feedback, when available."
            />
          </ClearAfterUnmountWrapper>
        </MediumFormRow>
      </FormSection>

      <FormSection title="Account">
        <AccountInput source="" idSource="accountId" nameSource="accountName" contactSource="primaryContactId" />
      </FormSection>
      <FormSection
        title="Related Opportunity"
        isVisible={(data: any) =>
          Boolean(isCreateForm) &&
          data.isContractRenewal === 'NO' &&
          data.accountType === 'EXISTING' &&
          !!data.accountId
        }
      >
        <FormRow>
          <ClearAfterUnmountWrapper
            isVisible={data => data.isContractRenewal === 'NO' && data.accountType === 'EXISTING' && !!data.accountId}
          >
            <BooleanDropdownOptionInput
              source="isAfterDiscovery"
              label="Is Development After Discovery"
              placeholder="Select..."
            />
          </ClearAfterUnmountWrapper>
          <ClearAfterUnmountWrapper isVisible={data => data.isAfterDiscovery === 'YES'}>
            <RelatedDiscoveryOpportunityInputDropdown
              accountAliasSource="accountId"
              source="discoveryOpportunityId"
              label="Discovery Opportunity"
              errorSource="discoveryOpportunity"
            />
          </ClearAfterUnmountWrapper>
        </FormRow>
      </FormSection>

      <FormSection title="Financial Information">
        <FormRow>
          <CurrencyInput source="amount" label="Amount (USD)" maxInteger={9} maxDecimal={2} suffix="$" />
        </FormRow>
      </FormSection>

      <FormSection title="Additional Information">
        <FormRow>
          <TextInput source="nextStep" />
          <DateInput source="nextStepDeadline" />
        </FormRow>
      </FormSection>

      <FormSection title="Description information">
        <MediumFormRow>
          <TextArea label="Opportunity Description" source="description" />
        </MediumFormRow>
        <BigFormRow>
          <CrmAttachmentUploaderInput label="Attachments" source="attachmentsIds" />
        </BigFormRow>
      </FormSection>

      <FormInputGroup isVisible={data => data?.type === 'NEW_BUSINESS' && Boolean(isCreateForm)}>
        <OpportunityChecklist source="opportunityChecklist" />
      </FormInputGroup>
    </>
  );
};

export default OpportunityForm;
