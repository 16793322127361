import React from 'react';
import classnames from 'classnames';
import { Breakpoint } from '@mui/system';
import { Button } from 'uibuilder/button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './ModalWindow.scss';
import useModalWindow from './modalWindowHelper';

export interface ModalWindowProps {
  buttons?: React.ReactNode;
  title?: Nullable<React.ReactChild>;
  children: React.ReactNode | React.ReactNode[];
  isOpen: boolean;
  onToggle: () => void;
  hasCancelButton?: boolean;
  modalSize?: Breakpoint | false;
  keyboard?: boolean;
  backdrop?: boolean | 'static';
  className?: string;
  hasCloseIcon?: boolean;
  paperStyles?: Dictionary<any>;
}

const ModalWindow = ({
  children,
  title,
  buttons,
  isOpen,
  onToggle,
  hasCancelButton = true,
  modalSize = 'smallMobile',
  backdrop = true,
  keyboard = true,
  className,
  hasCloseIcon = false,
  paperStyles = {},
}: ModalWindowProps) => {
  const { onModalContentClick, isFocusEnable } = useModalWindow();

  const onClose = (event: object, reason?: string) => {
    if (reason === 'backdropClick' && (!backdrop || backdrop === 'static')) {
      return;
    }

    if (reason === 'escapeKeyDown' && !keyboard) {
      return;
    }

    onToggle();
  };

  return (
    <Dialog
      fullWidth
      PaperProps={{ style: { overflowY: 'visible', ...paperStyles } }}
      className={classnames('modal-window', className)}
      open={isOpen}
      onClose={onClose}
      scroll="body"
      maxWidth={modalSize}
      disableEnforceFocus={isFocusEnable}
    >
      {(title || hasCloseIcon) && (
        <DialogTitle sx={{ minHeight: '59px' }}>
          {title}
          <IconButton className="close-modal-icon" aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      <DialogContent onClick={onModalContentClick} onFocus={onModalContentClick}>
        {children}
      </DialogContent>
      {buttons && (
        <DialogActions>
          {buttons}
          {hasCancelButton ? (
            <Button key="cancelBtn" color="primary" variant="outlined" onClick={onToggle}>
              Cancel
            </Button>
          ) : null}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ModalWindow;
