import React, { useCallback } from 'react';
import {
  SaveGroupButton,
  CloseEditGroupButton,
  EditGroupButton,
} from 'shared/uibuilder/FormShowContexts/buttons/EditSaveModeButtons';
import useAuthorization from 'shared/authorization/authorizationService';
import { RECRUITING_CANDIDATE_UPDATE } from 'erp/candidate/constants';
import { useFormShowContext } from 'shared/uibuilder/FormShowContexts/FormShowContext';
import CandidateTimeline from './CandidateTimelineSection/CandidateTimeline';
import EntityProfileLayout from 'shared/layout/EntityProfile/EntityProfileLayout';
import CandidateProfileShowFields from './shared/CandidateProfileShowFields';
import CandidateProfileEditFields from './shared/CandidateProfileEditFields';

const CandidateProfile = () => {
  const { isGranted } = useAuthorization();
  const { isEditMode } = useFormShowContext();

  const renderActionButtons = useCallback(() => {
    if (!isGranted(RECRUITING_CANDIDATE_UPDATE)) {
      return null;
    }

    return isEditMode ? (
      <>
        <SaveGroupButton />
        <CloseEditGroupButton />
      </>
    ) : (
      <EditGroupButton />
    );
  }, [isGranted, isEditMode]);

  return (
    <EntityProfileLayout
      renderActionButtons={renderActionButtons}
      leftColumnElement={isEditMode ? <CandidateProfileEditFields /> : <CandidateProfileShowFields />}
      rightColumnElement={<CandidateTimeline />}
    />
  );
};

export default CandidateProfile;
