import React, { useState } from 'react';
import { FormFieldsData, FormProvider } from '../form/FormContext';
import useGetData from 'shared/useGetData';
import Loading from '../Loading';
import SmallLoader from '../SmallLoader';
import Prompt from 'uibuilder/prompt/Prompt';
import ControlledShow from '../show/ControlledShow';
import { FormTemplateProps } from '../form/FormTemplate';
import { useBaseValidationMapping } from 'validation-schema-library';
import useForm from '../form/formHelper';
import { FormShowContextProvider } from './FormShowContext';

type FormShowTemplateProps = FormTemplateProps & {
  mapDataForForm?: (showData: FormFieldsData) => FormFieldsData | Promise<FormFieldsData>;
};

const FormShowTemplate = ({
  children,
  initialData = {},
  getValidationSchemaFunc,
  handleErrorsOnSubmit,
  isNeedToHandleErrorsOnSubmit = true,
  fieldsExceptions,
  isCreateRequest = true,
  submitFormFunc,
  afterSubmit,
  getDataFunc = null,
  handleDirty = true,
  errorsMap = {},
  messageContext,
  isSmallLoader,
  mapDataForForm,
}: FormShowTemplateProps) => {
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [data, setData] = useState<FormFieldsData>(initialData);

  const toggleEditMode = () => {
    setEditMode(prev => !prev);
  };

  const baseErrorMap = useBaseValidationMapping();
  const { isDirty, setFormData, resetFormData, ...contextValue } = useForm({
    initialData,
    afterSubmit: {
      ...afterSubmit,
      resetData: newData => newData,
      execute: (response: any) => {
        const { execute } = afterSubmit || {};
        if (execute) {
          execute(response);
        }
        toggleEditMode();
        setData(prev => ({
          ...prev,
          ...response,
        }));
      },
    },
    submitFormFunc,
    handleErrorsOnSubmit,
    isNeedToHandleErrorsOnSubmit,
    getValidationSchemaFunc,
    fieldsExceptions,
    handleDirty,
    isCreateRequest,
    errorsMap: {
      ...baseErrorMap,
      ...errorsMap,
    },
    messageContext,
  });

  const setDataMethod = async (newState: FormFieldsData) => {
    if (newState) {
      const mappedData = mapDataForForm ? await mapDataForForm(newState) : newState;
      setFormData(mappedData);
      setData(newState);
    }
  };

  const toggleEditModeWithResetForm = async () => {
    const mappedData = mapDataForForm ? await mapDataForForm(data) : data;
    resetFormData(mappedData);
    setEditMode(prev => !prev);
  };

  const { loading } = useGetData(getDataFunc, setDataMethod);

  if (loading) {
    return isSmallLoader ? <SmallLoader /> : <Loading />;
  }

  return (
    <FormShowContextProvider
      value={{
        isEditMode,
        toggleEditMode: toggleEditModeWithResetForm,
      }}
    >
      <ControlledShow data={data} setData={setDataMethod}>
        <FormProvider
          value={{
            isDirty,
            setFormData,
            resetFormData,
            ...contextValue,
          }}
        >
          <Prompt when={isDirty} message="Are you sure you want to go away? Unsaved data will be lost." />
          <>{children}</>
        </FormProvider>
      </ControlledShow>
    </FormShowContextProvider>
  );
};

export default FormShowTemplate;
