import { useRecruitmentApi } from 'api';
import useCrudService from 'shared/crud';

const SOURCING_API_PATH = '/candidates/saved-filters';

const useSavedCandidateFilterService = () => {
  const { sendGetRequest } = useRecruitmentApi();

  const { create: baseCreate, deleteById } = useCrudService({
    listResourceUrl: SOURCING_API_PATH,
    singleResourceUrl: `${SOURCING_API_PATH}/:id`,
    apiService: useRecruitmentApi,
  });

  const mapForCreate = ({ searchFields, ...other }: any) => {
    const filters = Object.entries(searchFields).reduce(
      (acc: any, [field, value]: any) => {
        const fieldName =
          {
            level: 'qualificationLevel',
          }[field as string] || field;

        acc[fieldName] = {
          value,
          mode: searchFields.stricts.includes(field) ? 'STRICT' : 'NON_STRICT',
        };
        return acc;
      },
      {
        specialization: '',
      },
    );
    return {
      ...other,
      searchFields: filters,
    };
  };

  const create = async (data: any) => {
    const mapped = mapForCreate(data);
    return baseCreate(mapped);
  };

  const mapToFilter = ({ id, name, searchFields }: any) => {
    const filters = Object.entries(searchFields).reduce(
      (acc: any, [field, value]: any) => {
        if (!value) {
          return acc;
        }

        acc[field] = value.value;
        if (value.mode === 'STRICT') {
          acc.stricts.push(field);
        }
        return acc;
      },
      {
        stricts: [],
        qualificationLevel: [],
        location: [],
        englishLevel: [],
        skills: [],
        tags: [],
      },
    );
    return {
      id,
      name,
      searchFields: filters,
    };
  };

  const getFiltersForGroup = async (groupId: string) => {
    const response = await sendGetRequest(`${SOURCING_API_PATH}?groupId=${groupId}`);
    const parsed = await response.json();
    return parsed.result.map(mapToFilter);
  };

  return {
    getFiltersForGroup,
    create,
    deleteById,
  };
};

export default useSavedCandidateFilterService;
