import React from 'react';
import { IconButton } from 'uibuilder/button';
import RedmineIcon from 'uibuilder/AppIcon/Icons/RedmineIcon';
import TeamsIcon from 'uibuilder/AppIcon/Icons/TeamsIcon';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import { generateLinkByEmail } from 'shared/teamsHelper/useTeamsHelper';
import { copyByNavigator } from 'shared/clipboard';

const LinkIconButtonsEmployeeDashboard = () => {
  const { getValue: getWorkingEmail } = useFieldHelper({ source: 'employeeWorkingEmail' });
  const { getValue: getAssigneeAlias } = useFieldHelper({ source: 'retentionRiskAssigneeAlias' });
  const assignAlias = getAssigneeAlias();
  const workingEmail = getWorkingEmail();

  const handleTeamsButton = () => {
    const teamsLink = generateLinkByEmail(workingEmail);

    window.open(teamsLink, '_blank');
  };

  // TODO redmine ticket
  const handleRedmineButton = () => {
    copyByNavigator(assignAlias);
  };

  return (
    <>
      {workingEmail && (
        <IconButton
          onClick={handleTeamsButton}
          size="small"
          sx={{
            padding: '5px',
            mr: '10px',
          }}
        >
          <TeamsIcon sx={{ fontSize: '13px' }} />
        </IconButton>
      )}

      {false && (
        <IconButton
          onClick={handleRedmineButton}
          size="small"
          sx={{
            padding: '5px',
          }}
        >
          <RedmineIcon sx={{ fontSize: '13px' }} />
        </IconButton>
      )}
    </>
  );
};

export default LinkIconButtonsEmployeeDashboard;
