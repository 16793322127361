import React from 'react';
import Box from 'uibuilder/Box';

import Stack from '@mui/material/Stack';

import styles from './EntityProfileLayout.module.scss';

type EntityProfileLayoutProps = {
  renderActionButtons: () => React.ReactNode;
  rightColumnElement: React.ReactNode;
  leftColumnElement: React.ReactNode;
};

const EntityProfileLayout = ({
  renderActionButtons,
  leftColumnElement,
  rightColumnElement,
}: EntityProfileLayoutProps) => {
  return (
    <Box className={styles.EntityProfile__wrapper}>
      <Box className={styles.EntityProfile__leftColumn}>
        <Box
          sx={{
            position: 'sticky',
            top: '70px',
          }}
        >
          <Box
            sx={{
              margin: '-0.5rem -0.5rem 0.5rem 0.5rem',
              display: 'flex',
              justifyContent: 'flex-end',
              gap: '10px',
            }}
          >
            {renderActionButtons()}
          </Box>

          <Stack>{leftColumnElement}</Stack>
        </Box>
      </Box>
      <Box className={styles.EntityProfile__rightColumn}>{rightColumnElement}</Box>
    </Box>
  );
};

export default EntityProfileLayout;
