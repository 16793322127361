/* istanbul ignore file */
import React from 'react';
import SecurityContextTemplate from 'shared/authorization/SecurityContextTemplate';
import { useRecruitmentApi } from 'api';
import { ResourceId } from 'shared/crud/baseCrudService';

const CandidateDuplicateScanContext = ({
  permissionToCheck,
  children,
}: {
  permissionToCheck: string | string[];
  children: React.ReactNode;
}) => {
  const { sendGetRequest } = useRecruitmentApi();

  const getResourceBasedRecruitingPermissions = async (id: ResourceId) => {
    const response = await sendGetRequest(`/permissions/CandidateDuplicateScanResult/${id}`);
    return response.json();
  };

  const getGlobalRecruitingPermissions = async (): Promise<string[]> => {
    const response = await sendGetRequest(`/permissions`);
    return response.json();
  };

  const getCombinedPermissions = async (id: ResourceId): Promise<string[]> => {
    const globalRecruitingPermissions = await getGlobalRecruitingPermissions();

    if (id) {
      const resourceBasedRecruitingPermissions = await getResourceBasedRecruitingPermissions(id);

      return [...globalRecruitingPermissions, ...resourceBasedRecruitingPermissions];
    }

    return globalRecruitingPermissions;
  };

  return (
    <SecurityContextTemplate
      getPermissionsFunc={getCombinedPermissions}
      permissionToCheck={permissionToCheck}
      contextName="CandidateDuplicateScan"
    >
      {children}
    </SecurityContextTemplate>
  );
};

export default CandidateDuplicateScanContext;
