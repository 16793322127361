import React from 'react';
import BaseInputLayout, {
  COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  getBaseInputLayoutProps,
  getCommonInputProps,
} from './BaseInputLayout';
import { TextAreaLayoutProps, TextAreaLayoutType } from 'shared/uibuilder/form/input/TextArea';

const DefaultTextAreaLayout: TextAreaLayoutType = (props: TextAreaLayoutProps) => {
  const allProps = { ...COMMON_INPUT_LAYOUT_DEFAULT_PROPS, ...props };
  return (
    <BaseInputLayout {...getBaseInputLayoutProps(allProps)}>
      <textarea style={{ resize: 'vertical' }} {...getCommonInputProps(allProps)} />
    </BaseInputLayout>
  );
};

export default DefaultTextAreaLayout;
