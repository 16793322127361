import { Stack } from '@mui/material';
import React from 'react';

const AdditionalContactWrapperProfile = ({ children }: { children: React.ReactNode }) => {
  return (
    <Stack
      direction="column"
      gap="0.5rem"
      sx={{
        marginTop: '0px !important',
        width: '100%',
        '&>div': {
          marginBottom: 0,
        },
        '& .additional-contacts-input>div>div': {
          display: 'flex',
          flexDirection: 'column',
          gap: '0.5rem',
        },
        '& .label__wrapper': {
          display: 'none',
        },
        '& div.removable-input': {
          marginBottom: 0,
          marginTop: 0,
          '&>div': {
            maxWidth: '100%',
            marginLeft: 0,
            width: '100%',
            '&>div:first-child': {
              paddingLeft: 0,
            },
            '&>div:last-child': {
              paddingLeft: '0.5rem',
            },
          },
        },
        '& div.form-group': {
          marginBottom: 0,
        },
        '& button.input-list-rm-btn': {
          margin: '8px 0 0 10px',
        },
        '@media (max-width: 989.98px)': {
          '& .removable-input>div>div': {
            paddingLeft: 0,
          },
        },
        '@media (max-width: 767.98px)': {},
      }}
    >
      {children}
    </Stack>
  );
};

export default AdditionalContactWrapperProfile;
