import React, { useEffect } from 'react';
import { Button } from 'uibuilder/button';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { InputListLayoutProps, InputListLayoutType } from 'shared/uibuilder/form/input/InputList/InputList';
import BaseInputLayout, {
  COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
  getBaseInputLayoutProps,
} from 'uibuilder/layout/form/input/BaseInputLayout';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { getNotNullIndex } from 'uibuilder/layout/form/input/InputListLayout';

const CandidateInputListLayout: InputListLayoutType = ({
  fields,
  addMethod,
  removeMethod,
  inputTemplate: InputComponent,
  onChangeCallback,
  addText = '',
  deleteText = '',
  maxFields,
  minFields,
  source,
  className,
  simpleLayout = true,
  inputProps,
  value,
  disabled,
  disabledRemove,
  isAddable = true,
  isDeletable = true,
  ...otherProps
}: InputListLayoutProps) => {
  const props = {
    ...COMMON_INPUT_LAYOUT_DEFAULT_PROPS,
    ...otherProps,
  };

  useEffect(() => {
    if (fields.length === 0 && addMethod) {
      addMethod();
    }
  }, [fields.length, addMethod]);

  const removeBtn = (index: number, fieldValue: any) => {
    return (
      <Button
        id={`remove-${index}`}
        size="small"
        color="primary"
        variant="text"
        onClick={() => removeMethod(index)}
        disabled={fields.length <= minFields || disabled || (disabledRemove && disabledRemove(fieldValue))}
        sx={{ padding: '4px', borderRadius: '50%', minWidth: 0 }}
      >
        <CloseIcon />
      </Button>
    );
  };

  return (
    <BaseInputLayout {...getBaseInputLayoutProps({ ...props, source, disabled })}>
      <Box>
        {fields.map((fieldValue, index) => (
          <Box pb={4}>
            <Box display="flex" sx={{ flexDirection: 'row', justifyContent: 'space-between', mt: 1, mb: 3 }}>
              <Typography variant="h4">Compensation details {index !== 0 ? index + 1 : ''}</Typography>
              {isDeletable && (index === 0 ? '' : removeBtn(index, fieldValue))}
            </Box>
            {/* eslint-disable-next-line react/no-array-index-key */}
            <div key={`input-${index}`}>
              <InputComponent
                {...inputProps}
                label=""
                isLast={fields.length === index + 1}
                source={fieldValue && `${source}.${getNotNullIndex(fields, index)}`}
                value={fieldValue}
                onChangeCallback={(newValue: FormFieldsData) => {
                  onChangeCallback(index, newValue);
                }}
                className={className || ''}
              />
              {simpleLayout && isDeletable}
            </div>
          </Box>
        ))}
      </Box>
    </BaseInputLayout>
  );
};

export default CandidateInputListLayout;
