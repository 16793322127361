import React from 'react';
import { CreateForm } from 'shared/uibuilder/form';
import useVacancyService from 'erp/recruitment/vacancy/shared/vacancyService';
import CreateHiringForm from 'erp/recruitment/hiring/createupdate/CreateHiringForm';
import OneStopPageLayout from 'uibuilder/layout/form/OneStopPageLayout';
import { useVacancyGroupUrl } from 'erp/recruitment/newVacancy/routing';

const OneStopHiringForm = () => {
  const { newDashboard } = useVacancyGroupUrl();
  const { createOneStop, getValidationSchemaOneStop, getInitialData } = useVacancyService();

  return (
    <CreateForm
      submitFormFunc={createOneStop}
      afterSubmit={{
        redirect: newDashboard,
        message: 'You are awesome! The Hiring request has been successfully created.',
      }}
      getValidationSchemaFunc={getValidationSchemaOneStop}
      getDataFunc={() => getInitialData(true)}
    >
      <OneStopPageLayout>
        <CreateHiringForm />
      </OneStopPageLayout>
    </CreateForm>
  );
};

export default OneStopHiringForm;
