import React from 'react';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { FormFieldsData, useFormContext } from 'shared/uibuilder/form/FormContext';
import Button from 'uibuilder/button/Button';
import { CreateForm } from 'shared/uibuilder/form';
import Message from 'shared/message/Message';
import EmployeesInput from 'financialAnalytic/corporateEvents/shared/input/EmployeesInput';

const Form = ({ closeModal }: { closeModal: () => void }) => {
  const { submitForm, isSubmitEnabled, isLoadingArtifact } = useFormContext();

  return (
    <>
      <EmployeesInput label="Interviewers" source="interviewers" />

      <div className="btn-area">
        <Button
          data-testid="submit-button"
          type="submit"
          onClick={submitForm}
          disabled={!isSubmitEnabled || isLoadingArtifact}
        >
          Copy link
        </Button>
        <Button data-testid="cancel-btn" outline type="button" onClick={closeModal}>
          Cancel
        </Button>
      </div>
    </>
  );
};

const MESSAGE_CONTEXT = 'InterviewerLinkForm';

const InterviewerLinkForm = ({
  closeModal,
  modalOpen,
  copyAction,
  getLink,
}: {
  closeModal: () => void;
  modalOpen: boolean;
  copyAction: (str: string) => void;
  getLink: (values: FormFieldsData) => Promise<object | void>;
}) => {
  return (
    <ModalWindow isOpen={modalOpen} hasCancelButton={false} onToggle={closeModal}>
      <CreateForm
        submitFormFunc={getLink}
        afterSubmit={{
          execute: response => {
            copyAction(response);
            closeModal();
          },
        }}
        messageContext={MESSAGE_CONTEXT}
        getValidationSchemaFunc={() =>
          Promise.resolve({
            interviewers: {
              type: 'array',
              required: true,
              forEach: {
                type: 'object',
                properties: {
                  value: {
                    type: 'string',
                    required: true,
                  },
                },
              },
            },
          })
        }
      >
        <Message messageContext={MESSAGE_CONTEXT} />
        <Form closeModal={closeModal} />
      </CreateForm>
    </ModalWindow>
  );
};

export default InterviewerLinkForm;
