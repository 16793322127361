import React from 'react';
import { Chip } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import AddIcon from '@mui/icons-material/Add';
import Typography from 'uibuilder/Typography';
import { IconButton } from 'uibuilder/button';
import Box from 'uibuilder/Box';
import { ChipFilter } from './useChipFilterLayout';

const FilterChip = ({
  filter,
  stricts = [],
  handleStrictToggle,
}: {
  filter: {
    id: string;
    label: string;
    remove: () => void;
  };
  stricts: string[];
  handleStrictToggle: (filterId: string) => void;
}) => (
  <Chip
    key={filter.label}
    label={
      <>
        {stricts.includes(filter.id) ? (
          <CircleIcon sx={{ fontSize: '10px', mr: '5px' }} />
        ) : (
          <CircleOutlinedIcon sx={{ fontSize: '10px', mr: '5px' }} />
        )}
        <Typography variant="caption">{filter.label}</Typography>
      </>
    }
    onClick={() => handleStrictToggle(filter.id)}
    onDelete={filter.remove}
    color="primary"
    sx={{
      borderRadius: '5px',
      marginRight: 1,
      '.MuiChip-deleteIcon': { fontSize: '13px' },
      '.MuiChip-label': { display: 'flex', alignItems: 'center', padding: '0 10px 0 6px' },
    }}
  />
);

const SelectedChipFiltersWithAdd = ({
  appliedFilters,
  handleRemoveFilter,
  toggleMenu,
  handleStrictToggle,
  setFiltersStatesByKey,
  filtersConfig,
  additionalButtons = [],
}: {
  appliedFilters: ChipFilter;
  handleRemoveFilter: (key: any, item: string) => void;
  setFiltersStatesByKey: (key: any, item: string[]) => void;
  toggleMenu: () => void;
  handleStrictToggle: (filterId: string) => void;
  filtersConfig: any[];
  additionalButtons?: React.ReactNode | React.ReactNode[]
}) => {
  const renderSelectedFilters = () => {
    const selectedFilters = filtersConfig
      .flatMap(({ mapViewChipText, isSingleChip, ...filter }) => {
        if (isSingleChip && appliedFilters[filter.key]?.length) {
          return {
            id: filter.key,
            label: mapViewChipText
              ? mapViewChipText(appliedFilters[filter.key])
              : appliedFilters[filter.key].join(', '),
            remove: () => setFiltersStatesByKey(filter.key, []),
          };
        }

        return appliedFilters[filter.key]?.sort()?.map(item => ({
          id: filter.key,
          label: item,
          remove: () => handleRemoveFilter(filter.key, item),
        }));
      })
      .filter(Boolean);

    if (selectedFilters.length === 0) {
      return (
        <Typography color="textSecondary" variant="caption">
          Select Filters
        </Typography>
      );
    }

    return selectedFilters.map(filter => (
      <FilterChip
        key={`${filter.id}-${filter.label}`}
        filter={filter}
        stricts={appliedFilters.stricts}
        handleStrictToggle={handleStrictToggle}
      />
    ));
  };

  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        borderRadius: 1,
        padding: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Box>{renderSelectedFilters()}</Box>
      <Box>
        <IconButton sx={{ p: 0 }} onClick={toggleMenu}>
          <AddIcon />
        </IconButton>
        {additionalButtons}
      </Box>
    </Box>
  );
};

export default SelectedChipFiltersWithAdd;
