import React, { useEffect, useState } from 'react';
import moment from 'moment';
import useDateHelper, {
  COMMON_DATE_INPUT_DEFAULT_PROPS,
  CommonDateInputProps,
} from 'shared/uibuilder/form/input/dateHelper';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import { DEFAULT_TIMEZONE } from 'shared/uibuilder/dateService/constants';
import InputLoader from 'shared/uibuilder/InputLoader';
import { CommonInputLayoutProps } from 'shared/uibuilder/form/input';
import { flushSync } from 'react-dom';

export interface DateInputLayoutProps extends CommonInputLayoutProps<React.ChangeEvent<HTMLInputElement>> {
  showCalendar?: boolean;
  toggleButton?: Nullable<string | React.ReactElement>;
  validRangeInclusivity?: '[]' | '()' | '[)' | '(]';
  timezone?: string;
  minDate?: moment.MomentInput;
  maxDate?: moment.MomentInput;
  dateFormat?: string;
  displayTimeZone: string;
  timeFormat?: Nullable<string>;
  afterBlurCallback?: () => void;
  readOnly?: boolean;
  availableDates?: string[];
}

export type DateInputLayoutType = ReactComponent<DateInputLayoutProps>;

const DateInput = ({
  disabled = COMMON_DATE_INPUT_DEFAULT_PROPS.disabled,
  dateFormat = COMMON_DATE_INPUT_DEFAULT_PROPS.dateFormat,
  minDate = COMMON_DATE_INPUT_DEFAULT_PROPS.minDate,
  maxDate = COMMON_DATE_INPUT_DEFAULT_PROPS.maxDate,
  timeFormat = COMMON_DATE_INPUT_DEFAULT_PROPS.timeFormat,
  ...other
}: CommonDateInputProps) => {
  const props = {
    disabled,
    dateFormat,
    minDate,
    maxDate,
    timeFormat,
    ...other,
  };
  const [timezone, setTimezone] = useState<Nullable<string>>(null);
  const { DateInputLayout } = useUiTheme<DateInputLayoutType>();
  const {
    getRawOnChangeCallback,
    getDateFormat,
    getTimezone,
    getTimeFormat,
    getValidationCallback,
    getClearValidationCallback,
    getIsDisabled,
    getMinDate,
    getMaxDate,
    getValue,
    getSource,
    getErrorMessages,
    getIsRequired,
    isVisible,
    getLabel,
    normalizeTextDate,
    getClassName,
    formatSourceDate,
  } = useDateHelper(props);
  const formatData = getDateFormat();

  useEffect(() => {
    (async () => {
      if (getTimezone) {
        const userTimezone = await getTimezone();
        // Fixed dateInput rendering delay
        flushSync(() => setTimezone(userTimezone || DEFAULT_TIMEZONE));
      }
    })();
    // Suppressed warnings because we only need to call useEffect callback ones after the first mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const originalOnChangeCallback = getRawOnChangeCallback();

  const formatDate =
    formatSourceDate ||
    ((textDate: string) => {
      let result = null;
      if (textDate) {
        result = normalizeTextDate(textDate);

        if (moment(textDate, formatData, true).isValid()) {
          result = moment(textDate).format(formatData);
        }
      }
      return result;
    });

  const onChangeCallback = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const result = formatDate(value);

    if (originalOnChangeCallback) {
      originalOnChangeCallback({
        [getSource()]: result,
      });
    }
  };

  return timezone ? (
    <DateInputLayout
      {...props}
      onChangeCallback={onChangeCallback}
      dateFormat={formatData}
      displayTimeZone={timezone}
      timeFormat={getTimeFormat()}
      disabled={getIsDisabled()}
      minDate={getMinDate()}
      maxDate={getMaxDate()}
      onBlurCallback={getValidationCallback()}
      onFocusCallback={getClearValidationCallback()}
      value={formatDate(getValue() as string)}
      errorMessages={getErrorMessages()}
      isRequired={getIsRequired()}
      isVisible={isVisible()}
      label={getLabel()}
      className={getClassName()}
    />
  ) : (
    <InputLoader />
  );
};

export default DateInput;
