import useCrudService from 'shared/crud';
import { usePeopleManagementApi } from 'api';
import { flushSync } from 'react-dom';
import { useCallback } from 'react';
import { ResourceData } from 'shared/crud/baseCrudService';
import { cloneDeep } from 'lodash';
import { COMPETENCY_LEVEL } from '../vacancy/shared/vacancyService';

export const HIRING_PATH = '/hiring-requests';

export const HIRING_REQUEST_READ = 'COMPANY_HIRING_REQUEST_READ';
export const HIRING_REQUEST_UPDATE = 'COMPANY_HIRING_REQUEST_UPDATE';
export const HIRING_REQUEST_POSITION_REOPEN = 'COMPANY_HIRING_REQUEST_POSITION_REOPEN';
export const HIRING_REQUEST_FORECAST_UPDATE = 'COMPANY_HIRING_REQUEST_FORECAST_UPDATE';
export const HIRING_REQUEST_CANCEL = 'COMPANY_HIRING_REQUEST_CANCEL';

export const HIRING_MODE = {
  ACTIVE: 'Active',
  BAR_RAISER: 'Bar raiser',
};

export const HIRING_STATUS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  FAILED: 'FAILED',
  CANCELED: 'CANCELED',
};

export const HIRING_STATUS_OPTIONS = {
  OPEN: 'Open',
  CLOSED: 'Closed',
  FAILED: 'Failed',
  CANCELED: 'Canceled',
};

export const NECESSITY_STATUS = {
  EXTRA: 'EXTRA',
  REQUIRED: 'REQUIRED',
};

export const POSITION_STATUS = {
  OPEN: 'OPEN',
  CLOSED: 'CLOSED',
  CANCELED: 'CANCELED',
};

export const NECESSITY_STATUS_OPTIONS = {
  [NECESSITY_STATUS.EXTRA]: 'Extra',
  [NECESSITY_STATUS.REQUIRED]: 'Required',
};

export const POSITION_STATUS_OPTIONS = {
  [POSITION_STATUS.OPEN]: 'Open',
  [POSITION_STATUS.CLOSED]: 'Closed',
  [POSITION_STATUS.CANCELED]: 'Canceled',
};

const RESOURCE_URL = '/hiring-requests';

export interface ForecastDate {
  id: Nullable<string>;
  forecastBy: Nullable<string>;
  forecastDate: string;
  createdAt: Nullable<string>;
}

export interface RequestPosition {
  id: string;
  positionStatus: keyof typeof POSITION_STATUS;
  necessityStatus: keyof typeof NECESSITY_STATUS;
  forecastDates: ForecastDate[];
  forecastDate?: string;
}

export interface HiringRequest {
  id: string;
  owner: string;
  specialisation: string;
  level: string;
  requestPositions: RequestPosition[];
  hiringMode: keyof typeof HIRING_MODE;
  targetDate: string | null;
  expirationDate: string;
  createdAt: string;
  createdBy: string;
  isAsap: boolean;
  vacancies: string[];
  status: keyof typeof HIRING_STATUS;
  newVacancies?: string[];
}

const useHiringService = () => {
  const { sendPutRequest, sendPostRequest } = usePeopleManagementApi();

  const {
    getById: baseGetById,
    search: baseSearch,
    update: baseUpdate,
    invalidateCache,
    ...baseCrud
  } = useCrudService({
    singleResourceUrl: `${HIRING_PATH}/:id`,
    listResourceUrl: HIRING_PATH,
    apiService: usePeopleManagementApi,
  });

  const mapForecastDates = (forecastDates: ForecastDate[], forecastDate: string): ForecastDate[] => {
    const isAddNewForecastDates = forecastDates[0]?.forecastDate !== forecastDate;
    return [
      ...forecastDates,
      ...(isAddNewForecastDates ? [{ id: null, forecastBy: null, forecastDate, createdAt: null }] : []),
    ];
  };

  const mapUpdateData = (data: HiringRequest) => {
    const newVacancies = (data.newVacancies || []).map((item: string) => item.split(' ')[0]);
    return {
      requestId: data.id,
      owner: data.owner,
      requestPositions: data.requestPositions.map((position: RequestPosition) => {
        return {
          ...position,
          forecastDate: undefined,
          forecastDates: mapForecastDates(position.forecastDates, position.forecastDate!),
        };
      }),
      vacancies: [...data.vacancies, ...newVacancies],
    };
  };

  const mapToViewData = (data: HiringRequest) => {
    return {
      ...data,
      level: data.level === null ? COMPETENCY_LEVEL['N/A'] : data.level,
      requestPositions: data.requestPositions.map((position: RequestPosition) => ({
        ...position,
        forecastDate: position.forecastDates[0]?.forecastDate,
      })),
    };
  };

  const getById = async (id: string) => {
    const data = (await baseGetById(id)) as HiringRequest;

    return mapToViewData(data);
  };

  const getByIds = async (ids: string[]) =>
    baseCrud.searchAll({
      filter: {
        id: {
          in: ids,
        },
      },
    });

  const searchForMigration = async (ids: string[], vacancyAlias: string) => {
    return baseCrud.searchAll({
      filterGroup: {
        condition: 'OR',
        filterType: 'FILTER_GROUP',
        filters: [
          {
            filterType: 'FILTER',
            property: 'vacancies.vacancy',
            operator: 'EQ',
            value: vacancyAlias,
            joinType: 'LEFT',
          },
          {
            filterType: 'FILTER',
            property: 'id',
            operator: 'IN',
            value: ids,
          },
        ],
      },
      type: 'advanced',
    } as any);
  };

  const update = async (_id: string, data: HiringRequest) => {
    flushSync(() => invalidateCache());
    return sendPutRequest(`${RESOURCE_URL}`, mapUpdateData(data));
  };

  const closePosition = async (data: HiringRequest) => {
    const response = await update('', data);
    const newData = await response.json();
    return mapToViewData(newData);
  };

  const reopen = async (_id: string, data: HiringRequest, positionId: string) => {
    flushSync(() => invalidateCache());
    return sendPostRequest(`${RESOURCE_URL}/${data.id}/positions/${positionId}/reopen`);
  };

  const reopenPosition = async (data: HiringRequest, positionId: string) => {
    const response = await reopen('', data, positionId);
    const newData = await response.json();
    return mapToViewData(newData);
  };

  const cancelRequest = async (id: string) => {
    const response = await sendPostRequest(`${RESOURCE_URL}/cancel`, { id });
    return response.json();
  };

  const search = useCallback(
    async (request: ResourceData) => {
      const params = cloneDeep(request);

      const searchString = params.filter?.['specification:search']?.eq || '';

      if (searchString && params.filter) {
        delete params.filter?.['specification:search'];
        params.filter.specialisation = {
          ct: searchString,
        };
      }

      ['isAsap'].forEach(source => {
        const filterValue = params.filter?.[source]?.eq || '';

        if (filterValue && params.filter) {
          params.filter[source] = {
            eq: filterValue === 'YES',
          };
        }
      });

      const response = await baseSearch(params);

      const newData = response?.result?.map((item: HiringRequest) => {
        return mapToViewData(item);
      });

      return {
        ...response,
        result: newData,
      };
    },
    [baseSearch],
  );

  return {
    ...baseCrud,
    search,
    searchForMigration,
    getById,
    getByIds,
    update,
    closePosition,
    reopenPosition,
    invalidateCache,
    cancelRequest,
    mapToViewData,
  };
};

export default useHiringService;
