import React, { useEffect, useState } from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import CandidateInterviewSteps from '../CandidateInterviewSteps/CandidateInterviewSteps';
import useCandidateService from '../../../candidateService';
import EditCandidateInterviewStep from '../EditComponents/EditCandidateInterviewStep/EditCandidateInterviewStep';
import { Stages } from 'erp/recruitment/recruitingBoard/constants';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import { DEFAULT_ERROR_MESSAGE } from 'shared/uibuilder/form/formHelper';
import CandidateVacancySpecialization from '../CandidateVacancySpecialization/CandidateVacancySpecialization';
import { VacancyGroupType } from 'erp/recruitment/newVacancy/types';
import Stack from '@mui/material/Stack';

export interface CandidateApplicationSectionWrapperProps {
  activeStep: Stages;
}

const CandidateApplicationSectionWrapper = () => {
  const { getCandidateApplications } = useCandidateService();
  const [error, setError] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<Stages>();
  const [candidateVacanciesName, setCandidateVacancies] = useState<VacancyGroupType | null>(null);
  const { data } = useShowContext();
  const { addMessage } = useMessageService();
  const { search } = useVacancyGroupService();

  useEffect(() => {
    (async () => {
      try {
        const result = await getCandidateApplications(data.id);
        if (result === false) {
          setError(true);
        } else {
          setActiveStep(result.result[0].candidateApplication.currentStage);
        }
      } catch (e) {
        const result = await (e as any).json();
        addMessage(new ErrorMessage(result.result?.errors?.stateStep || DEFAULT_ERROR_MESSAGE));
      }
      if (data.lastAppliedVacancy) {
        try {
          const vacancies = await search({ filter: { alias: { eq: data.lastAppliedVacancy } } });
          // Temporarly show only one vacancy - next will be implemented switching.
          setCandidateVacancies(vacancies.result[0]);
        } catch (e) {
          const result = await (e as any).json();
          addMessage(new ErrorMessage(result?.errors?.stateStep || DEFAULT_ERROR_MESSAGE));
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Stack
        direction="row"
        gap="10px"
        sx={{
          justifyContent: 'space-between',
          flexDirection: 'row-reverse',
        }}
      >
        {!error && activeStep && (
          <EditCandidateInterviewStep
            vacancyIdForHiredStep={(candidateVacanciesName && candidateVacanciesName.id) ?? ''}
            activeStep={activeStep}
            synchronizeStep={setActiveStep}
          />
        )}
        {candidateVacanciesName && <CandidateVacancySpecialization vacancyData={candidateVacanciesName} />}
      </Stack>
      {!error && activeStep && <CandidateInterviewSteps activeStep={activeStep} />}
    </>
  );
};

export default CandidateApplicationSectionWrapper;
