import React from 'react';
import { EMPLOYEE_DASHBOARD_COLORS, MAP_EMPLOYEE_STATUS } from '../constants';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import Badge from 'shared/uibuilder/badge/Badge';

export const commonChipStyles = {
  color: 'white',
  borderRadius: '3px',
  fontSize: '8px',
  textTransform: 'capitalize',
};

const STATUS_COLORS = {
  ACTIVE: 'var(--mui-palette-badge-green)',
  PROBATION: 'var(--mui-palette-badge-blue)',
  IN_DISMISSAL: 'var(--mui-palette-badge-yellow)',
  DISMISSAL: 'var(--mui-palette-badge-yellow)',
  DISMISSED: 'var(--mui-palette-badge-lightGrey)',
  RELOCATION: 'var(--mui-palette-badge-violet)',
};

const WorkingStatusChip = () => {
  const { getValue } = useFieldHelper<keyof typeof MAP_EMPLOYEE_STATUS>({ source: 'employeeStatus' });
  const status = getValue();

  const backgroundColor =
    status in STATUS_COLORS ? STATUS_COLORS[status as keyof typeof STATUS_COLORS] : EMPLOYEE_DASHBOARD_COLORS.SAFE;

  return (
    <Badge
      sx={{
        ...commonChipStyles,
        backgroundColor,
        ml: 2,
      }}
    >
      {MAP_EMPLOYEE_STATUS[status]}
    </Badge>
  );
};

export default WorkingStatusChip;
