import React from 'react';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import useCandidateService from 'erp/candidate/candidateService';
import { useParams } from 'react-router-dom';
import CandidateProfile from '../shared/CandidateProfile/CandidateProfile';
import FormShowTemplate from 'shared/uibuilder/FormShowContexts/FormShowTemplate';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

const CandidateViewPage = () => {
  const { getById, updateCandidateProfile, getInlineValidationSchema } = useCandidateService();
  const { id } = useParams<Dictionary<string>>();

  return (
    <FormShowTemplate
      getDataFunc={() => getById(id!)}
      submitFormFunc={(values: FormFieldsData) => {
        return updateCandidateProfile(id!, values);
      }}
      getValidationSchemaFunc={getInlineValidationSchema}
      afterSubmit={{
        message: 'You are awesome! The Candidate has been successfully saved.',
      }}
    >
      <ShowPageLayout hasSeparateSections>
        <CandidateProfile />
      </ShowPageLayout>
    </FormShowTemplate>
  );
};

export default CandidateViewPage;
