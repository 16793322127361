import React from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedChange } from 'erp/recruitment/newVacancy/types';
import { DOMAIN_LABELS } from '../constants';
import ChangeItem from './ChangeItem';

interface ChangesGroupProps {
  domainName: string;
  changes: FormattedChange[];
}

const ChangesGroup: React.FC<ChangesGroupProps> = ({ domainName, changes }) => {
  const domainLabel = DOMAIN_LABELS[domainName] || domainName;

  if (changes.length === 1 && changes[0].property === 'updatedAt') {
    return null;
  }

  return (
    <Box sx={{ mb: 3 }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 'bold', mb: 1, fontSize: '0.85rem' }}>
        {domainLabel}:
      </Typography>

      <Box sx={{ ml: 2 }}>
        {changes.map(change => (
          <ChangeItem key={change.id} change={change} showDomainHeader={false} />
        ))}
      </Box>
    </Box>
  );
};

export default ChangesGroup;
