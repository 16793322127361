import React from 'react';
import PushPinOutlinedIcon from '@mui/icons-material/PushPinOutlined';
import PushPinIcon from '@mui/icons-material/PushPin';
import { IconButton } from 'uibuilder/button';
import Tooltip from 'uibuilder/Tooltip';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { TimelineContextData } from 'shared/uibuilder/timeline/Timeline';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { DEFAULT_ERROR_MESSAGE } from 'shared/uibuilder/form/formHelper';

const MAX_PIN = 3;

type PinTimelineButtonProps = {
  pinStatus: boolean;
  pinMethod: () => Promise<any>;
};

const PinTimelineButton = ({ pinMethod, pinStatus }: PinTimelineButtonProps) => {
  const { countPinElements = 0 }: TimelineContextData = useListContext();
  const { addMessage } = useMessageService();

  const pinCallback = async () => {
    try {
      await pinMethod();
    } catch {
      addMessage(new ErrorMessage(DEFAULT_ERROR_MESSAGE));
    }
  };

  return (
    <IconButton disabled={!pinStatus && Number(countPinElements) >= MAX_PIN} onClick={pinCallback} sx={{ p: '4px' }}>
      <Tooltip title={pinStatus ? 'Unpin' : 'Pin'}>
        {pinStatus ? (
          <PushPinIcon sx={{ color: 'var(--mui-palette-primary-main)' }} />
        ) : (
          <PushPinOutlinedIcon sx={{ transform: 'rotate(45deg)' }} />
        )}
      </Tooltip>
    </IconButton>
  );
};

export default PinTimelineButton;
