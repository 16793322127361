/* istanbul ignore file */
import React, { useState, useEffect } from 'react';
import moment, { Moment } from 'moment';
import ButtonWithConfirmation from 'uibuilder/button/ButtonWithConfirmation';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { DateInput } from 'shared/uibuilder/form/input';
import useDateService from 'shared/uibuilder/dateService/useDateService';
import { DATE_FORMAT } from 'shared/uibuilder/dateService';

function rangeOverlap(range1: Moment[], range2: Moment[], gran: any = 'days', inc: any = '[]') {
  const [start1, end1] = range1;
  const [start2, end2] = range2;

  // check range1 is between range2
  const startFirst = start1.isBetween(start2, end2, gran, inc);
  const endFirst = end1.isBetween(start2, end2, gran, inc);

  // check range2 is between range1
  const startLast = start2.isBetween(start1, end1, gran, inc);
  const endLast = end2.isBetween(start1, end1, gran, inc);

  return startFirst || endFirst || startLast || endLast;
}

const RunMigrationButton = ({
  displayMessage,
  localStorageKey,
  permissionToCheck,
  submitMethod,
  children,
  validateDuplicates = false,
}: {
  displayMessage: string;
  localStorageKey: string;
  permissionToCheck: string;
  submitMethod: (data: Dictionary<any>) => Promise<void>;
  children: React.ReactNode;
  validateDuplicates?: boolean;
}) => {
  const { formatDateForAPI } = useDateService();
  const [errors, setErrors] = useState<Dictionary<string[]>>({});
  const [values, setValues] = useState<any>({});
  const [duplicateRange, setDuplicateRange] = useState(false);

  const onDateChange = (source: string, data: FormFieldsData) => {
    setValues({ ...values, [source]: data[source] });
    const isValid = moment(data[source], DATE_FORMAT.API_ONLY_DATE, true).isValid();

    if (!data[source]) {
      setErrors({ ...errors, [source]: ['Field is required'] });
    } else if (!isValid) {
      setErrors({ ...errors, [source]: ['Please, choose the correct date'] });
    } else {
      setErrors({ ...errors, [source]: [] });
    }
  };

  useEffect(() => {
    if (!values.startDate || !values.endDate || !validateDuplicates) {
      return;
    }

    const json = localStorage.getItem(localStorageKey);
    const savedData: any[] = (json && JSON.parse(json)) || [];

    const duplicate = savedData.find(savedValue => {
      const momentSavedStartDate = moment(savedValue.startDate);
      const momentSavedEndDate = moment(savedValue.endDate);

      return rangeOverlap(
        [momentSavedStartDate, momentSavedEndDate],
        [moment(values.startDate), moment(values.endDate)],
      );
    });

    setDuplicateRange(duplicate);
    // Suppressed warnings because we only need to call useCallback callback if values are changed.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  const onStartDateChange = (data: FormFieldsData) => {
    onDateChange('startDate', data);
  };

  const onEndDateChange = (data: FormFieldsData) => {
    onDateChange('endDate', data);
  };

  const onSubmit = async () => {
    const json = localStorage.getItem(localStorageKey);
    const savedData = (json && JSON.parse(json)) || [];

    const data = { startDate: formatDateForAPI(values.startDate), endDate: formatDateForAPI(values.endDate) };

    await submitMethod(data);
    localStorage.setItem(localStorageKey, JSON.stringify([...savedData, data]));

    setValues({});
  };

  return (
    <ButtonWithConfirmation
      displayMessage="Are you sure you want to run migration? This action cannot be undone. Please, Fill out inputs below"
      modalChildren={
        <div className="mt-5">
          <DateInput
            source="startDate"
            value={values.startDate as any}
            label="Start Date"
            onChangeCallback={onStartDateChange}
            errorMessages={errors.startDate || []}
            isRequired
            maxDate={values.endDate}
          />
          <DateInput
            source="endDate"
            value={values.endDate as any}
            label="End Date"
            onChangeCallback={onEndDateChange}
            errorMessages={errors.endDate || []}
            isRequired
            minDate={values.startDate}
          />
          {duplicateRange && (
            <div style={{ color: 'var(--mui-palette-error-main)' }}>
              We already have migration for this period ({JSON.stringify(duplicateRange)})
            </div>
          )}
        </div>
      }
      submitMethod={onSubmit}
      afterSubmit={{
        successMessage: 'The migration was run successful',
        errorMessage: "Can't run migration.",
      }}
      permissionToCheck={permissionToCheck}
      variant="text"
      confirmBtnProps={{
        disabled:
          duplicateRange || !values.startDate || !values.endDate || Object.values(errors).some(error => error.length),
      }}
    >
      {children}
    </ButtonWithConfirmation>
  );
};

export default RunMigrationButton;
