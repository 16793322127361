import React from 'react';
import { Box, Typography, ListItem, Paper } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import moment from 'moment';
import { Button } from 'uibuilder/button';
import useAuthorization from 'shared/authorization/authorizationService';
import { RECRUITING_CONTEXT } from 'erp/recruitment/RecruitingContext';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import { RECRUITING_VACANCY_RESOLVE_CHANGE_REQUEST } from '../../../../constants';
import { ChangeRequest } from './types';
import EmployeeLinkFieldWithNameLoading from 'erp/employee/shared/field/EmployeeLinkFieldWithNameLoading';
import { styled } from '@mui/material/styles';

const StyledEmployeeLink = styled(EmployeeLinkFieldWithNameLoading)(({ theme }) => ({
  display: 'inline-block',
  '& .MuiTypography-root': {
    fontSize: '12px',
  },
}));

interface ChangeRequestsContentProps {
  handleSolveChangeRequest?: (requestId: string) => Promise<void>;
}

const ChangeRequestsContent: React.FC<ChangeRequestsContentProps> = ({ handleSolveChangeRequest }) => {
  const { isGranted } = useAuthorization();
  const { data } = useShowContext();
  const changeRequests = data.getData().result as ChangeRequest[];

  if (!changeRequests || changeRequests.length === 0) {
    return (
      <Box sx={{ p: 3, textAlign: 'center' }}>
        <Typography variant="body1">No change requests found for this vacancy.</Typography>
      </Box>
    );
  }

  return (
    <>
      {Array.isArray(changeRequests) &&
        changeRequests.map((request: ChangeRequest) => (
          <ListItem
            key={request.id}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              p: 0,
            }}
          >
            <Paper
              elevation={1}
              sx={{
                mb: 2,
                p: 2,
                borderLeft: `4px solid ${request.status === 'ACTIVE' ? 'var(--mui-palette-success-main)' : 'var(--mui-palette-warning-main)'}`,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mb: 1 }}>
                <StyledEmployeeLink label="" externalId={request.createdBy} />
                <Typography variant="caption" color="text.secondary">
                  {moment(request.createdAt).format('MMM DD, YYYY HH:mm')}
                </Typography>
              </Box>

              <Typography variant="body1" sx={{ mb: 2 }}>
                {request.changeNote}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                {request.status === 'RESOLVED' && (
                  <Typography variant="caption" color="text.secondary">
                    Resolved by <StyledEmployeeLink label="" externalId={request.resolvedBy} /> on{' '}
                    {moment(request.resolvedAt).format('MMM DD, YYYY HH:mm')}
                  </Typography>
                )}
                {request.status === 'ACTIVE' &&
                  isGranted(RECRUITING_VACANCY_RESOLVE_CHANGE_REQUEST, RECRUITING_CONTEXT) &&
                  handleSolveChangeRequest && (
                    <Button
                      onClick={() => handleSolveChangeRequest(request.id)}
                      color="primary"
                      variant="contained"
                      size="small"
                      startIcon={<CheckCircleIcon />}
                      sx={{ ml: 'auto' }}
                    >
                      Mark as Solved
                    </Button>
                  )}
              </Box>
            </Paper>
          </ListItem>
        ))}
    </>
  );
};

export default ChangeRequestsContent;
