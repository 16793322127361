/* istanbul ignore file */
import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../CandidateTimeline.module.scss';
import Tooltip from 'uibuilder/Tooltip';
import LinkIcon from '@mui/icons-material/LinkOutlined';
import { copyToClipboard } from 'shared/clipboard';

type CandidateTimelineRecordAnchorProps = {
  timelineId: string | number;
  basePathname?: string;
};

const CandidateTimelineRecordAnchor = ({ timelineId, basePathname = '' }: CandidateTimelineRecordAnchorProps) => {
  const { pathname } = useLocation();
  const pathnameWithoutEntryId = basePathname || pathname.replace(/\/\d+$/, '');
  const link = `${pathnameWithoutEntryId}/${timelineId}`;

  const handleAnchorClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    copyToClipboard(e.currentTarget.href);
  };

  return (
    <Tooltip id={`anchor-entry${timelineId}`} arrow placement="bottom-end" title="Copy link">
      <a
        href={link}
        onClick={handleAnchorClick}
        id={`anchor-entry${timelineId}`}
        className={`copy-anchor ${styles.timeline_anchor}`}
        ref={{ current: null }}
      >
        <LinkIcon />
      </a>
    </Tooltip>
  );
};

export default CandidateTimelineRecordAnchor;
