import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const MinusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        focusable="false"
        width="18px"
        height="auto"
        fill="#c2c2c2"
        aria-hidden="true"
        viewBox="0 0 24 24"
        data-testid="RemoveOutlinedIcon"
      >
        <path d="M19 13H5v-2h14z" />
      </svg>
    </SvgIcon>
  );
};

export default MinusIcon;
