import React, { useEffect, useState } from 'react';
import Grid from 'uibuilder/Grid';
import Box from 'uibuilder/Box';
import OfferTemplatePreview from './OfferTemplatePreview';
import { useParams } from 'react-router-dom';
import useCandidateOfferService from '../candidateOfferService';
import OfferMarkdownEditor from './OfferMarkdownEditor';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import Loading from 'shared/uibuilder/Loading';
import JobOfferPdfGenerator from './JobOfferPdfGenerator';
import { markdownToHtml, replacePlaceholders } from 'shared/markdownEditor/TemplateUtils';
import { useOfferTemplateData } from './useOfferTemplateData';

const OfferTemplateWorkspace = () => {
  const [previewHtml, setPreviewHtml] = useState<string>('');
  const { data: candidateData } = useShowContext();
  const { offerId } = useParams();
  const { getById } = useCandidateOfferService();
  const { search } = useVacancyGroupService();
  const { addMessage } = useMessageService();

  const handleContentChange = (content: string) => {
    setEditedContent(content);
  };

  const { isLoading, editedContent, placeholderValues, setEditedContent, firstName, fullName } = useOfferTemplateData({
    offerId,
    candidateData,
    getById,
    search,
  });

  useEffect(() => {
    if (editedContent) {
      try {
        const contentWithValues = replacePlaceholders(editedContent, placeholderValues);

        const html = markdownToHtml(contentWithValues);
        setPreviewHtml(html);
      } catch (err) {
        addMessage(new ErrorMessage('Error during rendering Markdown to HTML'));
      }
    }
  }, [addMessage, editedContent, placeholderValues]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Box>
      <Grid container spacing={2} pr={2} sx={{ position: 'relative' }}>
        <Grid item xs={12} md={6}>
          <OfferMarkdownEditor value={editedContent} onChange={handleContentChange} />
        </Grid>

        <Grid item xs={12} md={6}>
          <OfferTemplatePreview html={previewHtml} />
          <JobOfferPdfGenerator
            editedContent={editedContent}
            placeholderValues={placeholderValues}
            firstName={firstName}
            fullName={fullName}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OfferTemplateWorkspace;
