import React from 'react';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion/Accordion';
import styled from '@mui/material/styles/styled';
import Typography from 'uibuilder/Typography';
import AccordionSummary from '@mui/material/AccordionSummary/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ChipFilterInputWrapperProps } from './useChipFilterLayout';
import Box from 'uibuilder/Box';

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters {...props} />)(() => ({
  '&::before': {
    opacity: '1 !important',
  },
}));

type FilterAccordionProps = {
  Icon?: React.ReactElement;
} & ChipFilterInputWrapperProps;

const FilterAccordion = ({ title, children, Icon, ...props }: FilterAccordionProps) => {
  return (
    <Accordion disableGutters>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box display="flex" alignItems="center">
          {Icon && React.cloneElement(Icon, { sx: { fontSize: '13px', mr: '5px' } })}
          <Typography variant="subtitle1">{title}</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{React.cloneElement(children, { ...props, ...children.props })}</AccordionDetails>
    </Accordion>
  );
};

export default FilterAccordion;
