import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const FlagBelarusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_i_3962_3784)">
          <path
            d="M4.16797 21.5277H22.2235C22.9602 21.5277 23.6668 21.2351 24.1877 20.7141C24.7086 20.1932 25.0013 19.4867 25.0013 18.7499V15.9722H4.16797V21.5277Z"
            fill="#4AA657"
          />
          <path
            d="M22.2235 3.47217H4.16797V15.9722H25.0013V6.24995C25.0013 5.51323 24.7086 4.80669 24.1877 4.28576C23.6668 3.76483 22.9602 3.47217 22.2235 3.47217Z"
            fill="#C8313E"
          />
          <path
            d="M2.77778 3.47217C2.04107 3.47217 1.33453 3.76483 0.813592 4.28576C0.292658 4.80669 0 5.51323 0 6.24995L0 18.7499C0 19.4867 0.292658 20.1932 0.813592 20.7141C1.33453 21.2351 2.04107 21.5277 2.77778 21.5277H4.16667V3.47217H2.77778Z"
            fill="white"
          />
          <path
            d="M0.532986 20.2694L0.967014 19.4993L1.41215 20.2916L1.03576 20.8965C1.15437 20.9928 1.28072 21.0792 1.41354 21.1548L1.96354 20.1569L0.977431 18.5763L0.201736 19.7749C0.328819 20.0944 0.514931 20.3805 0.744097 20.6291L0.532986 20.2694ZM4.15451 20.2062L3.11493 18.6631L2.07187 20.1708L2.96493 21.5277H3.41424L4.15451 20.2062ZM3.14549 19.4986L3.59063 20.2909L3.14549 21.0069L2.71146 20.2694L3.14549 19.4986ZM4.16771 12.7312V12.1104L3.95035 12.4131L4.16771 12.7312ZM1.96285 4.87911L1.34201 3.88328C0.798888 4.21434 0.385834 4.72177 0.171875 5.32078L1.06424 6.51036L1.96285 4.87911ZM0.532986 4.99161L0.967014 4.22147L1.41215 5.01383L0.967014 5.72911L0.532986 4.99161ZM3.20729 6.6187L4.15451 4.92772L3.17396 3.47217H3.05521L2.07257 4.893L3.20729 6.6187ZM3.58993 5.01314L3.14549 5.72911L2.71146 4.99161L3.14549 4.22147L3.58993 5.01314Z"
            fill="#C8313E"
          />
          <path
            d="M3.43958 10.4167L4.04722 9.40769L4.16667 9.46047V9.02783L3.19028 7.50839L2.01806 9.13617L0.976389 7.46533L0 8.97436V9.42575L0.596528 10.4167L1.01944 9.79797L0.715972 9.37505L0.94375 9.07089L1.60556 9.92783L0.629167 11.5334L0 10.6674V11.7459L0.0402778 11.6924L0.702083 12.5494L0 13.7049V14.5223L0.629167 13.6556L1.60556 15.2612L1.05694 15.9723L0.94375 16.1188L0.834028 15.9723L0.715972 15.8146L1.02014 15.3917L0.597222 14.773L0 15.7646V16.1639L0.998611 17.6869L1.97083 15.9723L2.00764 15.907L2.04792 15.9723L3.16875 17.7841L4.16667 16.0153V15.7438L4.07986 15.7827L3.47222 14.773L3.04861 15.3917L3.35278 15.8146L3.23472 15.9723L3.125 16.1188L3.01181 15.9723L2.46319 15.2619L3.43958 13.6563L4.16667 14.657V13.8639L3.36319 12.5431L4.025 11.6862L4.16667 11.8751V10.4882L3.40694 11.5348L2.43056 9.92853L3.09236 9.07158L3.32014 9.37505L3.01597 9.79797L3.43958 10.4167ZM2.97569 11.8271V13.166L2.00694 14.6959L1.09444 13.1598V11.8348L2.00694 10.298L2.97569 11.8271Z"
            fill="#C8313E"
          />
          <path
            d="M1.41189 11.6828L1.82717 12.4404L1.41189 13.198L1.71606 13.3647L2.02509 12.8015L2.33411 13.3647L2.63828 13.198L2.223 12.4404L2.63828 11.6828L2.33411 11.5161L2.02509 12.0793L1.71606 11.5161L1.41189 11.6828ZM0.0195312 18.1862L0.453559 18.9237L0.898698 18.2078L0.453559 17.4154L0.0195312 18.1862ZM1.07231 18.1862L1.50634 18.9237L1.95078 18.2078L1.50634 17.4154L1.07231 18.1862ZM2.10286 18.1862L2.53689 18.9237L2.98203 18.2078L2.53689 17.4154L2.10286 18.1862ZM3.20842 18.1862L3.64245 18.9237L4.08689 18.2078L3.64245 17.4154L3.20842 18.1862ZM0.0195312 7.03137L0.453559 7.76956L0.898698 7.0529L0.453559 6.26123L0.0195312 7.03137ZM1.07231 7.03137L1.50634 7.76956L1.95078 7.0529L1.50634 6.26123L1.07231 7.03137ZM2.10286 7.03137L2.53689 7.76956L2.98203 7.0529L2.53689 6.26123L2.10286 7.03137ZM3.20842 7.03137L3.64245 7.76956L4.08689 7.0529L3.64245 6.26123L3.20842 7.03137Z"
            fill="#C8313E"
          />
        </g>
        <defs>
          <filter
            id="filter0_i_3962_3784"
            x="0"
            y="0"
            width="25"
            height="29"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="2" />
            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
            <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3962_3784" />
          </filter>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default FlagBelarusIcon;
