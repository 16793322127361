import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ChangeEvent, OnSuggestionSelected, SuggestionSelectedEventData } from 'react-autosuggest';
import { debounce } from 'throttle-debounce';
import useUiTheme from 'shared/uibuilder/useUiTheme';
import useInputHelper from 'shared/uibuilder/form/input/inputHelper';
import { ResultResourceData } from 'shared/crud/baseCrudService';
import {
  DefaultInputLayoutProps,
  InputLayoutHintProps,
  InputLayoutStateProps,
  ValidationLayoutProps,
} from 'shared/uibuilder/form/input';

const DEBOUNCE_THRESHOLD = 500;
const DEFAULT_NUMBER_RESULTS_PER_PAGE = 30;

export type Suggestion = {
  id: number | string;
  text: string;
};

interface AutosuggestDropdownProps {
  source: string;
  loadSuggestionsMethod: (searchValue: string, loadCurrentPage: number) => ResultResourceData;
  mapResults: (data: any) => Suggestion;
  noResultMessage?: string;
  resultsPerPage?: number;
  initialValue?: string;
  isLoading?: boolean;
  valueDecorator?: (searchValue: string) => string;
  onInputChangeCallback?: (resultValue: string) => void;
  buildAutoSuggestLabel?: (info: Suggestion) => string;
  searchRequest: (object: object) => ResultResourceData;
  className?: string;
  placeholder?: string;
  InputIcon?: React.ReactNode;
  selecteSuggestionMethod?: (searchValueId: StringOrNumber) => void;
  isInfinityLoading?: boolean;
}

export interface AutosuggestionDropdownLayoutProps
  extends DefaultInputLayoutProps,
    InputLayoutHintProps,
    InputLayoutStateProps,
    ValidationLayoutProps {
  suggestions: Suggestion[];
  suggestionsLoaded: boolean;
  suggestionsTotalPages: number;
  onSuggestionsFetchRequested: ({ value }: { value: string; reason: string; loadNextPage?: boolean }) => void;
  onSuggestionsClearRequested: () => void;
  onSuggestionSelected: OnSuggestionSelected<Suggestion>;
  onChangeCallback: (event: React.FormEvent<HTMLElement>, { newValue }: ChangeEvent) => void;
  onBlurCallback?: () => void;
  onFocusCallback?: () => void;
  noResultMessage: string;
  resultsPerPage: number;
  loading: boolean;
  InputIcon?: React.ReactNode;
  suggestionsPageRef: React.MutableRefObject<number>;
  isInfinityLoading?: boolean;
}

export type AutosuggestionDropdownLayoutType = ReactComponent<AutosuggestionDropdownLayoutProps>;

const AutosuggestDropdown: React.FC<AutosuggestDropdownProps> = ({
  loadSuggestionsMethod,
  buildAutoSuggestLabel,
  noResultMessage = '',
  resultsPerPage = DEFAULT_NUMBER_RESULTS_PER_PAGE,
  initialValue = '',
  mapResults,
  isLoading = false,
  searchRequest,
  onInputChangeCallback,
  valueDecorator,
  selecteSuggestionMethod,
  ...props
}) => {
  const { AutosuggestionDropdownLayout } = useUiTheme<AutosuggestionDropdownLayoutType>();
  const inputHelper = useInputHelper(props);
  const source = inputHelper.getSource();
  const onChangeCallback = inputHelper.getRawOnChangeCallback();

  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
  const suggestionsPageRef = useRef(0);
  const [suggestionsTotalPages, setSuggestionsTotalPages] = useState(0);
  const [suggestionsLoaded, setSuggestionsLoaded] = useState(false);
  const [loading, setLoading] = useState(isLoading);
  const [selectedValue, setSelectedValue] = useState(initialValue);

  useEffect(() => {
    const fetchInitialValue = async () => {
      if (initialValue && buildAutoSuggestLabel) {
        setLoading(true);
        try {
          const filter = { filter: { id: { eq: initialValue } } };
          const { result = [] } = await searchRequest(filter);
          const info = result.find(({ id }: Suggestion) => id === Number(initialValue));

          if (info) {
            setSelectedValue(buildAutoSuggestLabel(info));
          }
        } catch (error) {
          throw new Error('Failed to fetch initial value');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchInitialValue();
  }, [initialValue, buildAutoSuggestLabel, searchRequest]);

  const handleInputChange = onInputChangeCallback || (() => onChangeCallback?.({ [source]: null }));

  const loadSuggestions = useCallback(
    async (searchValue: string, loadNextPage?: boolean) => {
      setLoading(true);
      try {
        const resultSearchValue = valueDecorator ? valueDecorator(searchValue) : searchValue;
        const nextPage = loadNextPage ? suggestionsPageRef.current + 1 : 0;

        const { result, totalPages } = await loadSuggestionsMethod(resultSearchValue, nextPage);

        if (loadNextPage) {
          suggestionsPageRef.current += 1;
          setSuggestions(prev => [...prev, ...result.map(mapResults).filter(Boolean)]);
        } else {
          suggestionsPageRef.current = 0;
          setSuggestions(result.map(mapResults).filter(Boolean));
        }

        setSuggestionsTotalPages(totalPages);
        setSuggestionsLoaded(true);
      } catch (error) {
        throw new Error('Error loading suggestions');
      } finally {
        setLoading(false);
      }
    },
    [loadSuggestionsMethod, mapResults, valueDecorator],
  );

  const debounceMethod = debounce(DEBOUNCE_THRESHOLD, loadSuggestions);

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
    setSuggestionsLoaded(false);
  };

  const onSuggestionsFetchRequested = ({
    value: searchValue,
    loadNextPage,
  }: {
    value: string;
    reason: string;
    loadNextPage?: boolean;
  }) => {
    debounceMethod(searchValue, loadNextPage);
  };

  const onChange = (event: React.FormEvent<HTMLElement>, { newValue }: ChangeEvent) => {
    const resultValue = valueDecorator ? valueDecorator(newValue) : newValue;
    setSelectedValue(resultValue);
    handleInputChange(resultValue);

    return resultValue;
  };

  const onSuggestionSelected = (
    event: React.FormEvent<any>,
    { suggestion }: SuggestionSelectedEventData<Suggestion>,
  ) => {
    const { text, id, ...otherFields } = suggestion;
    setSelectedValue(text);

    if (selecteSuggestionMethod) {
      selecteSuggestionMethod(id);
    }

    if (onChangeCallback) {
      onChangeCallback({
        [source]: id,
        ...otherFields,
      });
    }
  };

  return (
    <AutosuggestionDropdownLayout
      {...props}
      suggestionsPageRef={suggestionsPageRef}
      suggestions={suggestions}
      suggestionsLoaded={suggestionsLoaded}
      suggestionsTotalPages={suggestionsTotalPages}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      onSuggestionSelected={onSuggestionSelected}
      source={source}
      value={selectedValue}
      onChangeCallback={onChange}
      loading={loading}
      isRequired={inputHelper.getIsRequired()}
      isVisible={inputHelper.isVisible()}
      errorMessages={inputHelper.getErrorMessages()}
      onFocusCallback={inputHelper.getClearValidationCallback()}
      onBlurCallback={inputHelper.getValidationCallback()}
      noResultMessage={noResultMessage}
      resultsPerPage={resultsPerPage}
      disabled={inputHelper.getIsDisabled()}
    />
  );
};

export default AutosuggestDropdown;
