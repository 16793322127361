import React from 'react';
import { useParams } from 'react-router';
import CandidateTimelinePageLayout from 'erp/candidate/Timeline/CandidateTimelinePageLayout';
import CandidateTimelineLayout from 'erp/candidate/Timeline/shared/CandidateTimelineLayout';
import { Box } from '@mui/material';
import CandidateListFiltersLayout from 'erp/candidate/Timeline/shared/filter/CandidateListFiltersLayout';
import CandidateTimelineRecordTypeFilter from 'erp/candidate/Timeline/shared/filter/CandidateTimelineRecordTypeFilter';
import { Filters } from 'shared/uibuilder/list/filter';
import useCandidateService from '../../../candidateService';
import useCandidateApplicationItem from 'erp/candidate/Timeline/application/candidateApplicationRegistry';
import useCandidateNoteItem from 'erp/candidate/Timeline/note/note/candidateNoteRegistry';
import useCandidateSystemNoteItem from 'erp/candidate/Timeline/note/systemNote/candidateSystemNoteRegistry';
import useCandidateOfferItem from 'erp/candidate/Timeline/offer/candidateOfferRegistry';
import useCandidateResumeItem from 'erp/candidate/Timeline/resume/candidateResumeRegistry';
import Timeline from 'shared/uibuilder/timeline/Timeline';

const NOTE_TAG_SOURCE = 'note.tag';

const CandidateTimeline = () => {
  const { id: candidateId } = useParams<Dictionary<string>>();
  const { searchTimelineItems, createTimelineItem, getTimelinePageById } = useCandidateService();

  const noteRegistry = useCandidateNoteItem();
  const offerRegistry = useCandidateOfferItem();
  const resumeRegistry = useCandidateResumeItem();
  const applicationRegistry = useCandidateApplicationItem();
  const systemNoteRegistry = useCandidateSystemNoteItem();

  const filters = (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Filters>
        <CandidateListFiltersLayout>
          <CandidateTimelineRecordTypeFilter cleanableFilters={[NOTE_TAG_SOURCE]} source="type" />
        </CandidateListFiltersLayout>
      </Filters>
    </Box>
  );

  return (
    <Timeline
      registries={[noteRegistry, offerRegistry, resumeRegistry, systemNoteRegistry, applicationRegistry] as any}
      getDataMethod={request => searchTimelineItems(candidateId!, request)}
      createTimelineItemMethod={(itemType, request) => createTimelineItem(candidateId!, itemType, request) as any}
      getPageByEntryIdMethod={_id => getTimelinePageById(candidateId!, _id)}
    >
      <CandidateTimelinePageLayout filter={filters}>
        <CandidateTimelineLayout noEntriesMessage="No entries in this candidate timeline yet." />
      </CandidateTimelinePageLayout>
    </Timeline>
  );
};

export default CandidateTimeline;
