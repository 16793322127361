import React, { useEffect, useState } from 'react';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import Box from 'uibuilder/Box';
import Add from '@mui/icons-material/Add';
import CheckboxLayout from 'uibuilder/layout/form/input/CheckboxLayout';
import Typography from 'uibuilder/Typography';
import { Button } from 'uibuilder/button';
import { TextInput } from 'shared/uibuilder/form/input';

interface CustomIssue {
  id: number;
  text: string;
}

interface SelectedCheckboxes {
  [key: string]: boolean;
}

interface CallbackData {
  [key: string]: any;
}

interface IssuesModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: { selectedIssueTypes: string[]; customIssues: string[] }) => void;
  predefinedIssues: string[];
}

const IssuesEmployeeModal: React.FC<IssuesModalProps> = ({ isOpen, onClose, onSubmit, predefinedIssues = [] }) => {
  const [issues, setIssues] = useState<CustomIssue[]>([]);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState<SelectedCheckboxes>({});

  useEffect(() => {
    if (isOpen) {
      setIssues([]);
      setSelectedCheckboxes({});
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && predefinedIssues.length === 0 && issues.length === 0) {
      addCustomIssue();
    }
  }, [isOpen, predefinedIssues, issues.length]);

  const addCustomIssue = () => {
    setIssues(prev => [...prev, { id: Date.now(), text: '' }]);
  };

  const handleCheckboxChange = (text: string, checked: boolean) => {
    setSelectedCheckboxes(prev => ({
      ...prev,
      [text]: checked,
    }));
  };

  const handleIssueChange = (id: number, value: string) => {
    setIssues(issues.map(issue => (issue.id === id ? { ...issue, text: value } : issue)));
  };

  const handleSubmit = () => {
    const formData = {
      selectedIssueTypes: Object.entries(selectedCheckboxes)
        .filter(([_, isSelected]) => isSelected)
        .map(([text]) => text),
      customIssues: issues.map(issue => issue.text).filter(Boolean),
    };

    onSubmit(formData);
  };

  return (
    <ModalWindow
      isOpen={isOpen}
      onToggle={onClose}
      hasCancelButton
      buttons={
        <Button color="primary" variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      }
    >
      <Box mb={2}>
        {predefinedIssues.map((text, index) => {
          const checkboxId = `${index}`;
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index} sx={{ '& .MuiFormControl-root.checkbox-form-group': { mb: 0 } }}>
              <CheckboxLayout
                source={checkboxId}
                label={
                  <Typography
                    style={{
                      textDecoration: selectedCheckboxes[text] ? 'line-through' : 'none',
                    }}
                  >
                    {text}
                  </Typography>
                }
                value={selectedCheckboxes[text] || false}
                onChangeCallback={(event: object) => {
                  const data = event as CallbackData;
                  const isChecked = !!data[checkboxId];
                  handleCheckboxChange(text, isChecked);
                }}
              />
            </Box>
          );
        })}
      </Box>
      {issues.length > 0 && (
        <Box>
          {issues.map((issue, index) => (
            <Box mb={1} key={issue.id}>
              <TextInput
                source="test"
                label=" "
                placeholder="New issue"
                onChangeCallback={(inputData: any) => {
                  const value = inputData.test;
                  handleIssueChange(issue.id, value);
                }}
                value={issue.text}
              />
            </Box>
          ))}
        </Box>
      )}

      <Button link title="Add issue" onClick={addCustomIssue}>
        <Add />
        Add issue
      </Button>
    </ModalWindow>
  );
};

export default IssuesEmployeeModal;
