import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

const PlusIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask
          id="mask0_4731_2217"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <path
            d="M17.5 1H2.5C1.67157 1 1 1.67157 1 2.5V17.5C1 18.3284 1.67157 19 2.5 19H17.5C18.3284 19 19 18.3284 19 17.5V2.5C19 1.67157 18.3284 1 17.5 1Z"
            fill="white"
            stroke="white"
            strokeWidth="2"
            strokeLinejoin="round"
          />
          <path d="M10 6V14M6 10H14" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </mask>
        <g mask="url(#mask0_4731_2217)">
          <path id="plus_background" d="M-2 -2H22V22H-2V-2Z" fill="#5954A8" />
        </g>
      </svg>
    </SvgIcon>
  );
};

export default PlusIcon;
