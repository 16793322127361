import { useRecruitmentApi } from 'api';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ResourceData, ResourceId } from 'shared/crud/baseCrudService';
import { useCandidateUrl } from '../CandidateRouter';

const useDuplicateScanService = () => {
  const [mainCandidate, setMainCandidate] = useState<Dictionary<any>>({});
  const { sendGetRequest, sendPutRequest } = useRecruitmentApi();
  const { getSingleEntityUrl } = useCandidateUrl();
  const navigate = useNavigate();

  const getById = async (id: ResourceId) => {
    const response = await sendGetRequest(`/candidates/duplicate-scan/${id}`);
    const data = await response.json();
    setMainCandidate(data.mainCandidate);
    return {
      ...data,
      actualDuplicateIds: [],
    };
  };

  const process = async (id: ResourceId, data: ResourceData) => {
    const response = await sendPutRequest(`/candidates/duplicate-scan/${id}/process`, {
      actualDuplicateIds: data.actualDuplicateIds,
    });
    return response.json();
  };

  const redirectCallback = useCallback(() => {
    navigate(getSingleEntityUrl(mainCandidate.id));
  }, [getSingleEntityUrl, mainCandidate.id, navigate]);

  return {
    redirectCallback,
    mainCandidate,
    getById,
    process,
  };
};

export default useDuplicateScanService;
