import React from 'react';
import { RadioButtonGroup } from 'shared/uibuilder/form/input';
import { SOURCING_FILTERS } from '../../useVacancySourcingService';
import { ENGLISH_LEVEL_OPTIONS, MAP_ENGLISH_LEVEL_TO_SHORT } from 'erp/candidate/candidateService';

import './RadioButtonsSourcingFilter.scss';

const englishLevels = Object.values(ENGLISH_LEVEL_OPTIONS).map(item => {
  return {
    value: item,
    label: MAP_ENGLISH_LEVEL_TO_SHORT[item],
  };
});

const RadioButtonsSourcingFilter = (props: any) => {
  return (
    <RadioButtonGroup
      className="radio-buttons-sourcing"
      label=""
      source={SOURCING_FILTERS.englishLevel}
      options={englishLevels}
      {...props}
    />
  );
};

export default RadioButtonsSourcingFilter;
