import momentTimezone from 'moment-timezone';
import { orderBy } from 'lodash';

export const getBaseOptions = () => {
  const timeZonesNames = momentTimezone.tz
    .names()
    .filter(zone => zone.indexOf('/') >= 0)
    .filter(zone => !zone.includes('Etc'));

  let mappedTimeZones = timeZonesNames
    .map(zone => ({
      id: zone,
      city: zone.split('/').slice(-1)[0].replace(/_/i, ' '),
      utc: `(UTC${momentTimezone.tz(zone).format('Z')})`,
    }))
    .filter(
      (outerItem, index, currentArray) =>
        currentArray.findIndex(innerItem => innerItem.city === outerItem.city && innerItem.utc === outerItem.utc) ===
        index,
    )
    .filter(item => !item.city.includes('Kyiv')); // Delete dublicate (Kiev / Kyiv)
  mappedTimeZones = orderBy(mappedTimeZones, ['utc'], ['asc']);

  return mappedTimeZones;
};

// eslint-disable-next-line import/prefer-default-export
export const getTimezonesOptions = () => {
  const mappedTimeZones = getBaseOptions();

  const options = mappedTimeZones.map(zone => ({
    value: zone.id,
    label: `${zone.utc} ${zone.city}`,
  }));

  return options;
};
