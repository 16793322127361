import { useCallback, useEffect, useState } from 'react';
import { PlaceholderValues } from 'shared/markdownEditor/MarkdownTypes';
import { VacancyGroupType } from 'erp/recruitment/newVacancy/types';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import { useDateService } from 'shared/uibuilder/dateService';
import { fetchOfferTemplate } from './services/OfferTemplateService';
import { DEFAULT_ERROR_MESSAGE } from 'shared/uibuilder/form/formHelper';
import { calculateAcceptanceDate } from 'shared/markdownEditor/TemplateUtils';

interface UseTemplateDataProps {
  offerId: string | undefined;
  candidateData: any;
  getById: (id: string | undefined) => Promise<any>;
  search: (params: any) => Promise<any>;
}

interface TemplateDataResult {
  isLoading: boolean;
  editedContent: string;
  firstName: string;
  fullName: string;
  placeholderValues: PlaceholderValues;
  candidateVacancy: VacancyGroupType | null;
  setCandidateVacancy: (vacancy: VacancyGroupType | null) => void;
  setEditedContent: (content: string) => void;
}

// eslint-disable-next-line import/prefer-default-export
export const useOfferTemplateData = ({
  offerId,
  candidateData,
  getById,
  search,
}: UseTemplateDataProps): TemplateDataResult => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [editedContent, setEditedContent] = useState<string>('');
  const [placeholderValues, setPlaceholderValues] = useState<PlaceholderValues>({});
  const [candidateVacancy, setCandidateVacancy] = useState<VacancyGroupType | null>(null);

  const { addMessage } = useMessageService();
  const { getToday } = useDateService();
  const firstName = candidateData.getValueBySource('name.firstName');
  const fullName = candidateData.getValueBySource('name.fullName');

  const offerData = useCallback(() => {
    return getById(offerId);
  }, [offerId, getById]);

  useEffect(() => {
    const loadAllData = async () => {
      try {
        setIsLoading(true);

        const offer = await offerData();

        const loadedTemplate = await fetchOfferTemplate(offer);
        if (!loadedTemplate) {
          addMessage(new ErrorMessage(`Can't load template`));
          return;
        }

        setEditedContent(loadedTemplate.content);

        let vacancy = candidateVacancy;
        if (!vacancy && candidateData.lastAppliedVacancy) {
          try {
            const vacancies = await search({ filter: { alias: { eq: candidateData.lastAppliedVacancy } } });
            [vacancy] = vacancies.result;
            setCandidateVacancy(vacancy);
          } catch (e) {
            const result = await (e as any).json();
            addMessage(new ErrorMessage(result?.errors?.stateStep || DEFAULT_ERROR_MESSAGE));
          }
        }

        const compensationDetail = offer?.compensationDetails?.[0] || {};

        if (compensationDetail) {
          const { finalAmountGross, probationAmountGross, baseAmountType, contractType, currency, office } =
            compensationDetail;

          const initialValues: PlaceholderValues = {
            candidateName: firstName,
            vacancySpecialization: vacancy?.specialization,
            acceptanceDate: calculateAcceptanceDate(getToday()),
            fullName,
            finalAmountGross,
            probationAmountGross,
            contractType,
            currency,
            compensationDetails: !!compensationDetail,
            baseAmountType: baseAmountType ? baseAmountType.toLowerCase() : '',
            office,
          };

          setPlaceholderValues(initialValues);
        }
      } catch (err) {
        addMessage(new ErrorMessage(`Error loading data: ${err instanceof Error ? err.message : String(err)}`));
      } finally {
        setIsLoading(false);
      }
    };

    loadAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offerId]);

  return {
    isLoading,
    editedContent,
    placeholderValues,
    candidateVacancy,
    firstName,
    fullName,
    setCandidateVacancy,
    setEditedContent,
  };
};
