/* istanbul ignore file */
import React from 'react';
import { Button } from 'uibuilder/button';
import Box from 'uibuilder/Box';
import { useFilterContext } from 'shared/uibuilder/list/filter/FilterContext';
import styles from './CandidateTimelineRecordTypeDropdown.module.scss';

interface FilterActionsProps {
  onChangeCallback: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const CandidateTimelineFilterActions = ({ onChangeCallback }: FilterActionsProps) => {
  const { apply } = useFilterContext();

  return (
    <Box className={styles.dropdown_btn_box}>
      <Button
        contained
        onClick={(event: any) => {
          onChangeCallback(event);
          apply();
        }}
      >
        Apply
      </Button>
    </Box>
  );
};

export default CandidateTimelineFilterActions;
