import { createContext, useContext } from 'react';

interface FormShowContextData {
  isEditMode: boolean;
  toggleEditMode: () => void;
}

const FormShowContext = createContext<FormShowContextData>({
  isEditMode: false,
  toggleEditMode: () => {},
});
const FormShowContextProvider = FormShowContext.Provider;

const useFormShowContext = () => {
  const context = useContext(FormShowContext);
  return context || {};
};
export { FormShowContext, useFormShowContext, FormShowContextProvider };
