// libs
import React from 'react';
import { Navigate, Routes, useParams } from 'react-router-dom';
// components
import Page404 from 'shared/uibuilder/Page404';
import CandidateContext from 'erp/candidate/CandidateContext';
import CandidateViewPage from 'erp/candidate/Show';
import { ProtectedRouter } from 'routing';
import { Route } from 'shared/routing';

// recruiting services
import { RECRUITING_CANDIDATE_READ } from './constants';

// kernel services
import useRoute from 'shared/routing/useRoute';
import useEntityUrl from 'shared/routing/useEntityUrl';
import CandidateIdPage from 'erp/candidate/CandidateIdPage';
import OfferTemplateWorkspace from './Timeline/offer/offerEditor/OfferTemplateWorkspace';
import OfferTemplateWrapper from './Timeline/offer/offerEditor/OfferTemplateWrapper';

export const CANDIDATES_PATH = '/candidates';

export const useCandidateRoute = () => {
  const routes = useRoute({ listRoute: CANDIDATES_PATH, editPostfix: 'profile/edit' });

  return {
    ...routes,
    profileRoute: `${routes.singleEntityRoute}/profile/:timelineEntryId?`,
    offersRoute: `${routes.singleEntityRoute}/offers/*`,
    communicationRoute: `${routes.singleEntityRoute}/communication/:timelineEntryId?`,
    createCandidateApplicationFromJobBoardRoute: `${routes.listRoute}/applications/add/job-board`,
    applicationsRoute: `${routes.singleEntityRoute}/applications`,
    editOfferRoute: `${routes.singleEntityRoute}/profile/:offerId/edit`,
  };
};

const RedirectToProfile = () => {
  const id = useCandidateId();
  const { getProfileUrl } = useCandidateUrl();
  return <Navigate to={getProfileUrl(id)} />;
};

export const useCandidateUrl = () => {
  const entityUrl = useEntityUrl({
    baseLink: CANDIDATES_PATH,
    editPostfix: 'profile/edit',
  });

  const { getSingleEntityUrl } = entityUrl;

  return {
    ...entityUrl,
    getSingleEntityUrl,
    getProfileUrl: (id?: string) => `${getSingleEntityUrl(id)}/profile`,
    getProfileEditUrl: (id: StringOrNumber) => `${getSingleEntityUrl(id)}/profile/edit`,
    getCommunicationUrl: (id: StringOrNumber) => `${getSingleEntityUrl(id)}/communication`,
    getApplicationsUrl: (id: StringOrNumber) => `${getSingleEntityUrl(id)}/applications`,
  };
};

export const useCandidateId = () => {
  const params = useParams<Dictionary<string>>();

  return params.id!;
};

const CandidateRouter = () => {
  const { singleEntityRoute, profileRoute, timelineRoute, editOfferRoute } = useCandidateRoute();

  return (
    <ProtectedRouter basePath={CANDIDATES_PATH}>
      <Routes>
        <Route
          path={profileRoute}
          element={
            <CandidateContext permissionToCheck={RECRUITING_CANDIDATE_READ}>
              <CandidateViewPage />
            </CandidateContext>
          }
        />

        <Route path={timelineRoute} element={<RedirectToProfile />} />
        <Route path={singleEntityRoute} element={<CandidateIdPage />} />
        <Route
          path={editOfferRoute}
          element={
            <CandidateContext permissionToCheck="">
              <OfferTemplateWrapper>
                <OfferTemplateWorkspace />
              </OfferTemplateWrapper>
            </CandidateContext>
          }
        />

        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

CandidateRouter.getRouterPath = () => `${CANDIDATES_PATH}/*`;

export default CandidateRouter;
