import { useState } from 'react';
import { useKernelApi } from 'api';
import { useCandidateId } from 'erp/candidate/CandidateRouter';
import useMessageService, { ErrorMessage } from 'shared/message/messageService';
import useBaseCrudService, { ResourceId } from 'shared/crud/baseCrudService';
import candidateOfferValidation, {
  editOfferOnlyAttachmentValidation,
} from 'erp/candidate/offercompensation/createupdate/candidateOfferValidation';
import useAuthorization from 'shared/authorization/authorizationService';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';
import { CANDIDATE_RESOURCE_URL, UPDATE_CANDIDATE_OFFER_ATTACHMENTS } from 'erp/candidate/candidateService';

export const OFFER_COMPENSATION_URL = '/offers';

const useCandidateOfferService = () => {
  const [offerData, setOfferData] = useState(null);
  const { sendPostRequest, sendPatchRequest } = useKernelApi();
  const candidateId = useCandidateId();
  const parentResourceUrl = `${CANDIDATE_RESOURCE_URL}/${candidateId}`;

  const { addMessage } = useMessageService();

  const { isGranted } = useAuthorization();
  const isOnlyAttachments = isGranted(UPDATE_CANDIDATE_OFFER_ATTACHMENTS);

  const {
    getById: initialGetById,
    update: initialUpdate,
    ...baseCrudRequests
  } = useBaseCrudService({
    singleResourceUrl: `${parentResourceUrl}${OFFER_COMPENSATION_URL}/:id`,
    listResourceUrl: `${parentResourceUrl}${OFFER_COMPENSATION_URL}`,
    apiService: useKernelApi,
  });

  const getSensitiveDataByIds = async (ids: []) => {
    const url = `${parentResourceUrl}${OFFER_COMPENSATION_URL}/sensitive`;

    try {
      const response = await sendPostRequest(url, ids);
      const result = await response.json();

      return result;
    } catch (err) {
      addMessage(new ErrorMessage("Can't get compensation data"));

      return null;
    }
  };

  const getById = async (id: ResourceId) => {
    if (offerData) {
      return offerData;
    }

    const data = await initialGetById(id);
    const offerCompensations = data.offerCompensations || [];

    const sensitiveIds = offerCompensations.flatMap((offer: any) => offer.sensitiveDataIds);
    const sensitiveData = await getSensitiveDataByIds(sensitiveIds);

    const combinedOfferCompensations = offerCompensations.map((offer: any) => {
      const combinedSensitive = offer.sensitiveDataIds.reduce((acc: any, sensitiveId: any) => {
        return { ...acc, ...sensitiveData[sensitiveId] };
      }, {});
      return { ...offer, ...combinedSensitive };
    });

    const combinedData = { ...data, offerCompensations: combinedOfferCompensations };

    setOfferData(combinedData);

    return combinedData;
  };

  const update = async (id: StringOrNumber, values: FormFieldsData) => {
    if (isOnlyAttachments) {
      return sendPatchRequest(`${parentResourceUrl}${OFFER_COMPENSATION_URL}/${id}`, {
        attachmentIds: values.attachmentIds,
      });
    } else {
      return initialUpdate(id, values);
    }
  };

  const getValidationSchema = () =>
    Promise.resolve(isOnlyAttachments ? editOfferOnlyAttachmentValidation : candidateOfferValidation);

  return {
    ...baseCrudRequests,
    getValidationSchema,
    getSensitiveDataByIds,
    isOnlyAttachments,
    update,
    getById,
  };
};

export default useCandidateOfferService;
