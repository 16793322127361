/* istanbul ignore file */
import React from 'react';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import {
  READ_CANDIDATE_NOTES_PERMISSION,
  UPDATE_CANDIDATE_NOTE_PERMISSION,
} from 'erp/candidate/Timeline/note/note/candidateNoteService';
import { HtmlField } from 'shared/uibuilder/field';
import CandidateNoteTag from 'erp/candidate/Timeline/note/shared/NoteTimelineRecordLayout/CandidateNoteTag';
import BaseCandidateTimelineRecordLayout from 'erp/candidate/Timeline/CandidateTimelineRecord/BaseCandidateTimelineRecordLayout';
import TimelineCollapsible from 'shared/uibuilder/timeline/collapsible/TimelineCollapsible';
import { candidateTimelineRecords } from 'erp/candidate/Timeline/shared/input/CandidateTimelineRecordTypeDropdown';

type NoteTimelineRecordLayoutProps = {
  onEdit: () => void;
  actionName: string;
};

const NoteTimelineRecordLayout = ({ onEdit, actionName, ...props }: NoteTimelineRecordLayoutProps) => {
  const buttons = [
    <EditTimelineRecordButton permissionToCheck={UPDATE_CANDIDATE_NOTE_PERMISSION} key="edit" onEdit={onEdit} />,
  ];

  return (
    <BaseCandidateTimelineRecordLayout
      permissionToCheck={READ_CANDIDATE_NOTES_PERMISSION}
      actionName={actionName}
      timelineType={candidateTimelineRecords.NOTE_CREATED}
      buttons={buttons}
      fields={
        <TimelineCollapsible>
          <HtmlField source="noteText" label="" />
        </TimelineCollapsible>
      }
      timelineFooter={rest => (
        <div className="d-flex justify-content-between align-items-center feedback-timeline-footer">
          <CandidateNoteTag {...rest} />
        </div>
      )}
      {...props}
    />
  );
};

export default NoteTimelineRecordLayout;
