import React, { useState } from 'react';
import Droppable from 'shared/dnd/Droppable';
import Box from '@mui/material/Box';
import ModalWindow from 'shared/uibuilder/ModalWindow';
import { CreateForm } from 'shared/uibuilder/form';
import HiredForm from '../forms/HiredForm/HiredForm';
import DecisionForm from '../forms/DecisionForm/DecisionForm';
import { ColumnState, MappedApplication } from '../../types';
import { getBorderColor } from './utils';
import useRecruitingBoardService from '../../useRecruitingBoardService';
import { Stages } from '../../constants';
import RejectStageForm from '../forms/RejectForm/RejectForm';
import { GAP } from './Board';
import DraggableItem from './DraggableItem';
import MoveToInterviewForm from '../forms/MoveToInterviewForm/MoveToInterviewForm';

interface ColumnProps {
  entity: any;
  expanded: boolean;
  items: any;
  columnSettings: any;
}

interface PendingItem {
  vacancyId: string;
  version: number;
  applicationId: number;
  targetStage: string;
}

const Column: React.FC<ColumnProps> = ({ expanded, items, entity, columnSettings }) => {
  const [columnState, setColumnState] = useState<ColumnState>(ColumnState.IDLE);
  const [sourceStage, setSourceStage] = useState<string | null>(null);
  const [vacancyGroupStateId, setVacancyGroupStateId] = useState<string | null>(null);
  const renderItems = expanded ? items : items?.slice(0, 4);
  const [pendingItem, setPendingItem] = useState<PendingItem | null>(null);
  const [ActiveModal, setActiveModal] = useState<ReactComponent<any> | null>(null);
  const { moveStage, moveVacancyGroup } = useRecruitingBoardService();

  const closeDialog = () => {
    setActiveModal(null);
    setPendingItem(null);
    setSourceStage(null);
    setVacancyGroupStateId(null);
  };

  return (
    <>
      <Droppable
        params={{
          onDrop: ({ self, source }) => {
            const targetData = self.data;
            const targetDataId = self.data.id as string;
            const targetColumn = targetData.columnSettings as { stage: string };
            const targetStage = targetColumn.stage;

            const undoStates = source.data.undoStates as string[];

            const movedItem = source?.data as MappedApplication;
            const movedItemApplicationId = movedItem.id as number;
            const version = movedItem.version as number;
            const movedItemVacancyGroupId = movedItem.entityId as string;
            const movedItemIInitialColumnName = movedItem.stage;
            setSourceStage(movedItemIInitialColumnName as string);
            setVacancyGroupStateId(movedItemVacancyGroupId);
            setColumnState(ColumnState.IDLE);

            if (
              (targetStage === movedItemIInitialColumnName && targetDataId === movedItemVacancyGroupId) ||
              (targetDataId !== movedItemVacancyGroupId && targetStage !== movedItemIInitialColumnName)
            ) {
              return;
            }

            if (movedItem.stage === targetStage && targetDataId !== movedItemVacancyGroupId) {
              moveVacancyGroup(movedItem, targetDataId, targetData.alias as string);
              return;
            }

            if (undoStates.includes(targetStage)) {
              moveStage(movedItemVacancyGroupId, version, movedItemApplicationId, targetStage);
            } else if (targetStage === Stages.Interview) {
              setPendingItem({
                vacancyId: movedItemVacancyGroupId,
                applicationId: movedItemApplicationId,
                version,
                targetStage,
              });
              setActiveModal(() => MoveToInterviewForm);
            } else if (targetStage === Stages.Hired) {
              setPendingItem({
                vacancyId: movedItemVacancyGroupId,
                applicationId: movedItemApplicationId,
                version,
                targetStage,
              });
              setActiveModal(() => HiredForm);
            } else if (targetStage === Stages.Decision) {
              setPendingItem({
                vacancyId: movedItemVacancyGroupId,
                applicationId: movedItemApplicationId,
                version,
                targetStage,
              });
              setActiveModal(() => DecisionForm);
            } else if (targetStage === Stages.PendingRejection) {
              setPendingItem({
                vacancyId: movedItemVacancyGroupId,
                applicationId: movedItemApplicationId,
                version,
                targetStage,
              });
              setActiveModal(() => RejectStageForm);
            } else {
              moveStage(movedItemVacancyGroupId, version, movedItemApplicationId, targetStage);
            }
          },
          onDragEnter: args => {
            const movedItem = args?.source?.data;
            const targetColumn = args.self.data.columnSettings as { stage: string };
            const targetStage = targetColumn.stage;
            if (movedItem.entityId === entity.id || movedItem.stage === targetStage) {
              setColumnState(ColumnState.VALID_DROP);
            } else {
              setColumnState(ColumnState.INVALID_DROP);
            }
          },
          onDragLeave: () => setColumnState(ColumnState.IDLE),
        }}
        data={{ ...entity, columnSettings }}
      >
        {({ droppableRef }) => (
          <Box
            ref={droppableRef}
            gap={GAP}
            sx={{
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflowY: 'auto',
              overflowX: 'hidden',
              backgroundColor: 'white',
              borderRadius: 1,
              border: getBorderColor(columnState),
            }}
          >
            {renderItems?.map((itemData: any) => (
              <DraggableItem key={itemData.id} item={itemData} columnSettings={columnSettings} />
            ))}
          </Box>
        )}
      </Droppable>
      {ActiveModal && (
        <ModalWindow
          isOpen
          key="modal-not-strict"
          modalSize="sm"
          backdrop="static"
          title="Additional info required"
          onToggle={closeDialog}
        >
          <CreateForm
            submitFormFunc={formData => {
              if (pendingItem) {
                return moveStage(
                  pendingItem.vacancyId,
                  pendingItem.version,
                  pendingItem.applicationId,
                  pendingItem.targetStage,
                  formData,
                );
              } else {
                return Promise.reject();
              }
            }}
            afterSubmit={{
              execute: () => {
                setPendingItem(null);
                setActiveModal(null);
                setSourceStage(null);
              },
            }}
          >
            <ActiveModal
              handleCancelClick={closeDialog}
              sourceStage={sourceStage}
              vacancyGroupId={vacancyGroupStateId}
              entity={entity}
            />
          </CreateForm>
        </ModalWindow>
      )}
    </>
  );
};

export default React.memo(Column);
