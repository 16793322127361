/* istanbul ignore file */
import accountValidation from 'crm/account/createupdate/accountValidation';
import useTimelineService from 'shared/crud/timelineService';
import useCrudService from 'shared/crud';
import { useKernelApi } from 'api';
import accountFilterValidation from 'crm/account/list/filter/accountFilterValidation';
import { ResourceId } from 'shared/crud/baseCrudService';

export const READ_ACCOUNTS_LIST = 'READ_ACCOUNTS_LIST';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const UPDATE_ACCOUNT = 'UPDATE';
export const UPDATE_ACCOUNT_FROM_LIST_PAGE = 'CREATE_ACCOUNT';
export const READ_ACCOUNT = 'READ';
export const DELETE_ACCOUNT = UPDATE_ACCOUNT;
export const CHANGE_ACCOUNT_OWNER = 'CHANGE_OWNER';
export const READ_FULL_ACCOUNTS_LIST = 'READ_FULL_ACCOUNTS_LIST';
export const READ_SENSITIVE_ACCOUNT_NAME = 'READ_SENSITIVE_ACCOUNT_NAME';

export const ACCOUNT_TYPES_ALIAS = {
  CUSTOMER: 'CUSTOMER',
  PARTNER: 'PARTNER',
  OTHER: 'OTHER',
};

export const ACCOUNT_TYPES = {
  [ACCOUNT_TYPES_ALIAS.CUSTOMER]: 'Customer',
  [ACCOUNT_TYPES_ALIAS.PARTNER]: 'Partner',
  [ACCOUNT_TYPES_ALIAS.OTHER]: 'Other',
};

/**
 * Contact service.
 *
 * @returns {{getById: (function(*=): *), deleteById: ((function(*=): *)|Promise<any>), create: (function(*=): *), update: (function(*=, *=): *)}}
 */

const useAccountService = () => {
  const { update: baseUpdate, ...baseCrudRequests } = useCrudService({
    singleResourceUrl: '/crm/accounts/:id',
    listResourceUrl: '/crm/accounts',
    apiService: useKernelApi,
  });

  const { sendPostRequest, sendPatchRequest } = useKernelApi();

  const update = async (id: StringOrNumber, data: any) => {
    const preparedData = { ...data };
    delete preparedData.accountId;

    return baseUpdate(id, preparedData);
  };

  const getValidationSchema = () => Promise.resolve(accountValidation);

  // todo improve search by name
  const {
    search,
    create: createTimelineItem,
    getTimelinePageById,
  } = useTimelineService({
    parentEntityUrl: `/crm/accounts/:id`,
    apiService: useKernelApi,
  });

  const searchTimelineItems = async (accId: StringOrNumber, requestData: any) => {
    const pinRespoce = await search(accId, {
      ...requestData,
      filter: {
        ...requestData?.filter,
        isPinned: {
          eq: true,
        },
      },
    });

    const unPinRespoce = await search(accId, {
      ...requestData,
      filter: {
        ...requestData?.filter,
        isPinned: {
          eq: false,
        },
      },
    });

    return {
      ...unPinRespoce,
      result: [...pinRespoce.result, ...unPinRespoce.result],
    };
  };

  const pinAccountTimeline = async (accountId: ResourceId, timelineId: ResourceId, pinStatus: boolean) => {
    const request = await sendPatchRequest(`/crm/accounts/${accountId}/timeline/${timelineId}`, {
      isPinned: pinStatus,
    });

    return request.json();
  };

  const getFilterValidationSchema = () => Promise.resolve(accountFilterValidation);

  const getOriginalName = async (id: StringOrNumber) => {
    const response = await sendPostRequest(`/crm/accounts/${id}/name/sensitive`);
    return response.json();
  };

  return {
    ...baseCrudRequests,
    update,
    getValidationSchema,
    searchTimelineItems,
    createTimelineItem,
    getTimelinePageById,
    getFilterValidationSchema,
    getOriginalName,
    pinAccountTimeline,
  };
};

export default useAccountService;
