import React from 'react';
import FormSection from 'shared/layout/form/FormSection';
import FormRow from 'shared/layout/form/FormRow';
import { NumberInput } from 'shared/uibuilder/form/input';
import RowGroupInputsWrapper from 'uibuilder/form/RowGropInputsWrapper/RowGroupInputsWrapper';
import EnumDropdown from 'shared/uibuilder/form/input/EnumDropdown';
import ClearAfterUnmountWrapper from 'shared/uibuilder/form/ClearAfterUnmountWrapper';
import { HIRING_MODE } from '../../hiring/useHiringService';
import TargetHireDateInput from '../shared/inputs/TargetHireDateInput';
import InputList from 'shared/uibuilder/form/input/InputList/InputList';
import WorkingConditionsInputs from '../shared/inputs/WorkingConditionsInputs';
import ProjectDescriptionInputs from '../shared/inputs/ProjectDescriptionInputs';
import SkillsInput from 'shared/uibuilder/skillsInput/SkillsInput';
import useCommonSkillsService from '../../../candidate/commonskills/commonSkillsService';
import MediumFormRow from 'shared/layout/form/MediumFormRow';
import NoteAttachmentUploaderInput from 'artifact/shared/input/NoteAttachmentUploaderInput';
import BigFormRow from 'shared/layout/form/BigFormRow';
import { COMPETENCY_LEVEL, ENGLISH_LEVEL_SHORT_OPTIONS, EXPIRATION_PERIOD_OPTIONS } from '../constants';
import SpecializationDropdown from '../shared/inputs/SpecializationDropdown';
import VacancyWysiwygInput from '../../vacancy/shared/input/VacancyWysiwygInput';

const VacancyForm = () => {
  const { searchByName } = useCommonSkillsService();

  return (
    <>
      <FormSection title="Main Information">
        <FormRow>
          <SpecializationDropdown source="specialization" tooltip="Select Specialization or create new." />
          <ClearAfterUnmountWrapper>
            <EnumDropdown
              source="competencyLevel"
              options={COMPETENCY_LEVEL}
              label="Competency Level"
              placeholder="Select Competency level"
              isRequired
            />
          </ClearAfterUnmountWrapper>
          <RowGroupInputsWrapper isRequired wrapperLabel="People to hire">
            <NumberInput className="group-input-label" source="peopleToHireMin" label="Min" />
            <NumberInput className="group-input-label" source="peopleToHireMax" label="Max" />
          </RowGroupInputsWrapper>
        </FormRow>

        <FormRow>
          <TargetHireDateInput />

          <EnumDropdown
            options={EXPIRATION_PERIOD_OPTIONS}
            source="expirationPeriodInMonth"
            label="Expiration period (Month)"
            placeholder="Select period"
          />

          <EnumDropdown
            tooltip={
              <>
                Active hiring mode means that a new position is available and the person is needed to fill it.
                <br />
                Bar raiser means that no additional positions are available, but the staffing coordinator will replace
                low-performers with the hired person.
              </>
            }
            options={HIRING_MODE}
            source="hiringMode"
            label="Hiring Mode"
            placeholder="Select Mode"
          />
        </FormRow>
      </FormSection>

      <FormSection title="Working Conditions">
        <FormRow colMd={12} colXl={12}>
          <ClearAfterUnmountWrapper>
            <InputList
              minFields={1}
              source="workingConditions"
              addText="Add location"
              maxFields={10}
              inputTemplate={(props: any) => WorkingConditionsInputs({ isUpdateForm: false, props })}
            />
          </ClearAfterUnmountWrapper>
        </FormRow>
      </FormSection>

      <FormSection title="Project Description">
        <ClearAfterUnmountWrapper>
          <InputList source="projects" addText="Add project" maxFields={10} inputTemplate={ProjectDescriptionInputs} />
        </ClearAfterUnmountWrapper>
      </FormSection>

      <FormSection title="Responsibilities*">
        <BigFormRow>
          <VacancyWysiwygInput source="responsibilities" label="" />
        </BigFormRow>
      </FormSection>

      <FormSection title="Mandatory Requirements">
        <BigFormRow>
          <VacancyWysiwygInput source="mandatoryRequirements.description" label="" />
        </BigFormRow>

        <MediumFormRow>
          <SkillsInput
            source="mandatoryRequirements.skills"
            loadSuggestionsMethod={searchByName}
            label="Skills"
            helpText="Required skills for the Vacancy. These skills will be used for Candidate matching purposes and will not be publicly displayed."
          />
        </MediumFormRow>

        <FormRow>
          <NumberInput source="mandatoryRequirements.requiredExperienceInYears" label="Required Experience (years)" />

          <EnumDropdown
            source="mandatoryRequirements.englishLevel"
            label="English Level"
            options={ENGLISH_LEVEL_SHORT_OPTIONS}
            placeholder="Choose English Level"
          />
        </FormRow>
      </FormSection>

      <FormSection title="Optional Requirements">
        <BigFormRow>
          <VacancyWysiwygInput source="optionalRequirements.description" label="" />
        </BigFormRow>

        <MediumFormRow>
          <SkillsInput
            source="optionalRequirements.skills"
            loadSuggestionsMethod={searchByName}
            label="Skills:"
            helpText="Optional skills for the Vacancy. These skills will be used for Candidate matching purposes and will not be publicly displayed."
          />
        </MediumFormRow>
      </FormSection>

      <FormSection title="Notes">
        <BigFormRow>
          <VacancyWysiwygInput source="notes.description" label="" />
        </BigFormRow>
        <FormRow>
          <NoteAttachmentUploaderInput label="Attachments" source="notes.attachmentIds" />
        </FormRow>
      </FormSection>
    </>
  );
};

export default VacancyForm;
