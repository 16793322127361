import React from 'react';
import useFieldHelper from 'shared/uibuilder/field/fieldHelper';
import Box from 'uibuilder/Box';
import CountryBadge from 'shared/uibuilder/form/input/CountryBadge';

const CandidateCountry = () => {
  const { getValue } = useFieldHelper({ source: 'residenceCountry' });
  const countryCode = getValue();
  if (typeof countryCode === 'undefined') {
    return '';
  }
  return (
    <Box sx={{ padding: '0px', width: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <CountryBadge country={countryCode} />
    </Box>
  );
};

export default CandidateCountry;
