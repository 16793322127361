import React, { useEffect, useState } from 'react';
import { Button } from 'uibuilder/button';
import Box from 'uibuilder/Box';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import IssuesEmployeeModal from './IssuesEmployeeModal';
import { EMPLOYEE_DASHBOARD_COLORS } from '../../constants';

const ChangeZoneButton = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [predefinedIssues, setPredefinedIssues] = useState<string[]>([]);
  const { data } = useShowContext();
  const currentZone = data?.currentZone as keyof typeof EMPLOYEE_DASHBOARD_COLORS;

  useEffect(() => {
    if (data?.issues) {
      setPredefinedIssues(data.issues);
    }
  }, [data]);

  const buttonColor = EMPLOYEE_DASHBOARD_COLORS[currentZone] || EMPLOYEE_DASHBOARD_COLORS.SAFE;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSubmit = () => {
    toggleModal();
  };

  return (
    <Box>
      <Button size="small" onClick={toggleModal} sx={{ backgroundColor: buttonColor, fontSize: '10px' }}>
        {currentZone}
      </Button>

      <IssuesEmployeeModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        onSubmit={handleSubmit}
        predefinedIssues={predefinedIssues}
      />
    </Box>
  );
};

export default ChangeZoneButton;
