import React from 'react';
import { Route, Routes, useParams } from 'react-router-dom';
import Page404 from 'shared/uibuilder/Page404';
import CandidateDuplicateScanContext from './CandidateDuplicateScanContext';
import CandidateDuplicateScanProcessPage from './createupdate/CandidateDuplicateScanProcessPage';
import useRoute from 'shared/routing/useRoute';
import { ProtectedRouter } from 'routing';

export const useCandidateDuplicateScanRoute = () => {
  const routes = useRoute({ listRoute: '/candidates/duplicate-scan' });
  return {
    processRoute: `${routes.singleEntityRoute}`,
  };
};

export const useCandidatesIdDuplicateScan = () => useParams<Dictionary<string>>().id!;

const CandidateDuplicateScanRouter = () => {
  const { processRoute } = useCandidateDuplicateScanRoute();

  return (
    <ProtectedRouter basePath="/candidates/duplicate-scan">
      <Routes>
        <Route
          path={processRoute}
          element={
            <CandidateDuplicateScanContext permissionToCheck="READ">
              <CandidateDuplicateScanProcessPage />
            </CandidateDuplicateScanContext>
          }
        />
        <Route path="*" element={<Page404 />} />
      </Routes>
    </ProtectedRouter>
  );
};

CandidateDuplicateScanRouter.getRouterPath = () => `/candidates/duplicate-scan/*`;

export default CandidateDuplicateScanRouter;
