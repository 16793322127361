/* istanbul ignore file */
import React from 'react';
import { useShowContext } from 'shared/uibuilder/show/ShowContext';
import BaseTimelineRecordLayout from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout';
import PresaleRequestFields from 'crm/opportunity/presale/request/PresaleRequestTimelineRecordLayout/PresaleRequestFields';
import { TimelineType } from 'uibuilder/layout/timeline/layout/BaseTimelineRecordLayout/timelineLayoutHelper';
import Collapsible from 'shared/uibuilder/field/Collapsible';
import RedmineIssueField from 'redmine/RedmineIssueField';
import EditTimelineRecordButton from 'uibuilder/layout/timeline/buttons/EditTimelineRecordButton';
import { UPDATE_PRESALE_REQUEST } from 'crm/opportunity/shared/opportunityService';
import PresaleStatusBadge from 'crm/opportunity/presale/request/shared/field/PresaleStatusBadge';
import PinTimelineButton from 'crm/shared/buttons/PinTimelineButton';
import { useOpportunityId } from 'crm/opportunity/OpportunityRouter';
import { useListContext } from 'shared/uibuilder/list/ListContext';
import { TimelineContextData } from 'shared/uibuilder/timeline/Timeline';

const PresaleRequestTimelineRecordLayout = ({ onEdit, isPinned, ...props }: any) => {
  const opportunityId = useOpportunityId();
  const { data } = useShowContext();
  const timelineId = data.getValueBySource('timelineId');
  const { updateInList, pinTimelineAction }: TimelineContextData = useListContext();

  const buttons = [<EditTimelineRecordButton onEdit={onEdit} permissionToCheck={UPDATE_PRESALE_REQUEST} />];

  return (
    <BaseTimelineRecordLayout
      fields={
        <Collapsible>
          <PresaleRequestFields />
        </Collapsible>
      }
      entity={data}
      timelineType={TimelineType.FEEDBACK}
      buttons={buttons}
      actions={[
        <PinTimelineButton
          pinStatus={isPinned}
          pinMethod={async () => {
            const response = await pinTimelineAction!(opportunityId, timelineId, !isPinned);
            updateInList!(response?.id, response);
          }}
        />,
      ]}
      timelineFooter={() => (
        <div className="timeline__additional d-flex">
          <span>Redmine ticket:&nbsp;</span>
          <RedmineIssueField source="redmineTicketId" />
          <PresaleStatusBadge statusSource="requestStatus" />
        </div>
      )}
      {...props}
    />
  );
};

export default PresaleRequestTimelineRecordLayout;
