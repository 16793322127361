import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

const FeedbackIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.7706 12.4C14.9206 12.47 15.0906 12.5 15.2506 12.5C15.5806 12.5 15.8906 12.37 16.1306 12.14L18.3106 10H19.2506C20.7706 10 22.0006 8.77 22.0006 7.25V4.75C22.0006 3.23 20.7706 2 19.2506 2H14.7506C13.2306 2 12.0006 3.23 12.0006 4.75V7.25C12.0006 8.51 12.8506 9.57 14.0006 9.9V11.25C14.0006 11.75 14.3106 12.2 14.7706 12.4ZM8.00059 13.5C6.07059 13.5 4.50059 11.93 4.50059 10C4.50059 8.07 6.07059 6.5 8.00059 6.5C9.93059 6.5 11.5006 8.07 11.5006 10C11.5006 11.93 9.93059 13.5 8.00059 13.5ZM8.00059 22C5.94059 22 4.36059 21.44 3.30059 20.33C1.96459 18.926 1.99759 17.156 2.00059 16.973V16.96C2.00059 15.89 2.90059 15 4.00059 15H12.0006C13.1006 15 14.0006 15.9 14.0006 17L14.0016 17.006C14.0046 17.133 14.0466 18.916 12.7016 20.33C11.6406 21.44 10.0606 22 8.00059 22Z"
          fill="#5954A8"
        />
      </svg>
    </SvgIcon>
  );
};

export default FeedbackIcon;
