import { useBusinessEngineApi } from 'api';
import { SearchParams } from '../uibuilder/list/ListContext';
import { CrudServiceProvider } from './index';

export type ResourceId = StringOrNumber;
export type ResourceData = any;
export type ResultResourceData = any;

const useBaseCrudService: CrudServiceProvider = ({ listResourceUrl, singleResourceUrl, apiService = null }) => {
  let useService;

  if (!apiService) {
    useService = useBusinessEngineApi;
  } else {
    useService = apiService;
  }

  const { sendPostRequest, sendGetRequest, sendPutRequest, sendDeleteRequest } = useService();

  const create = async (data: ResourceData) => {
    const response = await sendPostRequest(listResourceUrl, data);
    const result = await response.json();
    return result.info || result;
  };

  const getById = async (id: ResourceId) => {
    const url = singleResourceUrl.replace(':id', `${id}`);
    const response = await sendGetRequest(url);
    const result = await response.json();
    return result.info || result;
  };

  const update = async (id: ResourceId, data: ResourceData) => {
    const url = singleResourceUrl.replace(':id', `${id}`);
    const response = await sendPutRequest(url, data);
    const result = await response.json();
    return result.info || result;
  };

  const deleteById = (id: ResourceId) => {
    const url = singleResourceUrl.replace(':id', `${id}`);
    return sendDeleteRequest(url);
  };

  const search = async (request: any) => {
    const url = `${listResourceUrl}/search`;
    const response = await sendPostRequest(url, request);
    return response.json();
  };

  const searchAll = async (params: SearchParams) => {
    let totalPages = 0;
    let currentPage = 0;
    let result: any[] = [];
    const url = `${listResourceUrl}/search`;

    do {
      // eslint-disable-next-line no-await-in-loop
      const response = await sendPostRequest(url, {
        pageSize: 30,
        ...params,
        pageNumber: currentPage,
      });
      // eslint-disable-next-line no-await-in-loop
      const data = await response.json();

      currentPage += 1;
      totalPages = data.totalPages;
      result = [...result, ...data.result];
    } while (currentPage < totalPages);

    return result;
  };

  const findAll = async () => {
    let totalPages = 0;
    let currentPage = 0;
    let result: any[] = [];

    do {
      const url = `${listResourceUrl}?pageNumber=${currentPage}`;
      // eslint-disable-next-line no-await-in-loop
      const response = await sendGetRequest(url);
      // eslint-disable-next-line no-await-in-loop
      const data = await response.json();

      currentPage += 1;
      totalPages = data.totalPages;
      result = [...result, ...data.result];
    } while (currentPage < totalPages);

    return result;
  };

  const getPermissions = async (id: ResourceId) => {
    const url = `${singleResourceUrl.replace(':id', `${id}`)}/permissions`;
    const response = await sendGetRequest(url);
    return response.json();
  };

  return {
    create,
    getById,
    update,
    deleteById,
    search,
    searchAll,
    getPermissions,
    findAll,
  };
};

export default useBaseCrudService;
