import { useRecruitmentApi } from 'api';
import { INTERVIEW_SCHEDULING_PATH } from 'erp/recruitment/publicInterviewScheduling/routing';
import { useState } from 'react';
import { copyByNavigator } from 'shared/clipboard';
import { FormFieldsData } from 'shared/uibuilder/form/FormContext';

const DAVINCI_CANDIDATE_PUBLIC = process.env.REACT_APP_DAVINCI_CANDIDATE_PUBLIC_FRONTEND_URL;

const useInterviewSchedulerLink = (currentApplicationId: number) => {
  const [isLoading, setLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const { sendPostRequest } = useRecruitmentApi();

  const copyAction = async (hash: string) => {
    try {
      await copyByNavigator(`${DAVINCI_CANDIDATE_PUBLIC}${INTERVIEW_SCHEDULING_PATH}/${hash}`);
      setIsCopied(true);
      setTimeout(() => {
        setIsCopied(false);
      }, 4000);
    } catch (err) {
      throw new Error('Clipboard error!');
    }
  };

  const getLink = async (requestData?: FormFieldsData) => {
    const { interviewers = [] } = requestData || {};
    try {
      setLoading(true);

      const responce = await sendPostRequest('/interviews/link', {
        applicationId: currentApplicationId,
        specifiedInterviewers: interviewers?.map((item: { value: string }) => item.value),
      });

      const data = await responce.json();
      setLoading(false);

      return data.interviewLinkToken;
    } catch (error) {
      setLoading(false);
      throw new Error('Something went wrong.');
    }
  };

  return {
    setLoading,
    isLoading,
    getLink,
    isCopied,
    copyAction,
  };
};

export default useInterviewSchedulerLink;
