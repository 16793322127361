import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import useCandidateService from 'erp/candidate/candidateService';
import useCrudService from 'shared/crud';
import { useRecruitmentApi } from 'api';
import { StepIconProps, Stepper, SvgIcon } from '@mui/material';
import Typography from 'uibuilder/Typography';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { columnNames, Stages } from 'erp/recruitment/recruitingBoard/constants';
import useVacancyGroupService from 'erp/recruitment/newVacancy/useVacancyGroupService';
import LoadingDecorator from 'shared/uibuilder/LoadingDecorator';
import { EMPTY_VALUE_INDICATOR } from 'shared/uibuilder/helper';
import { CROP_SIZES } from 'artifact/const';
import useArtifactThumbnailService from 'artifact/artifactThumbnailService';
import { useVacancyGroupUrl } from 'erp/recruitment/newVacancy/routing';
import Box from 'uibuilder/Box';
import { LinkField } from 'shared/uibuilder/field';
import CandidateView from '../candidateView/CandidateView';
import CandidateCard from './CandidateCard';
import _ from 'lodash';
import useCandidateApplicationFromExtensionService from 'erp/candidate/applications/shared/candidateApplicationFromExtensionService';
import ShowPageLayout from 'shared/layout/ShowPageLayout';
import ShowSection from 'shared/uibuilder/layout/ShowSection';
import ApplyToVacancyForm from 'erp/recruitment/extension/ApplyToVacancyForm';
import CandidateVacancyRejectForm from '../candidateView/CandidateVacancyRejectForm/CandidateVacancyRejectForm';

const getPossibleLinkedInLinks = (linkedInId: string) => [
  linkedInId,
  `https://linkedin.com/in/${linkedInId}`,
  `https://linkedin.com/in/${linkedInId}/`,
  `https://www.linkedin.com/in/${linkedInId}`,
  `https://www.linkedin.com/in/${linkedInId}/`,
  `linkedin.com/in/${linkedInId}`,
  `linkedin.com/in/${linkedInId}/`,
  `www.linkedin.com/in/${linkedInId}`,
  `www.linkedin.com/in/${linkedInId}/`,
];

export const renderValue = (value: any) => value ?? EMPTY_VALUE_INDICATOR;

const CustomStepIcon = ({ active, completed, error }: StepIconProps) => {
  const getColor = () => {
    switch (true) {
      case completed:
        return 'primary.main';
      case error:
        return 'error.main';
      case active:
        return '#5954A8B2';
      default:
        return 'grey.400';
    }
  };

  return (
    <SvgIcon
      sx={{
        width: 15,
        height: 15,
        color: getColor(),
        margin: 0.5,
      }}
    >
      <circle cx="14" cy="10" r="9" />
    </SvgIcon>
  );
};
const ApplicationStatusStepper = ({
  activeStep,
  rejectedStep,
  steps,
}: {
  activeStep: string;
  rejectedStep: string;
  steps: string[];
}) => {
  let stepIndex = steps.indexOf(activeStep);
  if (stepIndex === -1) {
    stepIndex = steps.indexOf(rejectedStep);
  }

  return (
    <Stepper activeStep={stepIndex} alternativeLabel>
      {steps.map(step => (
        <Step
          key={step}
          sx={{
            p: 0,
            '& .MuiStepLabel-iconContainer': {
              pr: 0,
            },
          }}
        >
          <StepLabel
            slots={{
              stepIcon: CustomStepIcon,
            }}
            error={rejectedStep === step}
            optional={
              rejectedStep === step ? (
                <Typography variant="caption" color="error">
                  Rejected
                </Typography>
              ) : null
            }
          >
            <Typography variant="subtitle1">{step}</Typography>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const CandidateContextPage = () => {
  const linkedInId = useParams<Dictionary<string>>().linkedInId!;
  const { search: searchCandidates } = useCandidateService();
  const { search: searchVacancyGroup } = useVacancyGroupService();
  const { search: searchApplications } = useCrudService({
    singleResourceUrl: '/candidates/applications/:id',
    listResourceUrl: '/candidates/applications',
    apiService: useRecruitmentApi,
  });
  const { getThumbnails } = useArtifactThumbnailService();
  const { getSingleEntityUrl } = useVacancyGroupUrl();
  const { isParsingFinished, parsedProfileData } = useCandidateApplicationFromExtensionService();
  const { getDuplicates } = useCandidateService();
  const [candidate, setCandidate] = useState<any>(null);
  const [duplicates, setDuplicates] = useState([]);
  const [candidatePhoto, setCandidatePhoto] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [showVacancyReject, setShowVacancyReject] = useState<boolean>(false);

  const togleVacancyReject = () => {
    setShowVacancyReject(prev => !prev);
  };

  useEffect(() => {
    (async () => {
      const data = parsedProfileData as any;
      const isParsedCorrectly = !_.isEmpty(parsedProfileData) && data?.name?.firstName && data?.name?.lastName;
      if (isParsedCorrectly) {
        setDuplicates(await getDuplicates(parsedProfileData));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedProfileData]);

  useEffect(() => {
    const findCandidate = async () => {
      const response = await searchCandidates({
        filter: {
          linkedInLink: { in: getPossibleLinkedInLinks(linkedInId) },
        },
        sort: {
          updatedAt: 'DESC',
        },
      });

      const [candidateDto] = response.result;

      let application: any;
      try {
        [application] = (
          await searchApplications({
            filter: {
              candidateId: { eq: candidateDto.id },
            },
            sort: {
              updatedAt: 'DESC',
            },
            pageSize: 1,
          })
        ).result;
      } catch {
        application = null;
      }

      let vacancy: any;
      try {
        if (application?.vacancyGroupAlias) {
          [vacancy] = (
            await searchVacancyGroup({
              filter: {
                alias: { eq: application?.vacancyGroupAlias },
              },
              sort: { createdAt: 'DESC' },
              pageSize: 1,
            })
          ).result;
        }
      } catch {
        vacancy = null;
      }

      if (candidateDto?.mainPhotoId) {
        const thumbnailsResponse = await getThumbnails(candidateDto?.mainPhotoId);
        setCandidatePhoto(thumbnailsResponse[CROP_SIZES.SIZE_100]);
      }

      if (application) {
        setCandidate({
          ...candidateDto,
          vacancy,
          application,
        });
      }
      setLoading(false);
    };
    findCandidate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const steps = columnNames.map(column => column.name);

  const specializationTitle = () => {
    if (!candidate?.vacancy?.specialization) {
      return '';
    }

    const competencyLevelMax = candidate?.vacancy?.details.competencyLevelMax;
    const competencyLevelMin = candidate?.vacancy?.details.competencyLevelMin;
    const specialization = candidate?.vacancy?.specialization;

    return competencyLevelMax === competencyLevelMin
      ? `${competencyLevelMax} ${specialization}`
      : `${competencyLevelMin} - ${competencyLevelMax} ${specialization}`;
  };

  const applicationStage = candidate?.application?.applicationPipelineState?.stage || '';
  const rejectedFrom = candidate?.application?.applicationPipelineState?.rejectedFrom;
  const isClosedOrRejected = applicationStage === Stages.Closed || applicationStage === Stages.Rejected;

  if (!candidate?.id && !loading) {
    return (
      <CandidateView
        duplicates={duplicates}
        isParsingFinished={isParsingFinished}
        parsedProfileData={parsedProfileData}
      />
    );
  }

  return (
    <LoadingDecorator loading={loading} deps={[loading]}>
      {candidate?.id && (
        <ShowPageLayout>
          <ShowSection sx={{ mb: '10px !important', pb: '0px !important' }}>
            <CandidateCard
              candidate={candidate}
              candidatePhoto={candidatePhoto}
              showVacancyReject={showVacancyReject}
              togleVacancyReject={togleVacancyReject}
              showVacancyRejectButton
            />
          </ShowSection>
          <ShowSection>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center', p: 1, pt: 0 }}>
              {showVacancyReject ? (
                <ShowSection>
                  <CandidateVacancyRejectForm togleVacancyReject={togleVacancyReject} />
                </ShowSection>
              ) : (
                <>
                  <Box sx={{ pb: 2 }}>
                    {candidate?.vacancy?.id ? (
                      <LinkField linkText={specializationTitle()} value={getSingleEntityUrl(candidate?.vacancy?.id)} />
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        {specializationTitle()}
                      </Typography>
                    )}
                  </Box>
                  <Box width="100%">
                    <ApplicationStatusStepper activeStep={applicationStage} rejectedStep={rejectedFrom} steps={steps} />
                  </Box>
                  {isClosedOrRejected && (
                    <Box width="100%" sx={{ pt: 2 }}>
                      <ApplyToVacancyForm parsedProfileData={candidate} isParsingFinished />
                    </Box>
                  )}
                </>
              )}
            </Box>
          </ShowSection>
        </ShowPageLayout>
      )}
    </LoadingDecorator>
  );
};

export default CandidateContextPage;
