import React from 'react';
import InterviewScheduledForm from './InterviewScheduledForm';
import roleRelatedInterviewFormValidationSchema from './roleRelatedInterviewFormValidationSchema';

interface RoleRelatedInterviewScheduledFormProps {
  handleCancelClick: () => void;
}

const RoleRelatedInterviewScheduledForm = (props: RoleRelatedInterviewScheduledFormProps) => {
  return <InterviewScheduledForm validationSchema={roleRelatedInterviewFormValidationSchema} {...props} />;
};

export default RoleRelatedInterviewScheduledForm;
