// eslint-disable-next-line import/prefer-default-export
export const generatePdf = async (apiUrl: string, template: string, viewData: any): Promise<Blob> => {
  const response = await fetch(apiUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      template,
      view: viewData,
    }),
  });

  if (!response.ok) {
    throw new Error(`Failed to generate PDF: ${response.statusText}`);
  }

  return response.blob();
};
