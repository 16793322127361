// eslint-disable-next-line no-restricted-syntax
import mixpanel from 'mixpanel-browser';

export default function initMixpanel() {
  const mixpanelToken = process.env.REACT_APP_DAVINCI_FRONTEND_MIXPANEL_TOKEN;
  if (mixpanelToken) {
    mixpanel.init(mixpanelToken, {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage',
      autocapture: true,
      ip: true,
      track_marketing: true,
    });
  }
}
